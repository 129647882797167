/*!
 * @dineug/erd-editor
 * @version 3.2.7 | Fri Feb 28 2025
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
var pf = typeof global == "object" && global && global.Object === Object && global, og = typeof self == "object" && self && self.Object === Object && self, yt = pf || og || Function("return this")(), Qe = yt.Symbol, yf = Object.prototype, rg = yf.hasOwnProperty, sg = yf.toString, lr = Qe ? Qe.toStringTag : void 0;
function ig(e) {
  var t = rg.call(e, lr), n = e[lr];
  try {
    e[lr] = void 0;
    var o = !0;
  } catch {
  }
  var r = sg.call(e);
  return o && (t ? e[lr] = n : delete e[lr]), r;
}
var ag = Object.prototype, lg = ag.toString;
function cg(e) {
  return lg.call(e);
}
var ug = "[object Null]", dg = "[object Undefined]", vu = Qe ? Qe.toStringTag : void 0;
function Un(e) {
  return e == null ? e === void 0 ? dg : ug : vu && vu in Object(e) ? ig(e) : cg(e);
}
function Et(e) {
  return e != null && typeof e == "object";
}
var fg = "[object Symbol]";
function oa(e) {
  return typeof e == "symbol" || Et(e) && Un(e) == fg;
}
function Zl(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length, r = Array(o); ++n < o; )
    r[n] = t(e[n], n, e);
  return r;
}
var Be = Array.isArray, mg = 1 / 0, bu = Qe ? Qe.prototype : void 0, Iu = bu ? bu.toString : void 0;
function gf(e) {
  if (typeof e == "string")
    return e;
  if (Be(e))
    return Zl(e, gf) + "";
  if (oa(e))
    return Iu ? Iu.call(e) : "";
  var t = e + "";
  return t == "0" && 1 / e == -mg ? "-0" : t;
}
var hg = /\s/;
function pg(e) {
  for (var t = e.length; t-- && hg.test(e.charAt(t)); )
    ;
  return t;
}
var yg = /^\s+/;
function gg(e) {
  return e && e.slice(0, pg(e) + 1).replace(yg, "");
}
function dt(e) {
  var t = typeof e;
  return e != null && (t == "object" || t == "function");
}
var Tu = NaN, vg = /^[-+]0x[0-9a-f]+$/i, bg = /^0b[01]+$/i, Ig = /^0o[0-7]+$/i, Tg = parseInt;
function vf(e) {
  if (typeof e == "number")
    return e;
  if (oa(e))
    return Tu;
  if (dt(e)) {
    var t = typeof e.valueOf == "function" ? e.valueOf() : e;
    e = dt(t) ? t + "" : t;
  }
  if (typeof e != "string")
    return e === 0 ? e : +e;
  e = gg(e);
  var n = bg.test(e);
  return n || Ig.test(e) ? Tg(e.slice(2), n ? 2 : 8) : vg.test(e) ? Tu : +e;
}
var wu = 1 / 0, wg = 17976931348623157e292;
function Vs(e) {
  if (!e)
    return e === 0 ? e : 0;
  if (e = vf(e), e === wu || e === -wu) {
    var t = e < 0 ? -1 : 1;
    return t * wg;
  }
  return e === e ? e : 0;
}
function Cg(e) {
  var t = Vs(e), n = t % 1;
  return t === t ? n ? t - n : t : 0;
}
function Vl(e) {
  return e;
}
var Og = "[object AsyncFunction]", Eg = "[object Function]", Sg = "[object GeneratorFunction]", xg = "[object Proxy]";
function bf(e) {
  if (!dt(e))
    return !1;
  var t = Un(e);
  return t == Eg || t == Sg || t == Og || t == xg;
}
var Ua = yt["__core-js_shared__"], Cu = function() {
  var e = /[^.]+$/.exec(Ua && Ua.keys && Ua.keys.IE_PROTO || "");
  return e ? "Symbol(src)_1." + e : "";
}();
function Ag(e) {
  return !!Cu && Cu in e;
}
var Mg = Function.prototype, Ng = Mg.toString;
function jn(e) {
  if (e != null) {
    try {
      return Ng.call(e);
    } catch {
    }
    try {
      return e + "";
    } catch {
    }
  }
  return "";
}
var $g = /[\\^$.*+?()[\]{}|]/g, kg = /^\[object .+?Constructor\]$/, Dg = Function.prototype, Rg = Object.prototype, Lg = Dg.toString, Pg = Rg.hasOwnProperty, _g = RegExp(
  "^" + Lg.call(Pg).replace($g, "\\$&").replace(/hasOwnProperty|(function).*?(?=\\\()| for .+?(?=\\\])/g, "$1.*?") + "$"
);
function Bg(e) {
  if (!dt(e) || Ag(e))
    return !1;
  var t = bf(e) ? _g : kg;
  return t.test(jn(e));
}
function Wg(e, t) {
  return e == null ? void 0 : e[t];
}
function Fn(e, t) {
  var n = Wg(e, t);
  return Bg(n) ? n : void 0;
}
var cl = Fn(yt, "WeakMap"), Ou = Object.create, Ug = /* @__PURE__ */ function() {
  function e() {
  }
  return function(t) {
    if (!dt(t))
      return {};
    if (Ou)
      return Ou(t);
    e.prototype = t;
    var n = new e();
    return e.prototype = void 0, n;
  };
}();
function jg(e, t, n) {
  switch (n.length) {
    case 0:
      return e.call(t);
    case 1:
      return e.call(t, n[0]);
    case 2:
      return e.call(t, n[0], n[1]);
    case 3:
      return e.call(t, n[0], n[1], n[2]);
  }
  return e.apply(t, n);
}
function Fg() {
}
function qg(e, t) {
  var n = -1, o = e.length;
  for (t || (t = Array(o)); ++n < o; )
    t[n] = e[n];
  return t;
}
var Hg = 800, Zg = 16, Vg = Date.now;
function zg(e) {
  var t = 0, n = 0;
  return function() {
    var o = Vg(), r = Zg - (o - n);
    if (n = o, r > 0) {
      if (++t >= Hg)
        return arguments[0];
    } else
      t = 0;
    return e.apply(void 0, arguments);
  };
}
function Gg(e) {
  return function() {
    return e;
  };
}
var ki = function() {
  try {
    var e = Fn(Object, "defineProperty");
    return e({}, "", {}), e;
  } catch {
  }
}(), Yg = ki ? function(e, t) {
  return ki(e, "toString", {
    configurable: !0,
    enumerable: !1,
    value: Gg(t),
    writable: !0
  });
} : Vl;
const Kg = Yg;
var If = zg(Kg);
function Qg(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length; ++n < o && t(e[n], n, e) !== !1; )
    ;
  return e;
}
function Jg(e, t, n, o) {
  for (var r = e.length, s = n + (o ? 1 : -1); o ? s-- : ++s < r; )
    if (t(e[s], s, e))
      return s;
  return -1;
}
function Xg(e) {
  return e !== e;
}
function ev(e, t, n) {
  for (var o = n - 1, r = e.length; ++o < r; )
    if (e[o] === t)
      return o;
  return -1;
}
function tv(e, t, n) {
  return t === t ? ev(e, t, n) : Jg(e, Xg, n);
}
function Tf(e, t) {
  var n = e == null ? 0 : e.length;
  return !!n && tv(e, t, 0) > -1;
}
var nv = 9007199254740991, ov = /^(?:0|[1-9]\d*)$/;
function ra(e, t) {
  var n = typeof e;
  return t = t ?? nv, !!t && (n == "number" || n != "symbol" && ov.test(e)) && e > -1 && e % 1 == 0 && e < t;
}
function zl(e, t, n) {
  t == "__proto__" && ki ? ki(e, t, {
    configurable: !0,
    enumerable: !0,
    value: n,
    writable: !0
  }) : e[t] = n;
}
function sa(e, t) {
  return e === t || e !== e && t !== t;
}
var rv = Object.prototype, sv = rv.hasOwnProperty;
function Gl(e, t, n) {
  var o = e[t];
  (!(sv.call(e, t) && sa(o, n)) || n === void 0 && !(t in e)) && zl(e, t, n);
}
function is(e, t, n, o) {
  var r = !n;
  n || (n = {});
  for (var s = -1, i = t.length; ++s < i; ) {
    var a = t[s], l = o ? o(n[a], e[a], a, n, e) : void 0;
    l === void 0 && (l = e[a]), r ? zl(n, a, l) : Gl(n, a, l);
  }
  return n;
}
var Eu = Math.max;
function wf(e, t, n) {
  return t = Eu(t === void 0 ? e.length - 1 : t, 0), function() {
    for (var o = arguments, r = -1, s = Eu(o.length - t, 0), i = Array(s); ++r < s; )
      i[r] = o[t + r];
    r = -1;
    for (var a = Array(t + 1); ++r < t; )
      a[r] = o[r];
    return a[t] = n(i), jg(e, this, a);
  };
}
function iv(e, t) {
  return If(wf(e, t, Vl), e + "");
}
var av = 9007199254740991;
function Yl(e) {
  return typeof e == "number" && e > -1 && e % 1 == 0 && e <= av;
}
function Wo(e) {
  return e != null && Yl(e.length) && !bf(e);
}
function lv(e, t, n) {
  if (!dt(n))
    return !1;
  var o = typeof t;
  return (o == "number" ? Wo(n) && ra(t, n.length) : o == "string" && t in n) ? sa(n[t], e) : !1;
}
var cv = Object.prototype;
function ia(e) {
  var t = e && e.constructor, n = typeof t == "function" && t.prototype || cv;
  return e === n;
}
function uv(e, t) {
  for (var n = -1, o = Array(e); ++n < e; )
    o[n] = t(n);
  return o;
}
var dv = "[object Arguments]";
function Su(e) {
  return Et(e) && Un(e) == dv;
}
var Cf = Object.prototype, fv = Cf.hasOwnProperty, mv = Cf.propertyIsEnumerable, hv = Su(/* @__PURE__ */ function() {
  return arguments;
}()) ? Su : function(e) {
  return Et(e) && fv.call(e, "callee") && !mv.call(e, "callee");
};
const aa = hv;
function pv() {
  return !1;
}
var Of = typeof exports == "object" && exports && !exports.nodeType && exports, xu = Of && typeof module == "object" && module && !module.nodeType && module, yv = xu && xu.exports === Of, Au = yv ? yt.Buffer : void 0, gv = Au ? Au.isBuffer : void 0, vv = gv || pv;
const Kr = vv;
var bv = "[object Arguments]", Iv = "[object Array]", Tv = "[object Boolean]", wv = "[object Date]", Cv = "[object Error]", Ov = "[object Function]", Ev = "[object Map]", Sv = "[object Number]", xv = "[object Object]", Av = "[object RegExp]", Mv = "[object Set]", Nv = "[object String]", $v = "[object WeakMap]", kv = "[object ArrayBuffer]", Dv = "[object DataView]", Rv = "[object Float32Array]", Lv = "[object Float64Array]", Pv = "[object Int8Array]", _v = "[object Int16Array]", Bv = "[object Int32Array]", Wv = "[object Uint8Array]", Uv = "[object Uint8ClampedArray]", jv = "[object Uint16Array]", Fv = "[object Uint32Array]", ee = {};
ee[Rv] = ee[Lv] = ee[Pv] = ee[_v] = ee[Bv] = ee[Wv] = ee[Uv] = ee[jv] = ee[Fv] = !0;
ee[bv] = ee[Iv] = ee[kv] = ee[Tv] = ee[Dv] = ee[wv] = ee[Cv] = ee[Ov] = ee[Ev] = ee[Sv] = ee[xv] = ee[Av] = ee[Mv] = ee[Nv] = ee[$v] = !1;
function qv(e) {
  return Et(e) && Yl(e.length) && !!ee[Un(e)];
}
function la(e) {
  return function(t) {
    return e(t);
  };
}
var Ef = typeof exports == "object" && exports && !exports.nodeType && exports, Fr = Ef && typeof module == "object" && module && !module.nodeType && module, Hv = Fr && Fr.exports === Ef, ja = Hv && pf.process, Mo = function() {
  try {
    var e = Fr && Fr.require && Fr.require("util").types;
    return e || ja && ja.binding && ja.binding("util");
  } catch {
  }
}(), Mu = Mo && Mo.isTypedArray, Zv = Mu ? la(Mu) : qv;
const Kl = Zv;
var Vv = Object.prototype, zv = Vv.hasOwnProperty;
function Sf(e, t) {
  var n = Be(e), o = !n && aa(e), r = !n && !o && Kr(e), s = !n && !o && !r && Kl(e), i = n || o || r || s, a = i ? uv(e.length, String) : [], l = a.length;
  for (var c in e)
    (t || zv.call(e, c)) && !(i && // Safari 9 has enumerable `arguments.length` in strict mode.
    (c == "length" || // Node.js 0.10 has enumerable non-index properties on buffers.
    r && (c == "offset" || c == "parent") || // PhantomJS 2 has enumerable non-index properties on typed arrays.
    s && (c == "buffer" || c == "byteLength" || c == "byteOffset") || // Skip index properties.
    ra(c, l))) && a.push(c);
  return a;
}
function xf(e, t) {
  return function(n) {
    return e(t(n));
  };
}
var Gv = xf(Object.keys, Object), Yv = Object.prototype, Kv = Yv.hasOwnProperty;
function Af(e) {
  if (!ia(e))
    return Gv(e);
  var t = [];
  for (var n in Object(e))
    Kv.call(e, n) && n != "constructor" && t.push(n);
  return t;
}
function as(e) {
  return Wo(e) ? Sf(e) : Af(e);
}
function Qv(e) {
  var t = [];
  if (e != null)
    for (var n in Object(e))
      t.push(n);
  return t;
}
var Jv = Object.prototype, Xv = Jv.hasOwnProperty;
function eb(e) {
  if (!dt(e))
    return Qv(e);
  var t = ia(e), n = [];
  for (var o in e)
    o == "constructor" && (t || !Xv.call(e, o)) || n.push(o);
  return n;
}
function Ql(e) {
  return Wo(e) ? Sf(e, !0) : eb(e);
}
var tb = /\.|\[(?:[^[\]]*|(["'])(?:(?!\1)[^\\]|\\.)*?\1)\]/, nb = /^\w*$/;
function Jl(e, t) {
  if (Be(e))
    return !1;
  var n = typeof e;
  return n == "number" || n == "symbol" || n == "boolean" || e == null || oa(e) ? !0 : nb.test(e) || !tb.test(e) || t != null && e in Object(t);
}
var Qr = Fn(Object, "create");
function ob() {
  this.__data__ = Qr ? Qr(null) : {}, this.size = 0;
}
function rb(e) {
  var t = this.has(e) && delete this.__data__[e];
  return this.size -= t ? 1 : 0, t;
}
var sb = "__lodash_hash_undefined__", ib = Object.prototype, ab = ib.hasOwnProperty;
function lb(e) {
  var t = this.__data__;
  if (Qr) {
    var n = t[e];
    return n === sb ? void 0 : n;
  }
  return ab.call(t, e) ? t[e] : void 0;
}
var cb = Object.prototype, ub = cb.hasOwnProperty;
function db(e) {
  var t = this.__data__;
  return Qr ? t[e] !== void 0 : ub.call(t, e);
}
var fb = "__lodash_hash_undefined__";
function mb(e, t) {
  var n = this.__data__;
  return this.size += this.has(e) ? 0 : 1, n[e] = Qr && t === void 0 ? fb : t, this;
}
function Pn(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
Pn.prototype.clear = ob;
Pn.prototype.delete = rb;
Pn.prototype.get = lb;
Pn.prototype.has = db;
Pn.prototype.set = mb;
function hb() {
  this.__data__ = [], this.size = 0;
}
function ca(e, t) {
  for (var n = e.length; n--; )
    if (sa(e[n][0], t))
      return n;
  return -1;
}
var pb = Array.prototype, yb = pb.splice;
function gb(e) {
  var t = this.__data__, n = ca(t, e);
  if (n < 0)
    return !1;
  var o = t.length - 1;
  return n == o ? t.pop() : yb.call(t, n, 1), --this.size, !0;
}
function vb(e) {
  var t = this.__data__, n = ca(t, e);
  return n < 0 ? void 0 : t[n][1];
}
function bb(e) {
  return ca(this.__data__, e) > -1;
}
function Ib(e, t) {
  var n = this.__data__, o = ca(n, e);
  return o < 0 ? (++this.size, n.push([e, t])) : n[o][1] = t, this;
}
function Ht(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
Ht.prototype.clear = hb;
Ht.prototype.delete = gb;
Ht.prototype.get = vb;
Ht.prototype.has = bb;
Ht.prototype.set = Ib;
var Jr = Fn(yt, "Map");
function Tb() {
  this.size = 0, this.__data__ = {
    hash: new Pn(),
    map: new (Jr || Ht)(),
    string: new Pn()
  };
}
function wb(e) {
  var t = typeof e;
  return t == "string" || t == "number" || t == "symbol" || t == "boolean" ? e !== "__proto__" : e === null;
}
function ua(e, t) {
  var n = e.__data__;
  return wb(t) ? n[typeof t == "string" ? "string" : "hash"] : n.map;
}
function Cb(e) {
  var t = ua(this, e).delete(e);
  return this.size -= t ? 1 : 0, t;
}
function Ob(e) {
  return ua(this, e).get(e);
}
function Eb(e) {
  return ua(this, e).has(e);
}
function Sb(e, t) {
  var n = ua(this, e), o = n.size;
  return n.set(e, t), this.size += n.size == o ? 0 : 1, this;
}
function Zt(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.clear(); ++t < n; ) {
    var o = e[t];
    this.set(o[0], o[1]);
  }
}
Zt.prototype.clear = Tb;
Zt.prototype.delete = Cb;
Zt.prototype.get = Ob;
Zt.prototype.has = Eb;
Zt.prototype.set = Sb;
var xb = "Expected a function";
function Xl(e, t) {
  if (typeof e != "function" || t != null && typeof t != "function")
    throw new TypeError(xb);
  var n = function() {
    var o = arguments, r = t ? t.apply(this, o) : o[0], s = n.cache;
    if (s.has(r))
      return s.get(r);
    var i = e.apply(this, o);
    return n.cache = s.set(r, i) || s, i;
  };
  return n.cache = new (Xl.Cache || Zt)(), n;
}
Xl.Cache = Zt;
var Ab = 500;
function Mb(e) {
  var t = Xl(e, function(o) {
    return n.size === Ab && n.clear(), o;
  }), n = t.cache;
  return t;
}
var Nb = /[^.[\]]+|\[(?:(-?\d+(?:\.\d+)?)|(["'])((?:(?!\2)[^\\]|\\.)*?)\2)\]|(?=(?:\.|\[\])(?:\.|\[\]|$))/g, $b = /\\(\\)?/g, kb = Mb(function(e) {
  var t = [];
  return e.charCodeAt(0) === 46 && t.push(""), e.replace(Nb, function(n, o, r, s) {
    t.push(r ? s.replace($b, "$1") : o || n);
  }), t;
});
function _n(e) {
  return e == null ? "" : gf(e);
}
function Uo(e, t) {
  return Be(e) ? e : Jl(e, t) ? [e] : kb(_n(e));
}
var Db = 1 / 0;
function jo(e) {
  if (typeof e == "string" || oa(e))
    return e;
  var t = e + "";
  return t == "0" && 1 / e == -Db ? "-0" : t;
}
function da(e, t) {
  t = Uo(t, e);
  for (var n = 0, o = t.length; e != null && n < o; )
    e = e[jo(t[n++])];
  return n && n == o ? e : void 0;
}
function Rb(e, t, n) {
  var o = e == null ? void 0 : da(e, t);
  return o === void 0 ? n : o;
}
function ec(e, t) {
  for (var n = -1, o = t.length, r = e.length; ++n < o; )
    e[r + n] = t[n];
  return e;
}
var Nu = Qe ? Qe.isConcatSpreadable : void 0;
function Lb(e) {
  return Be(e) || aa(e) || !!(Nu && e && e[Nu]);
}
function tc(e, t, n, o, r) {
  var s = -1, i = e.length;
  for (n || (n = Lb), r || (r = []); ++s < i; ) {
    var a = e[s];
    t > 0 && n(a) ? t > 1 ? tc(a, t - 1, n, o, r) : ec(r, a) : o || (r[r.length] = a);
  }
  return r;
}
function Pb(e) {
  var t = e == null ? 0 : e.length;
  return t ? tc(e, 1) : [];
}
function Mf(e) {
  return If(wf(e, void 0, Pb), e + "");
}
var _b = xf(Object.getPrototypeOf, Object);
const nc = _b;
var Bb = "[object Object]", Wb = Function.prototype, Ub = Object.prototype, Nf = Wb.toString, jb = Ub.hasOwnProperty, Fb = Nf.call(Object);
function qb(e) {
  if (!Et(e) || Un(e) != Bb)
    return !1;
  var t = nc(e);
  if (t === null)
    return !0;
  var n = jb.call(t, "constructor") && t.constructor;
  return typeof n == "function" && n instanceof n && Nf.call(n) == Fb;
}
function $f(e, t, n) {
  var o = -1, r = e.length;
  t < 0 && (t = -t > r ? 0 : r + t), n = n > r ? r : n, n < 0 && (n += r), r = t > n ? 0 : n - t >>> 0, t >>>= 0;
  for (var s = Array(r); ++o < r; )
    s[o] = e[o + t];
  return s;
}
function Hb(e, t, n) {
  var o = e.length;
  return n = n === void 0 ? o : n, !t && n >= o ? e : $f(e, t, n);
}
var Zb = "\\ud800-\\udfff", Vb = "\\u0300-\\u036f", zb = "\\ufe20-\\ufe2f", Gb = "\\u20d0-\\u20ff", Yb = Vb + zb + Gb, Kb = "\\ufe0e\\ufe0f", Qb = "\\u200d", Jb = RegExp("[" + Qb + Zb + Yb + Kb + "]");
function kf(e) {
  return Jb.test(e);
}
function Xb(e) {
  return e.split("");
}
var Df = "\\ud800-\\udfff", eI = "\\u0300-\\u036f", tI = "\\ufe20-\\ufe2f", nI = "\\u20d0-\\u20ff", oI = eI + tI + nI, rI = "\\ufe0e\\ufe0f", sI = "[" + Df + "]", ul = "[" + oI + "]", dl = "\\ud83c[\\udffb-\\udfff]", iI = "(?:" + ul + "|" + dl + ")", Rf = "[^" + Df + "]", Lf = "(?:\\ud83c[\\udde6-\\uddff]){2}", Pf = "[\\ud800-\\udbff][\\udc00-\\udfff]", aI = "\\u200d", _f = iI + "?", Bf = "[" + rI + "]?", lI = "(?:" + aI + "(?:" + [Rf, Lf, Pf].join("|") + ")" + Bf + _f + ")*", cI = Bf + _f + lI, uI = "(?:" + [Rf + ul + "?", ul, Lf, Pf, sI].join("|") + ")", dI = RegExp(dl + "(?=" + dl + ")|" + uI + cI, "g");
function fI(e) {
  return e.match(dI) || [];
}
function mI(e) {
  return kf(e) ? fI(e) : Xb(e);
}
function hI(e) {
  return function(t) {
    t = _n(t);
    var n = kf(t) ? mI(t) : void 0, o = n ? n[0] : t.charAt(0), r = n ? Hb(n, 1).join("") : t.slice(1);
    return o[e]() + r;
  };
}
var Wf = hI("toUpperCase");
function pI(e) {
  return Wf(_n(e).toLowerCase());
}
function yI(e, t, n, o) {
  var r = -1, s = e == null ? 0 : e.length;
  for (o && s && (n = e[++r]); ++r < s; )
    n = t(n, e[r], r, e);
  return n;
}
function gI(e) {
  return function(t) {
    return e == null ? void 0 : e[t];
  };
}
var vI = {
  // Latin-1 Supplement block.
  À: "A",
  Á: "A",
  Â: "A",
  Ã: "A",
  Ä: "A",
  Å: "A",
  à: "a",
  á: "a",
  â: "a",
  ã: "a",
  ä: "a",
  å: "a",
  Ç: "C",
  ç: "c",
  Ð: "D",
  ð: "d",
  È: "E",
  É: "E",
  Ê: "E",
  Ë: "E",
  è: "e",
  é: "e",
  ê: "e",
  ë: "e",
  Ì: "I",
  Í: "I",
  Î: "I",
  Ï: "I",
  ì: "i",
  í: "i",
  î: "i",
  ï: "i",
  Ñ: "N",
  ñ: "n",
  Ò: "O",
  Ó: "O",
  Ô: "O",
  Õ: "O",
  Ö: "O",
  Ø: "O",
  ò: "o",
  ó: "o",
  ô: "o",
  õ: "o",
  ö: "o",
  ø: "o",
  Ù: "U",
  Ú: "U",
  Û: "U",
  Ü: "U",
  ù: "u",
  ú: "u",
  û: "u",
  ü: "u",
  Ý: "Y",
  ý: "y",
  ÿ: "y",
  Æ: "Ae",
  æ: "ae",
  Þ: "Th",
  þ: "th",
  ß: "ss",
  // Latin Extended-A block.
  Ā: "A",
  Ă: "A",
  Ą: "A",
  ā: "a",
  ă: "a",
  ą: "a",
  Ć: "C",
  Ĉ: "C",
  Ċ: "C",
  Č: "C",
  ć: "c",
  ĉ: "c",
  ċ: "c",
  č: "c",
  Ď: "D",
  Đ: "D",
  ď: "d",
  đ: "d",
  Ē: "E",
  Ĕ: "E",
  Ė: "E",
  Ę: "E",
  Ě: "E",
  ē: "e",
  ĕ: "e",
  ė: "e",
  ę: "e",
  ě: "e",
  Ĝ: "G",
  Ğ: "G",
  Ġ: "G",
  Ģ: "G",
  ĝ: "g",
  ğ: "g",
  ġ: "g",
  ģ: "g",
  Ĥ: "H",
  Ħ: "H",
  ĥ: "h",
  ħ: "h",
  Ĩ: "I",
  Ī: "I",
  Ĭ: "I",
  Į: "I",
  İ: "I",
  ĩ: "i",
  ī: "i",
  ĭ: "i",
  į: "i",
  ı: "i",
  Ĵ: "J",
  ĵ: "j",
  Ķ: "K",
  ķ: "k",
  ĸ: "k",
  Ĺ: "L",
  Ļ: "L",
  Ľ: "L",
  Ŀ: "L",
  Ł: "L",
  ĺ: "l",
  ļ: "l",
  ľ: "l",
  ŀ: "l",
  ł: "l",
  Ń: "N",
  Ņ: "N",
  Ň: "N",
  Ŋ: "N",
  ń: "n",
  ņ: "n",
  ň: "n",
  ŋ: "n",
  Ō: "O",
  Ŏ: "O",
  Ő: "O",
  ō: "o",
  ŏ: "o",
  ő: "o",
  Ŕ: "R",
  Ŗ: "R",
  Ř: "R",
  ŕ: "r",
  ŗ: "r",
  ř: "r",
  Ś: "S",
  Ŝ: "S",
  Ş: "S",
  Š: "S",
  ś: "s",
  ŝ: "s",
  ş: "s",
  š: "s",
  Ţ: "T",
  Ť: "T",
  Ŧ: "T",
  ţ: "t",
  ť: "t",
  ŧ: "t",
  Ũ: "U",
  Ū: "U",
  Ŭ: "U",
  Ů: "U",
  Ű: "U",
  Ų: "U",
  ũ: "u",
  ū: "u",
  ŭ: "u",
  ů: "u",
  ű: "u",
  ų: "u",
  Ŵ: "W",
  ŵ: "w",
  Ŷ: "Y",
  ŷ: "y",
  Ÿ: "Y",
  Ź: "Z",
  Ż: "Z",
  Ž: "Z",
  ź: "z",
  ż: "z",
  ž: "z",
  Ĳ: "IJ",
  ĳ: "ij",
  Œ: "Oe",
  œ: "oe",
  ŉ: "'n",
  ſ: "s"
}, bI = gI(vI);
const II = bI;
var TI = /[\xc0-\xd6\xd8-\xf6\xf8-\xff\u0100-\u017f]/g, wI = "\\u0300-\\u036f", CI = "\\ufe20-\\ufe2f", OI = "\\u20d0-\\u20ff", EI = wI + CI + OI, SI = "[" + EI + "]", xI = RegExp(SI, "g");
function AI(e) {
  return e = _n(e), e && e.replace(TI, II).replace(xI, "");
}
var MI = /[^\x00-\x2f\x3a-\x40\x5b-\x60\x7b-\x7f]+/g;
function NI(e) {
  return e.match(MI) || [];
}
var $I = /[a-z][A-Z]|[A-Z]{2}[a-z]|[0-9][a-zA-Z]|[a-zA-Z][0-9]|[^a-zA-Z0-9 ]/;
function kI(e) {
  return $I.test(e);
}
var Uf = "\\ud800-\\udfff", DI = "\\u0300-\\u036f", RI = "\\ufe20-\\ufe2f", LI = "\\u20d0-\\u20ff", PI = DI + RI + LI, jf = "\\u2700-\\u27bf", Ff = "a-z\\xdf-\\xf6\\xf8-\\xff", _I = "\\xac\\xb1\\xd7\\xf7", BI = "\\x00-\\x2f\\x3a-\\x40\\x5b-\\x60\\x7b-\\xbf", WI = "\\u2000-\\u206f", UI = " \\t\\x0b\\f\\xa0\\ufeff\\n\\r\\u2028\\u2029\\u1680\\u180e\\u2000\\u2001\\u2002\\u2003\\u2004\\u2005\\u2006\\u2007\\u2008\\u2009\\u200a\\u202f\\u205f\\u3000", qf = "A-Z\\xc0-\\xd6\\xd8-\\xde", jI = "\\ufe0e\\ufe0f", Hf = _I + BI + WI + UI, Zf = "['’]", $u = "[" + Hf + "]", FI = "[" + PI + "]", Vf = "\\d+", qI = "[" + jf + "]", zf = "[" + Ff + "]", Gf = "[^" + Uf + Hf + Vf + jf + Ff + qf + "]", HI = "\\ud83c[\\udffb-\\udfff]", ZI = "(?:" + FI + "|" + HI + ")", VI = "[^" + Uf + "]", Yf = "(?:\\ud83c[\\udde6-\\uddff]){2}", Kf = "[\\ud800-\\udbff][\\udc00-\\udfff]", to = "[" + qf + "]", zI = "\\u200d", ku = "(?:" + zf + "|" + Gf + ")", GI = "(?:" + to + "|" + Gf + ")", Du = "(?:" + Zf + "(?:d|ll|m|re|s|t|ve))?", Ru = "(?:" + Zf + "(?:D|LL|M|RE|S|T|VE))?", Qf = ZI + "?", Jf = "[" + jI + "]?", YI = "(?:" + zI + "(?:" + [VI, Yf, Kf].join("|") + ")" + Jf + Qf + ")*", KI = "\\d*(?:1st|2nd|3rd|(?![123])\\dth)(?=\\b|[A-Z_])", QI = "\\d*(?:1ST|2ND|3RD|(?![123])\\dTH)(?=\\b|[a-z_])", JI = Jf + Qf + YI, XI = "(?:" + [qI, Yf, Kf].join("|") + ")" + JI, e0 = RegExp([
  to + "?" + zf + "+" + Du + "(?=" + [$u, to, "$"].join("|") + ")",
  GI + "+" + Ru + "(?=" + [$u, to + ku, "$"].join("|") + ")",
  to + "?" + ku + "+" + Du,
  to + "+" + Ru,
  QI,
  KI,
  Vf,
  XI
].join("|"), "g");
function t0(e) {
  return e.match(e0) || [];
}
function n0(e, t, n) {
  return e = _n(e), t = n ? void 0 : t, t === void 0 ? kI(e) ? t0(e) : NI(e) : e.match(t) || [];
}
var o0 = "['’]", r0 = RegExp(o0, "g");
function s0(e) {
  return function(t) {
    return yI(n0(AI(t).replace(r0, "")), e, "");
  };
}
var i0 = s0(function(e, t, n) {
  return t = t.toLowerCase(), e + (n ? pI(t) : t);
}), a0 = yt.isFinite, l0 = Math.min;
function c0(e) {
  var t = Math[e];
  return function(n, o) {
    if (n = vf(n), o = o == null ? 0 : l0(Cg(o), 292), o && a0(n)) {
      var r = (_n(n) + "e").split("e"), s = t(r[0] + "e" + (+r[1] + o));
      return r = (_n(s) + "e").split("e"), +(r[0] + "e" + (+r[1] - o));
    }
    return t(n);
  };
}
function u0() {
  this.__data__ = new Ht(), this.size = 0;
}
function d0(e) {
  var t = this.__data__, n = t.delete(e);
  return this.size = t.size, n;
}
function f0(e) {
  return this.__data__.get(e);
}
function m0(e) {
  return this.__data__.has(e);
}
var h0 = 200;
function p0(e, t) {
  var n = this.__data__;
  if (n instanceof Ht) {
    var o = n.__data__;
    if (!Jr || o.length < h0 - 1)
      return o.push([e, t]), this.size = ++n.size, this;
    n = this.__data__ = new Zt(o);
  }
  return n.set(e, t), this.size = n.size, this;
}
function Ot(e) {
  var t = this.__data__ = new Ht(e);
  this.size = t.size;
}
Ot.prototype.clear = u0;
Ot.prototype.delete = d0;
Ot.prototype.get = f0;
Ot.prototype.has = m0;
Ot.prototype.set = p0;
function y0(e, t) {
  return e && is(t, as(t), e);
}
function g0(e, t) {
  return e && is(t, Ql(t), e);
}
var Xf = typeof exports == "object" && exports && !exports.nodeType && exports, Lu = Xf && typeof module == "object" && module && !module.nodeType && module, v0 = Lu && Lu.exports === Xf, Pu = v0 ? yt.Buffer : void 0, _u = Pu ? Pu.allocUnsafe : void 0;
function b0(e, t) {
  if (t)
    return e.slice();
  var n = e.length, o = _u ? _u(n) : new e.constructor(n);
  return e.copy(o), o;
}
function I0(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length, r = 0, s = []; ++n < o; ) {
    var i = e[n];
    t(i, n, e) && (s[r++] = i);
  }
  return s;
}
function em() {
  return [];
}
var T0 = Object.prototype, w0 = T0.propertyIsEnumerable, Bu = Object.getOwnPropertySymbols, oc = Bu ? function(e) {
  return e == null ? [] : (e = Object(e), I0(Bu(e), function(t) {
    return w0.call(e, t);
  }));
} : em;
function C0(e, t) {
  return is(e, oc(e), t);
}
var O0 = Object.getOwnPropertySymbols, tm = O0 ? function(e) {
  for (var t = []; e; )
    ec(t, oc(e)), e = nc(e);
  return t;
} : em;
function E0(e, t) {
  return is(e, tm(e), t);
}
function nm(e, t, n) {
  var o = t(e);
  return Be(e) ? o : ec(o, n(e));
}
function fl(e) {
  return nm(e, as, oc);
}
function om(e) {
  return nm(e, Ql, tm);
}
var ml = Fn(yt, "DataView"), hl = Fn(yt, "Promise"), go = Fn(yt, "Set"), Wu = "[object Map]", S0 = "[object Object]", Uu = "[object Promise]", ju = "[object Set]", Fu = "[object WeakMap]", qu = "[object DataView]", x0 = jn(ml), A0 = jn(Jr), M0 = jn(hl), N0 = jn(go), $0 = jn(cl), vn = Un;
(ml && vn(new ml(new ArrayBuffer(1))) != qu || Jr && vn(new Jr()) != Wu || hl && vn(hl.resolve()) != Uu || go && vn(new go()) != ju || cl && vn(new cl()) != Fu) && (vn = function(e) {
  var t = Un(e), n = t == S0 ? e.constructor : void 0, o = n ? jn(n) : "";
  if (o)
    switch (o) {
      case x0:
        return qu;
      case A0:
        return Wu;
      case M0:
        return Uu;
      case N0:
        return ju;
      case $0:
        return Fu;
    }
  return t;
});
const No = vn;
var k0 = Object.prototype, D0 = k0.hasOwnProperty;
function R0(e) {
  var t = e.length, n = new e.constructor(t);
  return t && typeof e[0] == "string" && D0.call(e, "index") && (n.index = e.index, n.input = e.input), n;
}
var Di = yt.Uint8Array;
function rc(e) {
  var t = new e.constructor(e.byteLength);
  return new Di(t).set(new Di(e)), t;
}
function L0(e, t) {
  var n = t ? rc(e.buffer) : e.buffer;
  return new e.constructor(n, e.byteOffset, e.byteLength);
}
var P0 = /\w*$/;
function _0(e) {
  var t = new e.constructor(e.source, P0.exec(e));
  return t.lastIndex = e.lastIndex, t;
}
var Hu = Qe ? Qe.prototype : void 0, Zu = Hu ? Hu.valueOf : void 0;
function B0(e) {
  return Zu ? Object(Zu.call(e)) : {};
}
function W0(e, t) {
  var n = t ? rc(e.buffer) : e.buffer;
  return new e.constructor(n, e.byteOffset, e.length);
}
var U0 = "[object Boolean]", j0 = "[object Date]", F0 = "[object Map]", q0 = "[object Number]", H0 = "[object RegExp]", Z0 = "[object Set]", V0 = "[object String]", z0 = "[object Symbol]", G0 = "[object ArrayBuffer]", Y0 = "[object DataView]", K0 = "[object Float32Array]", Q0 = "[object Float64Array]", J0 = "[object Int8Array]", X0 = "[object Int16Array]", eT = "[object Int32Array]", tT = "[object Uint8Array]", nT = "[object Uint8ClampedArray]", oT = "[object Uint16Array]", rT = "[object Uint32Array]";
function sT(e, t, n) {
  var o = e.constructor;
  switch (t) {
    case G0:
      return rc(e);
    case U0:
    case j0:
      return new o(+e);
    case Y0:
      return L0(e, n);
    case K0:
    case Q0:
    case J0:
    case X0:
    case eT:
    case tT:
    case nT:
    case oT:
    case rT:
      return W0(e, n);
    case F0:
      return new o();
    case q0:
    case V0:
      return new o(e);
    case H0:
      return _0(e);
    case Z0:
      return new o();
    case z0:
      return B0(e);
  }
}
function iT(e) {
  return typeof e.constructor == "function" && !ia(e) ? Ug(nc(e)) : {};
}
var aT = "[object Map]";
function lT(e) {
  return Et(e) && No(e) == aT;
}
var Vu = Mo && Mo.isMap, cT = Vu ? la(Vu) : lT, uT = "[object Set]";
function dT(e) {
  return Et(e) && No(e) == uT;
}
var zu = Mo && Mo.isSet, fT = zu ? la(zu) : dT, mT = 1, hT = 2, pT = 4, rm = "[object Arguments]", yT = "[object Array]", gT = "[object Boolean]", vT = "[object Date]", bT = "[object Error]", sm = "[object Function]", IT = "[object GeneratorFunction]", TT = "[object Map]", wT = "[object Number]", im = "[object Object]", CT = "[object RegExp]", OT = "[object Set]", ET = "[object String]", ST = "[object Symbol]", xT = "[object WeakMap]", AT = "[object ArrayBuffer]", MT = "[object DataView]", NT = "[object Float32Array]", $T = "[object Float64Array]", kT = "[object Int8Array]", DT = "[object Int16Array]", RT = "[object Int32Array]", LT = "[object Uint8Array]", PT = "[object Uint8ClampedArray]", _T = "[object Uint16Array]", BT = "[object Uint32Array]", K = {};
K[rm] = K[yT] = K[AT] = K[MT] = K[gT] = K[vT] = K[NT] = K[$T] = K[kT] = K[DT] = K[RT] = K[TT] = K[wT] = K[im] = K[CT] = K[OT] = K[ET] = K[ST] = K[LT] = K[PT] = K[_T] = K[BT] = !0;
K[bT] = K[sm] = K[xT] = !1;
function qr(e, t, n, o, r, s) {
  var i, a = t & mT, l = t & hT, c = t & pT;
  if (n && (i = r ? n(e, o, r, s) : n(e)), i !== void 0)
    return i;
  if (!dt(e))
    return e;
  var u = Be(e);
  if (u) {
    if (i = R0(e), !a)
      return qg(e, i);
  } else {
    var d = No(e), f = d == sm || d == IT;
    if (Kr(e))
      return b0(e, a);
    if (d == im || d == rm || f && !r) {
      if (i = l || f ? {} : iT(e), !a)
        return l ? E0(e, g0(i, e)) : C0(e, y0(i, e));
    } else {
      if (!K[d])
        return r ? e : {};
      i = sT(e, d, a);
    }
  }
  s || (s = new Ot());
  var m = s.get(e);
  if (m)
    return m;
  s.set(e, i), fT(e) ? e.forEach(function(v) {
    i.add(qr(v, t, n, v, e, s));
  }) : cT(e) && e.forEach(function(v, T) {
    i.set(T, qr(v, t, n, T, e, s));
  });
  var p = c ? l ? om : fl : l ? Ql : as, g = u ? void 0 : p(e);
  return Qg(g || e, function(v, T) {
    g && (T = v, v = e[T]), Gl(i, T, qr(v, t, n, T, e, s));
  }), i;
}
var WT = 1, UT = 4;
function fa(e) {
  return qr(e, WT | UT);
}
var jT = "__lodash_hash_undefined__";
function FT(e) {
  return this.__data__.set(e, jT), this;
}
function qT(e) {
  return this.__data__.has(e);
}
function $o(e) {
  var t = -1, n = e == null ? 0 : e.length;
  for (this.__data__ = new Zt(); ++t < n; )
    this.add(e[t]);
}
$o.prototype.add = $o.prototype.push = FT;
$o.prototype.has = qT;
function HT(e, t) {
  for (var n = -1, o = e == null ? 0 : e.length; ++n < o; )
    if (t(e[n], n, e))
      return !0;
  return !1;
}
function sc(e, t) {
  return e.has(t);
}
var ZT = 1, VT = 2;
function am(e, t, n, o, r, s) {
  var i = n & ZT, a = e.length, l = t.length;
  if (a != l && !(i && l > a))
    return !1;
  var c = s.get(e), u = s.get(t);
  if (c && u)
    return c == t && u == e;
  var d = -1, f = !0, m = n & VT ? new $o() : void 0;
  for (s.set(e, t), s.set(t, e); ++d < a; ) {
    var p = e[d], g = t[d];
    if (o)
      var v = i ? o(g, p, d, t, e, s) : o(p, g, d, e, t, s);
    if (v !== void 0) {
      if (v)
        continue;
      f = !1;
      break;
    }
    if (m) {
      if (!HT(t, function(T, O) {
        if (!sc(m, O) && (p === T || r(p, T, n, o, s)))
          return m.push(O);
      })) {
        f = !1;
        break;
      }
    } else if (!(p === g || r(p, g, n, o, s))) {
      f = !1;
      break;
    }
  }
  return s.delete(e), s.delete(t), f;
}
function zT(e) {
  var t = -1, n = Array(e.size);
  return e.forEach(function(o, r) {
    n[++t] = [r, o];
  }), n;
}
function ic(e) {
  var t = -1, n = Array(e.size);
  return e.forEach(function(o) {
    n[++t] = o;
  }), n;
}
var GT = 1, YT = 2, KT = "[object Boolean]", QT = "[object Date]", JT = "[object Error]", XT = "[object Map]", ew = "[object Number]", tw = "[object RegExp]", nw = "[object Set]", ow = "[object String]", rw = "[object Symbol]", sw = "[object ArrayBuffer]", iw = "[object DataView]", Gu = Qe ? Qe.prototype : void 0, Fa = Gu ? Gu.valueOf : void 0;
function aw(e, t, n, o, r, s, i) {
  switch (n) {
    case iw:
      if (e.byteLength != t.byteLength || e.byteOffset != t.byteOffset)
        return !1;
      e = e.buffer, t = t.buffer;
    case sw:
      return !(e.byteLength != t.byteLength || !s(new Di(e), new Di(t)));
    case KT:
    case QT:
    case ew:
      return sa(+e, +t);
    case JT:
      return e.name == t.name && e.message == t.message;
    case tw:
    case ow:
      return e == t + "";
    case XT:
      var a = zT;
    case nw:
      var l = o & GT;
      if (a || (a = ic), e.size != t.size && !l)
        return !1;
      var c = i.get(e);
      if (c)
        return c == t;
      o |= YT, i.set(e, t);
      var u = am(a(e), a(t), o, r, s, i);
      return i.delete(e), u;
    case rw:
      if (Fa)
        return Fa.call(e) == Fa.call(t);
  }
  return !1;
}
var lw = 1, cw = Object.prototype, uw = cw.hasOwnProperty;
function dw(e, t, n, o, r, s) {
  var i = n & lw, a = fl(e), l = a.length, c = fl(t), u = c.length;
  if (l != u && !i)
    return !1;
  for (var d = l; d--; ) {
    var f = a[d];
    if (!(i ? f in t : uw.call(t, f)))
      return !1;
  }
  var m = s.get(e), p = s.get(t);
  if (m && p)
    return m == t && p == e;
  var g = !0;
  s.set(e, t), s.set(t, e);
  for (var v = i; ++d < l; ) {
    f = a[d];
    var T = e[f], O = t[f];
    if (o)
      var k = i ? o(O, T, f, t, e, s) : o(T, O, f, e, t, s);
    if (!(k === void 0 ? T === O || r(T, O, n, o, s) : k)) {
      g = !1;
      break;
    }
    v || (v = f == "constructor");
  }
  if (g && !v) {
    var R = e.constructor, ae = t.constructor;
    R != ae && "constructor" in e && "constructor" in t && !(typeof R == "function" && R instanceof R && typeof ae == "function" && ae instanceof ae) && (g = !1);
  }
  return s.delete(e), s.delete(t), g;
}
var fw = 1, Yu = "[object Arguments]", Ku = "[object Array]", ks = "[object Object]", mw = Object.prototype, Qu = mw.hasOwnProperty;
function hw(e, t, n, o, r, s) {
  var i = Be(e), a = Be(t), l = i ? Ku : No(e), c = a ? Ku : No(t);
  l = l == Yu ? ks : l, c = c == Yu ? ks : c;
  var u = l == ks, d = c == ks, f = l == c;
  if (f && Kr(e)) {
    if (!Kr(t))
      return !1;
    i = !0, u = !1;
  }
  if (f && !u)
    return s || (s = new Ot()), i || Kl(e) ? am(e, t, n, o, r, s) : aw(e, t, l, n, o, r, s);
  if (!(n & fw)) {
    var m = u && Qu.call(e, "__wrapped__"), p = d && Qu.call(t, "__wrapped__");
    if (m || p) {
      var g = m ? e.value() : e, v = p ? t.value() : t;
      return s || (s = new Ot()), r(g, v, n, o, s);
    }
  }
  return f ? (s || (s = new Ot()), dw(e, t, n, o, r, s)) : !1;
}
function ac(e, t, n, o, r) {
  return e === t ? !0 : e == null || t == null || !Et(e) && !Et(t) ? e !== e && t !== t : hw(e, t, n, o, ac, r);
}
var pw = 1, yw = 2;
function gw(e, t, n, o) {
  var r = n.length, s = r, i = !o;
  if (e == null)
    return !s;
  for (e = Object(e); r--; ) {
    var a = n[r];
    if (i && a[2] ? a[1] !== e[a[0]] : !(a[0] in e))
      return !1;
  }
  for (; ++r < s; ) {
    a = n[r];
    var l = a[0], c = e[l], u = a[1];
    if (i && a[2]) {
      if (c === void 0 && !(l in e))
        return !1;
    } else {
      var d = new Ot();
      if (o)
        var f = o(c, u, l, e, t, d);
      if (!(f === void 0 ? ac(u, c, pw | yw, o, d) : f))
        return !1;
    }
  }
  return !0;
}
function lm(e) {
  return e === e && !dt(e);
}
function vw(e) {
  for (var t = as(e), n = t.length; n--; ) {
    var o = t[n], r = e[o];
    t[n] = [o, r, lm(r)];
  }
  return t;
}
function cm(e, t) {
  return function(n) {
    return n == null ? !1 : n[e] === t && (t !== void 0 || e in Object(n));
  };
}
function bw(e) {
  var t = vw(e);
  return t.length == 1 && t[0][2] ? cm(t[0][0], t[0][1]) : function(n) {
    return n === e || gw(n, e, t);
  };
}
function Iw(e, t) {
  return e != null && t in Object(e);
}
function Tw(e, t, n) {
  t = Uo(t, e);
  for (var o = -1, r = t.length, s = !1; ++o < r; ) {
    var i = jo(t[o]);
    if (!(s = e != null && n(e, i)))
      break;
    e = e[i];
  }
  return s || ++o != r ? s : (r = e == null ? 0 : e.length, !!r && Yl(r) && ra(i, r) && (Be(e) || aa(e)));
}
function um(e, t) {
  return e != null && Tw(e, t, Iw);
}
var ww = 1, Cw = 2;
function Ow(e, t) {
  return Jl(e) && lm(t) ? cm(jo(e), t) : function(n) {
    var o = Rb(n, e);
    return o === void 0 && o === t ? um(n, e) : ac(t, o, ww | Cw);
  };
}
function Ew(e) {
  return function(t) {
    return t == null ? void 0 : t[e];
  };
}
function Sw(e) {
  return function(t) {
    return da(t, e);
  };
}
function xw(e) {
  return Jl(e) ? Ew(jo(e)) : Sw(e);
}
function Aw(e) {
  return typeof e == "function" ? e : e == null ? Vl : typeof e == "object" ? Be(e) ? Ow(e[0], e[1]) : bw(e) : xw(e);
}
function Mw(e, t, n, o) {
  for (var r = -1, s = e == null ? 0 : e.length; ++r < s; ) {
    var i = e[r];
    t(o, i, n(i), e);
  }
  return o;
}
function Nw(e) {
  return function(t, n, o) {
    for (var r = -1, s = Object(t), i = o(t), a = i.length; a--; ) {
      var l = i[e ? a : ++r];
      if (n(s[l], l, s) === !1)
        break;
    }
    return t;
  };
}
var $w = Nw();
const kw = $w;
function Dw(e, t) {
  return e && kw(e, t, as);
}
function Rw(e, t) {
  return function(n, o) {
    if (n == null)
      return n;
    if (!Wo(n))
      return e(n, o);
    for (var r = n.length, s = t ? r : -1, i = Object(n); (t ? s-- : ++s < r) && o(i[s], s, i) !== !1; )
      ;
    return n;
  };
}
var Lw = Rw(Dw);
function Pw(e, t, n, o) {
  return Lw(e, function(r, s, i) {
    t(o, r, n(r), i);
  }), o;
}
function _w(e, t) {
  return function(n, o) {
    var r = Be(n) ? Mw : Pw, s = t ? t() : {};
    return r(n, e, Aw(o), s);
  };
}
function Ju(e) {
  return Et(e) && Wo(e);
}
function dm(e, t, n) {
  for (var o = -1, r = e == null ? 0 : e.length; ++o < r; )
    if (n(t, e[o]))
      return !0;
  return !1;
}
var Bw = 200;
function Ww(e, t, n, o) {
  var r = -1, s = Tf, i = !0, a = e.length, l = [], c = t.length;
  if (!a)
    return l;
  n && (t = Zl(t, la(n))), o ? (s = dm, i = !1) : t.length >= Bw && (s = sc, i = !1, t = new $o(t));
  e:
    for (; ++r < a; ) {
      var u = e[r], d = n == null ? u : n(u);
      if (u = o || u !== 0 ? u : 0, i && d === d) {
        for (var f = c; f--; )
          if (t[f] === d)
            continue e;
        l.push(u);
      } else
        s(t, d, o) || l.push(u);
    }
  return l;
}
var lc = iv(function(e, t) {
  return Ju(e) ? Ww(e, tc(t, 1, Ju, !0)) : [];
});
function Fo(e) {
  var t = e == null ? 0 : e.length;
  return t ? e[t - 1] : void 0;
}
function ls(e) {
  return e && e.length ? e[0] : void 0;
}
var Uw = Object.prototype, jw = Uw.hasOwnProperty, cs = _w(function(e, t, n) {
  jw.call(e, n) ? e[n].push(t) : zl(e, n, [t]);
});
function Fw(e, t) {
  return t.length < 2 ? e : da(e, $f(t, 0, -1));
}
var qw = "[object Map]", Hw = "[object Set]", Zw = Object.prototype, Vw = Zw.hasOwnProperty;
function fm(e) {
  if (e == null)
    return !0;
  if (Wo(e) && (Be(e) || typeof e == "string" || typeof e.splice == "function" || Kr(e) || Kl(e) || aa(e)))
    return !e.length;
  var t = No(e);
  if (t == qw || t == Hw)
    return !e.size;
  if (ia(e))
    return !Af(e).length;
  for (var n in e)
    if (Vw.call(e, n))
      return !1;
  return !0;
}
function zw(e, t) {
  return t = Uo(t, e), e = Fw(e, t), e == null || delete e[jo(Fo(t))];
}
function Gw(e) {
  return qb(e) ? void 0 : e;
}
var Yw = 1, Kw = 2, Qw = 4, Jw = Mf(function(e, t) {
  var n = {};
  if (e == null)
    return n;
  var o = !1;
  t = Zl(t, function(s) {
    return s = Uo(s, e), o || (o = s.length > 1), s;
  }), is(e, om(e), n), o && (n = qr(n, Yw | Kw | Qw, Gw));
  for (var r = t.length; r--; )
    zw(n, t[r]);
  return n;
});
function Xw(e, t, n, o) {
  if (!dt(e))
    return e;
  t = Uo(t, e);
  for (var r = -1, s = t.length, i = s - 1, a = e; a != null && ++r < s; ) {
    var l = jo(t[r]), c = n;
    if (l === "__proto__" || l === "constructor" || l === "prototype")
      return e;
    if (r != i) {
      var u = a[l];
      c = o ? o(u, l, a) : void 0, c === void 0 && (c = dt(u) ? u : ra(t[r + 1]) ? [] : {});
    }
    Gl(a, l, c), a = a[l];
  }
  return e;
}
function eC(e, t, n) {
  for (var o = -1, r = t.length, s = {}; ++o < r; ) {
    var i = t[o], a = da(e, i);
    n(a, i) && Xw(s, Uo(i, e), a);
  }
  return s;
}
function tC(e, t) {
  return eC(e, t, function(n, o) {
    return um(e, o);
  });
}
var Xr = Mf(function(e, t) {
  return e == null ? {} : tC(e, t);
}), nC = Math.ceil, oC = Math.max;
function rC(e, t, n, o) {
  for (var r = -1, s = oC(nC((t - e) / (n || 1)), 0), i = Array(s); s--; )
    i[o ? s : ++r] = e, e += n;
  return i;
}
function sC(e) {
  return function(t, n, o) {
    return o && typeof o != "number" && lv(t, n, o) && (n = o = void 0), t = Vs(t), n === void 0 ? (n = t, t = 0) : n = Vs(n), o = o === void 0 ? t < n ? 1 : -1 : Vs(o), rC(t, n, o, e);
  };
}
var Xu = sC(), ft = c0("round"), iC = 1 / 0, aC = go && 1 / ic(new go([, -0]))[1] == iC ? function(e) {
  return new go(e);
} : Fg, lC = 200;
function cC(e, t, n) {
  var o = -1, r = Tf, s = e.length, i = !0, a = [], l = a;
  if (n)
    i = !1, r = dm;
  else if (s >= lC) {
    var c = t ? null : aC(e);
    if (c)
      return ic(c);
    i = !1, r = sc, l = new $o();
  } else
    l = t ? [] : a;
  e:
    for (; ++o < s; ) {
      var u = e[o], d = t ? t(u) : u;
      if (u = n || u !== 0 ? u : 0, i && d === d) {
        for (var f = l.length; f--; )
          if (l[f] === d)
            continue e;
        t && l.push(d), a.push(u);
      } else
        r(l, d, n) || (l !== a && l.push(d), a.push(u));
    }
  return a;
}
function Ri(e) {
  return e && e.length ? cC(e) : [];
}
function $(e) {
  const t = new Set(e);
  return (n) => t.has(n);
}
function uC(e, ...t) {
  try {
    return e == null ? void 0 : e(...t);
  } catch (n) {
    console.error(n);
  }
}
const dC = (e) => {
  Promise.resolve().then(e);
}, XR = queueMicrotask ?? dC, qo = (e) => (t) => typeof t === e, fC = qo("object"), He = qo("boolean"), eL = qo("function"), ne = qo("number"), q = qo("string"), mC = qo("undefined"), mm = (e) => e === null, pe = (e) => mm(e) || mC(e), { isArray: we } = Array, mt = (e) => fC(e) && !mm(e) && !we(e), hC = (e) => Number.isInteger(e), pC = "useandom-26T198340PX75pxJACKVERYMINDBUSHWOLF_GQZbfghjklqvwyzrict";
let yC = (e, t, n) => {
  let o = (2 << Math.log(e.length - 1) / Math.LN2) - 1, r = -~(1.6 * o * t / e.length);
  return (s = t) => {
    let i = "";
    for (; ; ) {
      let a = n(r), l = r;
      for (; l--; )
        if (i += e[a[l] & o] || "", i.length === s)
          return i;
    }
  };
};
const fe = yC(pC, 21, (e) => {
  let t = globalThis.crypto;
  return t === void 0 && (t = require("node:crypto").webcrypto), t.getRandomValues(new Uint8Array(e));
}), Je = (e, t) => (n) => Math.min(Math.max(n, e), t);
/*!
 * @dineug/erd-editor-schema
 * @version 0.1.0 | Fri Feb 28 2025
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
function w(e, t, n) {
  return (o) => {
    if (!n)
      return;
    const r = n[o];
    e(r) && (t[o] = r);
  };
}
function je(e) {
  return (t) => q(t) && e.includes(t);
}
function Tt(e) {
  return (t) => ne(t) && e.includes(t);
}
function Le(e, t, n) {
  return Reflect.get(e, t) ?? n;
}
function Ho() {
  const e = Date.now();
  return {
    updateAt: e,
    createAt: e
  };
}
function Zo(e, t) {
  const n = w(ne, e, t);
  n("updateAt"), n("createAt");
}
const gC = () => ({
  tableIds: [],
  relationshipIds: [],
  indexIds: [],
  memoIds: []
});
function vC(e) {
  const t = gC();
  if (!mt(e) || pe(e))
    return t;
  const n = w(we, t, e);
  return n("tableIds"), n("relationshipIds"), n("indexIds"), n("memoIds"), t;
}
const hm = () => ({
  id: "",
  name: "",
  tableId: "",
  indexColumnIds: [],
  seqIndexColumnIds: [],
  unique: !1,
  meta: Ho()
});
function bC(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = hm(), r = w(q, o, n), s = w(He, o, n), i = w(we, o, n);
    r("id"), r("name"), r("tableId"), s("unique"), i("indexColumnIds"), i("seqIndexColumnIds"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const cc = {
  ASC: (
    /*  */
    1
  ),
  DESC: (
    /* */
    2
  )
}, pm = Object.values(cc), ym = () => ({
  id: "",
  indexId: "",
  columnId: "",
  orderType: cc.ASC,
  meta: Ho()
});
function IC(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = ym(), r = w(q, o, n);
    r("id"), r("indexId"), r("columnId"), w(Tt(pm), o, n)("orderType"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const TC = () => ({});
function wC(e) {
  const t = TC();
  if (!mt(e) || pe(e))
    return t;
  for (const [n, o] of Object.entries(e)) {
    if (!we(o) || o.length !== 4)
      continue;
    const [r, s, i, a] = o;
    if (q(r) && ne(s) && ne(i) && mt(a)) {
      const l = {}, c = w(ne, l, a);
      Object.keys(a).forEach(c), t[n] = [r, s, i, l];
    }
  }
  return t;
}
const gm = () => ({
  id: "",
  value: "",
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    width: 116,
    height: 100,
    color: ""
  },
  meta: Ho()
});
function CC(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = gm(), r = w(q, o, n), s = w(ne, o.ui, n.ui), i = w(q, o.ui, n.ui);
    r("id"), r("value"), i("color"), s("x"), s("y"), s("zIndex"), s("width"), s("height"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const uc = {
  // ZeroOneN: /* */ 0b0000000000000000000000000000001,
  ZeroOne: (
    /*  */
    2
  ),
  ZeroN: (
    /*    */
    4
  ),
  OneOnly: (
    /*  */
    8
  ),
  OneN: (
    /*     */
    16
  )
  // One: /*      */ 0b0000000000000000000000000100000,
  // N: /*        */ 0b0000000000000000000000001000000,
}, vm = Object.values(uc), dc = {
  ring: (
    /* */
    1
  ),
  dash: (
    /* */
    2
  )
}, bm = Object.values(dc), Li = {
  left: (
    /*   */
    1
  ),
  right: (
    /*  */
    2
  ),
  top: (
    /*    */
    4
  ),
  bottom: (
    /* */
    8
  )
}, pl = Object.values(Li), Im = () => ({
  id: "",
  identification: !1,
  relationshipType: uc.ZeroN,
  startRelationshipType: dc.dash,
  start: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: Li.bottom
  },
  end: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: Li.bottom
  },
  meta: Ho()
});
function OC(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = Im(), r = w(q, o, n), s = w(He, o, n), i = w(ne, o.start, n.start), a = w(q, o.start, n.start), l = w(ne, o.end, n.end), c = w(q, o.end, n.end);
    r("id"), s("identification"), w(Tt(vm), o, n)("relationshipType"), w(Tt(bm), o, n)("startRelationshipType"), a("tableId"), i("x"), i("y"), w(Tt(pl), o.start, n.start)("direction"), w(we, o.start, n.start)("columnIds"), c("tableId"), l("x"), l("y"), w(Tt(pl), o.end, n.end)("direction"), w(we, o.end, n.end)("columnIds"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const fc = {
  ERD: "ERD",
  visualization: "@dineug/erd-editor/builtin-visualization",
  schemaSQL: "@dineug/erd-editor/builtin-schema-sql",
  generatorCode: "@dineug/erd-editor/builtin-generator-code",
  settings: "settings"
}, EC = Object.values(fc), Qt = {
  tableComment: (
    /*        */
    1
  ),
  columnComment: (
    /*       */
    2
  ),
  columnDataType: (
    /*      */
    4
  ),
  columnDefault: (
    /*       */
    8
  ),
  columnAutoIncrement: (
    /* */
    16
  ),
  columnPrimaryKey: (
    /*    */
    32
  ),
  columnUnique: (
    /*        */
    64
  ),
  columnNotNull: (
    /*       */
    128
  ),
  relationship: (
    /*        */
    256
  )
}, kt = {
  columnName: (
    /*          */
    1
  ),
  columnDataType: (
    /*      */
    2
  ),
  columnNotNull: (
    /*       */
    4
  ),
  columnUnique: (
    /*        */
    8
  ),
  columnAutoIncrement: (
    /* */
    16
  ),
  columnDefault: (
    /*       */
    32
  ),
  columnComment: (
    /*       */
    64
  )
}, yl = Object.values(kt), mc = {
  MariaDB: (
    /*    */
    1
  ),
  MSSQL: (
    /*      */
    2
  ),
  MySQL: (
    /*      */
    4
  ),
  Oracle: (
    /*     */
    8
  ),
  PostgreSQL: (
    /* */
    16
  ),
  SQLite: (
    /*     */
    32
  )
}, Tm = Object.values(mc), hc = {
  GraphQL: (
    /*    */
    1
  ),
  csharp: (
    /*     */
    2
  ),
  Java: (
    /*       */
    4
  ),
  Kotlin: (
    /*     */
    8
  ),
  TypeScript: (
    /* */
    16
  ),
  JPA: (
    /*        */
    32
  ),
  Scala: (
    /*      */
    64
  )
}, wm = Object.values(hc), Pi = {
  none: (
    /*       */
    1
  ),
  camelCase: (
    /*  */
    2
  ),
  pascalCase: (
    /* */
    4
  ),
  snakeCase: (
    /*  */
    8
  )
}, gl = Object.values(Pi), pc = {
  none: (
    /*        */
    1
  ),
  doubleQuote: (
    /* */
    2
  ),
  singleQuote: (
    /* */
    4
  ),
  backtick: (
    /*    */
    8
  )
}, Cm = Object.values(pc), SC = {
  scroll: (
    /*    */
    1
  ),
  zoomLevel: (
    /* */
    2
  )
}, Om = 0.1, Em = 1, Sm = 2e3, xm = 2e4, xC = Qt.tableComment | Qt.columnComment | Qt.columnDataType | Qt.columnDefault | Qt.columnPrimaryKey | Qt.columnNotNull | Qt.relationship, AC = () => ({
  width: 2e3,
  height: 2e3,
  scrollTop: 0,
  scrollLeft: 0,
  zoomLevel: 1,
  show: xC,
  database: mc.MySQL,
  databaseName: "",
  canvasType: fc.ERD,
  language: hc.GraphQL,
  tableNameCase: Pi.pascalCase,
  columnNameCase: Pi.camelCase,
  bracketType: pc.none,
  relationshipDataTypeSync: !0,
  relationshipOptimization: !1,
  columnOrder: [
    kt.columnName,
    kt.columnDataType,
    kt.columnNotNull,
    kt.columnUnique,
    kt.columnAutoIncrement,
    kt.columnDefault,
    kt.columnComment
  ],
  maxWidthComment: -1,
  ignoreSaveSettings: 0
}), ed = Je(Sm, xm), MC = Je(Om, Em), NC = Je(60, 200);
function $C(e) {
  const t = AC();
  if (!mt(e) || pe(e))
    return t;
  const n = w(ne, t, e), o = w(q, t, e), r = w(He, t, e);
  return ne(e.width) && (t.width = ed(e.width)), ne(e.height) && (t.height = ed(e.height)), ne(e.zoomLevel) && (t.zoomLevel = MC(e.zoomLevel)), ne(e.maxWidthComment) && e.maxWidthComment !== -1 && (t.maxWidthComment = NC(e.maxWidthComment)), n("scrollTop"), n("scrollLeft"), n("show"), n("ignoreSaveSettings"), o("databaseName"), o("canvasType"), r("relationshipDataTypeSync"), r("relationshipOptimization"), w(Tt(Tm), t, e)("database"), w(Tt(wm), t, e)("language"), w(Tt(gl), t, e)("tableNameCase"), w(Tt(gl), t, e)("columnNameCase"), w(Tt(Cm), t, e)("bracketType"), we(e.columnOrder) && yl.length === e.columnOrder.length && lc(yl, e.columnOrder).length === 0 && (t.columnOrder = e.columnOrder), t;
}
const Am = () => ({
  id: "",
  name: "",
  comment: "",
  columnIds: [],
  seqColumnIds: [],
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    widthName: 60,
    widthComment: 60,
    color: ""
  },
  meta: Ho()
});
function kC(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = Am(), r = w(q, o, n), s = w(we, o, n), i = w(ne, o.ui, n.ui), a = w(q, o.ui, n.ui);
    r("id"), r("name"), r("comment"), s("columnIds"), s("seqColumnIds"), a("color"), i("x"), i("y"), i("zIndex"), i("widthName"), i("widthComment"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
const Mm = () => ({
  id: "",
  tableId: "",
  name: "",
  comment: "",
  dataType: "",
  default: "",
  options: 0,
  ui: {
    keys: 0,
    widthName: 60,
    widthComment: 60,
    widthDataType: 60,
    widthDefault: 60
  },
  meta: Ho()
});
function DC(e) {
  const t = {};
  if (!mt(e) || pe(e))
    return t;
  for (const n of Object.values(e)) {
    if (!n)
      continue;
    const o = Mm(), r = w(q, o, n), s = w(ne, o, n), i = w(ne, o.ui, n.ui);
    r("id"), r("tableId"), r("name"), r("comment"), r("dataType"), r("default"), s("options"), i("keys"), i("widthName"), i("widthComment"), i("widthDataType"), i("widthDefault"), Zo(o.meta, n.meta), o.id && (t[o.id] = o);
  }
  return t;
}
function qn(e) {
  var t, n, o, r, s, i;
  const a = e, l = $C(a.settings), c = vC(a.doc), u = wC(a.lww), d = kC((t = a.collections) == null ? void 0 : t.tableEntities), f = DC((n = a.collections) == null ? void 0 : n.tableColumnEntities), m = OC((o = a.collections) == null ? void 0 : o.relationshipEntities), p = bC((r = a.collections) == null ? void 0 : r.indexEntities), g = IC((s = a.collections) == null ? void 0 : s.indexColumnEntities), v = CC((i = a.collections) == null ? void 0 : i.memoEntities);
  return {
    $schema: "https://raw.githubusercontent.com/dineug/erd-editor/main/json-schema/schema.json",
    version: "3.0.0",
    settings: l,
    doc: c,
    collections: {
      tableEntities: d,
      tableColumnEntities: f,
      relationshipEntities: m,
      indexEntities: p,
      indexColumnEntities: g,
      memoEntities: v
    },
    lww: u
  };
}
const RC = {
  autoIncrement: (
    /* */
    1
  ),
  primaryKey: (
    /*    */
    2
  ),
  unique: (
    /*        */
    4
  ),
  notNull: (
    /*       */
    8
  )
}, LC = {
  primaryKey: (
    /* */
    1
  ),
  foreignKey: (
    /* */
    2
  )
}, E = {
  CanvasType: fc,
  CanvasTypeList: EC,
  Show: Qt,
  ColumnType: kt,
  ColumnTypeList: yl,
  Database: mc,
  DatabaseList: Tm,
  Language: hc,
  LanguageList: wm,
  NameCase: Pi,
  NameCaseList: gl,
  BracketType: pc,
  BracketTypeList: Cm,
  RelationshipType: uc,
  RelationshipTypeList: vm,
  StartRelationshipType: dc,
  StartRelationshipTypeList: bm,
  Direction: Li,
  DirectionList: pl,
  ColumnOption: RC,
  ColumnUIKey: LC,
  OrderType: cc,
  OrderTypeList: pm,
  SaveSettingType: SC,
  CANVAS_SIZE_MAX: xm,
  CANVAS_SIZE_MIN: Sm,
  CANVAS_ZOOM_MAX: Em,
  CANVAS_ZOOM_MIN: Om
};
function PC(e) {
  const t = qn({});
  return _C(t.settings, e.canvas), BC(t, e.table), WC(t, e.memo), UC(t, e.relationship), t;
}
function _C(e, t) {
  e.width = t.width, e.height = t.height, e.scrollTop = t.scrollTop, e.scrollLeft = t.scrollLeft, e.zoomLevel = t.zoomLevel, e.databaseName = t.databaseName, e.canvasType = E.CanvasType.ERD, e.show = Object.keys(t.show).reduce((o, r) => {
    if (Le(t.show, r, !1)) {
      const s = Le(E.Show, r, 0);
      return o | s;
    }
    return o;
  }, 0), e.database = Reflect.get(E.Database, t.database) ?? E.Database.MySQL, e.language = t.language === "C#" ? E.Language.csharp : Le(E.Language, t.language, E.Language.GraphQL), e.tableNameCase = Le(E.NameCase, t.tableCase, E.NameCase.pascalCase), e.columnNameCase = Le(E.NameCase, t.columnCase, E.NameCase.camelCase), e.bracketType = Le(E.BracketType, t.bracketType, E.BracketType.none), e.relationshipDataTypeSync = t.setting.relationshipDataTypeSync, e.relationshipOptimization = t.setting.relationshipOptimization;
  const n = t.setting.columnOrder.map((o) => E.ColumnType[o]);
  lc(E.ColumnTypeList, n).length === 0 && (e.columnOrder = n);
}
function BC(e, t) {
  const n = t.tables.filter(({ id: r }) => !!r), o = t.indexes.filter(({ id: r }) => !!r);
  e.doc.tableIds = n.map(({ id: r }) => r), e.doc.indexIds = o.map(({ id: r }) => r);
  for (const r of n) {
    const s = Am();
    s.id = r.id, s.name = r.name, s.comment = r.comment, s.columnIds = r.columns.map(({ id: i }) => i), s.seqColumnIds = [...s.columnIds], s.ui.y = r.ui.top, s.ui.x = r.ui.left, s.ui.zIndex = r.ui.zIndex, s.ui.widthName = r.ui.widthName, s.ui.widthComment = r.ui.widthComment, s.ui.color = r.ui.color ?? "";
    for (const i of r.columns) {
      const a = Mm();
      a.id = i.id, a.tableId = r.id, a.name = i.name, a.comment = i.comment, a.dataType = i.dataType, a.default = i.default, a.options = Object.keys(i.option).reduce((l, c) => {
        if (Le(i.option, c, !1)) {
          const u = Le(E.ColumnOption, c, 0);
          return l | u;
        }
        return l;
      }, 0), a.ui.widthName = i.ui.widthName, a.ui.widthComment = i.ui.widthComment, a.ui.widthDataType = i.ui.widthDataType, a.ui.widthDefault = i.ui.widthDefault, i.ui.pfk ? a.ui.keys = E.ColumnUIKey.primaryKey | E.ColumnUIKey.foreignKey : i.ui.pk ? a.ui.keys = E.ColumnUIKey.primaryKey : i.ui.fk && (a.ui.keys = E.ColumnUIKey.foreignKey), e.collections.tableColumnEntities[a.id] = a;
    }
    e.collections.tableEntities[s.id] = s;
  }
  for (const r of o) {
    const s = hm();
    s.id = r.id, s.name = r.name, s.tableId = r.tableId, s.unique = r.unique;
    for (const i of r.columns) {
      const a = fe(), l = ym();
      s.indexColumnIds.push(a), s.seqIndexColumnIds.push(a), l.id = a, l.indexId = r.id, l.columnId = i.id, l.orderType = Le(E.OrderType, i.orderType, E.OrderType.ASC), e.collections.indexColumnEntities[l.id] = l;
    }
    e.collections.indexEntities[s.id] = s;
  }
}
function WC(e, t) {
  const n = t.memos.filter(({ id: o }) => !!o);
  e.doc.memoIds = n.map(({ id: o }) => o);
  for (const o of n) {
    const r = gm();
    r.id = o.id, r.value = o.value, r.ui.y = o.ui.top, r.ui.x = o.ui.left, r.ui.width = o.ui.width, r.ui.height = o.ui.height, r.ui.zIndex = o.ui.zIndex, r.ui.color = o.ui.color ?? "", e.collections.memoEntities[r.id] = r;
  }
}
function UC(e, t) {
  const n = t.relationships.filter(({ id: o }) => !!o);
  e.doc.relationshipIds = n.map(({ id: o }) => o);
  for (const o of n) {
    const r = Im();
    r.id = o.id, r.identification = o.identification, r.relationshipType = Le(E.RelationshipType, o.relationshipType, E.RelationshipType.ZeroN), r.startRelationshipType = Le(E.StartRelationshipType, o.startRelationshipType === "Ring" ? "ring" : "dash", E.StartRelationshipType.dash), r.start.tableId = o.start.tableId, r.start.columnIds = o.start.columnIds, r.start.x = o.start.x, r.start.y = o.start.y, r.start.direction = Le(E.Direction, o.start.direction, E.Direction.bottom), r.end.tableId = o.end.tableId, r.end.columnIds = o.end.columnIds, r.end.x = o.end.x, r.end.y = o.end.y, r.end.direction = Le(E.Direction, o.end.direction, E.Direction.bottom), e.collections.relationshipEntities[r.id] = r;
  }
}
function jC(e) {
  return [e, -1, -1, {}];
}
function yc(e, t, n) {
  return e[t] || (e[t] = jC(n), e[t]);
}
function FC(e, t, n, o, r) {
  const s = yc(e, n, o), i = s[1], a = s[2];
  i < t && (s[1] = t), a < t && r();
}
function qC(e, t, n, o, r) {
  const s = yc(e, n, o), i = s[2], a = s[1];
  i < t && (s[2] = t), a <= t && r();
}
function Nm(e, t, n, o, r, s) {
  const i = yc(e, n, o);
  (i[3][r] ?? -1) <= t && (i[3][r] = t, s());
}
let HC = class {
  constructor(t) {
    Object.defineProperty(this, "collections", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: t
    });
  }
  collection(t) {
    return new ZC(this.collections[t], t);
  }
}, ZC = class {
  constructor(t, n) {
    Object.defineProperty(this, "collection", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: t
    }), Object.defineProperty(this, "collectionKey", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: n
    });
  }
  selectById(t) {
    return this.collection[t];
  }
  selectByIds(t) {
    return t.length, t.map((n) => this.selectById(n)).filter(Boolean);
  }
  selectEntities() {
    return this.collection;
  }
  selectAll() {
    return Object.values(this.collection);
  }
  setOne(t) {
    return this.collection[t.id] = t, this;
  }
  setMany(t) {
    return t.forEach((n) => this.setOne(n)), this;
  }
  setAll(t) {
    return this.removeAll(), this.setMany(t), this;
  }
  addOne(t) {
    return this.selectById(t.id) ? this : (this.setOne(t), this);
  }
  addMany(t) {
    return t.forEach((n) => this.addOne(n)), this;
  }
  removeOne(t) {
    return this.selectById(t) && Reflect.deleteProperty(this.collection, t), this;
  }
  removeMany(t) {
    return t.forEach((n) => this.removeOne(n)), this;
  }
  removeAll() {
    return this.collection = {}, this;
  }
  updateOne(t, n) {
    const o = this.selectById(t);
    return o && (n(o), o.meta.updateAt = Date.now()), this;
  }
  updateMany(t, n) {
    return t.forEach((o) => this.updateOne(o, n)), this;
  }
  getOrCreate(t, n) {
    const o = this.selectById(t);
    if (o)
      return o;
    const r = n(t);
    return this.setOne(r), this.selectById(t);
  }
  addOperator(t, n, o, r) {
    return FC(t, n, o, this.collectionKey, r), this;
  }
  removeOperator(t, n, o, r) {
    return qC(t, n, o, this.collectionKey, r), this;
  }
  replaceOperator(t, n, o, r, s) {
    return Nm(t, n, o, this.collectionKey, r, s), this;
  }
};
const y = (e) => new HC(e);
function td(e, t) {
  return (e & t) === t;
}
const VC = {
  columnUnique: "columnUnique",
  columnAutoIncrement: "columnAutoIncrement",
  columnName: "columnName",
  columnDataType: "columnDataType",
  columnNotNull: "columnNotNull",
  columnDefault: "columnDefault",
  columnComment: "columnComment"
}, nd = Object.values(VC), zC = {
  ERD: "ERD",
  "@vuerd/builtin-sql-ddl": "@vuerd/builtin-sql-ddl",
  "@vuerd/builtin-grid": "@vuerd/builtin-grid",
  "@vuerd/builtin-generator-code": "@vuerd/builtin-generator-code",
  "@vuerd/builtin-visualization": "@vuerd/builtin-visualization"
}, GC = Object.values(zC), YC = {
  MariaDB: "MariaDB",
  MSSQL: "MSSQL",
  MySQL: "MySQL",
  Oracle: "Oracle",
  PostgreSQL: "PostgreSQL",
  SQLite: "SQLite"
}, KC = Object.values(YC), QC = {
  GraphQL: "GraphQL",
  "C#": "C#",
  Java: "Java",
  Kotlin: "Kotlin",
  TypeScript: "TypeScript",
  JPA: "JPA",
  Scala: "Scala"
}, JC = Object.values(QC), XC = {
  none: "none",
  camelCase: "camelCase",
  pascalCase: "pascalCase",
  snakeCase: "snakeCase"
}, od = Object.values(XC), e1 = {
  AtomOneDark: "AtomOneDark",
  AtomOneLight: "AtomOneLight",
  MonokaiSublime: "MonokaiSublime",
  GithubGist: "GithubGist",
  VS2015: "VS2015"
}, t1 = Object.values(e1), n1 = {
  none: "none",
  doubleQuote: "doubleQuote",
  singleQuote: "singleQuote",
  backtick: "backtick"
}, o1 = Object.values(n1), r1 = 0.1, s1 = 1, i1 = 2e3, a1 = 2e4, l1 = () => ({
  version: "2.2.11",
  width: 2e3,
  height: 2e3,
  scrollTop: 0,
  scrollLeft: 0,
  zoomLevel: 1,
  show: {
    tableComment: !0,
    columnComment: !0,
    columnDataType: !0,
    columnDefault: !0,
    columnAutoIncrement: !1,
    columnPrimaryKey: !0,
    columnUnique: !1,
    columnNotNull: !0,
    relationship: !0
  },
  database: "MySQL",
  databaseName: "",
  canvasType: "ERD",
  language: "GraphQL",
  tableCase: "pascalCase",
  columnCase: "camelCase",
  highlightTheme: "VS2015",
  bracketType: "none",
  setting: {
    relationshipDataTypeSync: !0,
    relationshipOptimization: !1,
    columnOrder: [
      "columnName",
      "columnDataType",
      "columnNotNull",
      "columnUnique",
      "columnAutoIncrement",
      "columnDefault",
      "columnComment"
    ]
  },
  pluginSerializationMap: {}
}), rd = Je(i1, a1), c1 = Je(r1, s1);
function u1(e) {
  var t;
  const n = l1();
  if (pe(e))
    return n;
  const o = w(ne, n, e), r = w(q, n, e), s = w(He, n.show, e.show), i = w(He, n.setting, e.setting);
  if (ne(e.width) && (n.width = rd(e.width)), ne(e.height) && (n.height = rd(e.height)), ne(e.zoomLevel) && (n.zoomLevel = c1(e.zoomLevel)), r("version"), r("databaseName"), o("scrollTop"), o("scrollLeft"), w(je(KC), n, e)("database"), w(je(GC), n, e)("canvasType"), w(je(JC), n, e)("language"), w(je(od), n, e)("tableCase"), w(je(od), n, e)("columnCase"), w(je(t1), n, e)("highlightTheme"), w(je(o1), n, e)("bracketType"), s("tableComment"), s("columnComment"), s("columnDataType"), s("columnDefault"), s("columnAutoIncrement"), s("columnPrimaryKey"), s("columnUnique"), s("columnNotNull"), s("relationship"), i("relationshipDataTypeSync"), i("relationshipOptimization"), (t = e.setting) != null && t.columnOrder && nd.length === e.setting.columnOrder.length && lc(nd, e.setting.columnOrder).length === 0 && (n.setting.columnOrder = e.setting.columnOrder), mt(e.pluginSerializationMap)) {
    const a = e.pluginSerializationMap;
    for (const l of Object.keys(a)) {
      const c = a[l];
      q(c) && (n.pluginSerializationMap[l] = c);
    }
  }
  return n;
}
const d1 = () => ({
  memos: []
}), f1 = () => ({
  id: "",
  value: "",
  ui: {
    active: !1,
    left: 200,
    top: 200,
    zIndex: 2,
    width: 127,
    height: 127
  }
});
function m1(e) {
  const t = d1();
  if (pe(e) || !we(e.memos))
    return t;
  for (const n of e.memos) {
    const o = f1(), r = w(q, o, n), s = w(ne, o.ui, n.ui), i = w(He, o.ui, n.ui), a = w(q, o.ui, n.ui);
    r("id"), r("value"), i("active"), a("color"), s("left"), s("top"), s("zIndex"), s("width"), s("height"), t.memos.push(o);
  }
  return t;
}
const h1 = ["ZeroOneN", "One", "N"], p1 = {
  ZeroOneN: "ZeroN",
  One: "OneOnly",
  N: "OneN"
}, y1 = (e) => h1.includes(e) ? p1[e] : e, gr = {
  ZeroOneN: "ZeroOneN",
  ZeroOne: "ZeroOne",
  ZeroN: "ZeroN",
  OneOnly: "OneOnly",
  OneN: "OneN",
  One: "One",
  N: "N"
}, g1 = Object.values(gr), v1 = {
  Ring: "Ring",
  Dash: "Dash"
}, b1 = Object.values(v1), vr = {
  left: "left",
  right: "right",
  top: "top",
  bottom: "bottom"
}, sd = Object.values(vr), I1 = () => ({
  relationships: []
}), T1 = () => ({
  id: "",
  identification: !1,
  relationshipType: "ZeroN",
  startRelationshipType: "Dash",
  start: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: "bottom"
  },
  end: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: "bottom"
  },
  constraintName: "",
  visible: !0
});
function w1(e) {
  var t, n, o, r;
  const s = I1();
  if (pe(e) || !we(e.relationships))
    return s;
  for (const i of e.relationships) {
    const a = T1(), l = w(q, a, i), c = w(He, a, i), u = w(ne, a.start, i.start), d = w(q, a.start, i.start), f = w(ne, a.end, i.end), m = w(q, a.end, i.end);
    l("id"), l("constraintName"), c("identification"), c("visible"), w(je(g1), a, i)("relationshipType"), a.relationshipType = y1(a.relationshipType), w(je(b1), a, i)("startRelationshipType"), d("tableId"), u("x"), u("y"), w(je(sd), a.start, i.start)("direction"), we((t = i.start) == null ? void 0 : t.columnIds) && (a.start.columnIds = (n = i.start) == null ? void 0 : n.columnIds.filter(q)), m("tableId"), f("x"), f("y"), w(je(sd), a.end, i.end)("direction"), we((o = i.end) == null ? void 0 : o.columnIds) && (a.end.columnIds = (r = i.end) == null ? void 0 : r.columnIds.filter(q)), s.relationships.push(a);
  }
  return s;
}
const $m = {
  ASC: "ASC",
  DESC: "DESC"
}, C1 = Object.values($m), O1 = () => ({
  tables: [],
  indexes: []
}), E1 = () => ({
  id: "",
  name: "",
  comment: "",
  columns: [],
  ui: {
    active: !1,
    left: 200,
    top: 100,
    zIndex: 2,
    widthName: 60,
    widthComment: 60
  },
  visible: !0
}), S1 = () => ({
  id: "",
  name: "",
  comment: "",
  dataType: "",
  default: "",
  option: {
    autoIncrement: !1,
    primaryKey: !1,
    unique: !1,
    notNull: !1
  },
  ui: {
    active: !1,
    pk: !1,
    fk: !1,
    pfk: !1,
    widthName: 60,
    widthComment: 60,
    widthDataType: 60,
    widthDefault: 60
  }
}), x1 = () => ({
  id: "",
  name: "",
  tableId: "",
  columns: [],
  unique: !1
}), A1 = () => ({
  id: "",
  orderType: $m.ASC
});
function M1(e) {
  const t = O1();
  if (pe(e))
    return t;
  if (we(e.tables))
    for (const n of e.tables) {
      const o = E1(), r = w(q, o, n), s = w(He, o, n), i = w(ne, o.ui, n.ui), a = w(He, o.ui, n.ui), l = w(q, o.ui, n.ui);
      if (r("id"), r("name"), r("comment"), s("visible"), a("active"), l("color"), i("left"), i("top"), i("zIndex"), i("widthName"), i("widthComment"), we(n.columns))
        for (const c of n.columns) {
          const u = S1(), d = w(q, u, c), f = w(ne, u.ui, c.ui), m = w(He, u.ui, c.ui), p = w(He, u.option, c.option);
          d("id"), d("name"), d("comment"), d("dataType"), d("default"), p("autoIncrement"), p("primaryKey"), p("unique"), p("notNull"), m("active"), m("pk"), m("fk"), m("pfk"), f("widthName"), f("widthComment"), f("widthDataType"), f("widthDefault"), o.columns.push(u);
        }
      t.tables.push(o);
    }
  if (we(e.indexes))
    for (const n of e.indexes) {
      const o = x1(), r = w(q, o, n), s = w(He, o, n);
      if (r("id"), r("name"), r("tableId"), s("unique"), we(n.columns))
        for (const i of n.columns) {
          const a = A1();
          w(q, a, i)("id"), w(je(C1), a, i)("orderType"), o.columns.push(a);
        }
      t.indexes.push(o);
    }
  return t;
}
function N1(e) {
  const t = e, n = u1(t.canvas), o = M1(t.table), r = w1(t.relationship), s = m1(t.memo);
  return { canvas: n, table: o, relationship: r, memo: s };
}
E.RelationshipType.ZeroOne + "", gr.ZeroOne, E.RelationshipType.ZeroN + "", gr.ZeroN, E.RelationshipType.OneOnly + "", gr.OneOnly, E.RelationshipType.OneN + "", gr.OneN;
E.Direction.left + "", vr.left, E.Direction.right + "", vr.right, E.Direction.top + "", vr.top, E.Direction.bottom + "", vr.bottom;
function km(e) {
  const t = JSON.parse(e);
  return Reflect.get(t, "version") === "3.0.0" ? qn(t) : PC(N1(t));
}
function gc(e) {
  const t = Xr(e, [
    "$schema",
    "version",
    "settings",
    "doc",
    "collections",
    "lww"
  ]);
  return td(t.settings.ignoreSaveSettings, E.SaveSettingType.scroll) && (t.settings.scrollTop = 0, t.settings.scrollLeft = 0), td(t.settings.ignoreSaveSettings, E.SaveSettingType.zoomLevel) && (t.settings.zoomLevel = 1), JSON.stringify(t, null, 2);
}
/*!
 * @dineug/r-html
 * @version 0.1.3 | Fri Feb 28 2025
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
function oL(e, t) {
  return Object.freeze({ key: t ?? Symbol(), value: e });
}
const Dm = {
  subscribe: "@@r-html/context-subscribe",
  unsubscribe: "@@r-html/context-unsubscribe"
};
function Rm(e, t) {
  function n(o, r) {
    return new CustomEvent(e, {
      detail: o,
      ...t,
      ...r
    });
  }
  return n.toString = () => `${e}`, n.type = e, n;
}
const vo = Rm(Dm.subscribe, { bubbles: !0, composed: !0 }), bo = Rm(Dm.unsubscribe, { bubbles: !0, composed: !0 });
function $1(e, t) {
  const n = (r) => {
    const s = r;
    t.dispatchEvent(vo(s.detail));
  }, o = (r) => {
    const s = r;
    t.dispatchEvent(bo(s.detail));
  };
  return e.addEventListener(vo.type, n), e.addEventListener(bo.type, o), () => {
    e.removeEventListener(vo.type, n), e.removeEventListener(bo.type, o);
  };
}
function ma(e, ...t) {
  try {
    return e == null ? void 0 : e(...t);
  } catch (n) {
    console.error(n);
  }
}
const k1 = (e) => {
  Promise.resolve().then(e);
}, Lm = queueMicrotask ?? k1, un = (e) => (t) => typeof t === e, Pm = un("object"), D1 = un("bigint"), _m = un("boolean"), sn = un("function"), R1 = un("number"), Bm = un("string"), L1 = un("symbol"), ht = un("undefined"), Ft = (e) => e === null, { isArray: he } = Array, Oe = (e) => Pm(e) && !Ft(e) && !he(e), us = (e) => D1(e) || _m(e) || R1(e) || Bm(e) || L1(e) || ht(e) || Ft(e);
function es() {
  const e = /* @__PURE__ */ new Set(), t = (n) => (e.has(n) || e.add(n), () => {
    e.delete(n);
  });
  return {
    subscribe: t,
    next: (n) => {
      e.forEach((o) => o(n));
    },
    asReadonly: () => ({ subscribe: t })
  };
}
const P1 = 1, ha = [], vl = /* @__PURE__ */ new Map(), Ds = /* @__PURE__ */ new Map();
let bl = !0, vc = 0;
function _1(e, t, n) {
  const o = ts.get(n);
  if (!o)
    return !1;
  const r = o.get(e);
  return r ? r.has(t) : !1;
}
const Wm = (e) => (t) => {
  const n = vl.get(t);
  let o = () => {
  };
  const r = n != null && n.promise ? n.promise : new Promise((s) => {
    o = s;
  });
  if (!n) {
    const s = {
      type: e,
      tickCount: vc,
      promise: r,
      fn: t,
      resolve: () => {
        o();
      }
    };
    ha.push(s), vl.set(t, s);
  }
  return bl && (Lm(F1), bl = !1), r;
}, B1 = Wm("observer"), W1 = Wm("nextTick"), id = (e, t) => {
  var n;
  return (n = Bi.get(e)) == null ? void 0 : n.forEach((o) => _1(e, t, o) && B1(o));
};
function Um() {
  const e = ha.shift();
  if (e) {
    if (vl.delete(e.fn), e.type === "observer")
      jm(e.fn), wc(e.fn), e.resolve();
    else if (e.type === "nextTick") {
      const t = ma(e.fn);
      t instanceof Promise ? t.finally(e.resolve) : e.resolve();
    }
    U1() && Um();
  }
}
function U1() {
  const e = ha[0];
  return e ? P1 <= vc - e.tickCount : !1;
}
function j1() {
  for (; ha.length; )
    Um();
  bl = !0, vc = 0;
}
function F1() {
  j1();
}
function ad(e, t) {
  const n = Gs.get(e);
  if (!n)
    return;
  const o = Tl.get(n);
  if (!o)
    return;
  const r = Ds.get(n);
  r ? r.has(t) || r.add(t) : (Ds.set(n, /* @__PURE__ */ new Set([t])), W1(() => {
    const s = Ds.get(n);
    s && (Ds.delete(n), s.forEach((i) => o.next(i)));
  }));
}
function h(e, t, n, o) {
  if (n === "a" && !o)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof t == "function" ? e !== t || !o : !t.has(e))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return n === "m" ? o : n === "a" ? o.call(e) : o ? o.value : t.get(e);
}
function b(e, t, n, o, r) {
  if (o === "m")
    throw new TypeError("Private method is not writable");
  if (o === "a" && !r)
    throw new TypeError("Private accessor was defined without a setter");
  if (typeof t == "function" ? e !== t || !r : !t.has(e))
    throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return o === "a" ? r.call(e, n) : r ? r.value = n : t.set(e, n), n;
}
const q1 = "on", H1 = "@", Z1 = ".", V1 = "?", z1 = "...", G1 = "@@r-html", Y1 = Math.random().toString().substring(2, 8), an = `${G1}-${Y1}`, K1 = `${z1}${an}`, Il = new RegExp(`${an}_(\\d+)_`, "g"), Q1 = new RegExp(`^${an}_\\d+_$`), J1 = /^\n/;
var J;
(function(e) {
  e.attribute = "attribute", e.boolean = "boolean", e.event = "event", e.property = "property", e.spread = "spread", e.directive = "directive";
})(J || (J = {}));
const pa = Symbol.for("https://github.com/dineug/r-html#beforeMount"), ya = Symbol.for("https://github.com/dineug/r-html#mounted"), ga = Symbol.for("https://github.com/dineug/r-html#unmounted"), bc = Symbol.for("https://github.com/dineug/r-html#beforeFirstUpdate"), va = Symbol.for("https://github.com/dineug/r-html#beforeUpdate"), Ic = Symbol.for("https://github.com/dineug/r-html#firstUpdated"), ba = Symbol.for("https://github.com/dineug/r-html#updated"), X1 = [
  pa,
  ya,
  ga,
  bc,
  va,
  Ic,
  ba
], Tc = Symbol.for("https://github.com/dineug/r-html#Directive"), Nn = Symbol.for("https://github.com/dineug/r-html#TemplateLiterals");
let zs = null;
function _i(e) {
  zs = e;
}
const ds = (e) => (t) => {
  zs && (zs[e] ?? (zs[e] = [])).push(t);
}, eO = ds(pa), rL = ds(ya), tO = ds(ga), sL = ds(va), iL = ds(ba);
function Fe(e, t) {
  const n = Reflect.get(e, t, e);
  he(n) && n.forEach(ma);
}
function nO(e) {
  X1.forEach((t) => Reflect.set(e, t, null, e));
}
const oO = es(), rO = (e) => e, sO = (e) => {
  var t, n, o;
  return o = class extends e {
    constructor(r, s, i) {
      super(r, s, i), t.set(this, []), n.set(this, null), this.hmr();
    }
    commit(r) {
      const s = rO(r);
      super.commit(s), b(this, t, r, "f");
    }
    hmr() {
      b(this, n, oO.subscribe((r) => h(this, t, "f").includes(r) && this.commit(h(this, t, "f"))), "f");
    }
    destroy() {
      var r, s;
      (r = h(this, n, "f")) == null || r.call(this), (s = super.destroy) == null || s.call(this);
    }
  }, t = /* @__PURE__ */ new WeakMap(), n = /* @__PURE__ */ new WeakMap(), o;
}, Gs = /* @__PURE__ */ new WeakMap(), Bi = /* @__PURE__ */ new WeakMap(), ld = /* @__PURE__ */ new WeakMap(), Tl = /* @__PURE__ */ new WeakMap(), ts = /* @__PURE__ */ new WeakMap(), iO = { shallow: !1 };
let Rt = null;
function wc(e) {
  return Rt = e, ma(e), Rt = null, () => jm(e);
}
function jm(e) {
  const t = ts.get(e);
  if (t)
    for (const [n] of t.entries()) {
      const o = Bi.get(n);
      o == null || o.delete(e);
    }
  t && ts.delete(e);
}
function aO(e) {
  if (!Rt)
    return;
  const t = Bi.get(e);
  t ? t.has(Rt) || t.add(Rt) : Bi.set(e, /* @__PURE__ */ new Set([Rt]));
}
function lO(e, t) {
  if (!Rt)
    return;
  const n = ts.get(Rt);
  if (n) {
    const o = n.get(e);
    o ? o.has(t) || o.add(t) : n.set(e, /* @__PURE__ */ new Set([t]));
  } else
    ts.set(Rt, /* @__PURE__ */ new Map([[e, /* @__PURE__ */ new Set([t])]]));
}
const cO = (e) => e instanceof Node || e instanceof Map || e instanceof Set || e instanceof WeakMap || e instanceof WeakSet || e instanceof RegExp || e instanceof Date || e instanceof Promise || (he(e) || Oe(e)) && Object.isFrozen(e);
function fs(e, t = {}) {
  const { shallow: n } = Object.assign({}, iO, t), o = new Proxy(e, {
    get(r, s, i) {
      const a = Reflect.get(r, s, i);
      return cO(a) ? a : (aO(e), lO(e, s), !n && (Oe(a) || he(a)) && !ld.has(a) ? Gs.has(a) ? Gs.get(a) : fs(a, t) : a);
    },
    set(r, s, i, a) {
      const l = Reflect.get(r, s, a), c = Reflect.set(r, s, i, a);
      return (!he(r) && l !== i || s === "length") && (id(r, s), ad(r, s)), c;
    },
    deleteProperty(r, s) {
      const i = Reflect.deleteProperty(r, s);
      return id(r, s), ad(r, s), i;
    }
  });
  return Gs.set(e, o), ld.set(o, e), o;
}
function aL(e) {
  return (Tl.get(e) ?? Tl.set(e, es()).get(e)).asReadonly();
}
function lL(e, t) {
  const n = fs({ value: t.value }, { shallow: !0 }), o = (i) => {
    n.value = i;
  }, r = () => e instanceof HTMLElement ? e : e.parentElement ?? e.host, s = () => {
    r().dispatchEvent(vo({
      context: t,
      observer: o
    }));
  };
  return s(), eO(s), tO(() => {
    r().dispatchEvent(bo({
      context: t,
      observer: o
    }));
  }), n;
}
function cL(e, t, n) {
  const o = e instanceof HTMLElement ? e : e.parentElement ?? e.host, r = es(), s = /* @__PURE__ */ new Map(), i = (l) => {
    var c, u;
    const d = l;
    ((u = (c = d.detail) == null ? void 0 : c.context) == null ? void 0 : u.key) === t.key && (d.stopPropagation(), d.detail.observer(n), s.set(d.detail.observer, r.subscribe(d.detail.observer)));
  }, a = (l) => {
    var c, u;
    const d = l;
    if (((u = (c = d.detail) == null ? void 0 : c.context) == null ? void 0 : u.key) === t.key) {
      d.stopPropagation();
      const f = s.get(d.detail.observer);
      f == null || f(), s.delete(d.detail.observer);
    }
  };
  return o.addEventListener(vo.type, i), o.addEventListener(bo.type, a), {
    set: (l) => {
      r.next(l);
    },
    destroy: () => {
      o.removeEventListener(vo.type, i), o.removeEventListener(bo.type, a);
      for (const l of s.values())
        l();
      s.clear();
    }
  };
}
var Ce;
(function(e) {
  e.element = "element", e.text = "text", e.comment = "comment";
})(Ce || (Ce = {}));
class Sn {
  constructor(t = {}) {
    Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: Ce.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    }), Object.defineProperty(this, "attrs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.assign(this, t);
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
}
const uO = (e, t = !1) => t ? document.createElementNS("http://www.w3.org/2000/svg", e) : document.createElement(e), dO = ({ type: e, value: t }, n = !1) => e === Ce.element ? uO(t, n) : e === Ce.text ? document.createTextNode(t) : document.createComment(t), Cc = (e) => !!e && e !== "false";
function fO(e, { type: t, name: n, value: o }) {
  switch (t) {
    case J.attribute:
      e.setAttribute(n, o ?? "");
      break;
    case J.boolean:
      Cc(o) && e.setAttribute(n, "");
      break;
    case J.property:
      Reflect.set(e, n, o, e);
      break;
  }
}
function mO(e, { type: t, name: n, value: o }) {
  switch (t) {
    case J.attribute:
    case J.property:
      Reflect.set(e, n, o);
      break;
    case J.boolean:
      Reflect.set(e, n, Cc(o));
      break;
  }
}
const Bn = (e, t) => e.length === t.length && e.every((n, o) => n === t[o]);
function wl(e, t) {
  if (e === t)
    return !0;
  const n = Oe(e) ? e : {}, o = Oe(t) ? t : {}, r = Object.keys(n), s = Object.keys(o);
  return r.length === s.length && s.every((i) => Reflect.get(n, i) === Reflect.get(o, i));
}
const hO = (e) => he(e) && sn(e[0]) && (ht(e[1]) || _m(e[1]) || Oe(e[1]));
function Xe(e, t) {
  const n = t.parentNode;
  n && n.insertBefore(e, t);
}
function et(e, t) {
  const n = t.parentNode;
  n && (t.nextSibling ? n.insertBefore(e, t.nextSibling) : n.appendChild(e));
}
const $e = (e) => e.parentNode && e.parentNode.removeChild(e), pO = (e) => e instanceof Node;
function ke(e, t) {
  const n = [];
  let o = e.nextSibling;
  for (; o && o !== t; )
    n.push(o), o = o.nextSibling;
  return n;
}
const Cl = () => {
}, cd = (e) => e instanceof HTMLElement, ud = (e) => e instanceof SVGElement, yO = (e) => e instanceof Promise, gO = (e) => {
  var t;
  return ((t = e.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)) == null ? void 0 : t.join("-").toLowerCase()) ?? "";
}, cr = (e) => e.replace(/^([A-Z])|[\s-_](\w)/g, (t, n, o) => o ? o.toUpperCase() : n.toLowerCase());
var St;
(function(e) {
  e.html = "html", e.svg = "svg", e.css = "css";
})(St || (St = {}));
const vO = new Set(Object.values(St)), qa = /* @__PURE__ */ new WeakMap(), Ha = /* @__PURE__ */ new WeakMap(), bO = /* @__PURE__ */ new Set([St.svg]), Fm = (e) => `${an}_${e}_`, IO = (e) => he(e) && he(e.raw), Ia = (e) => Oe(e) && IO(e.strings) && he(e.values) && vO.has(Reflect.get(e, Nn) ?? ""), Ta = (e) => Ia(e) && e[Nn] === St.css, Vo = (e, t = !0, n = !1) => (o) => t ? !!(o != null && o.trimStart().startsWith(e)) : n ? !!(o != null && o.trimEnd().endsWith(e)) : new RegExp(e).test(o ?? ""), qm = Vo(K1), Hm = Vo(Z1), Zm = Vo(V1), Vm = Vo(H1), zm = Vo(q1), ko = Vo(an, !1), ms = (e) => ko(e) && Q1.test((e == null ? void 0 : e.trim()) ?? ""), dd = ({ type: e, value: t }) => e === J.spread || e === J.directive || ko(t), TO = (e) => bO.has(e), fd = (e) => ms(e) ? J.directive : qm(e) ? J.spread : Hm(e) ? J.property : Vm(e) || zm(e) ? J.event : Zm(e) ? J.boolean : J.attribute, Rs = (e) => qm(e) ? e.substring(3) : ms(e) ? e : Hm(e) || Vm(e) || Zm(e) ? e.substring(1) : zm(e) ? e.substring(2) : e;
function be(e) {
  const t = [];
  let n = Il.exec(e);
  for (; n; ) {
    const o = Number(n[1]);
    t.push([n[0], Number.isInteger(o) ? o : -1]), n = Il.exec(e);
  }
  return t;
}
const md = "0123456789abcdefghijklmnopqrstuvwxyz-_";
function wO(e, t) {
  return e = Math.ceil(e), t = Math.floor(t), Math.floor(Math.random() * (t - e)) + e;
}
function CO(e = 21) {
  let t = "_";
  for (let n = 0; n < e; n++)
    t += md.charAt(wO(0, md.length));
  return t;
}
var xe, no, Ys, br, Jt, Ir, Tr;
class OO {
  constructor(t, { name: n, value: o }) {
    xe.set(this, void 0), no.set(this, void 0), Ys.set(this, void 0), br.set(this, []), Jt.set(this, []), Ir.set(this, null), Tr.set(this, null), b(this, xe, t, "f"), b(this, no, n, "f"), b(this, Ys, o, "f"), b(this, br, be(o ?? ""), "f");
  }
  commit(t) {
    const n = h(this, br, "f").map(([r, s]) => t[s]);
    if (Bn(h(this, Jt, "f"), n))
      return;
    const o = n[n.length - 1];
    if (h(this, no, "f") === "class")
      this.classCommit(o);
    else if (h(this, no, "f") === "style")
      this.styleCommit(o);
    else {
      const r = n.reduce((s, i, a) => s.replace(new RegExp(h(this, br, "f")[a][0]), Ks(i)), h(this, Ys, "f") ?? "");
      h(this, xe, "f").setAttribute(h(this, no, "f"), r.trim());
    }
    b(this, Jt, n, "f");
  }
  classCommit(t) {
    if (!cd(h(this, xe, "f")) && !ud(h(this, xe, "f")) || !Oe(t) && !he(t))
      return;
    const n = h(this, Jt, "f")[h(this, Jt, "f").length - 1];
    if (n === t || he(n) && he(t) && Bn(n, t) || Oe(n) && Oe(t) && wl(n, t))
      return;
    const o = [...h(this, xe, "f").classList], r = Gm(t);
    if (Ft(h(this, Tr, "f")))
      b(this, Tr, o, "f");
    else {
      const s = h(this, Tr, "f"), i = o.filter((a) => !s.includes(a) && !r.includes(a));
      h(this, xe, "f").classList.remove(...i);
    }
    h(this, xe, "f").classList.add(...r);
  }
  styleCommit(t) {
    if (!cd(h(this, xe, "f")) && !ud(h(this, xe, "f")))
      return;
    const n = h(this, Jt, "f")[h(this, Jt, "f").length - 1];
    if (wl(n, t))
      return;
    const o = EO(h(this, xe, "f")), r = Oe(t) ? t : {};
    if (Ft(h(this, Ir, "f")))
      b(this, Ir, o, "f");
    else {
      const s = h(this, Ir, "f");
      Object.keys(o).filter((i) => !s[i] && !r[i]).forEach((i) => h(this, xe, "f").style.removeProperty(i));
    }
    for (const s of Object.keys(r))
      h(this, xe, "f").style.setProperty(s, r[s]);
  }
}
xe = /* @__PURE__ */ new WeakMap(), no = /* @__PURE__ */ new WeakMap(), Ys = /* @__PURE__ */ new WeakMap(), br = /* @__PURE__ */ new WeakMap(), Jt = /* @__PURE__ */ new WeakMap(), Ir = /* @__PURE__ */ new WeakMap(), Tr = /* @__PURE__ */ new WeakMap();
function Ks(e) {
  return us(e) && !Ft(e) && !ht(e) || Ta(e) ? String(e) : "";
}
function EO(e) {
  const t = {};
  for (let n = 0; n < e.style.length; n++) {
    const o = e.style.item(n);
    t[o] = e.style.getPropertyValue(o);
  }
  return t;
}
function Gm(e, t = []) {
  if (Ta(e))
    return t.push(Ks(e)), t;
  if (us(e))
    t.push(Ks(e));
  else if (Pm(e))
    if (he(e))
      for (let n = 0; n < e.length; n++)
        e[n] && Gm(e[n], t);
    else
      for (let n in e)
        e[n] && t.push(Ks(n));
  return t;
}
var wr, Cr, Qs, Js;
class SO {
  constructor(t, { name: n, value: o }) {
    wr.set(this, void 0), Cr.set(this, void 0), Qs.set(this, []), Js.set(this, []), b(this, wr, t, "f"), b(this, Cr, n, "f"), b(this, Qs, be(o ?? ""), "f");
  }
  commit(t) {
    const n = h(this, Qs, "f").map(([r, s]) => t[s]);
    if (Bn(h(this, Js, "f"), n))
      return;
    const o = n[n.length - 1];
    Cc(o) ? h(this, wr, "f").setAttribute(h(this, Cr, "f"), "") : h(this, wr, "f").removeAttribute(h(this, Cr, "f")), b(this, Js, n, "f");
  }
}
wr = /* @__PURE__ */ new WeakMap(), Cr = /* @__PURE__ */ new WeakMap(), Qs = /* @__PURE__ */ new WeakMap(), Js = /* @__PURE__ */ new WeakMap();
var Do;
(function(e) {
  e.node = "node", e.attribute = "attribute";
})(Do || (Do = {}));
function Ym(e, t) {
  return Reflect.set(t, Tc, e), t;
}
var Xs, ei, ti, Or, Xt;
const xO = (e) => he(e) && Reflect.get(e, Tc) === Do.attribute;
let Km = class {
  constructor(e, { name: t }) {
    Xs.set(this, void 0), ei.set(this, void 0), ti.set(this, null), Or.set(this, null), Xt.set(this, null), b(this, Xs, e, "f"), b(this, ei, be(t)[0], "f");
  }
  commit(e) {
    var t, n, o, r;
    const [, s] = h(this, ei, "f"), i = e[s];
    if (!xO(i))
      return;
    const [a, l] = i;
    if (h(this, ti, "f") !== l)
      (t = h(this, Xt, "f")) == null || t.call(this), b(this, Or, l({ node: h(this, Xs, "f") }), "f"), b(this, ti, l, "f"), b(this, Xt, (n = h(this, Or, "f")) == null ? void 0 : n.call(this, a), "f");
    else {
      const c = (o = h(this, Or, "f")) == null ? void 0 : o.call(this, a);
      h(this, Xt, "f") !== c && ((r = h(this, Xt, "f")) == null || r.call(this), b(this, Xt, c, "f"));
    }
  }
  destroy() {
    var e;
    (e = h(this, Xt, "f")) == null || e.call(this);
  }
};
Xs = /* @__PURE__ */ new WeakMap(), ei = /* @__PURE__ */ new WeakMap(), ti = /* @__PURE__ */ new WeakMap(), Or = /* @__PURE__ */ new WeakMap(), Xt = /* @__PURE__ */ new WeakMap();
var bn, In, ni, Er;
class Qm {
  constructor(t, { name: n, value: o }) {
    bn.set(this, void 0), In.set(this, void 0), ni.set(this, []), Er.set(this, []), b(this, bn, t, "f"), b(this, In, n, "f"), b(this, ni, be(o ?? ""), "f");
  }
  commit(t) {
    const n = h(this, ni, "f").map(([o, r]) => t[r]).filter((o) => sn(o) || hO(o));
    Bn(h(this, Er, "f"), n) || (this.clear(), n.forEach((o) => sn(o) ? h(this, bn, "f").addEventListener(h(this, In, "f"), o) : h(this, bn, "f").addEventListener(h(this, In, "f"), o[0], o[1])), b(this, Er, n, "f"));
  }
  clear() {
    h(this, Er, "f").forEach((t) => sn(t) ? h(this, bn, "f").removeEventListener(h(this, In, "f"), t) : h(this, bn, "f").removeEventListener(h(this, In, "f"), t[0], t[1]));
  }
  destroy() {
    this.clear();
  }
}
bn = /* @__PURE__ */ new WeakMap(), In = /* @__PURE__ */ new WeakMap(), ni = /* @__PURE__ */ new WeakMap(), Er = /* @__PURE__ */ new WeakMap();
var Sr, oi, ri, si;
class AO {
  constructor(t, { name: n, value: o }) {
    Sr.set(this, void 0), oi.set(this, void 0), ri.set(this, []), si.set(this, []), b(this, Sr, t, "f"), b(this, oi, n, "f"), b(this, ri, be(o ?? ""), "f");
  }
  commit(t) {
    const n = h(this, ri, "f").map(([r, s]) => t[s]);
    if (Bn(h(this, si, "f"), n))
      return;
    const o = n[n.length - 1];
    Reflect.set(h(this, Sr, "f"), h(this, oi, "f"), o, h(this, Sr, "f")), b(this, si, n, "f");
  }
}
Sr = /* @__PURE__ */ new WeakMap(), oi = /* @__PURE__ */ new WeakMap(), ri = /* @__PURE__ */ new WeakMap(), si = /* @__PURE__ */ new WeakMap();
var xr, ii, ai;
class Jm {
  constructor(t, { name: n }) {
    xr.set(this, void 0), ii.set(this, void 0), ai.set(this, null), b(this, xr, t, "f"), b(this, ii, be(n)[0], "f");
  }
  commit(t) {
    const [, n] = h(this, ii, "f"), o = t[n];
    !Oe(o) || wl(h(this, ai, "f"), o) || (Object.keys(o).forEach((r) => Reflect.set(h(this, xr, "f"), r, o[r], h(this, xr, "f"))), b(this, ai, o, "f"));
  }
}
xr = /* @__PURE__ */ new WeakMap(), ii = /* @__PURE__ */ new WeakMap(), ai = /* @__PURE__ */ new WeakMap();
const MO = (e, t) => t.type === J.attribute ? new OO(e, t) : t.type === J.boolean ? new SO(e, t) : t.type === J.event ? new Qm(e, t) : t.type === J.property ? new AO(e, t) : t.type === J.spread ? new Jm(e, t) : new Km(e, t);
var li, ci, Ar, Mr;
class NO {
  constructor(t, { value: n }) {
    li.set(this, void 0), ci.set(this, void 0), Ar.set(this, []), Mr.set(this, []), b(this, li, t, "f"), b(this, ci, n, "f"), Bm(n) && b(this, Ar, be(n), "f");
  }
  commit(t) {
    const n = h(this, Ar, "f").map(([r, s]) => t[s]);
    if (Bn(h(this, Mr, "f"), n))
      return;
    b(this, Mr, n, "f");
    const o = h(this, Mr, "f").reduce((r, s, i) => r.replace(new RegExp(h(this, Ar, "f")[i][0]), us(s) && !Ft(s) && !ht(s) ? String(s) : ""), h(this, ci, "f") ?? "");
    h(this, li, "f").data = o;
  }
}
li = /* @__PURE__ */ new WeakMap(), ci = /* @__PURE__ */ new WeakMap(), Ar = /* @__PURE__ */ new WeakMap(), Mr = /* @__PURE__ */ new WeakMap();
const Ol = Symbol("https://github.com/dineug/r-html.git#hostBridge");
function $O(e, t) {
  if (t instanceof ShadowRoot) {
    const n = t.host;
    Reflect.set(e, Ol, n);
  }
  return () => {
    Reflect.deleteProperty(e, Ol);
  };
}
function kO(e) {
  return Reflect.get(e, Ol) ?? null;
}
var ui, di, fi, mi;
class DO {
  constructor(t, { name: n, value: o }) {
    ui.set(this, void 0), di.set(this, void 0), fi.set(this, []), mi.set(this, []), b(this, ui, t, "f"), b(this, di, n, "f"), b(this, fi, be(o ?? ""), "f");
  }
  commit(t) {
    const n = h(this, fi, "f").map(([r, s]) => t[s]);
    if (Bn(h(this, mi, "f"), n))
      return;
    const o = n[n.length - 1];
    Reflect.set(h(this, ui, "f"), h(this, di, "f"), o), b(this, mi, n, "f");
  }
}
ui = /* @__PURE__ */ new WeakMap(), di = /* @__PURE__ */ new WeakMap(), fi = /* @__PURE__ */ new WeakMap(), mi = /* @__PURE__ */ new WeakMap();
var Lt;
(function(e) {
  e.create = "create", e.move = "move";
})(Lt || (Lt = {}));
function RO(e) {
  const t = [], n = /* @__PURE__ */ new Map();
  return e.forEach(({ type: o, value: r }, s) => {
    const i = {
      type: o,
      key: o === te.templateLiterals ? r.strings : r
    };
    t.push(i), n.set(i, s);
  }), {
    items: t,
    itemToIndex: n
  };
}
function LO(e) {
  const t = [], n = /* @__PURE__ */ new Map();
  return e.forEach((o, r) => {
    const s = dn(o), i = {
      type: s,
      key: s === te.templateLiterals ? o.strings : o
    };
    t.push(i), n.set(i, r);
  }), {
    items: t,
    itemToIndex: n
  };
}
function Xm(e, t, n) {
  const o = !!(n != null && n.strict), r = {
    update: [],
    delete: []
  }, s = /* @__PURE__ */ new Set(), i = [], a = e.items, l = t.items;
  return a.forEach((c, u) => {
    const d = l.findIndex((f, m) => c.type === f.type && c.key === f.key && !s.has(m));
    d === -1 ? o ? r.delete.push({ from: u }) : i.push(c) : (s.add(d), r.update.push({ action: Lt.move, from: u, to: d }));
  }), i.forEach((c) => {
    const u = e.itemToIndex.get(c), d = l.find((f, m) => c.type === f.type && !s.has(m));
    if (d) {
      const f = t.itemToIndex.get(d);
      s.add(f), r.update.push({ action: Lt.move, from: u, to: f });
    } else
      r.delete.push({ from: u });
  }), l.forEach((c, u) => {
    s.has(u) || r.update.push({
      action: Lt.create,
      from: -1,
      to: t.itemToIndex.get(c)
    });
  }), r.update.sort((c, u) => c.to - u.to), r;
}
var Nr, hi, Re, $r;
class eh {
  constructor(t, n) {
    Nr.set(this, void 0), hi.set(this, void 0), Re.set(this, []), b(this, Nr, t, "f"), b(this, hi, n, "f");
  }
  commit(t) {
    const n = Xm(RO(h(this, Re, "f")), LO(t)), o = { length: t.length };
    n.update.forEach(({ action: r, from: s, to: i }) => {
      switch (r) {
        case Lt.create:
          const a = document.createComment("");
          i === 0 ? et(a, h(this, Nr, "f")) : h(this, Re, "f").length ? et(a, o[i - 1] ? o[i - 1].endNode : h(this, Re, "f")[i - 1].endNode) : Xe(a, h(this, hi, "f")), o[i] = new PO(a, t[i]);
          break;
        case Lt.move:
          if (o[i] = h(this, Re, "f")[s], i === s)
            return;
          i === 0 ? h(this, Re, "f")[s].insert("after", h(this, Nr, "f")) : h(this, Re, "f")[s].insert("after", o[i - 1] ? o[i - 1].endNode : h(this, Re, "f")[i - 1].endNode);
          break;
      }
    }), n.delete.forEach(({ from: r }) => h(this, Re, "f")[r].destroy()), b(this, Re, Array.from(o), "f"), h(this, Re, "f").forEach((r, s) => r.commit(t[s]));
  }
  destroy() {
    h(this, Re, "f").forEach((t) => t.destroy());
  }
}
Nr = /* @__PURE__ */ new WeakMap(), hi = /* @__PURE__ */ new WeakMap(), Re = /* @__PURE__ */ new WeakMap();
let PO = class {
  constructor(e, t) {
    $r.set(this, void 0), Object.defineProperty(this, "startNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "endNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Xe(this.startNode, e), et(this.endNode, e), $e(e), this.value = t, this.type = dn(t), b(this, $r, zo(this.type, this.startNode, this.endNode), "f");
  }
  commit(e) {
    h(this, $r, "f").commit(e), this.value = e;
  }
  insert(e, t) {
    const n = [
      this.startNode,
      ...ke(this.startNode, this.endNode),
      this.endNode
    ];
    e === "before" ? n.forEach((o) => Xe(o, t)) : n.reverse().forEach((o) => et(o, t));
  }
  destroy() {
    var e, t;
    (t = (e = h(this, $r, "f")).destroy) == null || t.call(e), ke(this.startNode, this.endNode).forEach($e), this.startNode.remove(), this.endNode.remove();
  }
};
$r = /* @__PURE__ */ new WeakMap();
var kr, Dr, pi, Rr, oo;
class th {
  constructor(t, n) {
    kr.set(this, void 0), Dr.set(this, void 0), pi.set(this, null), Rr.set(this, null), oo.set(this, void 0), b(this, kr, t, "f"), b(this, Dr, n, "f");
  }
  commit(t) {
    var n, o;
    if (!ah(t))
      return;
    const [r, s] = t;
    if (h(this, pi, "f") !== s)
      this.clear(), b(this, Rr, s({
        startNode: h(this, kr, "f"),
        endNode: h(this, Dr, "f")
      }), "f"), b(this, pi, s, "f"), b(this, oo, (n = h(this, Rr, "f")) == null ? void 0 : n.call(this, r), "f");
    else {
      const i = (o = h(this, Rr, "f")) == null ? void 0 : o.call(this, r);
      h(this, oo, "f") !== i && (this.clear(), b(this, oo, i, "f"));
    }
  }
  clear() {
    var t;
    (t = h(this, oo, "f")) == null || t.call(this), ke(h(this, kr, "f"), h(this, Dr, "f")).forEach($e);
  }
  destroy() {
    this.clear();
  }
}
kr = /* @__PURE__ */ new WeakMap(), Dr = /* @__PURE__ */ new WeakMap(), pi = /* @__PURE__ */ new WeakMap(), Rr = /* @__PURE__ */ new WeakMap(), oo = /* @__PURE__ */ new WeakMap();
class nh {
  constructor(t, n) {
  }
  commit(t) {
  }
}
var yi, ro;
class oh {
  constructor(t, n) {
    yi.set(this, void 0), ro.set(this, null), b(this, yi, n, "f");
  }
  commit(t) {
    h(this, ro, "f") !== t && (h(this, ro, "f") && $e(h(this, ro, "f")), Xe(t, h(this, yi, "f")), b(this, ro, t, "f"));
  }
}
yi = /* @__PURE__ */ new WeakMap(), ro = /* @__PURE__ */ new WeakMap();
var so, io, gi, ao, Lr;
class rh {
  constructor(t, n) {
    so.set(this, void 0), io.set(this, void 0), gi.set(this, null), ao.set(this, null), Lr.set(this, Cl), b(this, so, t, "f"), b(this, io, n, "f");
  }
  commit(t) {
    h(this, gi, "f") !== t && (this.clear(), yO(t) && this.promiseCommit(t));
  }
  promiseCommit(t) {
    const [n, o] = _O(t);
    b(this, Lr, o, "f"), n.then((r) => {
      var s;
      const i = dn(r);
      b(this, ao, zo(i, h(this, so, "f"), h(this, io, "f")), "f"), (s = h(this, ao, "f")) == null || s.commit(r);
    }), b(this, gi, t, "f");
  }
  partClear() {
    var t, n;
    (n = (t = h(this, ao, "f")) == null ? void 0 : t.destroy) == null || n.call(t), ke(h(this, so, "f"), h(this, io, "f")).forEach($e);
  }
  clear() {
    var t, n;
    h(this, Lr, "f").call(this), (n = (t = h(this, ao, "f")) == null ? void 0 : t.destroy) == null || n.call(t), ke(h(this, so, "f"), h(this, io, "f")).forEach($e), b(this, Lr, Cl, "f");
  }
  destroy() {
    this.clear();
  }
}
so = /* @__PURE__ */ new WeakMap(), io = /* @__PURE__ */ new WeakMap(), gi = /* @__PURE__ */ new WeakMap(), ao = /* @__PURE__ */ new WeakMap(), Lr = /* @__PURE__ */ new WeakMap();
function _O(e) {
  let t = Cl;
  const n = new Promise((r, s) => t = s), o = () => t();
  return [Promise.race([n, e]), o];
}
var vi, bi;
class sh {
  constructor(t, n) {
    vi.set(this, document.createTextNode("")), bi.set(this, null), et(h(this, vi, "f"), t);
  }
  commit(t) {
    h(this, bi, "f") !== t && (h(this, vi, "f").data = Ft(t) || ht(t) ? "" : String(t), b(this, bi, t, "f"));
  }
}
vi = /* @__PURE__ */ new WeakMap(), bi = /* @__PURE__ */ new WeakMap();
var Ii, Pr, lt;
class ih {
  constructor(t, n) {
    Ii.set(this, void 0), Pr.set(this, void 0), lt.set(this, null), b(this, Ii, t, "f"), b(this, Pr, n, "f");
  }
  commit(t) {
    const { strings: n, values: o } = t;
    h(this, lt, "f") && !h(this, lt, "f").equalStrings(n) && (h(this, lt, "f").destroy(), b(this, lt, null, "f")), h(this, lt, "f") || (b(this, lt, new uh(t, h(this, Ii, "f"), h(this, Pr, "f")), "f"), h(this, lt, "f").insert("before", h(this, Pr, "f"))), h(this, lt, "f").commit(o);
  }
  destroy() {
    var t;
    (t = h(this, lt, "f")) == null || t.destroy();
  }
}
Ii = /* @__PURE__ */ new WeakMap(), Pr = /* @__PURE__ */ new WeakMap(), lt = /* @__PURE__ */ new WeakMap();
var te;
(function(e) {
  e.primitive = "primitive", e.templateLiterals = "templateLiterals", e.array = "array", e.node = "node", e.object = "object", e.function = "function", e.directive = "directive";
})(te || (te = {}));
const ah = (e) => he(e) && Reflect.get(e, Tc) === Do.node, Hn = (e) => (t) => t instanceof e, BO = Hn(sh), WO = Hn(ih), UO = Hn(eh), jO = Hn(oh), FO = Hn(rh), qO = Hn(nh), HO = Hn(th), dn = (e) => us(e) ? te.primitive : Ia(e) ? te.templateLiterals : ah(e) ? te.directive : he(e) ? te.array : pO(e) ? te.node : sn(e) ? te.function : te.object, ZO = {
  [te.primitive]: BO,
  [te.templateLiterals]: WO,
  [te.array]: UO,
  [te.node]: jO,
  [te.function]: qO,
  [te.object]: FO,
  [te.directive]: HO
}, VO = {
  [te.primitive]: sh,
  [te.templateLiterals]: ih,
  [te.array]: eh,
  [te.node]: oh,
  [te.function]: nh,
  [te.object]: rh,
  [te.directive]: th
}, lh = (e, t) => ZO[e](t), zo = (e, t, n) => new VO[e](t, n);
var en, lo, Ti, El, _r, Tn, co, uo, wi, Br, Ci;
class zO {
  constructor(t, n, o, r) {
    var s;
    en.set(this, document.createComment("")), lo.set(this, document.createComment("")), Ti.set(this, void 0), El.set(this, void 0), _r.set(this, []), Tn.set(this, []), co.set(this, null), uo.set(this, fs({}, { shallow: !0 })), wi.set(this, null), Br.set(this, null), Ci.set(this, document.createElement("div")), b(this, en, t, "f"), b(this, lo, n, "f"), b(this, El, o, "f"), b(this, Ti, be(o.value)[0], "f"), o.staticAttrs && o.staticAttrs.forEach((i) => mO(h(this, uo, "f"), i)), (s = o.attrs) == null || s.forEach((i) => {
      i.type === J.directive ? h(this, _r, "f").push(i) : i.type === J.spread ? r.push(new Jm(h(this, uo, "f"), i)) : i.type === J.event ? r.push(new Qm(h(this, Ci, "f"), i)) : r.push(new DO(h(this, uo, "f"), i));
    });
  }
  createContext() {
    const t = h(this, en, "f"), n = {
      host: document.body,
      get parentElement() {
        return t.parentElement;
      },
      dispatchEvent: (r) => h(this, Ci, "f").dispatchEvent(r)
    }, o = h(this, en, "f").getRootNode();
    if (o instanceof ShadowRoot) {
      const r = o.host;
      n.host = r;
    } else if (o instanceof DocumentFragment) {
      const r = kO(o);
      r && (n.host = r);
    }
    return n;
  }
  commit(t) {
    const [, n] = h(this, Ti, "f"), o = t[n];
    if (!sn(o) || h(this, wi, "f") === o) {
      h(this, Tn, "f").forEach((a) => a.commit(t));
      return;
    }
    const r = this.createContext();
    this.clear(), _i(this);
    const s = o.call(r, h(this, uo, "f"), r);
    _i(null), h(this, _r, "f").length && h(this, Tn, "f").push(...h(this, _r, "f").map((a) => new Km(r, a))), Fe(this, pa);
    let i = !1;
    b(this, Br, wc(() => {
      var a;
      const l = s(), c = dn(l);
      lh(c, h(this, co, "f")) || (this.partClear(), b(this, co, zo(c, h(this, en, "f"), h(this, lo, "f")), "f")), Fe(this, i ? va : bc), (a = h(this, co, "f")) == null || a.commit(l), i ? Fe(this, ba) : (Fe(this, Ic), i = !0);
    }), "f"), h(this, Tn, "f").forEach((a) => a.commit(t)), Fe(this, ya), b(this, wi, o, "f");
  }
  partClear() {
    var t, n;
    (n = (t = h(this, co, "f")) == null ? void 0 : t.destroy) == null || n.call(t), ke(h(this, en, "f"), h(this, lo, "f")).forEach($e);
  }
  clear() {
    var t;
    ke(h(this, en, "f"), h(this, lo, "f")).forEach($e), Fe(this, ga), h(this, Tn, "f").forEach((n) => {
      var o;
      return (o = n.destroy) == null ? void 0 : o.call(n);
    }), (t = h(this, Br, "f")) == null || t.call(this), b(this, Tn, [], "f"), b(this, Br, null, "f"), nO(this);
  }
  destroy() {
    this.clear(), this.partClear();
  }
}
en = /* @__PURE__ */ new WeakMap(), lo = /* @__PURE__ */ new WeakMap(), Ti = /* @__PURE__ */ new WeakMap(), El = /* @__PURE__ */ new WeakMap(), _r = /* @__PURE__ */ new WeakMap(), Tn = /* @__PURE__ */ new WeakMap(), co = /* @__PURE__ */ new WeakMap(), uo = /* @__PURE__ */ new WeakMap(), wi = /* @__PURE__ */ new WeakMap(), Br = /* @__PURE__ */ new WeakMap(), Ci = /* @__PURE__ */ new WeakMap();
var ur, dr, fr, hd;
const GO = (hd = class {
  constructor(e, t, n) {
    ur.set(this, document.createComment("")), dr.set(this, document.createComment("")), fr.set(this, void 0), b(this, fr, new zO(h(this, ur, "f"), h(this, dr, "f"), t, n), "f"), Xe(h(this, ur, "f"), e), et(h(this, dr, "f"), e), e.remove();
  }
  commit(e) {
    h(this, fr, "f").commit(e);
  }
  destroy() {
    var e, t;
    (t = (e = h(this, fr, "f")).destroy) == null || t.call(e), h(this, ur, "f").remove(), h(this, dr, "f").remove();
  }
}, ur = /* @__PURE__ */ new WeakMap(), dr = /* @__PURE__ */ new WeakMap(), fr = /* @__PURE__ */ new WeakMap(), hd), YO = sO(GO);
var fo, mo, Oi, Ei, wn;
class KO {
  constructor(t, { value: n }) {
    fo.set(this, document.createComment("")), mo.set(this, document.createComment("")), Oi.set(this, void 0), Ei.set(this, null), wn.set(this, null), b(this, Oi, be(n)[0], "f"), Xe(h(this, fo, "f"), t), et(h(this, mo, "f"), t), t.remove();
  }
  commit(t) {
    var n;
    const [, o] = h(this, Oi, "f"), r = t[o];
    if (h(this, Ei, "f") === r)
      return;
    const s = dn(r);
    lh(s, h(this, wn, "f")) || (Ft(h(this, wn, "f")) || this.clear(), b(this, wn, zo(s, h(this, fo, "f"), h(this, mo, "f")), "f")), (n = h(this, wn, "f")) == null || n.commit(r), b(this, Ei, r, "f");
  }
  clear() {
    var t, n;
    (n = (t = h(this, wn, "f")) == null ? void 0 : t.destroy) == null || n.call(t), ke(h(this, fo, "f"), h(this, mo, "f")).forEach($e);
  }
  destroy() {
    this.clear(), h(this, fo, "f").remove(), h(this, mo, "f").remove();
  }
}
fo = /* @__PURE__ */ new WeakMap(), mo = /* @__PURE__ */ new WeakMap(), Oi = /* @__PURE__ */ new WeakMap(), Ei = /* @__PURE__ */ new WeakMap(), wn = /* @__PURE__ */ new WeakMap();
function ch(e = [], t, n = !1, o = []) {
  return e.forEach((r) => {
    if (r.isComponent) {
      const i = document.createComment("");
      t.appendChild(i), o.push(new YO(i, r, o));
      return;
    }
    const s = dO(r, r.isSvg || n);
    t.appendChild(s), s instanceof Comment && r.isMarker && o.push(new NO(s, r)), s instanceof Text && r.isMarkerOnly && o.push(new KO(s, r)), s instanceof Element && (r.staticAttrs && r.staticAttrs.forEach((i) => fO(s, i)), r.attrs && o.push(...r.attrs.map((i) => MO(s, i))), r.children && ch(r.children, s, r.isSvg || n, o));
  }), o;
}
function QO(e, t = !1) {
  const n = document.createDocumentFragment(), o = ch(e.children, n, t);
  return [n, o];
}
var ho, po, tn, Wr, Si, xi;
class uh {
  constructor(t, n, o) {
    if (ho.set(this, document.createComment("")), po.set(this, document.createComment("")), tn.set(this, null), Wr.set(this, []), Si.set(this, void 0), xi.set(this, !1), b(this, Si, t.strings, "f"), t[Nn] !== St.html && t[Nn] !== St.svg)
      return;
    const [r, s] = QO(t.template.node, TO(t[Nn]));
    b(this, tn, r, "f"), b(this, Wr, s, "f"), n && o ? (b(this, ho, n, "f"), b(this, po, o, "f"), b(this, xi, !0, "f")) : (r.prepend(h(this, ho, "f")), r.append(h(this, po, "f")));
  }
  equalStrings(t) {
    return h(this, Si, "f") === t;
  }
  commit(t) {
    h(this, Wr, "f").forEach((n) => n.commit(t));
  }
  insert(t, n) {
    h(this, tn, "f") && (t === "before" ? Xe(h(this, tn, "f"), n) : t === "after" ? et(h(this, tn, "f"), n) : n.appendChild(h(this, tn, "f")), b(this, tn, null, "f"));
  }
  destroy() {
    h(this, Wr, "f").forEach((t) => {
      var n;
      return (n = t.destroy) == null ? void 0 : n.call(t);
    }), ke(h(this, ho, "f"), h(this, po, "f")).forEach($e), h(this, xi, "f") || (h(this, ho, "f").remove(), h(this, po, "f").remove());
  }
}
ho = /* @__PURE__ */ new WeakMap(), po = /* @__PURE__ */ new WeakMap(), tn = /* @__PURE__ */ new WeakMap(), Wr = /* @__PURE__ */ new WeakMap(), Si = /* @__PURE__ */ new WeakMap(), xi = /* @__PURE__ */ new WeakMap();
const mr = /* @__PURE__ */ new WeakMap();
function JO(e, t) {
  var n;
  if (!Ia(t)) {
    mr.has(e) && ((n = mr.get(e)) == null || n.destroy(), mr.delete(e));
    return;
  }
  const { strings: o, values: r } = t, s = mr.get(e);
  if (s != null && s.equalStrings(o))
    s == null || s.commit(r);
  else {
    const i = new uh(t);
    s == null || s.destroy(), mr.set(e, i), i.insert("children", e), i.commit(r);
  }
}
function XO(e, t) {
  return (...n) => Ym(Do.attribute, [e(...n), t]);
}
const uL = (e) => ({ value: e }), dL = XO((e) => e, ({ node: e }) => {
  let t = null;
  const n = () => {
    t && (t.value = null);
  };
  return (o) => (t === o || (o.value = e, t = o), n);
});
function Oc(e, t) {
  return (...n) => Ym(Do.node, [
    e(...n),
    t
  ]);
}
const fL = Oc((e) => e, ({ startNode: e, endNode: t }) => {
  const n = e.getRootNode();
  let o = /* @__PURE__ */ new Map(), r = null;
  const s = (u) => Ia(u) ? u.strings : u, i = (u) => {
    const d = s(u);
    return o.has(d) ? o.get(d) : null;
  }, a = (u, d) => {
    o.set(s(u), d);
  }, l = (u) => {
    const d = dn(u), f = zo(d, e, t), m = document.createDocumentFragment(), p = $1(m, n), g = $O(m, n);
    return {
      part: f,
      fragment: m,
      destroy: () => {
        var v;
        p(), g(), (v = f.destroy) == null || v.call(f);
      }
    };
  }, c = () => {
    o.forEach(({ destroy: u }) => u()), o = /* @__PURE__ */ new Map(), ke(e, t).forEach($e);
  };
  return (u) => {
    const d = i(r), f = i(u);
    if (d && s(r) !== s(u) && ke(e, t).forEach((m) => d.fragment.appendChild(m)), f)
      s(r) !== s(u) && Xe(f.fragment, t), f.part.commit(u);
    else {
      const m = l(u);
      a(u, m), m.part.commit(u);
    }
    return r = u, c;
  };
}), mL = Oc((e) => e, ({ startNode: e, endNode: t }) => {
  let n = null;
  const o = () => {
    ke(e, t).forEach($e);
  };
  return (r) => {
    if (n === r)
      return o;
    o();
    const s = document.createElement("template");
    return s.innerHTML = r, Xe(s.content, t), n = r, o;
  };
});
var Ur;
const hL = Oc((e, t, n) => (e.length, [e, t, n]), ({ startNode: e, endNode: t }) => {
  let n = [];
  const o = () => {
    n.forEach((r) => r.destroy());
  };
  return ([r, s, i]) => {
    const a = nE(r, s, i), l = a.values, c = Xm(tE(n), a, {
      strict: !0
    }), u = { length: l.length };
    return c.update.forEach(({ action: d, from: f, to: m }) => {
      switch (d) {
        case Lt.create:
          const p = document.createComment("");
          m === 0 ? et(p, e) : n.length ? et(p, u[m - 1] ? u[m - 1].endNode : n[m - 1].endNode) : Xe(p, t), u[m] = new eE(p, l[m].value, l[m].key);
          break;
        case Lt.move:
          if (u[m] = n[f], m === f)
            return;
          m === 0 ? n[f].insert("after", e) : n[f].insert("after", u[m - 1] ? u[m - 1].endNode : n[m - 1].endNode);
          break;
      }
    }), c.delete.forEach(({ from: d }) => n[d].destroy()), n = Array.from(u), n.forEach((d, f) => d.commit(l[f].value)), o;
  };
});
class eE {
  constructor(t, n, o) {
    Ur.set(this, void 0), Object.defineProperty(this, "startNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "endNode", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: document.createComment("")
    }), Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "key", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Xe(this.startNode, t), et(this.endNode, t), $e(t), this.key = o, this.type = dn(n), b(this, Ur, zo(this.type, this.startNode, this.endNode), "f");
  }
  commit(t) {
    h(this, Ur, "f").commit(t);
  }
  insert(t, n) {
    const o = [
      this.startNode,
      ...ke(this.startNode, this.endNode),
      this.endNode
    ];
    t === "before" ? o.forEach((r) => Xe(r, n)) : o.reverse().forEach((r) => et(r, n));
  }
  destroy() {
    var t, n;
    (n = (t = h(this, Ur, "f")).destroy) == null || n.call(t), ke(this.startNode, this.endNode).forEach($e), this.startNode.remove(), this.endNode.remove();
  }
}
Ur = /* @__PURE__ */ new WeakMap();
function tE(e) {
  const t = [], n = /* @__PURE__ */ new Map();
  return e.forEach(({ type: o, key: r }, s) => {
    const i = { type: o, key: r };
    t.push(i), n.set(i, s);
  }), {
    items: t,
    itemToIndex: n
  };
}
function nE(e, t, n) {
  const o = [], r = /* @__PURE__ */ new Map(), s = [];
  return e.forEach((i, a, l) => {
    const c = {
      key: t(i),
      value: n(i, a, l)
    }, u = { type: dn(c.value), key: c.key };
    s.push(c), o.push(u), r.set(u, a);
  }), {
    items: o,
    itemToIndex: r,
    values: s
  };
}
const oE = (e) => he(e) ? e : ht(e) ? [] : Object.keys(e), rE = (e) => ht(e) || he(e) ? [] : Object.keys(e).filter((t) => {
  const n = e[t];
  return n === Number || n === String || n === Boolean || Oe(n) && !ht(n.default);
}).map((t) => {
  const n = e[t];
  return Oe(n) ? [t, Reflect.get(n, "default")] : n === Number ? [t, 0] : n === Boolean ? [t, !1] : [t, ""];
}), sE = (e) => ht(e) || he(e) ? [] : Object.keys(e).filter((t) => {
  const n = e[t];
  return sn(n) || Oe(n) && !ht(n.type);
}).map((t) => {
  const n = e[t];
  return Oe(n) ? [t, Reflect.get(n, "type")] : [t, n];
});
var A;
(function(e) {
  e.string = "string", e.whiteSpace = "whiteSpace", e.nextLine = "nextLine", e.equal = "equal", e.tilde = "tilde", e.plus = "plus", e.sharp = "sharp", e.asterisk = "asterisk", e.commercialAt = "commercialAt", e.ampersand = "ampersand", e.period = "period", e.comma = "comma", e.colon = "colon", e.semicolon = "semicolon", e.gt = "gt", e.leftBrace = "leftBrace", e.rightBrace = "rightBrace", e.leftBracket = "leftBracket", e.rightBracket = "rightBracket", e.leftParent = "leftParent", e.rightParent = "rightParent";
})(A || (A = {}));
var Dt;
(function(e) {
  e.style = "style", e.multiComment = "multiComment", e.singleComment = "singleComment";
})(Dt || (Dt = {}));
const X = {
  doubleQuote: '"',
  singleQuote: "'",
  whiteSpace: /\s/,
  string: /\S/,
  breakString: /:|;|{|&|#|@|,|>|~|\(|\)|\[|\.|\+/,
  nextLine: `
`,
  slash: "/",
  equal: "=",
  tilde: "~",
  plus: "+",
  sharp: "#",
  asterisk: "*",
  commercialAt: "@",
  ampersand: "&",
  period: ".",
  comma: ",",
  colon: ":",
  semicolon: ";",
  gt: ">",
  leftBrace: "{",
  rightBrace: "}",
  leftBracket: "[",
  rightBracket: "]",
  leftParent: "(",
  rightParent: ")"
}, se = (e) => (t) => e === t, Za = (e) => (t) => e.test(t), B = {
  doubleQuote: se(X.doubleQuote),
  singleQuote: se(X.singleQuote),
  whiteSpace: Za(X.whiteSpace),
  string: Za(X.string),
  breakString: Za(X.breakString),
  nextLine: se(X.nextLine),
  slash: se(X.slash),
  equal: se(X.equal),
  tilde: se(X.tilde),
  plus: se(X.plus),
  sharp: se(X.sharp),
  asterisk: se(X.asterisk),
  commercialAt: se(X.commercialAt),
  ampersand: se(X.ampersand),
  period: se(X.period),
  comma: se(X.comma),
  colon: se(X.colon),
  semicolon: se(X.semicolon),
  gt: se(X.gt),
  leftBrace: se(X.leftBrace),
  rightBrace: se(X.rightBrace),
  leftBracket: se(X.leftBracket),
  rightBracket: se(X.rightBracket),
  leftParent: se(X.leftParent),
  rightParent: se(X.rightParent)
}, iE = (e) => (t) => B.slash(e[t]) && B.asterisk(e[t + 1]), aE = (e) => (t) => B.asterisk(e[t]) && B.slash(e[t + 1]), lE = (e) => (t) => B.slash(e[t]) && B.slash(e[t + 1]);
function cE(e) {
  const t = [];
  let n = 0;
  const o = () => n < e.length, r = iE(e), s = aE(e), i = lE(e), a = (l = Dt.style) => {
    for (; o(); ) {
      let c = e[n];
      if (B.nextLine(c)) {
        if (t.push({ type: A.nextLine, value: c }), n++, l === Dt.singleComment)
          break;
        continue;
      }
      if (B.whiteSpace(c)) {
        let u = "";
        for (; o() && B.whiteSpace(c) && !B.nextLine(c); )
          u += c, c = e[++n];
        t.push({ type: A.whiteSpace, value: u });
        continue;
      }
      if (l === Dt.style) {
        if (r(n)) {
          a(Dt.multiComment);
          continue;
        }
        if (i(n)) {
          a(Dt.singleComment);
          continue;
        }
        if (B.colon(c)) {
          t.push({ type: A.colon, value: c });
          let u = "";
          if (c = e[++n], B.whiteSpace(c)) {
            for (u = ""; o() && B.whiteSpace(c); )
              u += c, c = e[++n];
            t.push({ type: A.whiteSpace, value: u }), u = "";
          } else
            continue;
          for (; o() && !B.semicolon(c); )
            u += c, c = e[++n];
          t.push({ type: A.string, value: u });
          continue;
        }
        if (B.leftBracket(c)) {
          t.push({ type: A.leftBracket, value: c });
          let u = "";
          for (c = e[++n]; o() && !B.equal(c) && !B.rightBracket(c); )
            u += c, c = e[++n];
          if (t.push({ type: A.string, value: u }), B.equal(c) && (t.push({ type: A.equal, value: c }), c = e[++n]), B.doubleQuote(c)) {
            let d = "";
            for (c = e[++n]; o() && !B.doubleQuote(c); )
              d += c, c = e[++n];
            t.push({ type: A.string, value: d }), c = e[++n];
          }
          if (B.singleQuote(c)) {
            let d = "";
            for (c = e[++n]; o() && !B.singleQuote(c); )
              d += c, c = e[++n];
            t.push({ type: A.string, value: d }), c = e[++n];
          }
          B.rightBracket(c) && (t.push({ type: A.rightBracket, value: c }), n++);
          continue;
        }
        if (B.commercialAt(c)) {
          t.push({ type: A.commercialAt, value: c }), n++;
          continue;
        }
        if (B.gt(c)) {
          t.push({ type: A.gt, value: c }), n++;
          continue;
        }
        if (B.tilde(c)) {
          t.push({ type: A.tilde, value: c }), n++;
          continue;
        }
        if (B.plus(c)) {
          t.push({ type: A.plus, value: c }), n++;
          continue;
        }
        if (B.sharp(c)) {
          t.push({ type: A.sharp, value: c }), n++;
          continue;
        }
        if (B.asterisk(c)) {
          t.push({ type: A.asterisk, value: c }), n++;
          continue;
        }
        if (B.ampersand(c)) {
          t.push({ type: A.ampersand, value: c }), n++;
          continue;
        }
        if (B.period(c)) {
          t.push({ type: A.period, value: c }), n++;
          continue;
        }
        if (B.comma(c)) {
          t.push({ type: A.comma, value: c }), n++;
          continue;
        }
        if (B.semicolon(c)) {
          t.push({ type: A.semicolon, value: c }), n++;
          continue;
        }
        if (B.leftBrace(c)) {
          t.push({ type: A.leftBrace, value: c }), n++;
          continue;
        }
        if (B.rightBrace(c)) {
          t.push({ type: A.rightBrace, value: c }), n++;
          continue;
        }
        if (B.leftParent(c)) {
          let u = "";
          for (; o() && !B.rightParent(c); )
            u += c, c = e[++n];
          u += c, t.push({ type: A.string, value: u }), n++;
          continue;
        }
      } else if (l === Dt.multiComment) {
        if (s(n)) {
          t.push({ type: A.string, value: "*/" }), n += 2;
          break;
        }
      } else if (l === Dt.singleComment && B.nextLine(c)) {
        t.push({ type: A.nextLine, value: c }), n++;
        break;
      }
      if (B.string(c)) {
        let u = "";
        for (; o() && B.string(c) && !B.breakString(c); )
          u += c, c = e[++n];
        t.push({ type: A.string, value: u });
        continue;
      }
      n++;
    }
  };
  return a(), t;
}
const dh = new RegExp(`^${an.replace("@@", "")}_\\d+_$`), fh = (e) => (t) => (n) => (o) => n[o] ? n[o][e] === t : !1, ce = fh("type"), Ec = fh("value"), fn = ce(A.string), uE = ce(A.whiteSpace), mh = ce(A.nextLine);
ce(A.equal);
ce(A.tilde);
ce(A.plus);
const dE = ce(A.sharp), fE = ce(A.asterisk), Sc = ce(A.commercialAt), mE = ce(A.ampersand), hE = ce(A.period);
ce(A.comma);
const hh = ce(A.colon), xc = ce(A.semicolon);
ce(A.gt);
const pE = ce(A.leftBrace), yE = ce(A.rightBrace);
ce(A.leftBracket);
ce(A.rightBracket);
ce(A.leftParent);
ce(A.rightParent);
const gE = Ec("/*"), vE = Ec("*/"), bE = Ec("//"), IE = mh, TE = (e) => {
  const t = fn(e), n = hh(e), o = uE(e), r = xc(e);
  return (s) => t(s) && n(s + 1) && o(s + 2) && t(s + 3) && r(s + 4);
}, wE = (e) => {
  const t = Sc(e), n = fn(e), o = xc(e), r = mh(e);
  return (s) => t(s) && t(s + 1) && n(s + 2) && dh.test(e[s + 2].value) && (r(s + 3) || o(s + 3));
}, ph = (e) => {
  const t = Sc(e), n = fn(e);
  return (o) => t(o) && n(o + 1);
}, CE = (e) => {
  const t = hh(e), n = fn(e);
  return (o) => t(o) && n(o + 1);
}, OE = fn, EE = fE, SE = (e) => {
  const t = Sc(e), n = fn(e);
  return (o) => t(o) && t(o + 1) && n(o + 2) && dh.test(e[o + 2].value);
}, xE = (e) => {
  const t = OE(e), n = EE(e), o = mE(e), r = SE(e);
  return (s) => t(s) || n(s) || o(s) || r(s);
}, AE = (e) => {
  const t = hE(e), n = fn(e);
  return (o) => t(o) && n(o + 1);
}, ME = (e) => {
  const t = dE(e), n = fn(e);
  return (o) => t(o) && n(o + 1);
}, NE = (e) => {
  const t = xE(e), n = AE(e), o = ME(e), r = ph(e), s = CE(e);
  return (i) => t(i) || n(i) || o(i) || r(i) || s(i);
}, yh = (e) => (t) => (n) => {
  var o;
  n && (t[e] ? (o = t[e]) == null || o.push(n) : t[e] = [n]);
}, pd = yh("properties"), hr = yh("children");
var Ne;
(function(e) {
  e.style = "style", e.comment = "comment", e.atRule = "atRule";
})(Ne || (Ne = {}));
let Ls = class {
  constructor(t = {}) {
    Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: Ne.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "properties", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.assign(this, t);
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
};
function $E(e) {
  let t = 0;
  const n = () => t < e.length, o = TE(e), r = wE(e), s = NE(e), i = pE(e), a = yE(e), l = gE(e), c = vE(e), u = bE(e), d = IE(e), f = ph(e), m = xc(e), p = (g, v, T = Ne.style) => {
    const O = new Ls({
      type: T,
      parent: g,
      value: v
    });
    for (; n(); ) {
      let k = e[t];
      if (a(t)) {
        t++;
        break;
      }
      if (o(t)) {
        const R = e[t + 3];
        pd(O)({
          name: k.value,
          value: R.value
        }), t += 5;
        continue;
      } else if (r(t)) {
        const R = `@@${e[t + 2].value}`;
        pd(O)({
          name: R,
          value: R
        }), t += 4;
        continue;
      } else if (l(t)) {
        let R = k.value;
        for (k = e[++t]; n() && !c(t); )
          R += k.value, k = e[++t];
        R += k.value, hr(O)(new Ls({
          type: Ne.comment,
          parent: O,
          value: R
        })), t++;
        continue;
      } else if (u(t)) {
        let R = k.value;
        for (k = e[++t]; n() && !d(t); )
          R += k.value, k = e[++t];
        hr(O)(new Ls({
          type: Ne.comment,
          parent: O,
          value: R
        })), t++;
        continue;
      } else if (f(t)) {
        let R = k.value;
        for (k = e[++t]; n() && !i(t) && !m(t); )
          R += k.value, k = e[++t];
        if (m(t)) {
          R += ";", hr(O)(new Ls({
            type: Ne.atRule,
            parent: O,
            value: R
          })), t++;
          continue;
        }
        hr(O)(p(O, R.trim(), Ne.atRule));
        continue;
      } else if (s(t)) {
        let R = k.value;
        for (k = e[++t]; n() && !i(t); )
          R += k.value, k = e[++t];
        hr(O)(p(O, R.trim()));
        continue;
      }
      t++;
    }
    return O;
  };
  return p(null);
}
const kE = (e) => $E(cE(e));
var ye;
(function(e) {
  e.string = "string", e.whiteSpace = "whiteSpace", e.lt = "lt", e.gt = "gt", e.slash = "slash", e.equal = "equal";
})(ye || (ye = {}));
var nn;
(function(e) {
  e.element = "element", e.text = "text", e.comment = "comment";
})(nn || (nn = {}));
const rt = {
  doubleQuote: '"',
  singleQuote: "'",
  whiteSpace: /\s/,
  string: /\S/,
  breakString: /<|>|=/,
  lt: "<",
  gt: ">",
  slash: "/",
  equal: "=",
  hyphen: "-",
  exclamationPoint: "!"
}, zt = (e) => (t) => e === t, Va = (e) => (t) => e.test(t), ue = {
  doubleQuote: zt(rt.doubleQuote),
  singleQuote: zt(rt.singleQuote),
  whiteSpace: Va(rt.whiteSpace),
  string: Va(rt.string),
  breakString: Va(rt.breakString),
  lt: zt(rt.lt),
  gt: zt(rt.gt),
  slash: zt(rt.slash),
  equal: zt(rt.equal),
  hyphen: zt(rt.hyphen),
  exclamationPoint: zt(rt.exclamationPoint)
}, DE = (e) => (t) => ue.lt(e[t]) && ue.exclamationPoint(e[t + 1]) && ue.hyphen(e[t + 2]) && ue.hyphen(e[t + 3]);
function RE(e) {
  const t = [];
  let n = 0;
  const o = () => n < e.length, r = DE(e), s = (i = nn.text) => {
    for (; o(); ) {
      let a = e[n];
      if (ue.whiteSpace(a)) {
        let l = "";
        for (; o() && ue.whiteSpace(a); )
          l += a, a = e[++n];
        i !== nn.element && t.push({ type: ye.whiteSpace, value: l });
        continue;
      }
      if (i === nn.element) {
        if (ue.lt(a)) {
          t.push({ type: ye.lt, value: a }), n++;
          continue;
        }
        if (ue.gt(a)) {
          t.push({ type: ye.gt, value: a }), n++;
          break;
        }
        if (ue.slash(a)) {
          t.push({ type: ye.slash, value: a }), n++;
          continue;
        }
        if (ue.equal(a)) {
          t.push({ type: ye.equal, value: a }), n++;
          continue;
        }
        if (ue.doubleQuote(a)) {
          let l = "";
          for (a = e[++n]; o() && !ue.doubleQuote(a); )
            l += a, a = e[++n];
          t.push({ type: ye.string, value: l }), n++;
          continue;
        }
        if (ue.singleQuote(a)) {
          let l = "";
          for (a = e[++n]; o() && !ue.singleQuote(a); )
            l += a, a = e[++n];
          t.push({ type: ye.string, value: l }), n++;
          continue;
        }
      } else if (i === nn.comment) {
        if (ue.lt(a)) {
          t.push({ type: ye.lt, value: a }), n++;
          continue;
        }
        if (ue.gt(a)) {
          t.push({ type: ye.gt, value: a }), n++;
          break;
        }
      } else if (ue.lt(a)) {
        s(r(n) ? nn.comment : nn.element);
        continue;
      }
      if (ue.string(a)) {
        let l = "";
        for (; o() && ue.string(a) && !ue.breakString(a); )
          l += a, a = e[++n];
        t.push({ type: ye.string, value: l });
        continue;
      }
      n++;
    }
  };
  return s(), t;
}
const LE = /^(area|base|br|col|embed|hr|img|input|keygen|link|meta|param|source|track|wbr)$/i, gh = (e) => (t) => (n) => (o) => n[o] ? n[o][e] === t : !1, Go = gh("type"), vh = gh("value"), hs = Go(ye.lt), ps = Go(ye.gt), Ac = Go(ye.slash), PE = Go(ye.equal), ys = Go(ye.string), _E = Go(ye.whiteSpace), BE = vh("!--"), WE = vh("--"), UE = (e) => {
  const t = hs(e), n = ys(e);
  return (o) => t(o) && n(o + 1);
}, jE = (e) => {
  const t = hs(e), n = Ac(e), o = ys(e), r = ps(e);
  return (s) => t(s) && n(s + 1) && o(s + 2) && r(s + 3);
}, FE = (e) => {
  const t = hs(e), n = Ac(e), o = ps(e);
  return (r) => t(r) && n(r + 1) && n(r + 2) && o(r + 3);
}, qE = (e) => {
  const t = Ac(e), n = ps(e);
  return (o) => t(o) && n(o + 1);
}, HE = (e) => LE.test(e), ZE = (e) => {
  const t = hs(e), n = ys(e), o = BE(e);
  return (r) => t(r) && n(r + 1) && o(r + 1);
}, VE = (e) => {
  const t = ys(e), n = WE(e), o = ps(e);
  return (r) => t(r) && n(r) && o(r + 1);
}, bh = (e) => (t) => (n) => {
  var o;
  n && (t[e] ? (o = t[e]) == null || o.push(n) : t[e] = [n]);
}, zE = bh("attrs"), yd = bh("children");
function GE(e) {
  const t = new Sn({
    type: Ce.element,
    value: "template",
    children: []
  });
  let n = 0;
  const o = () => n < e.length, r = UE(e), s = jE(e), i = FE(e), a = qE(e), l = ZE(e), c = VE(e), u = hs(e), d = ps(e), f = PE(e), m = _E(e), p = ys(e), g = () => {
    let T = e[n];
    const O = { name: T.value };
    return T = e[++n], f(n) && (T = e[++n], p(n) && (O.value = T.value, n++)), O;
  }, v = (T) => {
    let O = e[n];
    if (m(n) || p(n)) {
      let k = O.value;
      for (O = e[++n]; o() && !u(n); )
        k += O.value, O = e[++n];
      return k = k.trim(), k.length ? new Sn({ parent: T, type: Ce.text, value: k }) : null;
    }
    if (l(n)) {
      let k = "";
      for (n += 2, O = e[n]; o() && !c(n); )
        k += O.value, O = e[++n];
      return c(n) && (n += 2), new Sn({ parent: T, type: Ce.comment, value: k });
    }
    if (r(n)) {
      O = e[++n];
      const k = HE(O.value), R = new Sn({
        parent: T,
        type: Ce.element,
        value: O.value.toLowerCase()
      });
      for (n++; o() && !d(n) && !a(n); ) {
        if (p(n)) {
          zE(R)(g());
          continue;
        }
        n++;
      }
      if (a(n))
        return n += 2, R;
      for (O = e[++n]; o() && !s(n) && !i(n) && !k; )
        yd(R)(v(R));
      return (s(n) || i(n)) && (n += 4), R;
    }
    return n++, null;
  };
  for (; o(); )
    yd(t)(v(t));
  return t;
}
const YE = (e) => GE(RE(e)), KE = (e, t) => e.reduce((n, o) => {
  const r = n[t(o)];
  return r ? r.push(o) : n[t(o)] = [o], n;
}, {});
class Io {
  get isMarker() {
    return ko(this.value);
  }
  get isMarkerOnly() {
    return ms(this.value);
  }
  get isSvg() {
    return this.type === Ce.element && /^svg$/i.test(this.value);
  }
  get isComponent() {
    return this.type === Ce.element && this.isMarkerOnly;
  }
  constructor(t, n = null) {
    if (Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: Ce.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    }), Object.defineProperty(this, "staticAttrs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "attrs", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.type = t.type, this.value = t.value, this.parent = n, t.attrs) {
      const [o, r] = XE(t.attrs);
      o.length && (this.staticAttrs = o), r.length && (this.attrs = r);
    }
    t.children && (this.children = t.children.map((o) => new Io(o, this)));
  }
  insert(t, n, o) {
    if (this.children) {
      const r = t === "before" ? 0 : 1;
      this.children.includes(o) && this.children.splice(this.children.indexOf(o) + r, 0, n);
    } else
      this.children = [n];
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
}
function QE(e) {
  const t = new Io(e);
  for (const n of t)
    n.type === Ce.text && !ms(n.value) && JE(n);
  return t;
}
function JE(e) {
  const t = be(e.value);
  e.value.replace(Il, an).split(an).reduce((n, o, r) => (r < t.length ? n.push(new Io(new Sn({ type: Ce.text, value: o }), e.parent), new Io(new Sn({ type: Ce.text, value: t[r][0] }), e.parent)) : n.push(new Io(new Sn({ type: Ce.text, value: o }), e.parent)), n), []).filter((n) => n.value !== "" && !(!n.value.trim() && J1.test(n.value))).reverse().forEach((n, o, { length: r }) => o === r - 1 ? e.value = n.value : e.parent && e.parent.insert("after", n, e));
}
function XE(e = []) {
  const t = KE(e, (n) => Rs(n.name));
  return Object.keys(t).map((n) => t[n]).reduce((n, o) => {
    const [r, s] = n, i = o[o.length - 1], a = fd(i.name);
    if (a === J.event)
      s.push(...o.filter((l) => !!l.value).map((l) => ({
        type: fd(l.name),
        name: Rs(l.name),
        value: l.value
      })));
    else if (a === J.attribute) {
      const l = o.filter((u) => !!u.value).map((u) => u.value).join(" "), c = { type: a, name: Rs(i.name) };
      l && (c.value = l), dd(c) ? s.push(c) : r.push(c);
    } else {
      const l = { type: a, name: Rs(i.name) };
      i.value && (l.value = i.value), dd(l) ? s.push(l) : r.push(l);
    }
    return n;
  }, [[], []]);
}
const Ih = (e) => (t, ...n) => {
  const o = {
    strings: t,
    values: n,
    [Nn]: e
  };
  if (qa.has(t)) {
    const i = qa.get(t);
    return o.template = i, o;
  }
  const r = t.reduce((i, a, l) => (l < n.length ? i.push(a, Fm(l)) : i.push(a), i), []).join(""), s = QE(YE(r));
  return o.template = Object.freeze({ node: s }), qa.set(t, o.template), o;
}, eS = Ih(St.html), yL = Ih(St.svg), Th = (e) => us(e) && !Ft(e) && !ht(e) ? String(e) : Ta(e) ? `.${String(e)}` : "", wh = (e) => (t, [n, o]) => t.replace(new RegExp(n), Th(e[o]));
class Mc {
  get selector() {
    return [...this.iterParent()].reverse().map((t) => t.toString(!0)).join(" ");
  }
  get isAtRule() {
    return this.type === Ne.atRule;
  }
  constructor(t, n = null, o) {
    var r;
    if (Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: Ne.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "style", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: ""
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "skipParent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: !1
    }), this.type = t.type, this.value = t.value, this.parent = n, t.value && t.isMarker) {
      const a = wh(o), l = be(t.value).reduce(a, t.value);
      this.value = l;
    }
    this.value && t.isThis && this.parent && (this.value = this.value.replace(/\&/, (r = this.parent) == null ? void 0 : r.toString(!0)), this.skipParent = !0), this.value && t.isAtRule && this.parent && (this.skipParent = !0);
    const [s, i] = Ch(t, o);
    if (t.children && i.push(...t.children.map((a) => [a, o])), this.style = s, this.children = i.filter(([a]) => a.type === Ne.style || a.type === Ne.atRule).map(([a, l]) => new Mc(a, this, l)), this.value && t.isAtRule && this.parent && t.children) {
      let a = "";
      for (const l of this.children)
        l.value && (a += `${l.value} {
${l.style}}
`);
      this.style = a;
    }
  }
  toString(t = !1) {
    return this.value ? this.value : t ? `.${gd(this.style)}` : gd(this.style);
  }
  *iterParent() {
    if (yield this, !!this.parent)
      if (this.skipParent) {
        if (!this.parent.parent)
          return;
        yield* this.parent.parent.iterParent();
      } else
        yield* this.parent.iterParent();
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        t.isAtRule ? yield t : yield* t;
  }
}
const za = /* @__PURE__ */ new Map();
function gd(e) {
  if (za.has(e))
    return za.get(e);
  const t = CO();
  return za.set(e, t), t;
}
const Ps = (e, t) => `${e}: ${t};
`;
function Ch(e, t, n = ["", []]) {
  var o;
  const r = wh(t);
  return (o = e.properties) == null || o.forEach((s) => {
    if (s.isDynamic) {
      const i = be(s.name), a = t[i[0][1]];
      Ta(a) ? (a.template.node.children && n[1].push(...a.template.node.children.map((l) => [
        l,
        a.values
      ])), Ch(a.template.node, a.values, n)) : n[0] += Th(a);
    } else if (s.isMarkerName && s.isMarkerValue) {
      const i = be(s.name), a = be(s.value), l = i.reduce(r, s.name), c = a.reduce(r, s.value);
      n[0] += Ps(l, c);
    } else if (s.isMarkerName) {
      const i = be(s.name).reduce(r, s.name);
      n[0] += Ps(i, s.value);
    } else if (s.isMarkerValue) {
      const i = be(s.value).reduce(r, s.value);
      n[0] += Ps(s.name, i);
    } else
      n[0] += Ps(s.name, s.value);
  }), n;
}
const tS = {
  vCSSStyleSheetMap: /* @__PURE__ */ new Map(),
  hostContextMap: /* @__PURE__ */ new Map()
};
let Wi = globalThis.ShadowRoot && "adoptedStyleSheets" in Document.prototype && "replace" in CSSStyleSheet.prototype;
function gs() {
  return tS;
}
function vd(e, t) {
  const n = gs(), o = new Mc(e, null, t);
  return [...o].forEach((r) => {
    const s = r.selector;
    if (n.vCSSStyleSheetMap.has(s))
      return;
    const i = Wi ? new CSSStyleSheet() : null, a = Wi ? null : document.createElement("style"), l = r.isAtRule && !r.style ? `${s}` : `${s} {
${r.style}}`;
    i ? i.replaceSync(l) : a && (a.textContent = l), n.vCSSStyleSheetMap.set(s, {
      selector: s,
      cssText: l,
      style: r.style,
      sheet: i,
      styleElement: a
    }), Oh();
  }), String(o);
}
function Oh() {
  Wi ? nS() : oS();
}
function nS() {
  const e = gs(), t = Array.from(e.vCSSStyleSheetMap).map(([, { sheet: n }]) => n).filter(Boolean);
  Array.from(e.hostContextMap).forEach(([n]) => {
    n.adoptedStyleSheets = t;
  });
}
function oS() {
  const e = gs();
  Array.from(e.hostContextMap).forEach(([t, { vSheets: n, styleElements: o }]) => {
    Array.from(e.vCSSStyleSheetMap).filter(([, r]) => !n.has(r)).map(([, r]) => (n.add(r), r.styleElement ? document.importNode(r.styleElement, !0) : null)).filter(Boolean).forEach((r) => {
      t.appendChild(r), o.add(r);
    });
  });
}
function rS(e) {
  const t = gs();
  t.hostContextMap.has(e) || (t.hostContextMap.set(e, {
    vSheets: /* @__PURE__ */ new Set(),
    styleElements: /* @__PURE__ */ new Set()
  }), Oh());
}
function sS(e) {
  const t = gs(), n = t.hostContextMap.get(e);
  n && (Wi ? e.adoptedStyleSheets = [] : n.styleElements.forEach((o) => e.removeChild(o)), t.hostContextMap.delete(e));
}
const pn = Symbol.for("https://github.com/dineug/r-html#props");
function gL(e, t) {
  var n, o, r, s, i;
  t.shadow ?? (t.shadow = "open");
  const a = oE(t.observedProps), l = sE(t.observedProps), c = rE(t.observedProps), u = (i = class extends HTMLElement {
    static get observedAttributes() {
      return Array.from(/* @__PURE__ */ new Set([
        ...a,
        ...a.map((d) => gO(d))
      ]));
    }
    constructor() {
      super(), Object.defineProperty(this, s, {
        enumerable: !0,
        configurable: !0,
        writable: !0,
        value: fs({}, { shallow: !0 })
      }), n.set(this, null), o.set(this, this), r.set(this, void 0), Object.defineProperty(this, "host", {
        enumerable: !0,
        configurable: !0,
        writable: !0,
        value: this
      }), c.forEach(([d, f]) => Reflect.set(this[pn], cr(d), f)), t.shadow && b(this, o, this.attachShadow({ mode: t.shadow }), "f"), _i(this), b(this, r, t.render.call(this, this[pn], this), "f"), _i(null);
    }
    connectedCallback() {
      const d = this.getRootNode();
      d instanceof ShadowRoot && (this.host = d.host), h(this, o, "f") instanceof ShadowRoot && rS(h(this, o, "f")), Fe(this, pa);
      let f = !1;
      b(this, n, wc(() => {
        Fe(this, f ? va : bc), JO(h(this, o, "f"), eS`${h(this, r, "f").call(this)}`), f ? Fe(this, ba) : (Fe(this, Ic), f = !0);
      }), "f"), Fe(this, ya);
    }
    disconnectedCallback() {
      var d;
      (d = h(this, n, "f")) == null || d.call(this), b(this, n, null, "f"), Fe(this, ga), h(this, o, "f") instanceof ShadowRoot && sS(h(this, o, "f"));
    }
    attributeChangedCallback(d, f, m) {
      const p = l.find(([g]) => cr(g) === cr(d));
      p ? Reflect.set(this[pn], cr(d), p[1] === Boolean ? m === "true" || m === "" : p[1](m)) : Reflect.set(this[pn], cr(d), m);
    }
  }, n = /* @__PURE__ */ new WeakMap(), o = /* @__PURE__ */ new WeakMap(), r = /* @__PURE__ */ new WeakMap(), s = pn, i);
  a.forEach((d) => {
    Object.defineProperty(u.prototype, d, {
      get() {
        return Reflect.get(this[pn], d);
      },
      set(f) {
        Reflect.set(this[pn], d, f);
      }
    });
  }), customElements.define(e, u);
}
function I(e) {
  function t(n) {
    return {
      type: e,
      payload: n,
      timestamp: Date.now()
    };
  }
  return t.toString = () => `${e}`, t.type = e, t;
}
function* Sl(e, t, n) {
  for (const o of n)
    o != null && o[Symbol.iterator] ? yield* Sl(e, t, o) : sn(o) ? yield* Sl(e, t, o(e, t)) : yield o;
}
const Eh = (e, t, n) => [...Sl(e, t, n)], Ga = (...e) => (t) => e.reduce((n, o) => o(n), t);
function* iS(e) {
  yield e;
}
const Ya = function* (e) {
  for (const t of e)
    t.length && (yield t);
};
function aS({ context: e, state: t, reducers: n, enableObservable: o = !0 }) {
  const r = o ? fs(t) : t, s = es(), i = es();
  let a = Ga(Ya);
  const l = (g) => {
    const v = Reflect.get(n, g.type, n);
    ma(v, r, g, e);
  }, c = (...g) => {
    const v = Eh(r, e, g);
    s.next(v);
  }, u = (...g) => {
    Lm(() => c(...g));
  }, d = s.subscribe((g) => {
    const v = a(iS(g));
    for (const T of v)
      i.next(T);
  }), f = i.subscribe((g) => g.forEach(l)), m = (...g) => (a = Ga(...g, Ya), () => {
    a = Ga(Ya);
  }), p = () => {
    d(), f();
  };
  return {
    context: e,
    state: r,
    dispatch: u,
    dispatchSync: c,
    subscribe: i.subscribe,
    pipe: m,
    destroy: p
  };
}
class lS {
  get isMarkerName() {
    return ko(this.name);
  }
  get isMarkerValue() {
    return ko(this.value);
  }
  get isDynamic() {
    return ms(this.name) && this.name === this.value;
  }
  constructor(t) {
    Object.defineProperty(this, "name", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.name = t.name, this.value = t.value;
  }
}
class Nc {
  get isMarker() {
    return ko(this.value);
  }
  get isThis() {
    var t;
    return !!((t = this.value) != null && t.startsWith("&"));
  }
  get isAtRule() {
    return this.type === Ne.atRule;
  }
  constructor(t, n = null) {
    Object.defineProperty(this, "type", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: Ne.comment
    }), Object.defineProperty(this, "value", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "properties", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), Object.defineProperty(this, "parent", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: null
    }), Object.defineProperty(this, "children", {
      enumerable: !0,
      configurable: !0,
      writable: !0,
      value: void 0
    }), this.type = t.type, this.value = t.value, this.parent = n, t.properties && (this.properties = t.properties.map((o) => new lS(o))), t.children && (this.children = t.children.map((o) => new Nc(o, this)));
  }
  *iterParent() {
    yield this, this.parent && (yield* this.parent.iterParent());
  }
  *[Symbol.iterator]() {
    if (yield this, !!this.children)
      for (const t of this.children)
        yield* t;
  }
}
function cS(e) {
  return new Nc(e);
}
const vL = (e, ...t) => {
  const n = {
    strings: e,
    values: t,
    [Nn]: St.css
  };
  if (Ha.has(e)) {
    const i = Ha.get(e), a = vd(i.node, t);
    return n.template = i, n.toString = () => a, n;
  }
  const o = e.raw.reduce((i, a, l) => (l < t.length ? i.push(a, Fm(l)) : i.push(a), i), []).join(""), r = cS(kE(o)), s = vd(r, t);
  return n.template = Object.freeze({ node: r }), n.toString = () => s, Ha.set(e, n.template), n;
};
var xl = function(e, t) {
  return xl = Object.setPrototypeOf || { __proto__: [] } instanceof Array && function(n, o) {
    n.__proto__ = o;
  } || function(n, o) {
    for (var r in o)
      Object.prototype.hasOwnProperty.call(o, r) && (n[r] = o[r]);
  }, xl(e, t);
};
function mn(e, t) {
  if (typeof t != "function" && t !== null)
    throw new TypeError("Class extends value " + String(t) + " is not a constructor or null");
  xl(e, t);
  function n() {
    this.constructor = e;
  }
  e.prototype = t === null ? Object.create(t) : (n.prototype = t.prototype, new n());
}
function uS(e, t, n, o) {
  function r(s) {
    return s instanceof n ? s : new n(function(i) {
      i(s);
    });
  }
  return new (n || (n = Promise))(function(s, i) {
    function a(u) {
      try {
        c(o.next(u));
      } catch (d) {
        i(d);
      }
    }
    function l(u) {
      try {
        c(o.throw(u));
      } catch (d) {
        i(d);
      }
    }
    function c(u) {
      u.done ? s(u.value) : r(u.value).then(a, l);
    }
    c((o = o.apply(e, t || [])).next());
  });
}
function Sh(e, t) {
  var n = { label: 0, sent: function() {
    if (s[0] & 1)
      throw s[1];
    return s[1];
  }, trys: [], ops: [] }, o, r, s, i;
  return i = { next: a(0), throw: a(1), return: a(2) }, typeof Symbol == "function" && (i[Symbol.iterator] = function() {
    return this;
  }), i;
  function a(c) {
    return function(u) {
      return l([c, u]);
    };
  }
  function l(c) {
    if (o)
      throw new TypeError("Generator is already executing.");
    for (; i && (i = 0, c[0] && (n = 0)), n; )
      try {
        if (o = 1, r && (s = c[0] & 2 ? r.return : c[0] ? r.throw || ((s = r.return) && s.call(r), 0) : r.next) && !(s = s.call(r, c[1])).done)
          return s;
        switch (r = 0, s && (c = [c[0] & 2, s.value]), c[0]) {
          case 0:
          case 1:
            s = c;
            break;
          case 4:
            return n.label++, { value: c[1], done: !1 };
          case 5:
            n.label++, r = c[1], c = [0];
            continue;
          case 7:
            c = n.ops.pop(), n.trys.pop();
            continue;
          default:
            if (s = n.trys, !(s = s.length > 0 && s[s.length - 1]) && (c[0] === 6 || c[0] === 2)) {
              n = 0;
              continue;
            }
            if (c[0] === 3 && (!s || c[1] > s[0] && c[1] < s[3])) {
              n.label = c[1];
              break;
            }
            if (c[0] === 6 && n.label < s[1]) {
              n.label = s[1], s = c;
              break;
            }
            if (s && n.label < s[2]) {
              n.label = s[2], n.ops.push(c);
              break;
            }
            s[2] && n.ops.pop(), n.trys.pop();
            continue;
        }
        c = t.call(e, n);
      } catch (u) {
        c = [6, u], r = 0;
      } finally {
        o = s = 0;
      }
    if (c[0] & 5)
      throw c[1];
    return { value: c[0] ? c[1] : void 0, done: !0 };
  }
}
function Ro(e) {
  var t = typeof Symbol == "function" && Symbol.iterator, n = t && e[t], o = 0;
  if (n)
    return n.call(e);
  if (e && typeof e.length == "number")
    return {
      next: function() {
        return e && o >= e.length && (e = void 0), { value: e && e[o++], done: !e };
      }
    };
  throw new TypeError(t ? "Object is not iterable." : "Symbol.iterator is not defined.");
}
function Ui(e, t) {
  var n = typeof Symbol == "function" && e[Symbol.iterator];
  if (!n)
    return e;
  var o = n.call(e), r, s = [], i;
  try {
    for (; (t === void 0 || t-- > 0) && !(r = o.next()).done; )
      s.push(r.value);
  } catch (a) {
    i = { error: a };
  } finally {
    try {
      r && !r.done && (n = o.return) && n.call(o);
    } finally {
      if (i)
        throw i.error;
    }
  }
  return s;
}
function ji(e, t, n) {
  if (n || arguments.length === 2)
    for (var o = 0, r = t.length, s; o < r; o++)
      (s || !(o in t)) && (s || (s = Array.prototype.slice.call(t, 0, o)), s[o] = t[o]);
  return e.concat(s || Array.prototype.slice.call(t));
}
function To(e) {
  return this instanceof To ? (this.v = e, this) : new To(e);
}
function dS(e, t, n) {
  if (!Symbol.asyncIterator)
    throw new TypeError("Symbol.asyncIterator is not defined.");
  var o = n.apply(e, t || []), r, s = [];
  return r = {}, i("next"), i("throw"), i("return"), r[Symbol.asyncIterator] = function() {
    return this;
  }, r;
  function i(f) {
    o[f] && (r[f] = function(m) {
      return new Promise(function(p, g) {
        s.push([f, m, p, g]) > 1 || a(f, m);
      });
    });
  }
  function a(f, m) {
    try {
      l(o[f](m));
    } catch (p) {
      d(s[0][3], p);
    }
  }
  function l(f) {
    f.value instanceof To ? Promise.resolve(f.value.v).then(c, u) : d(s[0][2], f);
  }
  function c(f) {
    a("next", f);
  }
  function u(f) {
    a("throw", f);
  }
  function d(f, m) {
    f(m), s.shift(), s.length && a(s[0][0], s[0][1]);
  }
}
function fS(e) {
  if (!Symbol.asyncIterator)
    throw new TypeError("Symbol.asyncIterator is not defined.");
  var t = e[Symbol.asyncIterator], n;
  return t ? t.call(e) : (e = typeof Ro == "function" ? Ro(e) : e[Symbol.iterator](), n = {}, o("next"), o("throw"), o("return"), n[Symbol.asyncIterator] = function() {
    return this;
  }, n);
  function o(s) {
    n[s] = e[s] && function(i) {
      return new Promise(function(a, l) {
        i = e[s](i), r(a, l, i.done, i.value);
      });
    };
  }
  function r(s, i, a, l) {
    Promise.resolve(l).then(function(c) {
      s({ value: c, done: a });
    }, i);
  }
}
function bL(e, t, n, o) {
  if (n === "a" && !o)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof t == "function" ? e !== t || !o : !t.has(e))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return n === "m" ? o : n === "a" ? o.call(e) : o ? o.value : t.get(e);
}
function ve(e) {
  return typeof e == "function";
}
function xh(e) {
  var t = function(o) {
    Error.call(o), o.stack = new Error().stack;
  }, n = e(t);
  return n.prototype = Object.create(Error.prototype), n.prototype.constructor = n, n;
}
var Ka = xh(function(e) {
  return function(n) {
    e(this), this.message = n ? n.length + ` errors occurred during unsubscription:
` + n.map(function(o, r) {
      return r + 1 + ") " + o.toString();
    }).join(`
  `) : "", this.name = "UnsubscriptionError", this.errors = n;
  };
});
function Fi(e, t) {
  if (e) {
    var n = e.indexOf(t);
    0 <= n && e.splice(n, 1);
  }
}
var vs = function() {
  function e(t) {
    this.initialTeardown = t, this.closed = !1, this._parentage = null, this._finalizers = null;
  }
  return e.prototype.unsubscribe = function() {
    var t, n, o, r, s;
    if (!this.closed) {
      this.closed = !0;
      var i = this._parentage;
      if (i)
        if (this._parentage = null, Array.isArray(i))
          try {
            for (var a = Ro(i), l = a.next(); !l.done; l = a.next()) {
              var c = l.value;
              c.remove(this);
            }
          } catch (g) {
            t = { error: g };
          } finally {
            try {
              l && !l.done && (n = a.return) && n.call(a);
            } finally {
              if (t)
                throw t.error;
            }
          }
        else
          i.remove(this);
      var u = this.initialTeardown;
      if (ve(u))
        try {
          u();
        } catch (g) {
          s = g instanceof Ka ? g.errors : [g];
        }
      var d = this._finalizers;
      if (d) {
        this._finalizers = null;
        try {
          for (var f = Ro(d), m = f.next(); !m.done; m = f.next()) {
            var p = m.value;
            try {
              bd(p);
            } catch (g) {
              s = s ?? [], g instanceof Ka ? s = ji(ji([], Ui(s)), Ui(g.errors)) : s.push(g);
            }
          }
        } catch (g) {
          o = { error: g };
        } finally {
          try {
            m && !m.done && (r = f.return) && r.call(f);
          } finally {
            if (o)
              throw o.error;
          }
        }
      }
      if (s)
        throw new Ka(s);
    }
  }, e.prototype.add = function(t) {
    var n;
    if (t && t !== this)
      if (this.closed)
        bd(t);
      else {
        if (t instanceof e) {
          if (t.closed || t._hasParent(this))
            return;
          t._addParent(this);
        }
        (this._finalizers = (n = this._finalizers) !== null && n !== void 0 ? n : []).push(t);
      }
  }, e.prototype._hasParent = function(t) {
    var n = this._parentage;
    return n === t || Array.isArray(n) && n.includes(t);
  }, e.prototype._addParent = function(t) {
    var n = this._parentage;
    this._parentage = Array.isArray(n) ? (n.push(t), n) : n ? [n, t] : t;
  }, e.prototype._removeParent = function(t) {
    var n = this._parentage;
    n === t ? this._parentage = null : Array.isArray(n) && Fi(n, t);
  }, e.prototype.remove = function(t) {
    var n = this._finalizers;
    n && Fi(n, t), t instanceof e && t._removeParent(this);
  }, e.EMPTY = function() {
    var t = new e();
    return t.closed = !0, t;
  }(), e;
}(), Ah = vs.EMPTY;
function Mh(e) {
  return e instanceof vs || e && "closed" in e && ve(e.remove) && ve(e.add) && ve(e.unsubscribe);
}
function bd(e) {
  ve(e) ? e() : e.unsubscribe();
}
var Nh = {
  onUnhandledError: null,
  onStoppedNotification: null,
  Promise: void 0,
  useDeprecatedSynchronousErrorHandling: !1,
  useDeprecatedNextContext: !1
}, $h = {
  setTimeout: function(e, t) {
    for (var n = [], o = 2; o < arguments.length; o++)
      n[o - 2] = arguments[o];
    return setTimeout.apply(void 0, ji([e, t], Ui(n)));
  },
  clearTimeout: function(e) {
    var t = $h.delegate;
    return ((t == null ? void 0 : t.clearTimeout) || clearTimeout)(e);
  },
  delegate: void 0
};
function kh(e) {
  $h.setTimeout(function() {
    throw e;
  });
}
function Al() {
}
function Ai(e) {
  e();
}
var $c = function(e) {
  mn(t, e);
  function t(n) {
    var o = e.call(this) || this;
    return o.isStopped = !1, n ? (o.destination = n, Mh(n) && n.add(o)) : o.destination = yS, o;
  }
  return t.create = function(n, o, r) {
    return new Ml(n, o, r);
  }, t.prototype.next = function(n) {
    this.isStopped || this._next(n);
  }, t.prototype.error = function(n) {
    this.isStopped || (this.isStopped = !0, this._error(n));
  }, t.prototype.complete = function() {
    this.isStopped || (this.isStopped = !0, this._complete());
  }, t.prototype.unsubscribe = function() {
    this.closed || (this.isStopped = !0, e.prototype.unsubscribe.call(this), this.destination = null);
  }, t.prototype._next = function(n) {
    this.destination.next(n);
  }, t.prototype._error = function(n) {
    try {
      this.destination.error(n);
    } finally {
      this.unsubscribe();
    }
  }, t.prototype._complete = function() {
    try {
      this.destination.complete();
    } finally {
      this.unsubscribe();
    }
  }, t;
}(vs), mS = Function.prototype.bind;
function Qa(e, t) {
  return mS.call(e, t);
}
var hS = function() {
  function e(t) {
    this.partialObserver = t;
  }
  return e.prototype.next = function(t) {
    var n = this.partialObserver;
    if (n.next)
      try {
        n.next(t);
      } catch (o) {
        _s(o);
      }
  }, e.prototype.error = function(t) {
    var n = this.partialObserver;
    if (n.error)
      try {
        n.error(t);
      } catch (o) {
        _s(o);
      }
    else
      _s(t);
  }, e.prototype.complete = function() {
    var t = this.partialObserver;
    if (t.complete)
      try {
        t.complete();
      } catch (n) {
        _s(n);
      }
  }, e;
}(), Ml = function(e) {
  mn(t, e);
  function t(n, o, r) {
    var s = e.call(this) || this, i;
    if (ve(n) || !n)
      i = {
        next: n ?? void 0,
        error: o ?? void 0,
        complete: r ?? void 0
      };
    else {
      var a;
      s && Nh.useDeprecatedNextContext ? (a = Object.create(n), a.unsubscribe = function() {
        return s.unsubscribe();
      }, i = {
        next: n.next && Qa(n.next, a),
        error: n.error && Qa(n.error, a),
        complete: n.complete && Qa(n.complete, a)
      }) : i = n;
    }
    return s.destination = new hS(i), s;
  }
  return t;
}($c);
function _s(e) {
  kh(e);
}
function pS(e) {
  throw e;
}
var yS = {
  closed: !0,
  next: Al,
  error: pS,
  complete: Al
}, kc = function() {
  return typeof Symbol == "function" && Symbol.observable || "@@observable";
}();
function gS(e) {
  return e;
}
function vS(e) {
  return e.length === 0 ? gS : e.length === 1 ? e[0] : function(n) {
    return e.reduce(function(o, r) {
      return r(o);
    }, n);
  };
}
var tt = function() {
  function e(t) {
    t && (this._subscribe = t);
  }
  return e.prototype.lift = function(t) {
    var n = new e();
    return n.source = this, n.operator = t, n;
  }, e.prototype.subscribe = function(t, n, o) {
    var r = this, s = IS(t) ? t : new Ml(t, n, o);
    return Ai(function() {
      var i = r, a = i.operator, l = i.source;
      s.add(a ? a.call(s, l) : l ? r._subscribe(s) : r._trySubscribe(s));
    }), s;
  }, e.prototype._trySubscribe = function(t) {
    try {
      return this._subscribe(t);
    } catch (n) {
      t.error(n);
    }
  }, e.prototype.forEach = function(t, n) {
    var o = this;
    return n = Id(n), new n(function(r, s) {
      var i = new Ml({
        next: function(a) {
          try {
            t(a);
          } catch (l) {
            s(l), i.unsubscribe();
          }
        },
        error: s,
        complete: r
      });
      o.subscribe(i);
    });
  }, e.prototype._subscribe = function(t) {
    var n;
    return (n = this.source) === null || n === void 0 ? void 0 : n.subscribe(t);
  }, e.prototype[kc] = function() {
    return this;
  }, e.prototype.pipe = function() {
    for (var t = [], n = 0; n < arguments.length; n++)
      t[n] = arguments[n];
    return vS(t)(this);
  }, e.prototype.toPromise = function(t) {
    var n = this;
    return t = Id(t), new t(function(o, r) {
      var s;
      n.subscribe(function(i) {
        return s = i;
      }, function(i) {
        return r(i);
      }, function() {
        return o(s);
      });
    });
  }, e.create = function(t) {
    return new e(t);
  }, e;
}();
function Id(e) {
  var t;
  return (t = e ?? Nh.Promise) !== null && t !== void 0 ? t : Promise;
}
function bS(e) {
  return e && ve(e.next) && ve(e.error) && ve(e.complete);
}
function IS(e) {
  return e && e instanceof $c || bS(e) && Mh(e);
}
function TS(e) {
  return ve(e == null ? void 0 : e.lift);
}
function Zn(e) {
  return function(t) {
    if (TS(t))
      return t.lift(function(n) {
        try {
          return e(n, this);
        } catch (o) {
          this.error(o);
        }
      });
    throw new TypeError("Unable to lift unknown Observable type");
  };
}
function xt(e, t, n, o, r) {
  return new Dh(e, t, n, o, r);
}
var Dh = function(e) {
  mn(t, e);
  function t(n, o, r, s, i, a) {
    var l = e.call(this, n) || this;
    return l.onFinalize = i, l.shouldUnsubscribe = a, l._next = o ? function(c) {
      try {
        o(c);
      } catch (u) {
        n.error(u);
      }
    } : e.prototype._next, l._error = s ? function(c) {
      try {
        s(c);
      } catch (u) {
        n.error(u);
      } finally {
        this.unsubscribe();
      }
    } : e.prototype._error, l._complete = r ? function() {
      try {
        r();
      } catch (c) {
        n.error(c);
      } finally {
        this.unsubscribe();
      }
    } : e.prototype._complete, l;
  }
  return t.prototype.unsubscribe = function() {
    var n;
    if (!this.shouldUnsubscribe || this.shouldUnsubscribe()) {
      var o = this.closed;
      e.prototype.unsubscribe.call(this), !o && ((n = this.onFinalize) === null || n === void 0 || n.call(this));
    }
  }, t;
}($c), wS = xh(function(e) {
  return function() {
    e(this), this.name = "ObjectUnsubscribedError", this.message = "object unsubscribed";
  };
}), Rh = function(e) {
  mn(t, e);
  function t() {
    var n = e.call(this) || this;
    return n.closed = !1, n.currentObservers = null, n.observers = [], n.isStopped = !1, n.hasError = !1, n.thrownError = null, n;
  }
  return t.prototype.lift = function(n) {
    var o = new Td(this, this);
    return o.operator = n, o;
  }, t.prototype._throwIfClosed = function() {
    if (this.closed)
      throw new wS();
  }, t.prototype.next = function(n) {
    var o = this;
    Ai(function() {
      var r, s;
      if (o._throwIfClosed(), !o.isStopped) {
        o.currentObservers || (o.currentObservers = Array.from(o.observers));
        try {
          for (var i = Ro(o.currentObservers), a = i.next(); !a.done; a = i.next()) {
            var l = a.value;
            l.next(n);
          }
        } catch (c) {
          r = { error: c };
        } finally {
          try {
            a && !a.done && (s = i.return) && s.call(i);
          } finally {
            if (r)
              throw r.error;
          }
        }
      }
    });
  }, t.prototype.error = function(n) {
    var o = this;
    Ai(function() {
      if (o._throwIfClosed(), !o.isStopped) {
        o.hasError = o.isStopped = !0, o.thrownError = n;
        for (var r = o.observers; r.length; )
          r.shift().error(n);
      }
    });
  }, t.prototype.complete = function() {
    var n = this;
    Ai(function() {
      if (n._throwIfClosed(), !n.isStopped) {
        n.isStopped = !0;
        for (var o = n.observers; o.length; )
          o.shift().complete();
      }
    });
  }, t.prototype.unsubscribe = function() {
    this.isStopped = this.closed = !0, this.observers = this.currentObservers = null;
  }, Object.defineProperty(t.prototype, "observed", {
    get: function() {
      var n;
      return ((n = this.observers) === null || n === void 0 ? void 0 : n.length) > 0;
    },
    enumerable: !1,
    configurable: !0
  }), t.prototype._trySubscribe = function(n) {
    return this._throwIfClosed(), e.prototype._trySubscribe.call(this, n);
  }, t.prototype._subscribe = function(n) {
    return this._throwIfClosed(), this._checkFinalizedStatuses(n), this._innerSubscribe(n);
  }, t.prototype._innerSubscribe = function(n) {
    var o = this, r = this, s = r.hasError, i = r.isStopped, a = r.observers;
    return s || i ? Ah : (this.currentObservers = null, a.push(n), new vs(function() {
      o.currentObservers = null, Fi(a, n);
    }));
  }, t.prototype._checkFinalizedStatuses = function(n) {
    var o = this, r = o.hasError, s = o.thrownError, i = o.isStopped;
    r ? n.error(s) : i && n.complete();
  }, t.prototype.asObservable = function() {
    var n = new tt();
    return n.source = this, n;
  }, t.create = function(n, o) {
    return new Td(n, o);
  }, t;
}(tt), Td = function(e) {
  mn(t, e);
  function t(n, o) {
    var r = e.call(this) || this;
    return r.destination = n, r.source = o, r;
  }
  return t.prototype.next = function(n) {
    var o, r;
    (r = (o = this.destination) === null || o === void 0 ? void 0 : o.next) === null || r === void 0 || r.call(o, n);
  }, t.prototype.error = function(n) {
    var o, r;
    (r = (o = this.destination) === null || o === void 0 ? void 0 : o.error) === null || r === void 0 || r.call(o, n);
  }, t.prototype.complete = function() {
    var n, o;
    (o = (n = this.destination) === null || n === void 0 ? void 0 : n.complete) === null || o === void 0 || o.call(n);
  }, t.prototype._subscribe = function(n) {
    var o, r;
    return (r = (o = this.source) === null || o === void 0 ? void 0 : o.subscribe(n)) !== null && r !== void 0 ? r : Ah;
  }, t;
}(Rh), CS = {
  now: function() {
    return Date.now();
  },
  delegate: void 0
}, OS = function(e) {
  mn(t, e);
  function t(n, o) {
    return e.call(this) || this;
  }
  return t.prototype.schedule = function(n, o) {
    return this;
  }, t;
}(vs), Nl = {
  setInterval: function(e, t) {
    for (var n = [], o = 2; o < arguments.length; o++)
      n[o - 2] = arguments[o];
    return setInterval.apply(void 0, ji([e, t], Ui(n)));
  },
  clearInterval: function(e) {
    var t = Nl.delegate;
    return ((t == null ? void 0 : t.clearInterval) || clearInterval)(e);
  },
  delegate: void 0
}, ES = function(e) {
  mn(t, e);
  function t(n, o) {
    var r = e.call(this, n, o) || this;
    return r.scheduler = n, r.work = o, r.pending = !1, r;
  }
  return t.prototype.schedule = function(n, o) {
    var r;
    if (o === void 0 && (o = 0), this.closed)
      return this;
    this.state = n;
    var s = this.id, i = this.scheduler;
    return s != null && (this.id = this.recycleAsyncId(i, s, o)), this.pending = !0, this.delay = o, this.id = (r = this.id) !== null && r !== void 0 ? r : this.requestAsyncId(i, this.id, o), this;
  }, t.prototype.requestAsyncId = function(n, o, r) {
    return r === void 0 && (r = 0), Nl.setInterval(n.flush.bind(n, this), r);
  }, t.prototype.recycleAsyncId = function(n, o, r) {
    if (r === void 0 && (r = 0), r != null && this.delay === r && this.pending === !1)
      return o;
    o != null && Nl.clearInterval(o);
  }, t.prototype.execute = function(n, o) {
    if (this.closed)
      return new Error("executing a cancelled action");
    this.pending = !1;
    var r = this._execute(n, o);
    if (r)
      return r;
    this.pending === !1 && this.id != null && (this.id = this.recycleAsyncId(this.scheduler, this.id, null));
  }, t.prototype._execute = function(n, o) {
    var r = !1, s;
    try {
      this.work(n);
    } catch (i) {
      r = !0, s = i || new Error("Scheduled action threw falsy error");
    }
    if (r)
      return this.unsubscribe(), s;
  }, t.prototype.unsubscribe = function() {
    if (!this.closed) {
      var n = this, o = n.id, r = n.scheduler, s = r.actions;
      this.work = this.state = this.scheduler = null, this.pending = !1, Fi(s, this), o != null && (this.id = this.recycleAsyncId(r, o, null)), this.delay = null, e.prototype.unsubscribe.call(this);
    }
  }, t;
}(OS), wd = function() {
  function e(t, n) {
    n === void 0 && (n = e.now), this.schedulerActionCtor = t, this.now = n;
  }
  return e.prototype.schedule = function(t, n, o) {
    return n === void 0 && (n = 0), new this.schedulerActionCtor(this, t).schedule(o, n);
  }, e.now = CS.now, e;
}(), SS = function(e) {
  mn(t, e);
  function t(n, o) {
    o === void 0 && (o = wd.now);
    var r = e.call(this, n, o) || this;
    return r.actions = [], r._active = !1, r;
  }
  return t.prototype.flush = function(n) {
    var o = this.actions;
    if (this._active) {
      o.push(n);
      return;
    }
    var r;
    this._active = !0;
    do
      if (r = n.execute(n.state, n.delay))
        break;
    while (n = o.shift());
    if (this._active = !1, r) {
      for (; n = o.shift(); )
        n.unsubscribe();
      throw r;
    }
  }, t;
}(wd), Dc = new SS(ES), xS = Dc;
function AS(e) {
  return e && ve(e.schedule);
}
var MS = function(e) {
  return e && typeof e.length == "number" && typeof e != "function";
};
function NS(e) {
  return ve(e == null ? void 0 : e.then);
}
function $S(e) {
  return ve(e[kc]);
}
function kS(e) {
  return Symbol.asyncIterator && ve(e == null ? void 0 : e[Symbol.asyncIterator]);
}
function DS(e) {
  return new TypeError("You provided " + (e !== null && typeof e == "object" ? "an invalid object" : "'" + e + "'") + " where a stream was expected. You can provide an Observable, Promise, ReadableStream, Array, AsyncIterable, or Iterable.");
}
function RS() {
  return typeof Symbol != "function" || !Symbol.iterator ? "@@iterator" : Symbol.iterator;
}
var LS = RS();
function PS(e) {
  return ve(e == null ? void 0 : e[LS]);
}
function _S(e) {
  return dS(this, arguments, function() {
    var n, o, r, s;
    return Sh(this, function(i) {
      switch (i.label) {
        case 0:
          n = e.getReader(), i.label = 1;
        case 1:
          i.trys.push([1, , 9, 10]), i.label = 2;
        case 2:
          return [4, To(n.read())];
        case 3:
          return o = i.sent(), r = o.value, s = o.done, s ? [4, To(void 0)] : [3, 5];
        case 4:
          return [2, i.sent()];
        case 5:
          return [4, To(r)];
        case 6:
          return [4, i.sent()];
        case 7:
          return i.sent(), [3, 2];
        case 8:
          return [3, 10];
        case 9:
          return n.releaseLock(), [7];
        case 10:
          return [2];
      }
    });
  });
}
function BS(e) {
  return ve(e == null ? void 0 : e.getReader);
}
function bs(e) {
  if (e instanceof tt)
    return e;
  if (e != null) {
    if ($S(e))
      return WS(e);
    if (MS(e))
      return US(e);
    if (NS(e))
      return jS(e);
    if (kS(e))
      return Lh(e);
    if (PS(e))
      return FS(e);
    if (BS(e))
      return qS(e);
  }
  throw DS(e);
}
function WS(e) {
  return new tt(function(t) {
    var n = e[kc]();
    if (ve(n.subscribe))
      return n.subscribe(t);
    throw new TypeError("Provided object does not correctly implement Symbol.observable");
  });
}
function US(e) {
  return new tt(function(t) {
    for (var n = 0; n < e.length && !t.closed; n++)
      t.next(e[n]);
    t.complete();
  });
}
function jS(e) {
  return new tt(function(t) {
    e.then(function(n) {
      t.closed || (t.next(n), t.complete());
    }, function(n) {
      return t.error(n);
    }).then(null, kh);
  });
}
function FS(e) {
  return new tt(function(t) {
    var n, o;
    try {
      for (var r = Ro(e), s = r.next(); !s.done; s = r.next()) {
        var i = s.value;
        if (t.next(i), t.closed)
          return;
      }
    } catch (a) {
      n = { error: a };
    } finally {
      try {
        s && !s.done && (o = r.return) && o.call(r);
      } finally {
        if (n)
          throw n.error;
      }
    }
    t.complete();
  });
}
function Lh(e) {
  return new tt(function(t) {
    HS(e, t).catch(function(n) {
      return t.error(n);
    });
  });
}
function qS(e) {
  return Lh(_S(e));
}
function HS(e, t) {
  var n, o, r, s;
  return uS(this, void 0, void 0, function() {
    var i, a;
    return Sh(this, function(l) {
      switch (l.label) {
        case 0:
          l.trys.push([0, 5, 6, 11]), n = fS(e), l.label = 1;
        case 1:
          return [4, n.next()];
        case 2:
          if (o = l.sent(), !!o.done)
            return [3, 4];
          if (i = o.value, t.next(i), t.closed)
            return [2];
          l.label = 3;
        case 3:
          return [3, 1];
        case 4:
          return [3, 11];
        case 5:
          return a = l.sent(), r = { error: a }, [3, 11];
        case 6:
          return l.trys.push([6, , 9, 10]), o && !o.done && (s = n.return) ? [4, s.call(n)] : [3, 8];
        case 7:
          l.sent(), l.label = 8;
        case 8:
          return [3, 10];
        case 9:
          if (r)
            throw r.error;
          return [7];
        case 10:
          return [7];
        case 11:
          return t.complete(), [2];
      }
    });
  });
}
function ZS(e, t, n, o, r) {
  o === void 0 && (o = 0), r === void 0 && (r = !1);
  var s = t.schedule(function() {
    n(), r ? e.add(this.schedule(null, o)) : this.unsubscribe();
  }, o);
  if (e.add(s), !r)
    return s;
}
function VS(e) {
  return e instanceof Date && !isNaN(e);
}
function qi(e, t) {
  return Zn(function(n, o) {
    var r = 0;
    n.subscribe(xt(o, function(s) {
      o.next(e.call(t, s, r++));
    }));
  });
}
function zS(e, t, n, o, r, s, i, a) {
  var l = [], c = 0, u = 0, d = !1, f = function() {
    d && !l.length && !c && t.complete();
  }, m = function(g) {
    return c < o ? p(g) : l.push(g);
  }, p = function(g) {
    s && t.next(g), c++;
    var v = !1;
    bs(n(g, u++)).subscribe(xt(t, function(T) {
      r == null || r(T), s ? m(T) : t.next(T);
    }, function() {
      v = !0;
    }, void 0, function() {
      if (v)
        try {
          c--;
          for (var T = function() {
            var O = l.shift();
            i ? ZS(t, i, function() {
              return p(O);
            }) : p(O);
          }; l.length && c < o; )
            T();
          f();
        } catch (O) {
          t.error(O);
        }
    }));
  };
  return e.subscribe(xt(t, m, function() {
    d = !0, f();
  })), function() {
    a == null || a();
  };
}
function Ph(e, t, n) {
  return n === void 0 && (n = 1 / 0), ve(t) ? Ph(function(o, r) {
    return qi(function(s, i) {
      return t(o, s, r, i);
    })(bs(e(o, r)));
  }, n) : (typeof t == "number" && (n = t), Zn(function(o, r) {
    return zS(o, r, e, n);
  }));
}
function GS(e, t, n) {
  e === void 0 && (e = 0), n === void 0 && (n = xS);
  var o = -1;
  return t != null && (AS(t) ? n = t : o = t), new tt(function(r) {
    var s = VS(e) ? +e - n.now() : e;
    s < 0 && (s = 0);
    var i = 0;
    return n.schedule(function() {
      r.closed || (r.next(i++), 0 <= o ? this.schedule(void 0, o) : r.complete());
    }, s);
  });
}
function YS(e, t) {
  return Zn(function(n, o) {
    var r = 0;
    n.subscribe(xt(o, function(s) {
      return e.call(t, s, r++) && o.next(s);
    }));
  });
}
function KS(e) {
  return Zn(function(t, n) {
    var o = [];
    return t.subscribe(xt(n, function(r) {
      return o.push(r);
    }, function() {
      n.next(o), n.complete();
    })), bs(e).subscribe(xt(n, function() {
      var r = o;
      o = [], n.next(r);
    }, Al)), function() {
      o = null;
    };
  });
}
function QS(e, t) {
  return t === void 0 && (t = Dc), Zn(function(n, o) {
    var r = null, s = null, i = null, a = function() {
      if (r) {
        r.unsubscribe(), r = null;
        var c = s;
        s = null, o.next(c);
      }
    };
    function l() {
      var c = i + e, u = t.now();
      if (u < c) {
        r = this.schedule(void 0, c - u), o.add(r);
        return;
      }
      a();
    }
    n.subscribe(xt(o, function(c) {
      s = c, i = t.now(), r || (r = t.schedule(l, e), o.add(r));
    }, function() {
      a(), o.complete();
    }, void 0, function() {
      s = r = null;
    }));
  });
}
function JS(e, t, n, o) {
  return Zn(function(r, s) {
    var i;
    !t || typeof t == "function" ? i = t : (n = t.duration, i = t.element, o = t.connector);
    var a = /* @__PURE__ */ new Map(), l = function(p) {
      a.forEach(p), p(s);
    }, c = function(p) {
      return l(function(g) {
        return g.error(p);
      });
    }, u = 0, d = !1, f = new Dh(s, function(p) {
      try {
        var g = e(p), v = a.get(g);
        if (!v) {
          a.set(g, v = o ? o() : new Rh());
          var T = m(g, v);
          if (s.next(T), n) {
            var O = xt(v, function() {
              v.complete(), O == null || O.unsubscribe();
            }, void 0, void 0, function() {
              return a.delete(g);
            });
            f.add(bs(n(T)).subscribe(O));
          }
        }
        v.next(i ? i(p) : p);
      } catch (k) {
        c(k);
      }
    }, function() {
      return l(function(p) {
        return p.complete();
      });
    }, c, function() {
      return a.clear();
    }, function() {
      return d = !0, u === 0;
    });
    r.subscribe(f);
    function m(p, g) {
      var v = new tt(function(T) {
        u++;
        var O = g.subscribe(T);
        return function() {
          O.unsubscribe(), --u === 0 && d && f.unsubscribe();
        };
      });
      return v.key = p, v;
    }
  });
}
function XS(e, t) {
  return Zn(function(n, o) {
    var r = t ?? {}, s = r.leading, i = s === void 0 ? !0 : s, a = r.trailing, l = a === void 0 ? !1 : a, c = !1, u = null, d = null, f = !1, m = function() {
      d == null || d.unsubscribe(), d = null, l && (v(), f && o.complete());
    }, p = function() {
      d = null, f && o.complete();
    }, g = function(T) {
      return d = bs(e(T)).subscribe(xt(o, m, p));
    }, v = function() {
      if (c) {
        c = !1;
        var T = u;
        u = null, o.next(T), !f && g(T);
      }
    };
    n.subscribe(xt(o, function(T) {
      c = !0, u = T, !(d && !d.closed) && (i ? v() : g(T));
    }, function() {
      f = !0, !(l && c && d && !d.closed) && o.complete();
    }));
  });
}
function ex(e, t, n) {
  t === void 0 && (t = Dc);
  var o = GS(e, t);
  return XS(function() {
    return o;
  }, n);
}
const x = {
  changeHasHistory: "editor.changeHasHistory",
  selectAll: "editor.selectAll",
  unselectAll: "editor.unselectAll",
  select: "editor.select",
  changeViewport: "editor.changeViewport",
  clear: "editor.clear",
  loadJson: "editor.loadJson",
  initialClear: "editor.initialClear",
  initialLoadJson: "editor.initialLoadJson",
  focusTable: "editor.focusTable",
  focusColumn: "editor.focusColumn",
  focusTableEnd: "editor.focusTableEnd",
  focusMoveTable: "editor.focusMoveTable",
  editTable: "editor.editTable",
  editTableEnd: "editor.editTableEnd",
  selectAllColumn: "editor.selectAllColumn",
  drawStartRelationship: "editor.drawStartRelationship",
  drawStartAddRelationship: "editor.drawStartAddRelationship",
  drawEndRelationship: "editor.drawEndRelationship",
  drawRelationship: "editor.drawRelationship",
  hoverColumnMap: "editor.hoverColumnMap",
  hoverRelationshipMap: "editor.hoverRelationshipMap",
  changeOpenMap: "editor.changeOpenMap",
  dragstartColumn: "editor.dragstartColumn",
  dragendColumn: "editor.dragendColumn",
  sharedMouseTracker: "editor.sharedMouseTracker",
  validationIds: "editor.validationIds"
}, _h = E.CanvasType, tx = E.CanvasTypeList, Y = E.Show, P = E.ColumnType, nx = E.ColumnTypeList, Jn = E.Database, ox = E.DatabaseList, IL = E.Language, rx = E.LanguageList, TL = E.NameCase, sx = E.NameCaseList, Bs = E.BracketType, ix = E.BracketTypeList, Bh = E.RelationshipType, $l = E.StartRelationshipType, Ct = E.Direction, U = E.ColumnOption, Pe = E.ColumnUIKey, wo = E.OrderType, wL = E.SaveSettingType, ax = E.CANVAS_SIZE_MAX, lx = E.CANVAS_SIZE_MIN, cx = E.CANVAS_ZOOM_MAX, ux = E.CANVAS_ZOOM_MIN, CL = {
  [Bs.none]: "",
  [Bs.backtick]: "`",
  [Bs.doubleQuote]: '"',
  [Bs.singleQuote]: "'"
}, OL = {
  [P.columnName]: "Name",
  [P.columnDataType]: "DataType",
  [P.columnDefault]: "Default",
  [P.columnComment]: "Comment",
  [P.columnAutoIncrement]: "Auto Increment",
  [P.columnUnique]: "Unique",
  [P.columnNotNull]: "Not Null"
}, Vn = {
  shared: (
    /*     */
    1
  ),
  changeOnly: (
    /* */
    2
  ),
  following: (
    /*  */
    4
  )
};
function dx(e, t) {
  return {
    ...fa(t),
    tags: hC(t.tags) ? t.tags | e : e
  };
}
function fx(e, t) {
  return t.map((n) => dx(e, n));
}
const mx = (e) => (...t) => function* (n, o) {
  const r = Eh(n, o, t);
  yield fx(e, r);
}, EL = mx(Vn.changeOnly);
function M(e, t) {
  return (e & t) === t;
}
function Cd(e, t) {
  return !(e.x > t.x + t.w || e.x + e.w < t.x || e.y > t.y + t.h || e.y + e.h < t.y);
}
function kl(e, t, n) {
  const o = { x: 0, y: 0, w: 0, h: 0 };
  return o.w = e * n, o.h = t * n, o.x = (e - o.w) / 2, o.y = (t - o.h) / 2, o;
}
function hx({ x: e, y: t }, n, o, r) {
  const s = kl(n, o, r), i = e * r, a = t * r, l = s.x + i, c = s.y + a;
  return { x: l, y: c };
}
function Wh(e, t, n, o) {
  const { x: r, y: s } = e, { x: i, y: a } = hx(e, t, n, o), l = (r - i) / o, c = (s - a) / o, u = r + l, d = s + c;
  return { x: u, y: d };
}
const px = 200, yx = 100, Od = 50, gx = 16, vx = 9, Uh = 1200, bx = Uh / gx * vx, jh = 20, $n = 8, Fh = 12, Ix = 4, Hi = 1, Zi = 8, Tx = 2, wx = 2, Cx = jh + Tx * 2, Ox = Fh + wx + Cx, SL = 4, qh = 12, Hh = 12, Ze = 60, Zh = 35, Vh = 22, zh = 15, Ex = 2, Sx = jh + Ex * 2, Vi = 1, zi = 8, Gh = Fh + Ix, xx = 100 + Gh, Ax = 100, xL = 150, AL = 20, ML = 30, NL = 200, Cn = {
  MariaDB: "MariaDB",
  MSSQL: "MSSQL",
  MySQL: "MySQL",
  Oracle: "Oracle",
  PostgreSQL: "PostgreSQL",
  SQLite: "SQLite"
}, Mx = Object.values(Cn), $L = {
  [Cn.MariaDB]: Jn.MariaDB,
  [Cn.MSSQL]: Jn.MSSQL,
  [Cn.MySQL]: Jn.MySQL,
  [Cn.Oracle]: Jn.Oracle,
  [Cn.PostgreSQL]: Jn.PostgreSQL,
  [Cn.SQLite]: Jn.SQLite
}, Nx = /[^0-9]/g, Yh = (e) => e.replace(Nx, ""), $x = Je(lx, ax), kx = Je(ux, cx), Kh = Je(Ze, 200);
function Dl(e) {
  const t = q(e) ? Number(Yh(e)) : e;
  return $x(t);
}
function wa(e) {
  return ft(kx(e), 2);
}
function kL(e) {
  const t = q(e) ? Number(Yh(e)) : e;
  return `${Kh(t)}px`;
}
function DL(e) {
  return `${(e * 100).toFixed()}%`;
}
const Dx = $(ox), Qh = $(sx), Rx = $(ix), Lx = $(rx), Ed = $(nx), RL = $(Mx), Jh = $(tx);
function ge(e) {
  return Math.max(e, Ze);
}
function LL(e) {
  return q(e) ? e.trim() : "";
}
function PL(e) {
  return e <= 0.7;
}
const De = {
  table: "table",
  memo: "memo"
}, W = {
  tableName: "tableName",
  tableComment: "tableComment",
  columnName: "columnName",
  columnDataType: "columnDataType",
  columnNotNull: "columnNotNull",
  columnUnique: "columnUnique",
  columnAutoIncrement: "columnAutoIncrement",
  columnDefault: "columnDefault",
  columnComment: "columnComment"
}, ct = {
  ArrowUp: "ArrowUp",
  ArrowRight: "ArrowRight",
  ArrowDown: "ArrowDown",
  ArrowLeft: "ArrowLeft",
  Tab: "Tab"
}, _L = $(Object.values(ct)), Px = () => ({
  id: fe(),
  selectedMap: {},
  hasUndo: !1,
  hasRedo: !1,
  viewport: {
    width: Uh,
    height: bx
  },
  focusTable: null,
  drawRelationship: null,
  hoverColumnMap: {},
  hoverRelationshipMap: {},
  openMap: {},
  draggableColumn: null,
  draggingColumnMap: {},
  sharedMouseTrackerMap: {}
}), Sd = (e) => e.ui.zIndex, Ca = (e, t) => Math.max(1, ...e.map(Sd), ...t.map(Sd)) + 1, _x = (e) => (t) => e.y === t.y && e.x === t.x;
function Xh({ width: e, height: t, zoomLevel: n, scrollLeft: o, scrollTop: r }, s, i) {
  const a = Wh({
    x: px - o,
    y: yx - r
  }, e, t, n), l = [...s, ...i].map(({ ui: c }) => c);
  for (; l.some(_x(a)); )
    a.x += Od, a.y += Od;
  return a;
}
function Yo() {
  const e = Date.now();
  return {
    updateAt: e,
    createAt: e
  };
}
function Bx(e, t) {
  return e < t ? Xu(e, t + 1) : Xu(t, e + 1);
}
function BL(e) {
  return Wf(i0(e));
}
function Is(e, t) {
  return Ri([...e, t]);
}
function ep(e, t, n) {
  if (!t || t === n)
    return [n];
  const o = e.indexOf(t), r = e.indexOf(n);
  return o === -1 ? [n] : Bx(o, r).map((s) => e[s]);
}
function Wx(e, t, n, o) {
  return Ri([
    ...t,
    ...ep(e, n, o)
  ]);
}
const Ux = {
  [P.columnName]: !0,
  [P.columnDataType]: Y.columnDataType,
  [P.columnNotNull]: Y.columnNotNull,
  [P.columnUnique]: Y.columnUnique,
  [P.columnAutoIncrement]: Y.columnAutoIncrement,
  [P.columnDefault]: Y.columnDefault,
  [P.columnComment]: Y.columnComment
}, jx = {
  [P.columnName]: W.columnName,
  [P.columnDataType]: W.columnDataType,
  [P.columnNotNull]: W.columnNotNull,
  [P.columnUnique]: W.columnUnique,
  [P.columnAutoIncrement]: W.columnAutoIncrement,
  [P.columnDefault]: W.columnDefault,
  [P.columnComment]: W.columnComment
}, tp = [
  W.tableName,
  W.tableComment
];
function Ko({ settings: { show: e, columnOrder: t } }) {
  return t.filter((n) => {
    const o = Ux[n];
    return o === !0 ? !0 : M(e, o);
  }).map((n) => jx[n]);
}
function Ts({ collections: e, editor: { focusTable: t } }) {
  if (!t)
    return !1;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  return !!(n != null && n.columnIds.length);
}
function Rc(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return !0;
  const n = Ko(e);
  return n.indexOf(t.focusType) === n.length - 1;
}
function np(e) {
  const { editor: { focusTable: t } } = e;
  return t ? Ko(e).indexOf(t.focusType) === 0 : !0;
}
function Lc({ collections: e, editor: { focusTable: t } }) {
  if (!(t != null && t.columnId))
    return !0;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  return n ? n.columnIds.indexOf(t.columnId) === n.columnIds.length - 1 : !0;
}
function op({ collections: e, editor: { focusTable: t } }) {
  if (!(t != null && t.columnId))
    return !0;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  return n ? n.columnIds.indexOf(t.columnId) === 0 : !0;
}
function Rl(e) {
  const t = Ko(e);
  return t[t.length - 1];
}
function Ll(e) {
  return Ko(e)[0];
}
function Fx(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return W.columnName;
  const n = Ko(e), o = n.indexOf(t.focusType);
  return Rc(e) ? n[0] : n[o + 1];
}
function qx(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return W.columnName;
  const n = Ko(e), o = n.indexOf(t.focusType);
  return np(e) ? n[n.length - 1] : n[o - 1];
}
function Hx(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!(o != null && o.columnId))
    return null;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (!r)
    return null;
  const s = r.columnIds.indexOf(o.columnId);
  if (s <= 0)
    return null;
  let i = null;
  for (let a = s; a >= 0; a--) {
    const l = r.columnIds[a];
    if (!t.includes(l)) {
      i = l;
      break;
    }
  }
  return i;
}
function Oa({ settings: { show: e } }) {
  return M(e, Y.tableComment) ? tp : [W.tableName];
}
function Pc(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return !0;
  const n = Oa(e);
  return n.indexOf(t.focusType) === n.length - 1;
}
function rp(e) {
  const { editor: { focusTable: t } } = e;
  return t ? Oa(e).indexOf(t.focusType) === 0 : !0;
}
const Wn = $(tp);
function xd(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return W.tableName;
  const n = Oa(e), o = n.indexOf(t.focusType);
  return Pc(e) ? n[0] : n[o + 1];
}
function Ad(e) {
  const { editor: { focusTable: t } } = e;
  if (!t)
    return W.tableName;
  const n = Oa(e), o = n.indexOf(t.focusType);
  return rp(e) ? n[n.length - 1] : n[o - 1];
}
function Zx(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r) {
    if (Wn(o.focusType)) {
      if (Ts(e)) {
        const s = r.columnIds[r.columnIds.length - 1];
        o.focusType = Rl(e), o.columnId = s, o.prevSelectColumnId = s, o.selectColumnIds = [s];
      }
    } else if (op(e))
      o.focusType = W.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
    else if (o.columnId) {
      const s = r.columnIds.indexOf(o.columnId), i = r.columnIds[s - 1];
      o.columnId = i, o.prevSelectColumnId = i, t.shiftKey && t.moveKey !== ct.Tab ? o.selectColumnIds = Is(o.selectColumnIds, i) : o.selectColumnIds = [i];
    }
  }
}
function Vx(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r) {
    if (Wn(o.focusType)) {
      if (Ts(e)) {
        const s = r.columnIds[0];
        o.focusType = Ll(e), o.columnId = s, o.prevSelectColumnId = s, o.selectColumnIds = [s];
      }
    } else if (Lc(e))
      o.focusType = W.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
    else if (o.columnId) {
      const s = r.columnIds.indexOf(o.columnId), i = r.columnIds[s + 1];
      o.columnId = i, o.prevSelectColumnId = i, t.shiftKey && t.moveKey !== ct.Tab ? o.selectColumnIds = Is(o.selectColumnIds, i) : o.selectColumnIds = [i];
    }
  }
}
function Md(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r)
    if (Wn(o.focusType))
      if (rp(e))
        if (Ts(e)) {
          const s = r.columnIds[r.columnIds.length - 1];
          o.focusType = Rl(e), o.columnId = s, o.prevSelectColumnId = s, o.selectColumnIds = [s];
        } else
          o.focusType = Ad(e);
      else
        o.focusType = Ad(e);
    else if (np(e)) {
      if (op(e))
        o.focusType = M(e.settings.show, Y.tableComment) ? W.tableComment : W.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
      else if (o.columnId) {
        const s = r.columnIds.indexOf(o.columnId), i = r.columnIds[s - 1];
        o.focusType = Rl(e), o.columnId = i, o.prevSelectColumnId = i, t.shiftKey && t.moveKey !== ct.Tab ? o.selectColumnIds = Is(o.selectColumnIds, i) : o.selectColumnIds = [i];
      }
    } else
      o.focusType = qx(e), !t.shiftKey && o.columnId && (o.prevSelectColumnId = o.columnId, o.selectColumnIds = [o.columnId]);
}
function Nd(e, t) {
  const { collections: n, editor: { focusTable: o } } = e;
  if (!o)
    return;
  const r = y(n).collection("tableEntities").selectById(o.tableId);
  if (r)
    if (Wn(o.focusType))
      if (Pc(e))
        if (Ts(e)) {
          const s = r.columnIds[0];
          o.focusType = Ll(e), o.columnId = s, o.prevSelectColumnId = s, o.selectColumnIds = [s];
        } else
          o.focusType = xd(e);
      else
        o.focusType = xd(e);
    else if (Rc(e)) {
      if (Lc(e))
        o.focusType = W.tableName, o.columnId = null, o.prevSelectColumnId = null, o.selectColumnIds = [];
      else if (o.columnId) {
        const s = r.columnIds.indexOf(o.columnId), i = r.columnIds[s + 1];
        o.focusType = Ll(e), o.columnId = i, o.prevSelectColumnId = i, t.shiftKey && t.moveKey !== ct.Tab ? o.selectColumnIds = Is(o.selectColumnIds, i) : o.selectColumnIds = [i];
      }
    } else
      o.focusType = Fx(e), !t.shiftKey && o.columnId && (o.prevSelectColumnId = o.columnId, o.selectColumnIds = [o.columnId]);
}
const $d = 1e3 * 30, zx = I(x.changeHasHistory), Gx = ({ editor: e }, { payload: { hasRedo: t, hasUndo: n } }) => {
  e.hasRedo = t, e.hasUndo = n;
}, Yx = I(x.selectAll), Kx = ({ editor: e, doc: t }) => {
  const n = t.tableIds.reduce((r, s) => (r[s] = De.table, r), {}), o = t.memoIds.reduce((r, s) => (r[s] = De.memo, r), {});
  e.selectedMap = {
    ...o,
    ...n
  };
}, Ea = I(x.unselectAll), Qx = ({ editor: e }) => {
  Object.keys(e.selectedMap).forEach((t) => {
    Reflect.deleteProperty(e.selectedMap, t);
  });
}, Qo = I(x.select), Jx = ({ editor: e }, { payload: t }) => {
  Object.assign(e.selectedMap, t);
}, Xx = I(x.changeViewport), eA = ({ editor: e }, { payload: { width: t, height: n } }) => {
  e.viewport.width = t, e.viewport.height = n;
}, _c = I(x.clear), tA = (e) => {
  const { doc: t, collections: n, lww: o } = qn({});
  e.doc = t, e.collections = n, e.lww = o;
}, ln = I(x.loadJson), nA = (e, { payload: { value: t } }) => {
  const { version: n, settings: o, doc: r, collections: s, lww: i } = km(t);
  Jh(o.canvasType) || (o.canvasType = _h.ERD), Object.assign(e.settings, o), e.version = n, e.doc = r, e.collections = s, e.lww = i;
}, sp = I(x.initialClear), oA = (e) => {
  const { doc: t, collections: n, lww: o } = qn({});
  e.doc = t, e.collections = n, e.lww = o;
}, Lo = I(x.initialLoadJson), rA = (e, { payload: { value: t } }) => {
  const { version: n, settings: o, doc: r, collections: s, lww: i } = km(t);
  Jh(o.canvasType) || (o.canvasType = _h.ERD), Object.assign(e.settings, o), e.version = n, e.doc = r, e.collections = s, e.lww = i;
}, Sa = I(x.focusTable), sA = ({ editor: e, collections: t }, { payload: n }) => {
  var r, s;
  const o = y(t).collection("tableEntities");
  if (((r = e.focusTable) == null ? void 0 : r.tableId) === n.tableId && n.focusType)
    e.focusTable.focusType = n.focusType, e.focusTable.columnId = null, e.focusTable.prevSelectColumnId = null, e.focusTable.selectColumnIds = [];
  else if (n.focusType) {
    const i = o.selectById(n.tableId);
    if (!i)
      return;
    e.focusTable = {
      tableId: i.id,
      focusType: n.focusType,
      columnId: null,
      prevSelectColumnId: null,
      selectColumnIds: [],
      edit: !1
    };
  } else if (((s = e.focusTable) == null ? void 0 : s.tableId) !== n.tableId) {
    const i = o.selectById(n.tableId);
    if (!i)
      return;
    e.focusTable = {
      tableId: i.id,
      focusType: W.tableName,
      columnId: null,
      prevSelectColumnId: null,
      selectColumnIds: [],
      edit: !1
    };
  }
}, cn = I(x.focusColumn), iA = ({ editor: e, collections: t }, { payload: n }) => {
  var r;
  const o = y(t).collection("tableEntities");
  if (((r = e.focusTable) == null ? void 0 : r.tableId) === n.tableId) {
    const s = o.selectById(n.tableId);
    if (!s)
      return;
    const i = e.focusTable;
    i.columnId = n.columnId, i.focusType = n.focusType, n.$mod && n.shiftKey ? i.selectColumnIds = Wx(s.columnIds, i.selectColumnIds, i.prevSelectColumnId, i.columnId) : n.shiftKey ? i.selectColumnIds = ep(s.columnIds, i.prevSelectColumnId, i.columnId) : n.$mod ? i.selectColumnIds = Is(i.selectColumnIds, n.columnId) : i.selectColumnIds = [n.columnId], i.prevSelectColumnId = n.columnId;
  } else {
    const s = o.selectById(n.tableId);
    if (!s)
      return;
    e.focusTable = {
      tableId: s.id,
      focusType: n.focusType,
      columnId: n.columnId,
      prevSelectColumnId: n.columnId,
      selectColumnIds: [n.columnId],
      edit: !1
    };
  }
}, Bc = I(x.focusTableEnd), aA = ({ editor: e }) => {
  e.focusTable = null;
}, ip = I(x.focusMoveTable), lA = (e, { payload: t }) => {
  const { editor: { focusTable: n } } = e;
  if (n)
    switch (n.edit = !1, t.moveKey) {
      case ct.ArrowUp:
        Zx(e, t);
        break;
      case ct.ArrowDown:
        Vx(e, t);
        break;
      case ct.ArrowLeft:
        Md(e, t);
        break;
      case ct.ArrowRight:
        Nd(e, t);
        break;
      case ct.Tab:
        t.shiftKey ? Md(e, t) : Nd(e, t);
        break;
    }
}, cA = I(x.editTable), uA = ({ editor: { focusTable: e } }) => {
  e && (e.edit = !0);
}, dA = I(x.editTableEnd), fA = ({ editor: { focusTable: e } }) => {
  e && (e.edit = !1);
}, mA = I(x.selectAllColumn), hA = ({ collections: e, editor: { focusTable: t } }) => {
  if (!t)
    return;
  const n = y(e).collection("tableEntities").selectById(t.tableId);
  n && (t.selectColumnIds = [...n.columnIds]);
}, ap = I(x.drawStartRelationship), pA = ({ editor: e }, { payload: { relationshipType: t } }) => {
  e.drawRelationship = {
    relationshipType: t,
    start: null,
    end: { x: 0, y: 0 }
  };
}, lp = I(x.drawStartAddRelationship), yA = ({ editor: { drawRelationship: e }, collections: t }, { payload: { tableId: n } }) => {
  if (!e)
    return;
  const o = y(t).collection("tableEntities").selectById(n);
  o && (e.start = {
    tableId: n,
    x: o.ui.x,
    y: o.ui.y
  });
}, Wc = I(x.drawEndRelationship), gA = ({ editor: e }) => {
  e.drawRelationship = null;
}, vA = I(x.drawRelationship), bA = ({ editor: { drawRelationship: e }, settings: { scrollLeft: t, scrollTop: n, zoomLevel: o, width: r, height: s } }, { payload: { x: i, y: a } }) => {
  if (!(e != null && e.start))
    return;
  const l = Wh({ x: i - t, y: a - n }, r, s, o);
  e.end.x = l.x, e.end.y = l.y;
}, Uc = I(x.hoverColumnMap), IA = ({ editor: e }, { payload: { columnIds: t } }) => {
  Object.keys(e.hoverColumnMap).forEach((n) => {
    Reflect.deleteProperty(e.hoverColumnMap, n);
  });
  for (const n of t)
    e.hoverColumnMap[n] = !0;
}, jc = I(x.hoverRelationshipMap), TA = ({ editor: e }, { payload: { relationshipIds: t } }) => {
  Object.keys(e.hoverRelationshipMap).forEach((n) => {
    Reflect.deleteProperty(e.hoverRelationshipMap, n);
  });
  for (const n of t)
    e.hoverRelationshipMap[n] = !0;
}, wA = I(x.changeOpenMap), CA = ({ editor: e }, { payload: t }) => {
  Object.assign(e.openMap, t);
}, Fc = I(x.dragstartColumn), OA = ({ editor: e }, { payload: t }) => {
  e.draggableColumn = t, t.columnIds.forEach((n) => {
    e.draggingColumnMap[n] = !0;
  });
}, EA = I(x.dragendColumn), SA = ({ editor: e }) => {
  e.draggableColumn = null, Object.keys(e.draggingColumnMap).forEach((t) => {
    Reflect.deleteProperty(e.draggingColumnMap, t);
  });
}, xA = I(x.sharedMouseTracker), AA = ({ editor: e }, { payload: t, tags: n, meta: o }) => {
  if (pe(n) || !M(n, Vn.shared) || !q(o == null ? void 0 : o.editorId) || e.id === o.editorId)
    return;
  const r = e.sharedMouseTrackerMap[o.editorId], s = !q(o.nickname) || fm(o.nickname.trim()) ? "user" : o.nickname.trim();
  r ? (r.x = t.x, r.y = t.y, r.nickname = s, clearTimeout(r.timeoutId), r.timeoutId = setTimeout(() => {
    Reflect.deleteProperty(e.sharedMouseTrackerMap, o.editorId);
  }, $d)) : e.sharedMouseTrackerMap[o.editorId] = {
    ...t,
    id: o.editorId,
    nickname: s,
    timeoutId: setTimeout(() => {
      Reflect.deleteProperty(e.sharedMouseTrackerMap, o.editorId);
    }, $d)
  };
}, MA = I(x.validationIds), NA = ({ doc: e, collections: t }) => {
  const n = y(t).collection("tableEntities"), o = y(t).collection("tableColumnEntities"), r = y(t).collection("indexEntities"), s = y(t).collection("indexColumnEntities"), i = y(t).collection("relationshipEntities"), a = y(t).collection("memoEntities"), l = e.tableIds.filter((f) => !n.selectById(f)), c = e.relationshipIds.filter((f) => !i.selectById(f)), u = e.indexIds.filter((f) => !r.selectById(f)), d = e.memoIds.filter((f) => !a.selectById(f));
  e.tableIds = e.tableIds.filter((f) => !l.includes(f)), e.relationshipIds = e.relationshipIds.filter((f) => !c.includes(f)), e.indexIds = e.indexIds.filter((f) => !u.includes(f)), e.memoIds = e.memoIds.filter((f) => !d.includes(f)), n.selectAll().forEach((f) => {
    const m = f.columnIds.filter((g) => !o.selectById(g)), p = f.seqColumnIds.filter((g) => !o.selectById(g));
    f.columnIds = f.columnIds.filter((g) => !m.includes(g)), f.seqColumnIds = f.seqColumnIds.filter((g) => !p.includes(g));
  }), r.selectAll().forEach((f) => {
    const m = f.indexColumnIds.filter((g) => !s.selectById(g)), p = f.seqIndexColumnIds.filter((g) => !s.selectById(g));
    f.indexColumnIds = f.indexColumnIds.filter((g) => !m.includes(g)), f.seqIndexColumnIds = f.seqIndexColumnIds.filter((g) => !p.includes(g));
  });
}, $A = {
  [x.changeHasHistory]: Gx,
  [x.selectAll]: Kx,
  [x.unselectAll]: Qx,
  [x.select]: Jx,
  [x.changeViewport]: eA,
  [x.clear]: tA,
  [x.loadJson]: nA,
  [x.initialClear]: oA,
  [x.initialLoadJson]: rA,
  [x.focusTable]: sA,
  [x.focusColumn]: iA,
  [x.focusTableEnd]: aA,
  [x.focusMoveTable]: lA,
  [x.editTable]: uA,
  [x.editTableEnd]: fA,
  [x.selectAllColumn]: hA,
  [x.drawStartRelationship]: pA,
  [x.drawStartAddRelationship]: yA,
  [x.drawEndRelationship]: gA,
  [x.drawRelationship]: bA,
  [x.hoverColumnMap]: IA,
  [x.hoverRelationshipMap]: TA,
  [x.changeOpenMap]: CA,
  [x.dragstartColumn]: OA,
  [x.dragendColumn]: SA,
  [x.sharedMouseTracker]: AA,
  [x.validationIds]: NA
}, kA = {
  changeHasHistoryAction: zx,
  selectAllAction: Yx,
  unselectAllAction: Ea,
  selectAction: Qo,
  changeViewportAction: Xx,
  clearAction: _c,
  loadJsonAction: ln,
  initialClearAction: sp,
  initialLoadJsonAction: Lo,
  focusTableAction: Sa,
  focusColumnAction: cn,
  focusTableEndAction: Bc,
  focusMoveTableAction: ip,
  editTableAction: cA,
  editTableEndAction: dA,
  selectAllColumnAction: mA,
  drawStartRelationshipAction: ap,
  drawStartAddRelationshipAction: lp,
  drawEndRelationshipAction: Wc,
  drawRelationshipAction: vA,
  hoverColumnMapAction: Uc,
  hoverRelationshipMapAction: jc,
  changeOpenMapAction: wA,
  dragstartColumnAction: Fc,
  dragendColumnAction: EA,
  sharedMouseTrackerAction: xA,
  validationIdsAction: MA
}, DA = (e, t, n) => {
  e.push(_c(), ln({ value: gc(n) }));
}, RA = (e, t, n) => {
  e.push(ln({ value: gc(n) }));
}, LA = {
  [x.loadJson]: DA,
  [x.clear]: RA
}, Ve = {
  addIndex: "index.add",
  removeIndex: "index.remove",
  changeIndexName: "index.changeName",
  changeIndexUnique: "index.changeUnique"
};
var WL = typeof globalThis < "u" ? globalThis : typeof window < "u" ? window : typeof global < "u" ? global : typeof self < "u" ? self : {};
function PA(e) {
  return e && e.__esModule && Object.prototype.hasOwnProperty.call(e, "default") ? e.default : e;
}
var _A = function(t) {
  return BA(t) && !WA(t);
};
function BA(e) {
  return !!e && typeof e == "object";
}
function WA(e) {
  var t = Object.prototype.toString.call(e);
  return t === "[object RegExp]" || t === "[object Date]" || FA(e);
}
var UA = typeof Symbol == "function" && Symbol.for, jA = UA ? Symbol.for("react.element") : 60103;
function FA(e) {
  return e.$$typeof === jA;
}
function qA(e) {
  return Array.isArray(e) ? [] : {};
}
function ns(e, t) {
  return t.clone !== !1 && t.isMergeableObject(e) ? Po(qA(e), e, t) : e;
}
function HA(e, t, n) {
  return e.concat(t).map(function(o) {
    return ns(o, n);
  });
}
function ZA(e, t) {
  if (!t.customMerge)
    return Po;
  var n = t.customMerge(e);
  return typeof n == "function" ? n : Po;
}
function VA(e) {
  return Object.getOwnPropertySymbols ? Object.getOwnPropertySymbols(e).filter(function(t) {
    return Object.propertyIsEnumerable.call(e, t);
  }) : [];
}
function kd(e) {
  return Object.keys(e).concat(VA(e));
}
function cp(e, t) {
  try {
    return t in e;
  } catch {
    return !1;
  }
}
function zA(e, t) {
  return cp(e, t) && !(Object.hasOwnProperty.call(e, t) && Object.propertyIsEnumerable.call(e, t));
}
function GA(e, t, n) {
  var o = {};
  return n.isMergeableObject(e) && kd(e).forEach(function(r) {
    o[r] = ns(e[r], n);
  }), kd(t).forEach(function(r) {
    zA(e, r) || (cp(e, r) && n.isMergeableObject(t[r]) ? o[r] = ZA(r, n)(e[r], t[r], n) : o[r] = ns(t[r], n));
  }), o;
}
function Po(e, t, n) {
  n = n || {}, n.arrayMerge = n.arrayMerge || HA, n.isMergeableObject = n.isMergeableObject || _A, n.cloneUnlessOtherwiseSpecified = ns;
  var o = Array.isArray(t), r = Array.isArray(e), s = o === r;
  return s ? o ? n.arrayMerge(e, t, n) : GA(e, t, n) : ns(t, n);
}
Po.all = function(t, n) {
  if (!Array.isArray(t))
    throw new Error("first argument should be an array");
  return t.reduce(function(o, r) {
    return Po(o, r, n);
  }, {});
};
var YA = Po, KA = YA;
const Jo = /* @__PURE__ */ PA(KA), zn = (e) => Jo({
  id: fe(),
  name: "",
  tableId: "",
  indexColumnIds: [],
  seqIndexColumnIds: [],
  unique: !1,
  meta: Yo()
}, e ?? {}), qc = I(Ve.addIndex), QA = ({ doc: e, collections: t, lww: n }, { payload: { id: o, tableId: r }, timestamp: s }) => {
  y(t).collection("indexEntities").addOne(zn({ id: o, tableId: r })).addOperator(n, s, o, () => {
    $(e.indexIds)(o) || e.indexIds.push(o);
  });
}, os = I(Ve.removeIndex), JA = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("indexEntities").removeOperator(n, r, o, () => {
    const s = e.indexIds.indexOf(o);
    s !== -1 && e.indexIds.splice(s, 1);
  });
}, up = I(Ve.changeIndexName), XA = ({ collections: e, lww: t }, { payload: { id: n, tableId: o, value: r }, timestamp: s }) => {
  const i = y(e).collection("indexEntities");
  i.getOrCreate(n, (a) => zn({ id: a, tableId: o })), i.replaceOperator(t, s, n, "name", () => {
    i.updateOne(n, (a) => {
      a.name = r;
    });
  });
}, Hc = I(Ve.changeIndexUnique), eM = ({ collections: e, lww: t }, { payload: { id: n, tableId: o, value: r }, timestamp: s }) => {
  const i = y(e).collection("indexEntities");
  i.getOrCreate(n, (a) => zn({ id: a, tableId: o })), i.replaceOperator(t, s, n, "unique", () => {
    i.updateOne(n, (a) => {
      a.unique = r;
    });
  });
}, tM = {
  [Ve.addIndex]: QA,
  [Ve.removeIndex]: JA,
  [Ve.changeIndexName]: XA,
  [Ve.changeIndexUnique]: eM
}, nM = {
  addIndexAction: qc,
  removeIndexAction: os,
  changeIndexNameAction: up,
  changeIndexUniqueAction: Hc
}, oM = (e, { payload: { id: t } }) => {
  e.push(os({ id: t }));
}, rM = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("indexEntities").selectById(t);
  o && e.push(qc({
    id: t,
    tableId: o.tableId
  }));
}, sM = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("indexEntities").selectById(t);
  r && e.push(up({
    id: t,
    tableId: n,
    value: r.name
  }));
}, iM = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("indexEntities").selectById(t) && e.push(Hc({
    id: t,
    tableId: n,
    value: !o
  }));
}, aM = {
  [Ve.addIndex]: oM,
  [Ve.removeIndex]: rM,
  [Ve.changeIndexName]: sM,
  [Ve.changeIndexUnique]: iM
}, ze = {
  addIndexColumn: "indexColumn.add",
  removeIndexColumn: "indexColumn.remove",
  moveIndexColumn: "indexColumn.move",
  changeIndexColumnOrderType: "indexColumn.changeOrderType"
}, Zc = (e) => Jo({
  id: fe(),
  indexId: "",
  columnId: "",
  orderType: wo.ASC,
  meta: Yo()
}, e ?? {});
function dp(e, t, n) {
  if ($(t)(n)) {
    const r = t.reduce((s, i, a) => (s[i] = a, s), {});
    e.push(n), e.sort((s, i) => {
      const a = r[s], l = r[i];
      return a === void 0 ? 1 : l === void 0 ? -1 : a - l;
    });
  } else
    e.push(n), t.push(n);
}
const Gi = I(ze.addIndexColumn), lM = ({ collections: e, lww: t }, { payload: { id: n, indexId: o, tableId: r, columnId: s }, timestamp: i }) => {
  const a = y(e).collection("indexEntities"), l = a.getOrCreate(o, (c) => zn({ id: c, tableId: r }));
  y(e).collection("indexColumnEntities").addOne(Zc({ id: n, indexId: o, columnId: s })).addOperator(t, i, n, () => {
    $(l.indexColumnIds)(n) || a.updateOne(o, (c) => {
      dp(c.indexColumnIds, c.seqIndexColumnIds, n);
    });
  });
}, Vc = I(ze.removeIndexColumn), cM = ({ collections: e, lww: t }, { payload: { id: n, indexId: o, tableId: r }, timestamp: s }) => {
  const i = y(e).collection("indexEntities"), a = i.getOrCreate(o, (l) => zn({ id: l, tableId: r }));
  y(e).collection("indexColumnEntities").removeOperator(t, s, n, () => {
    const l = a.indexColumnIds.indexOf(n);
    l !== -1 && i.updateOne(o, (c) => {
      c.indexColumnIds.splice(l, 1);
    });
  });
}, zc = I(ze.moveIndexColumn), uM = ({ collections: e }, { payload: { id: t, indexId: n, tableId: o, targetId: r } }) => {
  if (t === r)
    return;
  const s = y(e).collection("indexEntities"), i = s.getOrCreate(n, (c) => zn({ id: c, tableId: o })), a = i.indexColumnIds.indexOf(t);
  if (a === -1)
    return;
  const l = i.indexColumnIds.indexOf(r);
  l !== -1 && s.updateOne(n, (c) => {
    c.indexColumnIds.splice(a, 1), c.indexColumnIds.splice(l, 0, t);
    const u = c.seqIndexColumnIds.indexOf(t), d = c.seqIndexColumnIds.indexOf(r);
    u !== -1 && d !== -1 && (c.seqIndexColumnIds.splice(u, 1), c.seqIndexColumnIds.splice(d, 0, t));
  });
}, Gc = I(ze.changeIndexColumnOrderType), dM = ({ collections: e, lww: t }, { payload: { id: n, indexId: o, columnId: r, value: s }, timestamp: i }) => {
  const a = y(e).collection("indexColumnEntities");
  a.getOrCreate(n, (l) => Zc({ id: l, indexId: o, columnId: r })), a.replaceOperator(t, i, n, "orderType", () => {
    a.updateOne(n, (l) => {
      l.orderType = s;
    });
  });
}, fM = {
  [ze.addIndexColumn]: lM,
  [ze.removeIndexColumn]: cM,
  [ze.moveIndexColumn]: uM,
  [ze.changeIndexColumnOrderType]: dM
}, mM = {
  addIndexColumnAction: Gi,
  removeIndexColumnAction: Vc,
  moveIndexColumnAction: zc,
  changeIndexColumnOrderTypeAction: Gc
}, hM = (e, { payload: { id: t, indexId: n, tableId: o } }) => {
  e.push(Vc({ id: t, indexId: n, tableId: o }));
}, pM = (e, { payload: { id: t, indexId: n, tableId: o } }, { collections: r }) => {
  const s = y(r).collection("indexColumnEntities").selectById(t);
  s && e.push(Gi({
    id: t,
    indexId: n,
    tableId: o,
    columnId: s.columnId
  }));
}, yM = (e, { payload: { id: t, indexId: n, tableId: o, targetId: r } }, { collections: s }) => {
  const i = y(s).collection("indexEntities").selectById(n);
  if (!i)
    return;
  const a = i.indexColumnIds.indexOf(t);
  if (a === -1)
    return;
  const l = i.indexColumnIds.indexOf(r);
  if (l === -1)
    return;
  const c = a < l ? a + 1 : a - 1, u = i.indexColumnIds[c];
  e.push(zc({
    indexId: n,
    tableId: o,
    id: t,
    targetId: u
  }));
}, gM = (e, { payload: { id: t, indexId: n, columnId: o } }, { collections: r }) => {
  const s = y(r).collection("indexColumnEntities").selectById(t);
  s && e.push(Gc({
    id: t,
    indexId: n,
    columnId: o,
    value: s.orderType
  }));
}, vM = {
  [ze.addIndexColumn]: hM,
  [ze.removeIndexColumn]: pM,
  [ze.moveIndexColumn]: yM,
  [ze.changeIndexColumnOrderType]: gM
}, oe = {
  addMemo: "memo.add",
  moveMemo: "memo.move",
  moveToMemo: "memo.moveTo",
  removeMemo: "memo.remove",
  changeMemoValue: "memo.changeValue",
  changeMemoColor: "memo.changeColor",
  resizeMemo: "memo.resize",
  changeZIndex: "memo.changeZIndex"
}, Gn = (e) => Jo({
  id: fe(),
  value: "",
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    width: xx,
    height: Ax,
    color: ""
  },
  meta: Yo()
}, e ?? {}), Yc = I(oe.addMemo), bM = ({ doc: e, collections: t, lww: n }, { payload: { id: o, ui: r }, timestamp: s }) => {
  y(t).collection("memoEntities").addOne(Gn({ id: o, ui: r })).addOperator(n, s, o, () => {
    $(e.memoIds)(o) || e.memoIds.push(o);
  });
}, Co = I(oe.moveMemo), IM = ({ collections: e }, { payload: { ids: t, movementX: n, movementY: o } }) => {
  const r = y(e).collection("memoEntities");
  for (const s of t)
    r.getOrCreate(s, (i) => Gn({ id: i }));
  r.updateMany(t, (s) => {
    s.ui.x = ft(s.ui.x + n, 4), s.ui.y = ft(s.ui.y + o, 4);
  });
}, fp = I(oe.moveToMemo), TM = ({ collections: e }, { payload: { id: t, x: n, y: o } }) => {
  const r = y(e).collection("memoEntities");
  r.getOrCreate(t, (s) => Gn({ id: s })), r.updateOne(t, (s) => {
    s.ui.x = n, s.ui.y = o;
  });
}, Yi = I(oe.removeMemo), wM = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("memoEntities").removeOperator(n, r, o, () => {
    const s = e.memoIds.indexOf(o);
    s !== -1 && e.memoIds.splice(s, 1);
  });
}, mp = I(oe.changeMemoValue), CM = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("memoEntities");
  s.getOrCreate(n, (i) => Gn({ id: i })), s.replaceOperator(t, r, n, "value", () => {
    s.updateOne(n, (i) => {
      i.value = o;
    });
  });
}, Hr = I(oe.changeMemoColor), OM = ({ collections: e, lww: t }, { payload: { id: n, color: o }, timestamp: r }) => {
  const s = y(e).collection("memoEntities");
  s.getOrCreate(n, (i) => Gn({ id: i })), s.replaceOperator(t, r, n, "ui.color", () => {
    s.updateOne(n, (i) => {
      i.ui.color = o;
    });
  });
}, hp = I(oe.resizeMemo), EM = ({ collections: e }, { payload: { id: t, x: n, y: o, width: r, height: s } }) => {
  const i = y(e).collection("memoEntities");
  i.getOrCreate(t, (a) => Gn({ id: a })), i.updateOne(t, (a) => {
    a.ui.x = n, a.ui.y = o, a.ui.width = r, a.ui.height = s;
  });
}, pp = I(oe.changeZIndex), SM = ({ collections: e }, { payload: { id: t, zIndex: n } }) => {
  const o = y(e).collection("memoEntities");
  o.getOrCreate(t, (r) => Gn({ id: r })), o.updateOne(t, (r) => {
    r.ui.zIndex = n;
  });
}, xM = {
  [oe.addMemo]: bM,
  [oe.moveMemo]: IM,
  [oe.moveToMemo]: TM,
  [oe.removeMemo]: wM,
  [oe.changeMemoValue]: CM,
  [oe.changeMemoColor]: OM,
  [oe.resizeMemo]: EM,
  [oe.changeZIndex]: SM
}, AM = {
  addMemoAction: Yc,
  moveMemoAction: Co,
  moveToMemoAction: fp,
  removeMemoAction: Yi,
  changeMemoValueAction: mp,
  changeMemoColorAction: Hr,
  resizeMemoAction: hp,
  changeZIndexAction: pp
}, MM = 20, NM = (e, { payload: { id: t } }) => {
  e.push(Yi({ id: t }));
}, $M = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("memoEntities").selectById(t);
  o && e.push(Yc({ id: o.id, ui: Xr(o.ui, ["x", "y", "zIndex"]) }));
}, kM = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("memoEntities").selectById(t);
  o && e.push(mp({ id: t, value: o.value }));
}, DM = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("memoEntities").selectById(t);
  o && e.push(fp({
    id: t,
    x: o.ui.x,
    y: o.ui.y
  }));
}, RM = {
  [oe.addMemo]: NM,
  [oe.removeMemo]: $M,
  [oe.changeMemoValue]: kM,
  [oe.moveToMemo]: DM
}, LM = (e, t, n) => {
  const o = n.filter((s) => s.type === Co.type);
  if (!o.length)
    return;
  const r = cs(o, (s) => s.payload.ids.join(","));
  for (const [, s] of Object.entries(r)) {
    const { payload: { ids: i } } = ls(s), { x: a, y: l } = s.reduce((c, { payload: { movementX: u, movementY: d } }) => (c.x += u, c.y += d, c), { x: 0, y: 0 });
    Math.abs(a) + Math.abs(l) < MM || (e.push(Co({
      ids: i,
      movementX: -1 * a,
      movementY: -1 * l
    })), t.push(Co({
      ids: i,
      movementX: a,
      movementY: l
    })));
  }
}, PM = (e, t, n) => {
  const o = n.filter(({ type: s }) => s === Hr.type);
  if (!o.length)
    return;
  const r = cs(o, (s) => s.payload.id);
  for (const [s, i] of Object.entries(r)) {
    const a = ls(i), l = Fo(i);
    e.push(Hr({
      id: s,
      color: a.payload.prevColor,
      prevColor: l.payload.color
    })), t.push(Hr({
      id: s,
      color: l.payload.color,
      prevColor: a.payload.prevColor
    }));
  }
}, _M = (e, t, n) => {
  const o = n.filter((s) => s.type === hp.type);
  if (!o.length)
    return;
  const r = cs(o, (s) => s.payload.id);
  for (const [, s] of Object.entries(r)) {
    if (s.length < 2)
      continue;
    const i = ls(s), a = Fo(s);
    e.push(i), t.push(a);
  }
}, BM = {
  [oe.moveMemo]: LM,
  [oe.changeMemoColor]: PM,
  [oe.resizeMemo]: _M
}, Pt = {
  addRelationship: "relationship.add",
  removeRelationship: "relationship.remove",
  changeRelationshipType: "relationship.changeType"
}, yp = (e) => Jo({
  id: fe(),
  identification: !1,
  relationshipType: Bh.ZeroN,
  startRelationshipType: $l.dash,
  start: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: Ct.bottom
  },
  end: {
    tableId: "",
    columnIds: [],
    x: 0,
    y: 0,
    direction: Ct.bottom
  },
  meta: Yo()
}, e ?? {}), ws = I(Pt.addRelationship), WM = ({ doc: e, collections: t, lww: n }, { payload: { id: o, relationshipType: r, start: s, end: i }, timestamp: a }) => {
  y(t).collection("relationshipEntities").addOne(yp({
    id: o,
    relationshipType: r,
    start: {
      tableId: s.tableId,
      columnIds: s.columnIds
    },
    end: {
      tableId: i.tableId,
      columnIds: i.columnIds
    }
  })).addOperator(n, a, o, () => {
    $(e.relationshipIds)(o) || e.relationshipIds.push(o);
  });
}, _o = I(Pt.removeRelationship), UM = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("relationshipEntities").removeOperator(n, r, o, () => {
    const s = e.relationshipIds.indexOf(o);
    s !== -1 && e.relationshipIds.splice(s, 1);
  });
}, gp = I(Pt.changeRelationshipType), jM = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("relationshipEntities");
  s.replaceOperator(t, r, n, "relationshipType", () => {
    s.updateOne(n, (i) => {
      i.relationshipType = o;
    });
  });
}, FM = {
  [Pt.addRelationship]: WM,
  [Pt.removeRelationship]: UM,
  [Pt.changeRelationshipType]: jM
}, qM = {
  addRelationshipAction: ws,
  removeRelationshipAction: _o,
  changeRelationshipTypeAction: gp
}, HM = (e, { payload: { id: t } }) => {
  e.push(_o({ id: t }));
}, ZM = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("relationshipEntities").selectById(t);
  o && e.push(ws({
    id: o.id,
    relationshipType: o.relationshipType,
    start: Xr(o.start, ["tableId", "columnIds"]),
    end: Xr(o.end, ["tableId", "columnIds"])
  }));
}, VM = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("relationshipEntities").selectById(t);
  o && e.push(gp({
    id: t,
    value: o.relationshipType
  }));
}, zM = {
  [Pt.addRelationship]: HM,
  [Pt.removeRelationship]: ZM,
  [Pt.changeRelationshipType]: VM
}, L = {
  changeDatabaseName: "settings.changeDatabaseName",
  resize: "settings.resize",
  changeZoomLevel: "settings.changeZoomLevel",
  streamZoomLevel: "settings.streamZoomLevel",
  scrollTo: "settings.scrollTo",
  streamScrollTo: "settings.streamScrollTo",
  changeShow: "settings.changeShow",
  changeDatabase: "settings.changeDatabase",
  changeCanvasType: "settings.changeCanvasType",
  changeLanguage: "settings.changeLanguage",
  changeTableNameCase: "settings.changeTableNameCase",
  changeColumnNameCase: "settings.changeColumnNameCase",
  changeBracketType: "settings.changeBracketType",
  changeRelationshipDataTypeSync: "settings.changeRelationshipDataTypeSync",
  changeRelationshipOptimization: "settings.changeRelationshipOptimization",
  changeColumnOrder: "settings.changeColumnOrder",
  changeMaxWidthComment: "settings.changeMaxWidthComment",
  changeIgnoreSaveSettings: "settings.changeIgnoreSaveSettings"
}, GM = I(L.changeDatabaseName), YM = ({ settings: e, lww: t }, { payload: { value: n }, timestamp: o }) => {
  Nm(t, o, "settings.databaseName", "settings", "databaseName", () => {
    e.databaseName = n;
  });
}, vp = I(L.resize), KM = ({ settings: e }, { payload: { width: t, height: n } }) => {
  e.width = Dl(t), e.height = Dl(n);
}, Kc = I(L.changeZoomLevel), QM = ({ settings: e }, { payload: { value: t }, tags: n }) => {
  !pe(n) && M(n, Vn.following) || (e.zoomLevel = wa(t));
}, Zr = I(L.streamZoomLevel), JM = ({ settings: e }, { payload: { value: t }, tags: n }) => {
  !pe(n) && M(n, Vn.following) || (e.zoomLevel = wa(e.zoomLevel + t));
}, Qc = I(L.scrollTo), XM = ({ settings: e, editor: { viewport: t } }, { payload: { scrollTop: n, scrollLeft: o }, tags: r }) => {
  if (!pe(r) && M(r, Vn.following))
    return;
  const s = Je(t.height - e.height, 0), i = Je(t.width - e.width, 0);
  e.scrollTop = ft(s(n), 4), e.scrollLeft = ft(i(o), 4);
}, Vr = I(L.streamScrollTo), eN = ({ settings: e, editor: { viewport: t } }, { payload: { movementX: n, movementY: o }, tags: r }) => {
  if (!pe(r) && M(r, Vn.following))
    return;
  const s = Je(t.height - e.height, 0), i = Je(t.width - e.width, 0);
  e.scrollTop = ft(s(e.scrollTop + o), 4), e.scrollLeft = ft(i(e.scrollLeft + n), 4);
}, Jc = I(L.changeShow), tN = ({ settings: e }, { payload: { show: t, value: n } }) => {
  e.show = n ? e.show | t : e.show & ~t;
}, nN = I(L.changeDatabase), oN = ({ settings: e }, { payload: { value: t } }) => {
  Dx(t) && (e.database = t);
}, rN = I(L.changeCanvasType), sN = ({ settings: e }, { payload: { value: t }, tags: n }) => {
  !pe(n) && M(n, Vn.following) || (e.canvasType = t);
}, iN = I(L.changeLanguage), aN = ({ settings: e }, { payload: { value: t } }) => {
  Lx(t) && (e.language = t);
}, lN = I(L.changeTableNameCase), cN = ({ settings: e }, { payload: { value: t } }) => {
  Qh(t) && (e.tableNameCase = t);
}, uN = I(L.changeColumnNameCase), dN = ({ settings: e }, { payload: { value: t } }) => {
  Qh(t) && (e.columnNameCase = t);
}, fN = I(L.changeBracketType), mN = ({ settings: e }, { payload: { value: t } }) => {
  Rx(t) && (e.bracketType = t);
}, hN = I(L.changeRelationshipDataTypeSync), pN = ({ settings: e }, { payload: { value: t } }) => {
  e.relationshipDataTypeSync = t;
}, yN = I(L.changeRelationshipOptimization), gN = ({ settings: e }, { payload: { value: t } }) => {
  e.relationshipOptimization = t;
}, vN = I(L.changeColumnOrder), bN = ({ settings: e }, { payload: { value: t, target: n } }) => {
  if (t === n || !Ed(t) || !Ed(n))
    return;
  const o = e.columnOrder.indexOf(t), r = e.columnOrder.indexOf(n);
  o === -1 || r === -1 || (e.columnOrder.splice(o, 1), e.columnOrder.splice(r, 0, t));
}, bp = I(L.changeMaxWidthComment), IN = ({ settings: e }, { payload: { value: t } }) => {
  e.maxWidthComment = t === -1 ? t : Kh(t);
}, TN = I(L.changeIgnoreSaveSettings), wN = ({ settings: e }, { payload: { saveSettingType: t, value: n } }) => {
  e.ignoreSaveSettings = n ? e.ignoreSaveSettings | t : e.ignoreSaveSettings & ~t;
}, CN = {
  [L.changeDatabaseName]: YM,
  [L.resize]: KM,
  [L.changeZoomLevel]: QM,
  [L.streamZoomLevel]: JM,
  [L.scrollTo]: XM,
  [L.streamScrollTo]: eN,
  [L.changeShow]: tN,
  [L.changeDatabase]: oN,
  [L.changeCanvasType]: sN,
  [L.changeLanguage]: aN,
  [L.changeTableNameCase]: cN,
  [L.changeColumnNameCase]: dN,
  [L.changeBracketType]: mN,
  [L.changeRelationshipDataTypeSync]: pN,
  [L.changeRelationshipOptimization]: gN,
  [L.changeColumnOrder]: bN,
  [L.changeMaxWidthComment]: IN,
  [L.changeIgnoreSaveSettings]: wN
}, ON = {
  changeDatabaseNameAction: GM,
  resizeAction: vp,
  changeZoomLevelAction: Kc,
  streamZoomLevelAction: Zr,
  scrollToAction: Qc,
  streamScrollToAction: Vr,
  changeShowAction: Jc,
  changeDatabaseAction: nN,
  changeCanvasTypeAction: rN,
  changeLanguageAction: iN,
  changeTableNameCaseAction: lN,
  changeColumnNameCaseAction: uN,
  changeBracketTypeAction: fN,
  changeRelationshipDataTypeSyncAction: hN,
  changeRelationshipOptimizationAction: yN,
  changeColumnOrderAction: vN,
  changeMaxWidthCommentAction: bp,
  changeIgnoreSaveSettingsAction: TN
}, EN = 20, SN = (e, t, { settings: n }) => {
  e.push(vp({ width: n.width, height: n.height }));
}, xN = (e, t, { settings: n }) => {
  e.push(Qc({
    scrollLeft: n.scrollLeft,
    scrollTop: n.scrollTop
  }));
}, AN = (e, { payload: { show: t, value: n } }) => {
  e.push(Jc({ show: t, value: !n }));
}, MN = (e, t, { settings: n }) => {
  e.push(Kc({ value: n.zoomLevel }));
}, NN = {
  [L.resize]: SN,
  [L.scrollTo]: xN,
  [L.changeShow]: AN,
  [L.changeZoomLevel]: MN
}, $N = (e, t, n) => {
  const o = n.filter((i) => i.type === Vr.type);
  if (!o.length)
    return;
  const { x: r, y: s } = o.reduce((i, { payload: { movementX: a, movementY: l } }) => (i.x += a, i.y += l, i), { x: 0, y: 0 });
  Math.abs(r) + Math.abs(s) < EN || (e.push(Vr({
    movementX: -1 * r,
    movementY: -1 * s
  })), t.push(Vr({
    movementX: r,
    movementY: s
  })));
}, kN = (e, t, n) => {
  const o = n.filter((s) => s.type === Zr.type);
  if (!o.length)
    return;
  const r = o.reduce((s, { payload: { value: i } }) => s + i, 0);
  e.push(Zr({ value: -1 * r })), t.push(Zr({ value: r }));
}, DN = {
  [L.streamScrollTo]: $N,
  [L.streamZoomLevel]: kN
}, Q = {
  addTable: "table.add",
  moveTable: "table.move",
  moveToTable: "table.moveTo",
  removeTable: "table.remove",
  changeTableName: "table.changeName",
  changeTableComment: "table.changeComment",
  changeTableColor: "table.changeColor",
  changeZIndex: "table.changeZIndex",
  sortTable: "table.sort"
};
function Xc(e, { settings: { show: t, maxWidthComment: n }, collections: o }) {
  let r = e.ui.widthName + $n;
  if (M(t, Y.tableComment)) {
    const l = n === -1 ? e.ui.widthComment : n < e.ui.widthComment ? n : e.ui.widthComment;
    r += l + $n;
  }
  const s = LN(t);
  r < s && (r = s);
  const i = y(o).collection("tableColumnEntities").selectByIds(e.columnIds), a = PN(i, t, n);
  return r < a.width && (r = a.width), {
    ...a,
    width: Hi + Zi + r + Zi + Hi
  };
}
const RN = [
  {
    key: Y.columnComment,
    width: Ze
  },
  {
    key: Y.columnDataType,
    width: Ze
  },
  {
    key: Y.columnDefault,
    width: Ze
  },
  {
    key: Y.columnNotNull,
    width: Zh
  },
  {
    key: Y.columnAutoIncrement,
    width: zh
  },
  {
    key: Y.columnUnique,
    width: Vh
  }
];
function LN(e) {
  return RN.reduce((t, { key: n, width: o }) => M(e, n) ? t + o + $n : t, Hh + $n + Ze + $n + qh);
}
function PN(e, t, n) {
  const o = {
    width: 0,
    name: 0,
    comment: 0,
    dataType: 0,
    default: 0,
    notNull: 0,
    autoIncrement: 0,
    unique: 0
  };
  for (const r of e) {
    if (o.name < r.ui.widthName && (o.name = r.ui.widthName), M(t, Y.columnComment) && o.comment < r.ui.widthComment) {
      const s = n === -1 ? r.ui.widthComment : n < r.ui.widthComment ? n : r.ui.widthComment;
      o.comment = s;
    }
    M(t, Y.columnDataType) && o.dataType < r.ui.widthDataType && (o.dataType = r.ui.widthDataType), M(t, Y.columnDefault) && o.default < r.ui.widthDefault && (o.default = r.ui.widthDefault);
  }
  return M(t, Y.columnNotNull) && (o.notNull = Zh), M(t, Y.columnAutoIncrement) && (o.autoIncrement = zh), M(t, Y.columnUnique) && (o.unique = Vh), o.width = Object.entries(o).reduce((r, [s, i]) => s === "width" || i === 0 ? r : r + i + $n, Hh + $n + qh), o;
}
function eu(e) {
  return Hi + Zi + Ox + e.columnIds.length * Sx + Zi + Hi;
}
function _N({ doc: { tableIds: e }, collections: t }, { toWidth: n }) {
  const o = y(t).collection("tableEntities").selectByIds(e), r = y(t).collection("tableColumnEntities");
  for (const s of o) {
    s.ui.widthName = ge(n(s.name)), s.ui.widthComment = ge(n(s.comment));
    const i = r.selectByIds(s.columnIds);
    for (const a of i)
      a.ui.widthName = ge(n(a.name)), a.ui.widthDataType = ge(n(a.dataType)), a.ui.widthDefault = ge(n(a.default)), a.ui.widthComment = ge(n(a.comment));
  }
}
const gt = (e) => Jo({
  id: fe(),
  name: "",
  comment: "",
  columnIds: [],
  seqColumnIds: [],
  ui: {
    x: 200,
    y: 100,
    zIndex: 2,
    widthName: Ze,
    widthComment: Ze,
    color: ""
  },
  meta: Yo()
}, e ?? {}), tu = I(Q.addTable), BN = ({ doc: e, collections: t, lww: n }, { payload: { id: o, ui: r }, timestamp: s }) => {
  y(t).collection("tableEntities").addOne(gt({ id: o, ui: r })).addOperator(n, s, o, () => {
    $(e.tableIds)(o) || e.tableIds.push(o);
  });
}, Oo = I(Q.moveTable), WN = ({ collections: e }, { payload: { ids: t, movementX: n, movementY: o } }) => {
  const r = y(e).collection("tableEntities");
  for (const s of t)
    r.getOrCreate(s, (i) => gt({ id: i }));
  r.updateMany(t, (s) => {
    s.ui.x = ft(s.ui.x + n, 4), s.ui.y = ft(s.ui.y + o, 4);
  });
}, nu = I(Q.moveToTable), UN = ({ collections: e }, { payload: { id: t, x: n, y: o } }) => {
  const r = y(e).collection("tableEntities");
  r.getOrCreate(t, (s) => gt({ id: s })), r.updateOne(t, (s) => {
    s.ui.x = n, s.ui.y = o;
  });
}, Ki = I(Q.removeTable), jN = ({ doc: e, collections: t, lww: n }, { payload: { id: o }, timestamp: r }) => {
  y(t).collection("tableEntities").removeOperator(n, r, o, () => {
    const s = e.tableIds.indexOf(o);
    s !== -1 && e.tableIds.splice(s, 1);
  });
}, ou = I(Q.changeTableName), FN = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableEntities");
  i.getOrCreate(n, (a) => gt({ id: a })), i.replaceOperator(t, r, n, "name", () => {
    i.updateOne(n, (a) => {
      a.name = o, a.ui.widthName = ge(s(o));
    });
  });
}, ru = I(Q.changeTableComment), qN = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableEntities");
  i.getOrCreate(n, (a) => gt({ id: a })), i.replaceOperator(t, r, n, "comment", () => {
    i.updateOne(n, (a) => {
      a.comment = o, a.ui.widthComment = ge(s(o));
    });
  });
}, zr = I(Q.changeTableColor), HN = ({ collections: e, lww: t }, { payload: { id: n, color: o }, timestamp: r }) => {
  const s = y(e).collection("tableEntities");
  s.getOrCreate(n, (i) => gt({ id: i })), s.replaceOperator(t, r, n, "ui.color", () => {
    s.updateOne(n, (i) => {
      i.ui.color = o;
    });
  });
}, Ip = I(Q.changeZIndex), ZN = ({ collections: e }, { payload: { id: t, zIndex: n } }) => {
  const o = y(e).collection("tableEntities");
  o.getOrCreate(t, (r) => gt({ id: r })), o.updateOne(t, (r) => {
    r.ui.zIndex = n;
  });
}, su = I(Q.sortTable), VN = (e) => {
  const { doc: t, settings: n, collections: o } = e, r = n.width, s = y(o).collection("tableEntities").selectByIds(t.tableIds), i = 80;
  s.sort((u, d) => u.columnIds.length - d.columnIds.length);
  let a = 50, l = 50, c = 50;
  s.forEach((u) => {
    const d = Xc(u, e).width + i, f = eu(u) + i;
    a + d > r && (l += c, c = 0, a = 50), c < f && (c = f), u.ui.y = l, u.ui.x = a, a += d;
  });
}, zN = {
  [Q.addTable]: BN,
  [Q.moveTable]: WN,
  [Q.moveToTable]: UN,
  [Q.removeTable]: jN,
  [Q.changeTableName]: FN,
  [Q.changeTableComment]: qN,
  [Q.changeTableColor]: HN,
  [Q.changeZIndex]: ZN,
  [Q.sortTable]: VN
}, GN = {
  addTableAction: tu,
  moveTableAction: Oo,
  moveToTableAction: nu,
  removeTableAction: Ki,
  changeTableNameAction: ou,
  changeTableCommentAction: ru,
  changeTableColorAction: zr,
  changeZIndexAction: Ip,
  sortTableAction: su
}, YN = 20, KN = (e, { payload: { id: t } }) => {
  e.push(Ki({ id: t }));
}, QN = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(tu({ id: o.id, ui: Xr(o.ui, ["x", "y", "zIndex"]) }));
}, JN = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(ou({ id: t, value: o.name }));
}, XN = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(ru({ id: t, value: o.comment }));
}, e$ = (e, { payload: { id: t } }, { collections: n }) => {
  const o = y(n).collection("tableEntities").selectById(t);
  o && e.push(nu({
    id: t,
    x: o.ui.x,
    y: o.ui.y
  }));
}, t$ = () => {
}, n$ = {
  [Q.addTable]: KN,
  [Q.removeTable]: QN,
  [Q.changeTableName]: JN,
  [Q.changeTableComment]: XN,
  [Q.moveToTable]: e$,
  [Q.sortTable]: t$
}, o$ = (e, t, n) => {
  const o = n.filter((s) => s.type === Oo.type);
  if (!o.length)
    return;
  const r = cs(o, (s) => s.payload.ids.join(","));
  for (const [, s] of Object.entries(r)) {
    const { payload: { ids: i } } = ls(s), { x: a, y: l } = s.reduce((c, { payload: { movementX: u, movementY: d } }) => (c.x += u, c.y += d, c), { x: 0, y: 0 });
    Math.abs(a) + Math.abs(l) < YN || (e.push(Oo({
      ids: i,
      movementX: -1 * a,
      movementY: -1 * l
    })), t.push(Oo({
      ids: i,
      movementX: a,
      movementY: l
    })));
  }
}, r$ = (e, t, n) => {
  const o = n.filter(({ type: s }) => s === zr.type);
  if (!o.length)
    return;
  const r = cs(o, (s) => s.payload.id);
  for (const [s, i] of Object.entries(r)) {
    const a = ls(i), l = Fo(i);
    e.push(zr({
      id: s,
      color: a.payload.prevColor,
      prevColor: l.payload.color
    })), t.push(zr({
      id: s,
      color: l.payload.color,
      prevColor: a.payload.prevColor
    }));
  }
}, s$ = {
  [Q.moveTable]: o$,
  [Q.changeTableColor]: r$
}, F = {
  addColumn: "column.add",
  removeColumn: "column.remove",
  changeColumnName: "column.changeName",
  changeColumnComment: "column.changeComment",
  changeColumnDataType: "column.changeDataType",
  changeColumnDefault: "column.changeDefault",
  changeColumnAutoIncrement: "column.changeAutoIncrement",
  changeColumnPrimaryKey: "column.changePrimaryKey",
  changeColumnUnique: "column.changeUnique",
  changeColumnNotNull: "column.changeNotNull",
  moveColumn: "column.move"
}, Mt = (e) => Jo({
  id: fe(),
  tableId: "",
  name: "",
  comment: "",
  dataType: "",
  default: "",
  options: 0,
  ui: {
    keys: 0,
    widthName: Ze,
    widthComment: Ze,
    widthDataType: Ze,
    widthDefault: Ze
  },
  meta: Yo()
}, e ?? {}), At = I(F.addColumn), i$ = ({ collections: e, lww: t }, { payload: { id: n, tableId: o }, timestamp: r }) => {
  const s = y(e).collection("tableEntities"), i = s.getOrCreate(o, (a) => gt({ id: a }));
  y(e).collection("tableColumnEntities").addOne(Mt({ id: n, tableId: o })).addOperator(t, r, n, () => {
    $(i.columnIds)(n) || s.updateOne(o, (a) => {
      dp(a.columnIds, a.seqColumnIds, n);
    });
  });
}, Eo = I(F.removeColumn), a$ = ({ collections: e, lww: t }, { payload: { id: n, tableId: o }, timestamp: r }) => {
  const s = y(e).collection("tableEntities"), i = s.getOrCreate(o, (a) => gt({ id: a }));
  y(e).collection("tableColumnEntities").removeOperator(t, r, n, () => {
    const a = i.columnIds.indexOf(n);
    a !== -1 && s.updateOne(o, (l) => {
      l.columnIds.splice(a, 1);
    });
  });
}, _t = I(F.changeColumnName), l$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (a) => Mt({ id: a })), i.replaceOperator(t, r, n, "name", () => {
    i.updateOne(n, (a) => {
      a.name = o, a.ui.widthName = ge(s(o));
    });
  });
}, Bt = I(F.changeColumnComment), c$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (a) => Mt({ id: a })), i.replaceOperator(t, r, n, "comment", () => {
    i.updateOne(n, (a) => {
      a.comment = o, a.ui.widthComment = ge(s(o));
    });
  });
}, Wt = I(F.changeColumnDataType), u$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (a) => Mt({ id: a })), i.replaceOperator(t, r, n, "dataType", () => {
    i.updateOne(n, (a) => {
      a.dataType = o, a.ui.widthDataType = ge(s(o));
    });
  });
}, Ut = I(F.changeColumnDefault), d$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }, { toWidth: s }) => {
  const i = y(e).collection("tableColumnEntities");
  i.getOrCreate(n, (a) => Mt({ id: a })), i.replaceOperator(t, r, n, "default", () => {
    i.updateOne(n, (a) => {
      a.default = o, a.ui.widthDefault = ge(s(o));
    });
  });
}, kn = I(F.changeColumnAutoIncrement), f$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (i) => Mt({ id: i })), s.replaceOperator(t, r, n, "options(autoIncrement)", () => {
    s.updateOne(n, (i) => {
      i.options = o ? i.options | U.autoIncrement : i.options & ~U.autoIncrement;
    });
  });
}, Yn = I(F.changeColumnPrimaryKey), m$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (i) => Mt({ id: i })), s.replaceOperator(t, r, n, "options(primaryKey)", () => {
    s.updateOne(n, (i) => {
      i.options = o ? i.options | U.primaryKey : i.options & ~U.primaryKey, i.ui.keys = o ? i.ui.keys | Pe.primaryKey : i.ui.keys & ~Pe.primaryKey;
    });
  });
}, Dn = I(F.changeColumnUnique), h$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (i) => Mt({ id: i })), s.replaceOperator(t, r, n, "options(unique)", () => {
    s.updateOne(n, (i) => {
      i.options = o ? i.options | U.unique : i.options & ~U.unique;
    });
  });
}, jt = I(F.changeColumnNotNull), p$ = ({ collections: e, lww: t }, { payload: { id: n, value: o }, timestamp: r }) => {
  const s = y(e).collection("tableColumnEntities");
  s.getOrCreate(n, (i) => Mt({ id: i })), s.replaceOperator(t, r, n, "options(notNull)", () => {
    s.updateOne(n, (i) => {
      i.options = o ? i.options | U.notNull : i.options & ~U.notNull;
    });
  });
}, Qi = I(F.moveColumn), y$ = ({ collections: e }, { payload: { id: t, tableId: n, targetId: o } }) => {
  if (t === o)
    return;
  const r = y(e).collection("tableEntities"), s = r.getOrCreate(n, (l) => gt({ id: l })), i = s.columnIds.indexOf(t);
  if (i === -1)
    return;
  const a = s.columnIds.indexOf(o);
  a !== -1 && r.updateOne(n, (l) => {
    l.columnIds.splice(i, 1), l.columnIds.splice(a, 0, t);
    const c = l.seqColumnIds.indexOf(t), u = l.seqColumnIds.indexOf(o);
    c !== -1 && u !== -1 && (l.seqColumnIds.splice(c, 1), l.seqColumnIds.splice(u, 0, t));
  });
}, g$ = {
  [F.addColumn]: i$,
  [F.removeColumn]: a$,
  [F.changeColumnName]: l$,
  [F.changeColumnComment]: c$,
  [F.changeColumnDataType]: u$,
  [F.changeColumnDefault]: d$,
  [F.changeColumnAutoIncrement]: f$,
  [F.changeColumnPrimaryKey]: m$,
  [F.changeColumnUnique]: h$,
  [F.changeColumnNotNull]: p$,
  [F.moveColumn]: y$
}, v$ = {
  addColumnAction: At,
  removeColumnAction: Eo,
  changeColumnNameAction: _t,
  changeColumnCommentAction: Bt,
  changeColumnDataTypeAction: Wt,
  changeColumnDefaultAction: Ut,
  changeColumnAutoIncrementAction: kn,
  changeColumnPrimaryKeyAction: Yn,
  changeColumnUniqueAction: Dn,
  changeColumnNotNullAction: jt,
  moveColumnAction: Qi
}, b$ = (e, { payload: { id: t, tableId: n } }) => {
  e.push(Eo({ id: t, tableId: n }));
}, I$ = (e, { payload: { id: t, tableId: n } }) => {
  e.push(At({ id: t, tableId: n }));
}, T$ = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(_t({ id: t, tableId: n, value: r.name }));
}, w$ = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(Wt({ id: t, tableId: n, value: r.dataType }));
}, C$ = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(Ut({ id: t, tableId: n, value: r.default }));
}, O$ = (e, { payload: { id: t, tableId: n } }, { collections: o }) => {
  const r = y(o).collection("tableColumnEntities").selectById(t);
  r && e.push(Bt({ id: t, tableId: n, value: r.comment }));
}, E$ = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(kn({
    id: t,
    tableId: n,
    value: !o
  }));
}, S$ = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(jt({
    id: t,
    tableId: n,
    value: !o
  }));
}, x$ = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(Yn({
    id: t,
    tableId: n,
    value: !o
  }));
}, A$ = (e, { payload: { id: t, tableId: n, value: o } }, { collections: r }) => {
  y(r).collection("tableColumnEntities").selectById(t) && e.push(Dn({
    id: t,
    tableId: n,
    value: !o
  }));
}, M$ = (e, { payload: { id: t, tableId: n, targetId: o } }, { collections: r }) => {
  const s = y(r).collection("tableEntities").selectById(n);
  if (!s)
    return;
  const i = s.columnIds.indexOf(t);
  if (i === -1)
    return;
  const a = s.columnIds.indexOf(o);
  if (a === -1)
    return;
  const l = i < a ? i + 1 : i - 1, c = s.columnIds[l];
  e.push(Qi({
    id: t,
    tableId: n,
    targetId: c
  }));
}, N$ = {
  [F.addColumn]: b$,
  [F.removeColumn]: I$,
  [F.changeColumnName]: T$,
  [F.changeColumnDataType]: w$,
  [F.changeColumnDefault]: C$,
  [F.changeColumnComment]: O$,
  [F.changeColumnAutoIncrement]: E$,
  [F.changeColumnNotNull]: S$,
  [F.changeColumnPrimaryKey]: x$,
  [F.changeColumnUnique]: A$,
  [F.moveColumn]: M$
}, Tp = {
  ...n$,
  ...N$,
  ...zM,
  ...RM,
  ...NN,
  ...LA,
  ...aM,
  ...vM
}, rs = {
  ...s$,
  ...BM,
  ...DN
};
function $$(e, t, n) {
  const o = [], r = [];
  for (const s of n) {
    const i = Tp[s.type];
    i && (i(o, s, e.state), r.push(fa(s)));
  }
  for (const s of Object.keys(rs))
    rs[s](o, r, n);
  !o.length || !r.length || t.push({
    undo: (s) => {
      const i = Date.now();
      s(o.map(Dd(i)));
    },
    redo: (s) => {
      const i = Date.now();
      s(r.map(Dd(i)));
    }
  });
}
function Dd(e) {
  return (t) => ({
    ...fa(t),
    timestamp: e
  });
}
const UL = (e, t) => (n) => {
  uC($$, e, t, n);
}, k$ = () => function* ({ settings: e, doc: { tableIds: t, memoIds: n }, collections: o }) {
  const r = y(o).collection("tableEntities").selectByIds(t), s = y(o).collection("memoEntities").selectByIds(n), i = Xh(e, r, s), a = fe();
  yield $a(), yield Qo({ [a]: De.memo }), yield Yc({
    id: a,
    ui: {
      ...i,
      zIndex: Ca(r, s)
    }
  });
}, wp = (e) => function* ({ editor: { selectedMap: t } }) {
  if (e) {
    yield Yi({ id: e });
    return;
  }
  const n = Object.entries(t).filter(([, o]) => o === De.memo);
  for (const [o] of n)
    yield Yi({ id: o });
}, D$ = (e, t) => function* ({ doc: { tableIds: n, memoIds: o }, collections: r }) {
  const s = y(r).collection("tableEntities").selectByIds(n), i = y(r).collection("memoEntities").selectByIds(o);
  t || (yield $a()), yield Qo({ [e]: De.memo }), yield pp({ id: e, zIndex: Ca(s, i) });
}, R$ = {
  addMemoAction$: k$,
  removeMemoAction$: wp,
  selectMemoAction$: D$
}, L$ = {
  columnName: "columnName",
  columnDataType: "columnDataType",
  columnNotNull: "columnNotNull",
  columnUnique: "columnUnique",
  columnAutoIncrement: "columnAutoIncrement",
  columnDefault: "columnDefault",
  columnComment: "columnComment"
}, jL = $(Object.values(L$));
function P$(e, t) {
  return t.filter((n) => {
    switch (n) {
      case P.columnName:
        return !0;
      case P.columnDataType:
        return M(e, Y.columnDataType);
      case P.columnDefault:
        return M(e, Y.columnDefault);
      case P.columnComment:
        return M(e, Y.columnComment);
      case P.columnAutoIncrement:
        return M(e, Y.columnAutoIncrement);
      case P.columnUnique:
        return M(e, Y.columnUnique);
      case P.columnNotNull:
        return M(e, Y.columnNotNull);
      default:
        return !1;
    }
  });
}
const _$ = () => function* ({ settings: e, doc: { tableIds: t, memoIds: n }, collections: o }) {
  const r = y(o).collection("tableEntities").selectByIds(t), s = y(o).collection("memoEntities").selectByIds(n), i = Xh(e, r, s), a = fe();
  yield Ea(), yield Qo({ [a]: De.table }), yield tu({
    id: a,
    ui: {
      ...i,
      zIndex: Ca(r, s)
    }
  }), yield Sa({ tableId: a });
}, Cp = (e) => function* ({ doc: { relationshipIds: t, indexIds: n }, editor: { selectedMap: o }, collections: r }) {
  const s = y(r).collection("relationshipEntities").selectByIds(t), i = y(r).collection("indexEntities").selectByIds(n);
  if (e) {
    const d = s.filter(({ start: m, end: p }) => m.tableId === e || p.tableId === e), f = i.filter(({ tableId: m }) => m === e);
    for (const { id: m } of f)
      yield os({ id: m });
    for (const { id: m } of d)
      yield _o({ id: m });
    yield Ki({ id: e });
    return;
  }
  const a = Object.entries(o).filter(([, d]) => d === De.table).map(([d]) => d), l = $(a), c = s.filter(({ start: d, end: f }) => l(d.tableId) || l(f.tableId)), u = i.filter(({ tableId: d }) => l(d));
  for (const { id: d } of u)
    yield os({ id: d });
  for (const { id: d } of c)
    yield _o({ id: d });
  for (const d of a)
    yield Ki({ id: d });
}, B$ = (e, t) => function* ({ doc: { tableIds: n, memoIds: o }, collections: r, editor: { drawRelationship: s } }) {
  const i = y(r).collection("tableEntities"), a = i.selectByIds(n), l = y(r).collection("memoEntities").selectByIds(o);
  if (t || (yield Ea()), yield Qo({ [e]: De.table }), yield Ip({ id: e, zIndex: Ca(a, l) }), yield Sa({ tableId: e }), !!s)
    if (s.start) {
      const c = i.selectById(s.start.tableId), u = i.selectById(e);
      if (!c || !u)
        return;
      const d = y(r).collection("tableColumnEntities").selectByIds(c.columnIds).filter(({ options: m }) => M(m, U.primaryKey));
      if (!d.length)
        return;
      const f = d.map(() => fe());
      for (let m = 0; m < d.length; m++) {
        const p = d[m], v = {
          id: f[m],
          tableId: u.id
        };
        yield [
          At(v),
          jt({
            ...v,
            value: !0
          }),
          _t({
            ...v,
            value: p.name
          }),
          Wt({
            ...v,
            value: p.dataType
          }),
          Ut({
            ...v,
            value: p.default
          }),
          Bt({
            ...v,
            value: p.comment
          })
        ];
      }
      yield ws({
        id: fe(),
        relationshipType: s.relationshipType,
        start: {
          tableId: c.id,
          columnIds: d.map(({ id: m }) => m)
        },
        end: {
          tableId: u.id,
          columnIds: f
        }
      }), yield Wc();
    } else
      yield Fp(e);
}, W$ = (e) => function* ({ editor: { selectedMap: t, focusTable: n }, settings: { show: o, columnOrder: r }, collections: s }) {
  const i = n && !Wn(n.focusType), a = Object.entries(t).filter(([, p]) => p === De.table).map(([p]) => p).filter((p) => i ? p !== (n == null ? void 0 : n.tableId) : !0);
  for (const p of a)
    for (const g of e) {
      const T = {
        id: fe(),
        tableId: p
      };
      yield [
        At(T),
        _t({
          ...T,
          value: g.name
        }),
        Wt({
          ...T,
          value: g.dataType
        }),
        Ut({
          ...T,
          value: g.default
        }),
        Bt({
          ...T,
          value: g.comment
        }),
        jt({
          ...T,
          value: M(g.options, U.notNull)
        }),
        Dn({
          ...T,
          value: M(g.options, U.unique)
        }),
        kn({
          ...T,
          value: M(g.options, U.autoIncrement)
        })
      ];
    }
  if (!n || !i)
    return;
  const l = y(s).collection("tableEntities").selectById(n.tableId);
  if (!l)
    return;
  const c = l.columnIds.filter($(n.selectColumnIds));
  if (c.length === 0)
    return;
  const u = l.columnIds.slice(l.columnIds.indexOf(c[c.length - 1]) + 1), d = [...c, ...u], f = P$(o, r);
  yield Bc();
  for (let p = 0; p < d.length; p++) {
    const g = d[p], v = e[p];
    if (!v)
      break;
    const T = {
      id: g,
      tableId: l.id
    };
    yield [
      ...f.map((O) => {
        switch (O) {
          case P.columnName:
            return _t({
              ...T,
              value: v.name
            });
          case P.columnDataType:
            return Wt({
              ...T,
              value: v.dataType
            });
          case P.columnDefault:
            return Ut({
              ...T,
              value: v.default
            });
          case P.columnComment:
            return Bt({
              ...T,
              value: v.comment
            });
          case P.columnAutoIncrement:
            return kn({
              ...T,
              value: M(v.options, U.autoIncrement)
            });
          case P.columnUnique:
            return Dn({
              ...T,
              value: M(v.options, U.unique)
            });
          case P.columnNotNull:
            return jt({
              ...T,
              value: M(v.options, U.notNull)
            });
          default:
            return null;
        }
      }).filter(Boolean),
      cn({
        tableId: l.id,
        columnId: g,
        focusType: n.focusType,
        $mod: !0,
        shiftKey: !1
      })
    ];
  }
  const m = e.slice(d.length);
  for (const p of m) {
    const g = fe(), v = {
      id: g,
      tableId: l.id
    };
    yield [
      At(v),
      ...f.map((T) => {
        switch (T) {
          case P.columnName:
            return _t({
              ...v,
              value: p.name
            });
          case P.columnDataType:
            return Wt({
              ...v,
              value: p.dataType
            });
          case P.columnDefault:
            return Ut({
              ...v,
              value: p.default
            });
          case P.columnComment:
            return Bt({
              ...v,
              value: p.comment
            });
          case P.columnAutoIncrement:
            return kn({
              ...v,
              value: M(p.options, U.autoIncrement)
            });
          case P.columnUnique:
            return Dn({
              ...v,
              value: M(p.options, U.unique)
            });
          case P.columnNotNull:
            return jt({
              ...v,
              value: M(p.options, U.notNull)
            });
          default:
            return null;
        }
      }).filter(Boolean),
      cn({
        tableId: l.id,
        columnId: g,
        focusType: n.focusType,
        $mod: !0,
        shiftKey: !1
      })
    ];
  }
}, U$ = {
  addTableAction$: _$,
  removeTableAction$: Cp,
  selectTableAction$: B$,
  pasteTableAction$: W$
};
function Op(e, t, n, o = []) {
  const { doc: { relationshipIds: r }, collections: s } = t, i = e.pop();
  return i && (o.some(({ id: a }) => a === i.id) || (o.push(i), y(s).collection("relationshipEntities").selectByIds(r).forEach(({ start: a, end: l }) => {
    const c = a.columnIds.indexOf(i.id);
    if (c !== -1) {
      const u = l.columnIds[c];
      e.push({
        id: u,
        tableId: l.tableId,
        value: n.value
      });
    } else {
      const u = l.columnIds.indexOf(i.id);
      if (u !== -1) {
        const d = a.columnIds[u];
        e.push({
          id: d,
          tableId: a.tableId,
          value: n.value
        });
      }
    }
  })), Op(e, t, n, o)), o;
}
const j$ = $([
  W.columnNotNull,
  W.columnUnique,
  W.columnAutoIncrement
]), F$ = $([
  W.columnName,
  W.columnDataType,
  W.columnDefault,
  W.columnComment
]), Ep = (e) => function* ({ editor: { selectedMap: t } }) {
  if (e) {
    const r = fe();
    yield At({
      id: r,
      tableId: e
    }), yield cn({
      tableId: e,
      columnId: r,
      focusType: W.columnName,
      $mod: !1,
      shiftKey: !1
    });
    return;
  }
  const n = Object.entries(t).filter(([, r]) => r === De.table).map(([r]) => ({ tableId: r, id: fe() }));
  for (const r of n)
    yield At(r);
  const o = Fo(n);
  o && (yield cn({
    tableId: o.tableId,
    columnId: o.id,
    focusType: W.columnName,
    $mod: !1,
    shiftKey: !1
  }));
}, Sp = (e, t) => function* (n) {
  const { doc: { relationshipIds: o, indexIds: r }, editor: { focusTable: s }, collections: i } = n;
  if (s != null && s.columnId) {
    const u = Hx(n, t);
    u ? yield cn({
      tableId: s.tableId,
      columnId: u,
      focusType: s.focusType,
      $mod: !1,
      shiftKey: !1
    }) : yield Sa({
      tableId: s.tableId,
      focusType: W.tableName
    });
  }
  const a = $(t), l = y(i).collection("relationshipEntities").selectByIds(o).filter(({ start: u, end: d }) => u.tableId === e && u.columnIds.some(a) || d.tableId === e && d.columnIds.some(a)), c = y(i).collection("indexEntities").selectByIds(r).filter((u) => u.tableId === e && y(i).collection("indexColumnEntities").selectByIds(u.indexColumnIds).map(({ columnId: d }) => d).some(a));
  for (const { id: u } of c)
    yield os({ id: u });
  for (const { id: u } of l)
    yield _o({ id: u });
  for (const u of t)
    yield Eo({
      id: u,
      tableId: e
    });
}, q$ = (e, t, n) => function* ({ collections: o }) {
  if (!j$(e))
    return;
  const r = y(o).collection("tableColumnEntities").selectById(n);
  if (r)
    switch (e) {
      case W.columnNotNull:
        yield jt({
          id: n,
          tableId: t,
          value: !M(r.options, U.notNull)
        });
        break;
      case W.columnUnique:
        yield Dn({
          id: n,
          tableId: t,
          value: !M(r.options, U.unique)
        });
        break;
      case W.columnAutoIncrement:
        yield kn({
          id: n,
          tableId: t,
          value: !M(r.options, U.autoIncrement)
        });
        break;
    }
}, xp = (e) => function* (t) {
  const { settings: { relationshipDataTypeSync: n } } = t;
  let o = [e];
  n && (o = Op([e], t, e)), yield o.map(Wt);
}, H$ = (e, t, n, o) => function* ({ collections: r }) {
  if (!F$(e) || !y(r).collection("tableColumnEntities").selectById(n))
    return;
  const i = {
    id: n,
    tableId: t,
    value: o
  };
  switch (e) {
    case W.columnName:
      yield _t(i);
      break;
    case W.columnDataType:
      yield xp(i);
      break;
    case W.columnDefault:
      yield Ut(i);
      break;
    case W.columnComment:
      yield Bt(i);
      break;
  }
}, Z$ = (e, t) => function* ({ collections: n }) {
  const o = y(n).collection("tableColumnEntities").selectById(t);
  if (!o)
    return;
  const r = M(o.options, U.primaryKey);
  yield Yn({
    tableId: e,
    id: t,
    value: !r
  });
}, V$ = {
  addColumnAction$: Ep,
  removeColumnAction$: Sp,
  toggleColumnValueAction$: q$,
  changeColumnDataTypeAction$: xp,
  changeColumnValueAction$: H$,
  changeColumnPrimaryKeyAction$: Z$
};
function z$(e) {
  return Vi + zi + e.ui.width + zi + Vi;
}
function G$(e) {
  return Vi + zi + Gh + e.ui.height + zi + Vi;
}
/*!
 * @dineug/schema-sql-parser
 * @version 0.1.0 | Fri Feb 28 2025
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
const Y$ = [
  "BIGINT",
  "BINARY",
  "BIT",
  "BLOB",
  "BOOL",
  "BOOLEAN",
  "CHAR",
  "DATE",
  "DATETIME",
  "DEC",
  "DECIMAL",
  "DOUBLE PRECISION",
  "DOUBLE",
  "ENUM",
  "FIXED",
  "FLOAT",
  "GEOMETRY",
  "GEOMETRYCOLLECTION",
  "INT",
  "INTEGER",
  "JSON",
  "LINESTRING",
  "LONGBLOB",
  "LONGTEXT",
  "MEDIUMBLOB",
  "MEDIUMINT",
  "MEDIUMTEXT",
  "MULTILINESTRING",
  "MULTIPOINT",
  "MULTIPOLYGON",
  "NUMERIC",
  "POINT",
  "POLYGON",
  "REAL",
  "SET",
  "SMALLINT",
  "TEXT",
  "TIME",
  "TIMESTAMP",
  "TINYBLOB",
  "TINYINT",
  "TINYTEXT",
  "VARBINARY",
  "VARCHAR",
  "YEAR"
], K$ = [
  "BIGINT",
  "BINARY",
  "BIT",
  "CHAR",
  "DATE",
  "DATETIME",
  "DATETIME2",
  "DATETIMEOFFSET",
  "DECIMAL",
  "FLOAT",
  "GEOGRAPHY",
  "GEOMETRY",
  "IMAGE",
  "INT",
  "MONEY",
  "NCHAR",
  "NTEXT",
  "NUMERIC",
  "NVARCHAR",
  "REAL",
  "SMALLDATETIME",
  "SMALLINT",
  "SMALLMONEY",
  "SQL_VARIANT",
  "TEXT",
  "TIME",
  "TINYINT",
  "UNIQUEIDENTIFIER",
  "VARBINARY",
  "VARCHAR",
  "XML"
], Q$ = [
  "BIGINT",
  "BINARY",
  "BIT",
  "BLOB",
  "BOOL",
  "BOOLEAN",
  "CHAR",
  "DATE",
  "DATETIME",
  "DEC",
  "DECIMAL",
  "DOUBLE PRECISION",
  "DOUBLE",
  "ENUM",
  "FLOAT",
  "GEOMETRY",
  "GEOMETRYCOLLECTION",
  "INT",
  "INTEGER",
  "JSON",
  "LINESTRING",
  "LONGBLOB",
  "LONGTEXT",
  "MEDIUMBLOB",
  "MEDIUMINT",
  "MEDIUMTEXT",
  "MULTILINESTRING",
  "MULTIPOINT",
  "MULTIPOLYGON",
  "NUMERIC",
  "POINT",
  "POLYGON",
  "SET",
  "SMALLINT",
  "TEXT",
  "TIME",
  "TIMESTAMP",
  "TINYBLOB",
  "TINYINT",
  "TINYTEXT",
  "VARBINARY",
  "VARCHAR",
  "YEAR"
], J$ = [
  "BFILE",
  "BINARY_DOUBLE",
  "BINARY_FLOAT",
  "BLOB",
  "CHAR",
  "CLOB",
  "DATE",
  "DATETIME",
  "LONG RAW",
  "LONG",
  "NCHAR",
  "NCLOB",
  "NUMBER",
  "NVARCHAR2",
  "RAW",
  "TIMESTAMP WITH LOCAL TIME ZONE",
  "TIMESTAMP WITH TIME ZONE",
  "TIMESTAMP",
  "UriType",
  "VARCHAR",
  "VARCHAR2",
  "XMLType"
], X$ = [
  "BIGINT",
  "BIGSERIAL",
  "BIT VARYING",
  "BIT",
  "BOOL",
  "BOOLEAN",
  "BOX",
  "BYTEA",
  "CHAR",
  "CHARACTER VARYING",
  "CHARACTER",
  "CIDR",
  "CIRCLE",
  "DATE",
  "DECIMAL",
  "DOUBLE PRECISION",
  "FLOAT4",
  "FLOAT8",
  "INET",
  "INT",
  "INT2",
  "INT4",
  "INT8",
  "INTEGER",
  "INTERVAL",
  "JSON",
  "JSONB",
  "LINE",
  "LSEG",
  "MACADDR",
  "MACADDR8",
  "MONEY",
  "NUMERIC",
  "PATH",
  "PG_LSN",
  "POINT",
  "POLYGON",
  "REAL",
  "SERIAL",
  "SERIAL2",
  "SERIAL4",
  "SERIAL8",
  "SMALLINT",
  "SMALLSERIAL",
  "TEXT",
  "TIME WITH",
  "TIME",
  "TIMESTAMP WITH",
  "TIMESTAMP",
  "TIMESTAMPTZ",
  "TIMETZ",
  "TSQUERY",
  "TSVECTOR",
  "TXID_SNAPSHOT",
  "UUID",
  "VARBIT",
  "VARCHAR",
  "XML"
], ek = [
  "BLOB",
  "INTEGER",
  "NUMERIC",
  "REAL",
  "TEXT"
], Ie = {
  string: "string",
  leftParent: "leftParent",
  rightParent: "rightParent",
  leftBracket: "leftBracket",
  rightBracket: "rightBracket",
  comma: "comma",
  period: "period",
  equal: "equal",
  semicolon: "semicolon"
}, Se = {
  doubleQuote: '"',
  singleQuote: "'",
  backtick: "`",
  whiteSpace: /\s/,
  string: /\S/,
  breakString: /;|,|\(|\)|\[|\]|\./,
  equal: "=",
  period: ".",
  comma: ",",
  semicolon: ";",
  leftParent: "(",
  rightParent: ")",
  leftBracket: "[",
  rightBracket: "]"
}, st = (e) => (t) => e === t, Ja = (e) => (t) => e.test(t), me = {
  doubleQuote: st(Se.doubleQuote),
  singleQuote: st(Se.singleQuote),
  backtick: st(Se.backtick),
  whiteSpace: Ja(Se.whiteSpace),
  string: Ja(Se.string),
  breakString: Ja(Se.breakString),
  equal: st(Se.equal),
  period: st(Se.period),
  comma: st(Se.comma),
  semicolon: st(Se.semicolon),
  leftParent: st(Se.leftParent),
  rightParent: st(Se.rightParent),
  leftBracket: st(Se.leftBracket),
  rightBracket: st(Se.rightBracket)
};
function tk(e) {
  const t = [];
  let n = 0;
  const o = () => n < e.length;
  for (; o(); ) {
    let r = e[n];
    if (me.whiteSpace(r)) {
      for (; o() && me.whiteSpace(r); )
        r = e[++n];
      continue;
    }
    if (me.leftParent(r)) {
      t.push({ type: Ie.leftParent, value: r }), n++;
      continue;
    }
    if (me.rightParent(r)) {
      t.push({ type: Ie.rightParent, value: r }), n++;
      continue;
    }
    if (me.comma(r)) {
      t.push({ type: Ie.comma, value: r }), n++;
      continue;
    }
    if (me.period(r)) {
      t.push({ type: Ie.period, value: r }), n++;
      continue;
    }
    if (me.equal(r)) {
      t.push({ type: Ie.equal, value: r }), n++;
      continue;
    }
    if (me.semicolon(r)) {
      t.push({ type: Ie.semicolon, value: r }), n++;
      continue;
    }
    if (me.leftBracket(r)) {
      let s = "";
      for (r = e[++n]; o() && !me.rightBracket(r); )
        s += r, r = e[++n];
      t.push({ type: Ie.string, value: s }), n++;
      continue;
    }
    if (me.doubleQuote(r)) {
      let s = "";
      for (r = e[++n]; o() && !me.doubleQuote(r); )
        s += r, r = e[++n];
      t.push({ type: Ie.string, value: s }), n++;
      continue;
    }
    if (me.singleQuote(r)) {
      let s = "";
      for (r = e[++n]; o() && !me.singleQuote(r); )
        s += r, r = e[++n];
      t.push({ type: Ie.string, value: s }), n++;
      continue;
    }
    if (me.backtick(r)) {
      let s = "";
      for (r = e[++n]; o() && !me.backtick(r); )
        s += r, r = e[++n];
      t.push({ type: Ie.string, value: s }), n++;
      continue;
    }
    if (me.string(r)) {
      let s = "";
      for (; o() && me.string(r) && !me.breakString(r); )
        s += r, r = e[++n];
      t.push({ type: Ie.string, value: s });
      continue;
    }
    n++;
  }
  return t;
}
const Cs = (e) => (t) => (n) => t[n] ? t[n].type === e : !1, z = (e) => {
  const t = e.toUpperCase();
  return (n) => (o) => n[o] ? n[o].value.toUpperCase() === t : !1;
}, hn = Cs(Ie.string), Os = Cs(Ie.period), Ap = Cs(Ie.comma), Xo = Cs(Ie.leftParent), Es = Cs(Ie.rightParent), Ss = z("CREATE"), Mp = z("ALTER"), nk = z("DROP"), ok = z("USE"), rk = z("RENAME"), sk = z("DELETE"), ik = z("SELECT"), er = z("TABLE"), xa = z("INDEX"), tr = z("UNIQUE"), nr = z("ADD"), Aa = z("PRIMARY"), Kn = z("KEY"), Nt = z("CONSTRAINT"), Ma = z("FOREIGN"), Np = z("NOT"), ak = z("NULL"), lk = z("DEFAULT"), $p = z("COMMENT"), ck = z("REFERENCES"), kp = z("ASC"), Dp = z("DESC"), uk = z("ON"), dk = z("AUTO_INCREMENT"), fk = z("AUTOINCREMENT"), mk = z("IF"), hk = z("EXISTS"), pk = z("ONLY"), yk = (e) => {
  const t = dk(e), n = fk(e);
  return (o) => t(o) || n(o);
}, xs = (e) => {
  const t = Ss(e), n = Mp(e), o = nk(e), r = ok(e), s = rk(e), i = sk(e), a = ik(e);
  return (l) => t(l) || n(l) || o(l) || r(l) || s(l) || i(l) || a(l);
}, gk = (e) => {
  const t = Ss(e), n = er(e), o = mk(e), r = Np(e), s = hk(e);
  return (i) => t(i) && n(i + 1) && o(i + 2) && r(i + 3) && s(i + 4);
}, vk = (e) => {
  const t = Ss(e), n = er(e);
  return (o) => t(o) && n(o + 1);
}, Rp = (e) => {
  const t = Ss(e), n = xa(e), o = tr(e);
  return (r) => t(r) && o(r + 1) && n(r + 2);
}, bk = (e) => {
  const t = Ss(e), n = xa(e), o = Rp(e);
  return (r) => t(r) && n(r + 1) || o(r);
}, Na = (e) => {
  const t = Mp(e), n = er(e);
  return (o) => t(o) && n(o + 1);
}, iu = (e) => {
  const t = Na(e), n = pk(e);
  return (o) => t(o) && n(o + 2);
}, Lp = (e) => {
  const t = iu(e), n = nr(e), o = Aa(e), r = Kn(e), s = Nt(e), i = (u) => t(u) && n(u + 4) && o(u + 5) && r(u + 6), a = (u) => t(u) && n(u + 4) && s(u + 5) && o(u + 7) && r(u + 8), l = (u) => t(u) && n(u + 6) && o(u + 7) && r(u + 8), c = (u) => t(u) && n(u + 6) && s(u + 7) && o(u + 9) && r(u + 10);
  return (u) => i(u) || a(u) || l(u) || c(u);
}, Ik = (e) => {
  const t = Lp(e), n = Na(e), o = nr(e), r = Aa(e), s = Kn(e), i = Nt(e), a = (d) => n(d) && o(d + 3) && r(d + 4) && s(d + 5), l = (d) => n(d) && o(d + 3) && i(d + 4) && r(d + 6) && s(d + 7), c = (d) => n(d) && o(d + 5) && r(d + 6) && s(d + 7), u = (d) => n(d) && o(d + 5) && i(d + 6) && r(d + 8) && s(d + 9);
  return (d) => a(d) || l(d) || c(d) || u(d) || t(d);
}, Pp = (e) => {
  const t = iu(e), n = nr(e), o = Ma(e), r = Kn(e), s = Nt(e), i = (u) => t(u) && n(u + 4) && o(u + 5) && r(u + 6), a = (u) => t(u) && n(u + 4) && s(u + 5) && o(u + 7) && r(u + 8), l = (u) => t(u) && n(u + 6) && o(u + 7) && r(u + 8), c = (u) => t(u) && n(u + 6) && s(u + 7) && o(u + 9) && r(u + 10);
  return (u) => i(u) || a(u) || l(u) || c(u);
}, Tk = (e) => {
  const t = Pp(e), n = Na(e), o = nr(e), r = Ma(e), s = Kn(e), i = Nt(e), a = (d) => n(d) && o(d + 3) && r(d + 4) && s(d + 5), l = (d) => n(d) && o(d + 3) && i(d + 4) && r(d + 6) && s(d + 7), c = (d) => n(d) && o(d + 5) && r(d + 6) && s(d + 7), u = (d) => n(d) && o(d + 5) && i(d + 6) && r(d + 8) && s(d + 9);
  return (d) => a(d) || l(d) || c(d) || u(d) || t(d);
}, _p = (e) => {
  const t = iu(e), n = nr(e), o = tr(e), r = Nt(e), s = (c) => t(c) && n(c + 4) && o(c + 5), i = (c) => t(c) && n(c + 4) && r(c + 5) && o(c + 7), a = (c) => t(c) && n(c + 6) && o(c + 7), l = (c) => t(c) && n(c + 6) && r(c + 7) && o(c + 9);
  return (c) => s(c) || i(c) || a(c) || l(c);
}, wk = (e) => {
  const t = _p(e), n = Na(e), o = nr(e), r = tr(e), s = Nt(e), i = (u) => n(u) && o(u + 3) && r(u + 4), a = (u) => n(u) && o(u + 3) && s(u + 4) && r(u + 6), l = (u) => n(u) && o(u + 5) && r(u + 6), c = (u) => n(u) && o(u + 5) && s(u + 6) && r(u + 8);
  return (u) => i(u) || a(u) || l(u) || c(u) || t(u);
}, Ck = Array.from(new Set([
  ...Y$,
  ...K$,
  ...Q$,
  ...J$,
  ...X$,
  ...ek
].map((e) => e.toUpperCase()))), Ok = (e) => {
  const t = hn(e);
  return (n) => {
    const o = e[n];
    return o ? t(n) && Ck.includes(o.value.toUpperCase()) : !1;
  };
}, wt = {
  createTable: "create.table",
  createIndex: "create.index",
  alterTableAddUnique: "alter.table.add.unique",
  alterTableAddPrimaryKey: "alter.table.add.primaryKey",
  alterTableAddForeignKey: "alter.table.add.foreignKey"
}, Rn = {
  asc: "ASC",
  desc: "DESC"
};
function Ek(e, t) {
  const n = xs(e), o = hn(e), r = Xo(e), s = Os(e), i = $p(e), a = gk(e), l = () => t.value < e.length, c = {
    type: wt.createTable,
    name: "",
    comment: "",
    columns: [],
    indexes: [],
    foreignKeys: []
  };
  for (t.value += a(t.value) ? 5 : 2; l() && !n(t.value); ) {
    let u = e[t.value];
    if (r(t.value)) {
      t.value++;
      const { columns: d, indexes: f, foreignKeys: m } = Sk(e, t);
      c.columns = d, c.indexes = f, c.foreignKeys = m;
      continue;
    }
    if (o(t.value) && !c.name) {
      c.name = u.value, u = e[++t.value], s(t.value) && (u = e[++t.value], o(t.value) && (c.name = u.value, t.value++));
      continue;
    }
    if (i(t.value)) {
      u = e[++t.value], o(t.value) && (c.comment = u.value, t.value++);
      continue;
    }
    t.value++;
  }
  return c;
}
function Sk(e, t) {
  const n = hn(e), o = Xo(e), r = Es(e), s = Ap(e), i = Nt(e), a = xa(e), l = Aa(e), c = Ma(e), u = yk(e), d = tr(e), f = ak(e), m = Np(e), p = lk(e), g = $p(e), v = Dp(e), T = kp(e), O = Kn(e), k = Ok(e), R = () => t.value < e.length, ae = [], vt = [], bt = [], nt = [], We = [];
  let G = {
    name: "",
    dataType: "",
    default: "",
    comment: "",
    primaryKey: !1,
    autoIncrement: !1,
    unique: !1,
    nullable: !0
  };
  for (; R(); ) {
    let _ = e[t.value];
    if (n(t.value) && !G.name && !i(t.value) && !l(t.value) && !c(t.value) && !d(t.value) && !a(t.value) && !O(t.value)) {
      G.name = _.value, t.value++;
      continue;
    }
    if (o(t.value)) {
      for (_ = e[++t.value]; R() && !r(t.value); )
        _ = e[++t.value];
      t.value++;
      continue;
    }
    if (i(t.value)) {
      _ = e[++t.value], n(t.value) && t.value++;
      continue;
    }
    if (l(t.value)) {
      if (_ = e[++t.value], O(t.value))
        if (_ = e[++t.value], o(t.value)) {
          for (_ = e[++t.value]; R() && !r(t.value); )
            n(t.value) && nt.push(_.value.toUpperCase()), _ = e[++t.value];
          t.value++;
        } else
          G.primaryKey = !0;
      continue;
    }
    if (c(t.value)) {
      const ot = Bp(e, t);
      ot && bt.push(ot);
      continue;
    }
    if (a(t.value) || O(t.value)) {
      if (_ = e[++t.value], n(t.value)) {
        const ot = _.value, Vt = [];
        if (_ = e[++t.value], o(t.value)) {
          _ = e[++t.value];
          let $t = {
            name: "",
            sort: Rn.asc
          };
          for (; R() && !r(t.value); )
            n(t.value) && !v(t.value) && !T(t.value) && ($t.name = _.value), v(t.value) && ($t.sort = Rn.desc), s(t.value) && (Vt.push($t), $t = {
              name: "",
              sort: Rn.asc
            }), _ = e[++t.value];
          !Vt.includes($t) && $t.name !== "" && Vt.push($t), Vt.length && vt.push({
            name: ot,
            unique: !1,
            columns: Vt
          }), t.value++;
        }
      }
      continue;
    }
    if (d(t.value)) {
      if (_ = e[++t.value], O(t.value) && (_ = e[++t.value]), n(t.value) && (_ = e[++t.value]), o(t.value)) {
        for (_ = e[++t.value]; R() && !r(t.value); )
          n(t.value) && We.push(_.value.toUpperCase()), _ = e[++t.value];
        t.value++;
      } else
        G.unique = !0;
      continue;
    }
    if (m(t.value)) {
      _ = e[++t.value], f(t.value) && (G.nullable = !1, t.value++);
      continue;
    }
    if (p(t.value)) {
      _ = e[++t.value], n(t.value) && (G.default = _.value, t.value++);
      continue;
    }
    if (g(t.value)) {
      _ = e[++t.value], n(t.value) && (G.comment = _.value, t.value++);
      continue;
    }
    if (u(t.value)) {
      G.autoIncrement = !0, t.value++;
      continue;
    }
    if (k(t.value)) {
      let ot = _.value;
      if (_ = e[++t.value], o(t.value)) {
        for (ot += "(", _ = e[++t.value]; R() && !r(t.value); )
          ot += _.value, _ = e[++t.value];
        ot += ")", t.value++;
      }
      G.dataType = ot;
      continue;
    }
    if (s(t.value)) {
      (G.name || G.dataType) && ae.push(G), G = {
        name: "",
        dataType: "",
        default: "",
        comment: "",
        primaryKey: !1,
        autoIncrement: !1,
        unique: !1,
        nullable: !0
      }, t.value++;
      continue;
    }
    if (r(t.value)) {
      t.value++;
      break;
    }
    t.value++;
  }
  return !ae.includes(G) && (G.name || G.dataType) && ae.push(G), ae.forEach((_) => {
    nt.includes(_.name.toUpperCase()) && (_.primaryKey = !0), We.includes(_.name.toUpperCase()) && (_.unique = !0);
  }), {
    columns: ae,
    indexes: vt,
    foreignKeys: bt
  };
}
function Bp(e, t) {
  const n = hn(e), o = Xo(e), r = Es(e), s = ck(e), i = Os(e), a = Kn(e), l = () => t.value < e.length, c = {
    columnNames: [],
    refTableName: "",
    refColumnNames: []
  };
  let u = e[++t.value];
  if (a(t.value)) {
    if (u = e[++t.value], o(t.value)) {
      for (u = e[++t.value]; l() && !r(t.value); )
        n(t.value) && c.columnNames.push(u.value), u = e[++t.value];
      u = e[++t.value];
    }
    if (s(t.value) && (u = e[++t.value], n(t.value) && (c.refTableName = u.value, u = e[++t.value], i(t.value) && (u = e[++t.value], n(t.value) && (c.refTableName = u.value, u = e[++t.value])), o(t.value)))) {
      for (u = e[++t.value]; l() && !r(t.value); )
        n(t.value) && c.refColumnNames.push(u.value), u = e[++t.value];
      u = e[++t.value];
    }
    if (c.columnNames.length && c.columnNames.length === c.refColumnNames.length)
      return c;
  }
  return null;
}
function xk(e, t) {
  const n = xs(e), o = hn(e), r = Nt(e), s = Os(e), i = er(e), a = Ma(e), l = Pp(e)(t.value), c = () => t.value < e.length, u = {
    type: wt.alterTableAddForeignKey,
    name: "",
    columnNames: [],
    refTableName: "",
    refColumnNames: []
  };
  for (t.value++; c() && !n(t.value); ) {
    let d = e[t.value];
    if (i(t.value)) {
      d = e[++t.value], l && (d = e[++t.value]), o(t.value) && (u.name = d.value, d = e[++t.value], s(t.value) && (d = e[++t.value], o(t.value) && (u.name = d.value, t.value++)));
      continue;
    }
    if (r(t.value)) {
      d = e[++t.value], o(t.value) && t.value++;
      continue;
    }
    if (a(t.value)) {
      const f = Bp(e, t);
      f && (u.columnNames = f.columnNames, u.refTableName = f.refTableName, u.refColumnNames = f.refColumnNames);
      continue;
    }
    t.value++;
  }
  return u;
}
function Ak(e, t) {
  const n = xs(e), o = hn(e), r = Xo(e), s = Es(e), i = Nt(e), a = Aa(e), l = Os(e), c = Kn(e), u = er(e), d = Lp(e)(t.value), f = () => t.value < e.length, m = {
    type: wt.alterTableAddPrimaryKey,
    name: "",
    columnNames: []
  };
  for (t.value++; f() && !n(t.value); ) {
    let p = e[t.value];
    if (u(t.value)) {
      p = e[++t.value], d && (p = e[++t.value]), o(t.value) && (m.name = p.value, p = e[++t.value], l(t.value) && (p = e[++t.value], o(t.value) && (m.name = p.value, t.value++)));
      continue;
    }
    if (i(t.value)) {
      p = e[++t.value], o(t.value) && t.value++;
      continue;
    }
    if (a(t.value)) {
      if (p = e[++t.value], c(t.value) && (p = e[++t.value], r(t.value))) {
        for (p = e[++t.value]; f() && !s(t.value); )
          o(t.value) && m.columnNames.push(p.value), p = e[++t.value];
        p = e[++t.value];
      }
      continue;
    }
    t.value++;
  }
  return m;
}
function Mk(e, t) {
  const n = xs(e), o = hn(e), r = Nt(e), s = Os(e), i = er(e), a = tr(e), l = Xo(e), c = Es(e), u = _p(e)(t.value), d = () => t.value < e.length, f = {
    type: wt.alterTableAddUnique,
    name: "",
    columnNames: []
  };
  for (t.value++; d() && !n(t.value); ) {
    let m = e[t.value];
    if (i(t.value)) {
      m = e[++t.value], u && (m = e[++t.value]), o(t.value) && (f.name = m.value, m = e[++t.value], s(t.value) && (m = e[++t.value], o(t.value) && (f.name = m.value, t.value++)));
      continue;
    }
    if (r(t.value)) {
      m = e[++t.value], o(t.value) && t.value++;
      continue;
    }
    if (a(t.value)) {
      if (m = e[++t.value], l(t.value)) {
        for (m = e[++t.value]; d() && !c(t.value); )
          o(t.value) && f.columnNames.push(m.value), m = e[++t.value];
        t.value++;
      }
      continue;
    }
    t.value++;
  }
  return f;
}
function Nk(e, t) {
  const n = xs(e), o = tr(e), r = hn(e), s = Xo(e), i = Es(e), a = Ap(e), l = xa(e), c = uk(e), u = Dp(e), d = kp(e), f = Rp(e), m = () => t.value < e.length, p = {
    type: wt.createIndex,
    name: "",
    unique: o(t.value + 1),
    tableName: "",
    columns: []
  };
  for (t.value += f(t.value) ? 2 : 1; m() && !n(t.value); ) {
    let g = e[t.value];
    if (l(t.value)) {
      g = e[++t.value], r(t.value) && (p.name = g.value);
      continue;
    }
    if (c(t.value)) {
      if (g = e[++t.value], r(t.value) && (p.tableName = g.value, g = e[++t.value], s(t.value))) {
        g = e[++t.value];
        let v = {
          name: "",
          sort: Rn.asc
        };
        for (; m() && !i(t.value); )
          r(t.value) && !u(t.value) && !d(t.value) && (v.name = g.value), u(t.value) && (v.sort = Rn.desc), a(t.value) && (p.columns.push(v), v = {
            name: "",
            sort: Rn.asc
          }), g = e[++t.value];
        !p.columns.includes(v) && v.name !== "" && p.columns.push(v), t.value++;
      }
      continue;
    }
    t.value++;
  }
  return p;
}
function $k(e) {
  const t = [], n = { value: 0 }, o = () => n.value < e.length, r = vk(e), s = bk(e), i = Ik(e), a = Tk(e), l = wk(e);
  for (; o(); ) {
    if (r(n.value)) {
      t.push(Ek(e, n));
      continue;
    }
    if (s(n.value)) {
      t.push(Nk(e, n));
      continue;
    }
    if (i(n.value)) {
      t.push(Ak(e, n));
      continue;
    }
    if (a(n.value)) {
      t.push(xk(e, n));
      continue;
    }
    if (l(n.value)) {
      t.push(Mk(e, n));
      continue;
    }
    n.value++;
  }
  return t;
}
const kk = (e) => $k(tk(e));
function qe(e, t) {
  for (const n of e)
    if (n.name.toUpperCase() === t.toUpperCase())
      return n;
  return null;
}
function Dk(e, t, n) {
  const o = qn({}), r = kk(e), s = Rk(r), i = Lk(s), a = Dl(i.length * 100);
  return o.settings.width = a, o.settings.height = a, i.forEach((l) => Pk(o, l, t)), _k(o, i), Bk(o, i), gc(n ? n(o) : o);
}
function Rk(e) {
  const t = {
    tables: [],
    indexes: [],
    primaryKeys: [],
    foreignKeys: [],
    uniques: []
  };
  for (const n of e)
    switch (n.type) {
      case wt.createTable:
        n.name && t.tables.push(n);
        break;
      case wt.createIndex:
        n.tableName && n.columns.length && t.indexes.push(n);
        break;
      case wt.alterTableAddPrimaryKey:
        n.name && n.columnNames.length && t.primaryKeys.push(n);
        break;
      case wt.alterTableAddForeignKey:
        n.name && n.columnNames.length && n.refTableName && n.refColumnNames.length && n.columnNames.length === n.refColumnNames.length && t.foreignKeys.push(n);
        break;
      case wt.alterTableAddUnique:
        n.name && n.columnNames.length && t.uniques.push(n);
        break;
    }
  return t;
}
function Lk({ tables: e, indexes: t, primaryKeys: n, foreignKeys: o, uniques: r }) {
  return t.forEach((s) => {
    const i = qe(e, s.tableName);
    i && i.indexes.push({
      name: s.name,
      unique: s.unique,
      columns: s.columns
    });
  }), n.forEach((s) => {
    const i = qe(e, s.name);
    i && s.columnNames.forEach((a) => {
      const l = qe(i.columns, a);
      l && (l.primaryKey = !0);
    });
  }), r.forEach((s) => {
    const i = qe(e, s.name);
    i && s.columnNames.forEach((a) => {
      const l = qe(i.columns, a);
      l && (l.unique = !0);
    });
  }), o.forEach((s) => {
    const i = qe(e, s.name);
    i && i.foreignKeys.push({
      columnNames: s.columnNames,
      refTableName: s.refTableName,
      refColumnNames: s.refColumnNames
    });
  }), e;
}
function Pk({ doc: e, collections: t }, n, { toWidth: o }) {
  const r = gt({
    name: n.name,
    comment: n.comment,
    ui: {
      widthName: ge(o(n.name)),
      widthComment: ge(o(n.comment))
    }
  });
  n.columns.forEach((s) => {
    const i = Mt({
      tableId: r.id,
      name: s.name,
      comment: s.comment,
      dataType: s.dataType,
      default: s.default,
      options: (s.autoIncrement ? U.autoIncrement : 0) | (s.primaryKey ? U.primaryKey : 0) | (s.unique ? U.unique : 0) | (s.nullable ? 0 : U.notNull),
      ui: {
        widthName: ge(o(s.name)),
        widthComment: ge(o(s.comment)),
        widthDataType: ge(o(s.dataType)),
        widthDefault: ge(o(s.default)),
        keys: s.primaryKey ? Pe.primaryKey : 0
      }
    });
    r.columnIds.push(i.id), r.seqColumnIds.push(i.id), y(t).collection("tableColumnEntities").setOne(i);
  }), e.tableIds.push(r.id), y(t).collection("tableEntities").setOne(r);
}
function _k({ doc: e, collections: t }, n) {
  const o = y(t).collection("tableEntities").selectByIds(e.tableIds), r = y(t).collection("tableColumnEntities");
  n.forEach((s) => {
    if (!s.foreignKeys.length)
      return;
    const i = qe(o, s.name);
    if (!i)
      return;
    const a = r.selectByIds(i.columnIds);
    s.foreignKeys.forEach((l) => {
      const c = qe(o, l.refTableName);
      if (!c)
        return;
      const u = r.selectByIds(c.columnIds), d = [], f = [];
      l.refColumnNames.forEach((p) => {
        const g = qe(u, p);
        g && d.push(g);
      }), l.columnNames.forEach((p) => {
        const g = qe(a, p);
        g && (f.push(g), M(g.ui.keys, Pe.primaryKey) ? g.ui.keys |= Pe.foreignKey : g.ui.keys = Pe.foreignKey);
      });
      const m = yp({
        identification: !f.some((p) => !(M(p.ui.keys, Pe.primaryKey) && M(p.ui.keys, Pe.foreignKey))),
        relationshipType: Bh.ZeroN,
        start: {
          tableId: c.id,
          columnIds: d.map((p) => p.id)
        },
        end: {
          tableId: i.id,
          columnIds: f.map((p) => p.id)
        }
      });
      e.relationshipIds.push(m.id), y(t).collection("relationshipEntities").setOne(m);
    });
  });
}
function Bk({ doc: e, collections: t }, n) {
  const o = y(t).collection("tableEntities").selectByIds(e.tableIds);
  n.forEach((r) => {
    r.indexes.forEach((s) => {
      const i = qe(o, r.name);
      if (!i)
        return;
      const a = y(t).collection("tableColumnEntities").selectByIds(i.columnIds), l = [], c = zn({
        name: s.name,
        tableId: i.id,
        unique: s.unique
      });
      s.columns.forEach((u) => {
        const d = qe(a, u.name);
        if (!d)
          return;
        const f = Zc({
          indexId: c.id,
          columnId: d.id,
          orderType: u.sort === Rn.asc ? wo.ASC : wo.DESC
        });
        l.push(f);
      }), l.length !== 0 && (l.forEach((u) => {
        c.indexColumnIds.push(u.id), c.seqIndexColumnIds.push(u.id), y(t).collection("indexColumnEntities").setOne(u);
      }), e.indexIds.push(c.id), y(t).collection("indexEntities").setOne(c));
    });
  });
}
function Wp(e, t, n = []) {
  const { doc: { relationshipIds: o }, collections: r } = t, s = e.pop();
  return s && (n.some(({ columnId: i }) => i === s.columnId) || (n.push(s), y(r).collection("relationshipEntities").selectByIds(o).forEach(({ id: i, start: a, end: l }) => {
    const c = a.columnIds.indexOf(s.columnId);
    if (c !== -1) {
      const u = l.columnIds[c];
      e.push({
        columnId: u,
        relationshipIds: [i]
      });
    } else {
      const u = l.columnIds.indexOf(s.columnId);
      if (u !== -1) {
        const d = a.columnIds[u];
        e.push({
          columnId: d,
          relationshipIds: [i]
        });
      }
    }
  })), Wp(e, t, n)), n;
}
const Gt = 15;
function Up(e) {
  return Object.entries(e).reduce((t, [n, o]) => (o === De.table ? t.tableIds.push(n) : o === De.memo && t.memoIds.push(n), t), { tableIds: [], memoIds: [] });
}
const jp = (e) => function* () {
  yield _c(), yield ln({ value: e });
}, Wk = (e) => function* () {
  yield sp(), yield Lo({ value: e });
}, Uk = (e, t) => function* ({ editor: { selectedMap: n }, settings: { zoomLevel: o } }) {
  const { tableIds: r, memoIds: s } = Up(n), i = e / o, a = t / o;
  r.length && (yield Oo({
    ids: r,
    movementX: i,
    movementY: a
  })), s.length && (yield Co({
    ids: s,
    movementX: i,
    movementY: a
  }));
}, jk = () => function* () {
  yield Cp(), yield wp();
}, Fk = (e) => function* (t) {
  const { doc: { tableIds: n, memoIds: o }, collections: r } = t, s = {
    ...y(r).collection("tableEntities").selectByIds(n).reduce((i, a) => {
      const l = Xc(a, t).width, c = eu(a), u = a.ui.x + l / 2 - Gt, d = a.ui.y + c / 2 - Gt;
      return Cd(e, {
        x: u,
        y: d,
        w: Gt,
        h: Gt
      }) && (i[a.id] = De.table), i;
    }, {}),
    ...y(r).collection("memoEntities").selectByIds(o).reduce((i, a) => {
      const l = z$(a), c = G$(a), u = a.ui.x + l / 2 - Gt, d = a.ui.y + c / 2 - Gt;
      return Cd(e, {
        x: u,
        y: d,
        w: Gt,
        h: Gt
      }) && (i[a.id] = De.memo), i;
    }, {})
  };
  yield $a(), fm(s) || (yield Qo(s));
}, $a = () => function* () {
  yield Ea(), yield Bc();
}, qk = (e, t) => function* (n) {
  const { editor: { focusTable: o } } = n;
  o && (e === ct.Tab && !t && (Wn(o.focusType) && Pc(n) && !Ts(n) || !Wn(o.focusType) && Rc(n) && Lc(n)) ? yield Ep(o.tableId) : yield ip({ moveKey: e, shiftKey: t }));
}, Hk = (e) => function* ({ editor: t }) {
  var n;
  ((n = t.drawRelationship) == null ? void 0 : n.relationshipType) === e ? yield Wc() : yield ap({ relationshipType: e });
}, Fp = (e) => function* ({ collections: t }) {
  const n = y(t).collection("tableEntities").selectById(e);
  if (!n)
    return;
  if (!y(t).collection("tableColumnEntities").selectByIds(n.columnIds).some((r) => M(r.options, U.primaryKey))) {
    const r = fe();
    yield At({
      tableId: e,
      id: r
    }), yield Yn({
      tableId: e,
      id: r,
      value: !0
    }), yield cn({
      tableId: e,
      columnId: r,
      focusType: W.columnName,
      $mod: !1,
      shiftKey: !1
    });
  }
  yield lp({ tableId: e });
}, Zk = (e) => function* ({ editor: { selectedMap: t }, collections: n }) {
  const { tableIds: o, memoIds: r } = Up(t), s = y(n).collection("tableEntities").selectByIds(o), i = y(n).collection("memoEntities").selectByIds(r);
  yield s.map((a) => zr({ id: a.id, color: e, prevColor: a.ui.color })), yield i.map((a) => Hr({ id: a.id, color: e, prevColor: a.ui.color }));
}, Vk = (e) => function* ({ settings: t }, n) {
  yield jp(Dk(e, n, (o) => (o.settings = {
    ...o.settings,
    ...Jw(fa(t), [
      "width",
      "height",
      "scrollTop",
      "scrollLeft",
      "zoomLevel"
    ])
  }, o))), yield su();
}, zk = (e) => function* ({ editor: { focusTable: t } }) {
  !t || !t.columnId || (yield Fc({
    tableId: t.tableId,
    columnIds: e ? [...t.selectColumnIds] : [t.columnId]
  }));
}, Gk = (e, t) => function* ({ editor: { draggableColumn: n }, collections: o }) {
  if (!n || n.columnIds.length === 0)
    return;
  const { tableId: r, columnIds: s } = n, i = y(o).collection("tableEntities"), a = i.selectById(r);
  if (!a)
    return;
  if (t === r) {
    const d = a.columnIds.indexOf(s[0]);
    if (d === -1)
      return;
    const f = a.columnIds.indexOf(e);
    if (f === -1)
      return;
    const m = s.map((p) => Qi({ tableId: r, id: p, targetId: e }));
    d < f && m.reverse(), yield m;
    return;
  }
  if (!i.selectById(t))
    return;
  const c = y(o).collection("tableColumnEntities").selectByIds(s);
  if (c.length === 0)
    return;
  yield Sp(r, s);
  const u = c.map(() => fe());
  for (let d = 0; d < c.length; d++) {
    const f = c[d], m = u[d], p = {
      id: m,
      tableId: t
    };
    yield [
      At(p),
      _t({
        ...p,
        value: f.name
      }),
      Wt({
        ...p,
        value: f.dataType
      }),
      Ut({
        ...p,
        value: f.default
      }),
      Bt({
        ...p,
        value: f.comment
      }),
      Yn({
        ...p,
        value: M(f.options, U.primaryKey)
      }),
      jt({
        ...p,
        value: M(f.options, U.notNull)
      }),
      Dn({
        ...p,
        value: M(f.options, U.unique)
      }),
      kn({
        ...p,
        value: M(f.options, U.autoIncrement)
      }),
      Qi({
        ...p,
        targetId: e
      }),
      cn({
        tableId: t,
        columnId: m,
        focusType: W.columnName,
        $mod: !0,
        shiftKey: !1
      })
    ];
  }
  yield Fc({
    tableId: t,
    columnIds: u
  });
}, Yk = (e) => function* (t) {
  const { columnIds: n, relationshipIds: o } = Wp([{ columnId: e, relationshipIds: [] }], t).reduce((r, { columnId: s, relationshipIds: i }) => (r.columnIds.push(s), r.relationshipIds.push(...i), r), { columnIds: [], relationshipIds: [] });
  yield Uc({ columnIds: Ri(n) }), yield jc({
    relationshipIds: Ri(o)
  });
}, Kk = () => function* () {
  yield Uc({ columnIds: [] }), yield jc({ relationshipIds: [] });
}, Qk = {
  loadJsonAction$: jp,
  initialLoadJsonAction$: Wk,
  moveAllAction$: Uk,
  removeSelectedAction$: jk,
  dragSelectAction$: Fk,
  unselectAllAction$: $a,
  focusMoveTableAction$: qk,
  drawStartRelationshipAction$: Hk,
  drawStartAddRelationshipAction$: Fp,
  changeColorAllAction$: Zk,
  loadSchemaSQLAction$: Vk,
  dragstartColumnAction$: zk,
  dragoverColumnAction$: Gk,
  columnKeyHoverStartAction$: Yk,
  columnKeyHoverEndAction$: Kk
}, Jk = (e) => function* () {
  yield qc({
    id: fe(),
    tableId: e
  });
}, Xk = (e) => function* ({ collections: t }) {
  const n = y(t).collection("indexEntities").selectById(e);
  n && (yield Hc({
    id: e,
    tableId: n.tableId,
    value: !n.unique
  }));
}, e2 = {
  addIndexAction$: Jk,
  changeIndexUniqueAction$: Xk
}, t2 = (e, t) => function* ({ collections: n }) {
  const o = y(n).collection("indexEntities").selectById(e);
  if (!o)
    return;
  const r = y(n).collection("indexColumnEntities").selectByIds(o.seqIndexColumnIds).find((s) => s.columnId === t);
  r ? yield Gi({
    id: r.id,
    indexId: e,
    tableId: o.tableId,
    columnId: t
  }) : yield Gi({
    id: fe(),
    indexId: e,
    tableId: o.tableId,
    columnId: t
  });
}, n2 = (e, t) => function* ({ collections: n }) {
  const o = y(n).collection("indexEntities").selectById(e);
  if (!o)
    return;
  const r = y(n).collection("indexColumnEntities").selectByIds(o.indexColumnIds).filter((s) => s.columnId === t);
  for (const s of r)
    yield Vc({
      id: s.id,
      indexId: e,
      tableId: o.tableId
    });
}, o2 = (e) => function* ({ collections: t }) {
  const n = y(t).collection("indexColumnEntities").selectById(e);
  n && (yield Gc({
    id: e,
    indexId: n.indexId,
    columnId: n.columnId,
    value: n.orderType === wo.ASC ? wo.DESC : wo.ASC
  }));
}, r2 = (e, t) => function* ({ collections: n }) {
  if (e === t)
    return;
  const o = y(n).collection("indexColumnEntities").selectById(e);
  if (!o)
    return;
  const r = y(n).collection("indexEntities").selectById(o.indexId);
  r && (yield zc({
    id: e,
    indexId: o.indexId,
    tableId: r.tableId,
    targetId: t
  }));
}, s2 = {
  addIndexColumnAction$: t2,
  removeIndexColumnAction$: n2,
  changeIndexColumnOrderTypeAction$: o2,
  moveIndexColumnAction$: r2
}, i2 = {};
function qp({ editor: { viewport: e }, settings: { scrollLeft: t, scrollTop: n, zoomLevel: o, width: r, height: s } }, i) {
  const a = kl(r, s, o), l = kl(r, s, i), c = (a.w - l.w) / 2, u = (a.h - l.h) / 2, d = r / 2, f = s / 2, m = t * -1 + e.width / 2, p = n * -1 + e.height / 2, g = (d - m) / d, v = (f - p) / f, T = ft(-1 * c * g, 4), O = ft(-1 * u * v, 4);
  return { movementX: T, movementY: O };
}
const a2 = (e) => function* (t) {
  const { settings: { scrollLeft: n, scrollTop: o } } = t, r = wa(e), { movementX: s, movementY: i } = qp(t, r);
  yield Kc({ value: e }), yield Qc({
    scrollLeft: n + s,
    scrollTop: o + i
  });
}, l2 = (e) => function* (t) {
  const { settings: { zoomLevel: n } } = t, o = wa(n + e), { movementX: r, movementY: s } = qp(t, o);
  yield Zr({ value: e }), yield Vr({
    movementX: r,
    movementY: s
  });
}, c2 = {
  changeZoomLevelAction$: a2,
  streamZoomLevelAction$: l2
}, FL = Object.freeze({
  ...kA,
  ...Qk,
  ...nM,
  ...e2,
  ...mM,
  ...s2,
  ...AM,
  ...R$,
  ...qM,
  ...i2,
  ...ON,
  ...c2,
  ...GN,
  ...U$,
  ...v$,
  ...V$
}), Hp = [
  // table
  "table.add",
  "table.move",
  "table.moveTo",
  "table.remove",
  "table.changeName",
  "table.changeComment",
  "table.changeColor",
  "table.sort",
  // column
  "column.add",
  "column.remove",
  "column.changeName",
  "column.changeComment",
  "column.changeDataType",
  "column.changeDefault",
  "column.changeAutoIncrement",
  "column.changePrimaryKey",
  "column.changeUnique",
  "column.changeNotNull",
  "column.move",
  // relationship
  "relationship.add",
  "relationship.remove",
  "relationship.changeType",
  // index
  "index.add",
  "index.remove",
  "index.changeName",
  "index.changeUnique",
  // indexColumn
  "indexColumn.add",
  "indexColumn.remove",
  "indexColumn.move",
  "indexColumn.changeOrderType",
  // memo
  "memo.add",
  "memo.move",
  "memo.moveTo",
  "memo.remove",
  "memo.changeValue",
  "memo.changeColor",
  "memo.resize",
  // settings
  "settings.changeDatabaseName",
  "settings.resize",
  "settings.changeZoomLevel",
  "settings.streamZoomLevel",
  "settings.scrollTo",
  "settings.streamScrollTo",
  "settings.changeShow",
  "settings.changeDatabase",
  "settings.changeCanvasType",
  "settings.changeLanguage",
  "settings.changeTableNameCase",
  "settings.changeColumnNameCase",
  "settings.changeBracketType",
  "settings.changeRelationshipDataTypeSync",
  "settings.changeRelationshipOptimization",
  "settings.changeColumnOrder",
  "settings.changeMaxWidthComment",
  "settings.changeIgnoreSaveSettings",
  // editor
  "editor.loadJson",
  "editor.clear"
], u2 = $([
  "settings.changeZoomLevel",
  "settings.streamZoomLevel",
  "settings.scrollTo",
  "settings.streamScrollTo",
  "settings.changeDatabase",
  "settings.changeCanvasType",
  "settings.changeLanguage",
  "settings.changeTableNameCase",
  "settings.changeColumnNameCase",
  "settings.changeBracketType"
]), qL = [
  ...Hp.filter((e) => !u2(e))
], au = [
  "editor.sharedMouseTracker"
], HL = [
  ...Hp,
  ...au
], ZL = [
  "settings.changeZoomLevel",
  "settings.streamZoomLevel",
  "settings.scrollTo",
  "settings.streamScrollTo",
  "settings.changeCanvasType"
], d2 = [
  "table.move",
  "memo.move"
], f2 = [
  "table.changeColor",
  "memo.changeColor"
], m2 = [
  "settings.streamZoomLevel",
  "settings.streamScrollTo"
], lu = [
  ...Object.keys(rs)
], VL = [
  ...Object.keys(Tp),
  ...lu
], Zp = YS((e) => !!e.length), zL = (e) => {
  const t = $(e);
  return (n) => new tt((o) => n.subscribe({
    next: (r) => o.next(r.filter((s) => t(s.type))),
    error: (r) => o.error(r),
    complete: () => o.complete()
  })).pipe(Zp);
}, Vp = "@@none-stream", h2 = (e, t) => (n) => {
  const o = t.find(([, r]) => r(n));
  return o ? o[0] : e(n) ? n : Vp;
}, Rd = (e, t = [], n = QS(200)) => {
  const o = $(e), r = t.map(([i, a]) => [
    i,
    $(a)
  ]), s = h2(o, r);
  return (i) => new tt((a) => i.subscribe({
    next: (l) => {
      const c = l.reduce((u, d) => {
        const f = s(d.type);
        return u[f] || (u[f] = []), u[f].push(d), u;
      }, {});
      Object.values(c).forEach((u) => a.next(u));
    },
    error: (l) => a.error(l),
    complete: () => a.complete()
  })).pipe(Zp, JS((a) => s(a[0].type)), Ph((a) => a.key === Vp ? a : a.pipe(KS(a.pipe(n)), qi((l) => l.flat()))));
}, p2 = $(lu), y2 = $(au), GL = (e) => e.pipe(Rd(au, [], ex(100)), qi((t) => {
  var n;
  return y2((n = t[0]) == null ? void 0 : n.type) ? [Fo(t)] : t;
}), Rd(lu, [
  ["@@move", d2],
  ["@@scroll", m2],
  ["@@color", f2]
]), qi((t) => {
  var o;
  if (!p2((o = t[0]) == null ? void 0 : o.type))
    return t;
  const n = [];
  for (const r of Object.keys(rs))
    rs[r]([], n, t);
  return n.length ? n : t;
}));
function YL(e, t) {
  return aS({
    context: e,
    state: {
      ...qn({}),
      editor: Px()
    },
    reducers: {
      ...$A,
      ...zN,
      ...g$,
      ...xM,
      ...FM,
      ...CN,
      ...tM,
      ...fM
    },
    enableObservable: t ?? !0
  });
}
/*!
 * @dineug/go
 * @version 0.1.8 | Sun Dec 24 2023
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
function Z(e, t, n, o) {
  if (n === "a" && !o)
    throw new TypeError("Private accessor was defined without a getter");
  if (typeof t == "function" ? e !== t || !o : !t.has(e))
    throw new TypeError("Cannot read private member from an object whose class did not declare it");
  return n === "m" ? o : n === "a" ? o.call(e) : o ? o.value : t.get(e);
}
function Gr(e, t, n, o, r) {
  if (o === "m")
    throw new TypeError("Private method is not writable");
  if (o === "a" && !r)
    throw new TypeError("Private accessor was defined without a setter");
  if (typeof t == "function" ? e !== t || !r : !t.has(e))
    throw new TypeError("Cannot write private member to an object whose class did not declare it");
  return o === "a" ? r.call(e, n) : r ? r.value = n : t.set(e, n), n;
}
var Pl, Ae, En, zp;
const g2 = {
  limit: -1,
  leading: !1,
  trailing: !1
};
class v2 {
  constructor(t) {
    Pl.add(this), Ae.set(this, []), En.set(this, void 0), Gr(this, En, Object.assign({}, g2, {
      ...t
    }), "f");
  }
  isEmpty() {
    return !Z(this, Ae, "f").length;
  }
  put(t) {
    Z(this, Ae, "f").push(t), Z(this, Pl, "m", zp).call(this);
  }
  take() {
    return Z(this, Ae, "f").shift();
  }
  flush() {
    const t = Z(this, Ae, "f");
    return Gr(this, Ae, [], "f"), t;
  }
  drop(t) {
    const n = Z(this, Ae, "f").findIndex(t);
    if (n === -1)
      return !1;
    const o = Z(this, Ae, "f").slice();
    return o.splice(n, 1), Gr(this, Ae, o, "f"), !0;
  }
}
Ae = /* @__PURE__ */ new WeakMap(), En = /* @__PURE__ */ new WeakMap(), Pl = /* @__PURE__ */ new WeakSet(), zp = function() {
  Z(this, En, "f").limit !== -1 && Z(this, En, "f").limit < Z(this, Ae, "f").length && (Z(this, En, "f").trailing ? Z(this, Ae, "f").pop() : Z(this, En, "f").leading ? Z(this, Ae, "f").shift() : Z(this, Ae, "f").pop());
};
const b2 = (e) => new v2(e), Ld = {
  limitBuffer: b2
};
var Mi, xn, An, On, _l;
const Xa = Symbol.for("https://github.com/dineug/go.git#closed");
class I2 {
  get closed() {
    return Z(this, On, "f");
  }
  constructor(t = Ld.limitBuffer()) {
    Mi.add(this), xn.set(this, void 0), An.set(this, Ld.limitBuffer()), On.set(this, !1), Gr(this, xn, t, "f");
  }
  put(t) {
    Z(this, On, "f") || (Z(this, xn, "f").put(t), Z(this, Mi, "m", _l).call(this));
  }
  take(t, n) {
    return Z(this, On, "f") ? (n == null || n(Xa), () => !1) : (Z(this, An, "f").put([t, n]), Z(this, Mi, "m", _l).call(this), () => Z(this, An, "f").drop(([o]) => o === t));
  }
  flush(t, n) {
    if (Z(this, On, "f")) {
      n == null || n(Xa);
      return;
    }
    t(Z(this, xn, "f").flush());
  }
  close() {
    Gr(this, On, !0, "f"), Z(this, An, "f").flush().forEach(([t, n]) => n == null ? void 0 : n(Xa));
  }
}
xn = /* @__PURE__ */ new WeakMap(), An = /* @__PURE__ */ new WeakMap(), On = /* @__PURE__ */ new WeakMap(), Mi = /* @__PURE__ */ new WeakSet(), _l = function() {
  if (Z(this, An, "f").isEmpty() || Z(this, xn, "f").isEmpty())
    return;
  const [e] = Z(this, An, "f").take(), t = Z(this, xn, "f").take();
  e == null || e(t);
};
const T2 = (e) => new I2(e), Gp = (e) => (t) => typeof t === e, w2 = Gp("object"), So = Gp("function"), C2 = (e) => e === null, { isArray: Ji } = Array, ka = (e) => w2(e) && !C2(e) && !Ji(e), Yp = (e) => ka(e) && So(e.then), Kp = (e) => ka(e) && So(e.next), O2 = (e) => ka(e) && So(e.next) && So(e.throw) && So(e.return), cu = Symbol.for("https://github.com/dineug/go.git#cancel"), Qp = Symbol.for("https://github.com/dineug/go.git#attachCancel"), Jp = (e, t) => (Reflect.set(e, Qp, t), e), E2 = (e) => e === cu, Xp = (e) => {
  if (ka(e)) {
    const t = Reflect.get(e, Qp);
    t == null || t();
  }
  return Ke(() => new Promise((t, n) => n(cu)));
}, ey = (e) => Ke(function* () {
  let t = () => !1;
  const n = new Promise((o, r) => {
    t = e.take(o, r);
  });
  return Jp(n, () => {
    t();
  }), yield n;
}), KL = (e) => Ke(() => new Promise((t) => setTimeout(t, e))), S2 = Symbol.for("https://github.com/dineug/go.git#kill"), x2 = (e) => e === S2, A2 = (e, t) => {
  e.put(t);
}, Da = (e, t) => Ke(function* () {
  for (; ; ) {
    const n = yield ey(e);
    Ke(t, n);
  }
}), M2 = {
  leading: !0,
  trailing: !1
}, Ra = (e, t, n, o) => Ke(function* () {
  const r = Object.assign({}, M2, {
    ...o
  });
  let s = -1, i, a;
  for (; ; ) {
    const l = yield ey(e);
    a = l, s === -1 && (r.leading && (i = l, Ke(t, l)), s = setTimeout(() => {
      r.trailing && (!r.leading || i !== a) && Ke(t, a), s = -1;
    }, n));
  }
});
function Ke(e, ...t) {
  let n = !1, o = null;
  const r = new Promise(async (s, i) => {
    let a = null;
    o = () => {
      i(cu), a == null || a.forEach(Xp), a = null;
    };
    try {
      const l = e(...t);
      if (Yp(l))
        return a = [l], s(await l);
      if (!Kp(l))
        return s(l);
      let c = await l.next(), u;
      for (; !n && !c.done; ) {
        try {
          const d = N2(c.value);
          a = Ji(d) ? d : [d], u = await (Ji(d) ? Promise.all(d) : d), c = await l.next(u);
        } catch (d) {
          if (x2(d))
            throw d;
          O2(l) && (c = await l.throw(d));
        }
        u = void 0, a = null;
      }
      s(c.value);
    } catch (l) {
      E2(l) && (n = !0), i(l);
    }
  });
  return r.catch(() => {
  }), Jp(r, () => {
    n = !0, o == null || o();
  }), r;
}
function N2(e) {
  return Yp(e) ? e : Kp(e) ? Ke(() => e) : So(e) ? Ke(e) : Ji(e) ? e.map((t) => Ke(() => t)) : Promise.resolve();
}
const re = {
  left: "left",
  right: "right",
  top: "top",
  bottom: "bottom"
}, Bl = Object.values(re);
$([
  re.top,
  re.bottom,
  re.left,
  re.right
]);
const $2 = 30, QL = $2 + 20, JL = 35, XL = 10, eP = 16, tP = 26;
function k2(e, t) {
  const n = Xc(t, e).width, o = eu(t), { x: r, y: s } = t.ui;
  return {
    width: n,
    height: o,
    top: {
      x: r + n / 2,
      y: s
    },
    bottom: {
      x: r + n / 2,
      y: s + o
    },
    left: {
      x: r,
      y: s + o / 2
    },
    right: {
      x: r + n,
      y: s + o / 2
    },
    lt: {
      x: r,
      y: s
    },
    rt: {
      x: r + n,
      y: s
    },
    lb: {
      x: r,
      y: s + o
    },
    rb: {
      x: r + n,
      y: s + o
    }
  };
}
function Pd(e, t) {
  return Math.sqrt(Math.pow(e.x - t.x, 2) + Math.pow(e.y - t.y, 2));
}
const _d = {
  [re.top]: Ct.top,
  [re.bottom]: Ct.bottom,
  [re.left]: Ct.left,
  [re.right]: Ct.right
};
function ty(e) {
  const { doc: { tableIds: t, relationshipIds: n }, collections: o } = e, r = $(t), s = y(o).collection("tableEntities"), i = y(o).collection("relationshipEntities").selectByIds(n).filter(({ start: c, end: u }) => r(c.tableId) && r(u.tableId)), a = /* @__PURE__ */ new Map(), l = /* @__PURE__ */ new Map();
  for (const c of i) {
    const u = D2(c), { start: d, end: f } = u, m = s.selectById(d.tableId), p = s.selectById(f.tableId);
    if (!(!m || !p))
      if (l.set(c, u), d.tableId === f.tableId) {
        d.direction = Ct.top, f.direction = Ct.right;
        const g = el(e, a, m);
        d.x = g.objectPoint.rt.x - 20, d.y = g.objectPoint.rt.y, f.x = g.objectPoint.rt.x, f.y = g.objectPoint.rt.y + 20, g.top.set(u.id, u), g.right.set(u.id, u);
      } else {
        const g = el(e, a, m), v = el(e, a, p), [T, O] = R2(g.objectPoint, v.objectPoint, u);
        g[T].set(u.id, u), v[O].set(u.id, u);
      }
  }
  for (const c of a.values())
    for (const u of Bl) {
      const d = u;
      c[d].size < 2 || L2(d, c);
    }
  for (const [c, u] of l.entries())
    c.start.direction = u.start.direction, c.start.x = u.start.x, c.start.y = u.start.y, c.end.direction = u.end.direction, c.end.x = u.end.x, c.end.y = u.end.y;
}
function el(e, t, n) {
  let o = t.get(n.id);
  return o || (o = {
    tableId: n.id,
    objectPoint: k2(e, n),
    top: /* @__PURE__ */ new Map(),
    bottom: /* @__PURE__ */ new Map(),
    left: /* @__PURE__ */ new Map(),
    right: /* @__PURE__ */ new Map()
  }, t.set(n.id, o)), o;
}
function D2(e) {
  return {
    id: e.id,
    start: {
      tableId: e.start.tableId,
      x: e.start.x,
      y: e.start.y,
      direction: e.start.direction
    },
    end: {
      tableId: e.end.tableId,
      x: e.end.x,
      y: e.end.y,
      direction: e.end.direction
    }
  };
}
function R2(e, t, n) {
  const o = [
    re.bottom,
    re.bottom
  ];
  let r = Pd(e.bottom, t.bottom);
  n.start.x = e.bottom.x, n.start.y = e.bottom.y, n.end.x = t.bottom.x, n.end.y = t.bottom.y, n.start.direction = Ct.bottom, n.end.direction = Ct.bottom;
  for (const s of Bl)
    for (const i of Bl) {
      const a = s, l = i, c = Pd(e[a], t[l]);
      r <= c || (r = c, o[0] = a, o[1] = l, n.start.x = e[a].x, n.start.y = e[a].y, n.start.direction = _d[a], n.end.x = t[l].x, n.end.y = t[l].y, n.end.direction = _d[l]);
    }
  return o;
}
function L2(e, t) {
  const n = P2(e, t), o = _2(e, t);
  e === re.left || e === re.right ? n.yArray.forEach((r, s) => {
    o[s].start.y = r;
  }) : (e === re.top || e === re.bottom) && n.xArray.forEach((r, s) => {
    o[s].start.x = r;
  });
}
function P2(e, t) {
  const n = t[e].size, o = {
    x: t.objectPoint.width / n,
    y: t.objectPoint.height / n
  }, r = {
    x: o.x / 2,
    y: o.y / 2
  }, s = [], i = [];
  if (e === re.left || e === re.right) {
    let a = t.objectPoint.lt.y - r.y;
    for (let l = 0; l < n; l++)
      a += o.y, i.push(a);
  } else if (e === re.top || e === re.bottom) {
    let a = t.objectPoint.lt.x - r.x;
    for (let l = 0; l < n; l++)
      a += o.x, s.push(a);
  }
  return {
    xArray: s,
    yArray: i
  };
}
function _2(e, t) {
  const n = [], o = [], r = [], s = e === re.top || e === re.bottom;
  for (const i of t[e].values()) {
    const { start: a, end: l } = i;
    a.tableId === l.tableId ? e === re.top ? (n.push(i.start), o.push(i.end)) : e === re.right && (n.push(i.end), o.push(i.start)) : i.start.tableId === t.tableId ? (n.push(i.start), o.push(i.end)) : (n.push(i.end), o.push(i.start));
  }
  return o.forEach((i, a) => {
    r.push({
      start: n[a],
      end: o[a],
      distance: s ? i.x : i.y
    });
  }), r.sort((i, a) => i.distance - a.distance);
}
const B2 = function* (e, t) {
  yield Ra(e, function* () {
    const { doc: n, collections: o } = t(), s = y(o).collection("relationshipEntities").selectByIds(n.relationshipIds);
    for (const i of s) {
      const { end: a, identification: l } = i, c = y(o).collection("tableEntities").selectById(a.tableId);
      if (!c)
        continue;
      const u = $(c.columnIds), d = y(o).collection("tableColumnEntities").selectByIds(a.columnIds).filter((m) => u(m.id));
      if (!d.length)
        continue;
      const f = d.every((m) => M(m.options, U.primaryKey));
      f !== l && (i.identification = f);
    }
  }, 10, { leading: !1, trailing: !0 });
}, W2 = function* (e, t) {
  yield Ra(e, function* () {
    const { doc: n, collections: o } = t(), s = y(o).collection("relationshipEntities").selectByIds(n.relationshipIds);
    for (const i of s) {
      const { end: a, startRelationshipType: l } = i, c = y(o).collection("tableEntities").selectById(a.tableId);
      if (!c)
        continue;
      const u = $(c.columnIds), d = y(o).collection("tableColumnEntities").selectByIds(a.columnIds).filter((m) => u(m.id));
      if (!d.length)
        continue;
      const f = d.every((m) => M(m.options, U.notNull)) ? $l.dash : $l.ring;
      f !== l && (i.startRelationshipType = f);
    }
  }, 10, { leading: !1, trailing: !0 });
}, U2 = function* (e, t) {
  yield Ra(e, function* () {
    ty(t());
  }, 5, { leading: !1, trailing: !0 });
}, j2 = [
  [
    [
      Eo,
      Yn,
      ln,
      Lo
    ],
    B2
  ],
  [
    [
      Eo,
      jt,
      ln,
      Lo
    ],
    W2
  ],
  [
    [
      Jc,
      bp,
      ws,
      Co,
      Oo,
      nu,
      ou,
      ru,
      At,
      Eo,
      _t,
      Bt,
      Wt,
      Ut,
      su
    ],
    U2
  ]
], F2 = function* (e, t, n) {
  yield Ra(e, function* () {
    const o = t();
    _N(o, n), ty(o);
  }, 5, { leading: !1, trailing: !0 });
}, q2 = [
  [[ln, Lo], F2]
], H2 = function* (e, t) {
  yield Da(e, function* ({ payload: { id: n } }) {
    const { collections: o } = t(), s = y(o).collection("tableColumnEntities").selectById(n);
    !s || !M(s.options, U.primaryKey) || M(s.options, U.notNull) || (s.options = s.options | U.notNull);
  });
}, Z2 = function* (e, t) {
  yield Da(e, function* ({ payload: { id: n, end: o } }) {
    const { doc: { relationshipIds: r }, collections: s } = t();
    if (!r.includes(n))
      return;
    const i = y(s).collection("tableColumnEntities").selectByIds(o.columnIds);
    for (const a of i)
      a.ui.keys = a.ui.keys | Pe.foreignKey;
  });
}, V2 = function* (e, t) {
  yield Da(e, function* ({ payload: { id: n } }) {
    const { doc: { relationshipIds: o }, collections: r } = t();
    if (o.includes(n))
      return;
    const s = y(r).collection("relationshipEntities").selectById(n);
    if (!s)
      return;
    const i = y(r).collection("tableColumnEntities").selectByIds(s.end.columnIds);
    for (const a of i)
      a.ui.keys = a.ui.keys & ~Pe.foreignKey;
  });
}, z2 = function* (e, t) {
  yield Da(e, function* () {
    const { doc: n, collections: o } = t(), r = y(o).collection("relationshipEntities").selectByIds(n.relationshipIds), s = y(o).collection("tableEntities").selectByIds(n.tableIds), i = /* @__PURE__ */ new Set(), a = y(o).collection("tableColumnEntities");
    for (const { end: l } of r) {
      const c = a.selectByIds(l.columnIds);
      for (const u of c)
        u.ui.keys = u.ui.keys | Pe.foreignKey, i.add(u.id);
    }
    for (const l of s) {
      const c = a.selectByIds(l.columnIds);
      for (const u of c)
        M(u.ui.keys, Pe.foreignKey) && !i.has(u.id) && (u.ui.keys = u.ui.keys & ~Pe.foreignKey);
    }
  });
}, G2 = [
  [[Yn], H2],
  [[ws], Z2],
  [[_o], V2],
  [[ln, Lo], z2]
], Y2 = [...q2, ...G2, ...j2];
function nP(e) {
  const t = () => e.state, n = Y2.map(([s, i]) => {
    const a = T2();
    return {
      pattern: $(s.map(String)),
      channel: a,
      proc: Ke(i, a, t, e.context)
    };
  }), o = e.subscribe((s) => {
    for (const i of s)
      for (const a of n)
        a.pattern(i.type) && A2(a.channel, i);
  });
  return { destroy: () => {
    n.forEach(({ proc: s }) => Xp(s)), n.splice(0, n.length), o();
  } };
}
class Qn extends Error {
}
class K2 extends Qn {
  constructor(t) {
    super(`Invalid DateTime: ${t.toMessage()}`);
  }
}
class Q2 extends Qn {
  constructor(t) {
    super(`Invalid Interval: ${t.toMessage()}`);
  }
}
class J2 extends Qn {
  constructor(t) {
    super(`Invalid Duration: ${t.toMessage()}`);
  }
}
class yo extends Qn {
}
class ny extends Qn {
  constructor(t) {
    super(`Invalid unit ${t}`);
  }
}
class Me extends Qn {
}
class Yt extends Qn {
  constructor() {
    super("Zone is an abstract class");
  }
}
const C = "numeric", pt = "short", _e = "long", Xi = {
  year: C,
  month: C,
  day: C
}, oy = {
  year: C,
  month: pt,
  day: C
}, X2 = {
  year: C,
  month: pt,
  day: C,
  weekday: pt
}, ry = {
  year: C,
  month: _e,
  day: C
}, sy = {
  year: C,
  month: _e,
  day: C,
  weekday: _e
}, iy = {
  hour: C,
  minute: C
}, ay = {
  hour: C,
  minute: C,
  second: C
}, ly = {
  hour: C,
  minute: C,
  second: C,
  timeZoneName: pt
}, cy = {
  hour: C,
  minute: C,
  second: C,
  timeZoneName: _e
}, uy = {
  hour: C,
  minute: C,
  hourCycle: "h23"
}, dy = {
  hour: C,
  minute: C,
  second: C,
  hourCycle: "h23"
}, fy = {
  hour: C,
  minute: C,
  second: C,
  hourCycle: "h23",
  timeZoneName: pt
}, my = {
  hour: C,
  minute: C,
  second: C,
  hourCycle: "h23",
  timeZoneName: _e
}, hy = {
  year: C,
  month: C,
  day: C,
  hour: C,
  minute: C
}, py = {
  year: C,
  month: C,
  day: C,
  hour: C,
  minute: C,
  second: C
}, yy = {
  year: C,
  month: pt,
  day: C,
  hour: C,
  minute: C
}, gy = {
  year: C,
  month: pt,
  day: C,
  hour: C,
  minute: C,
  second: C
}, eD = {
  year: C,
  month: pt,
  day: C,
  weekday: pt,
  hour: C,
  minute: C
}, vy = {
  year: C,
  month: _e,
  day: C,
  hour: C,
  minute: C,
  timeZoneName: pt
}, by = {
  year: C,
  month: _e,
  day: C,
  hour: C,
  minute: C,
  second: C,
  timeZoneName: pt
}, Iy = {
  year: C,
  month: _e,
  day: C,
  weekday: _e,
  hour: C,
  minute: C,
  timeZoneName: _e
}, Ty = {
  year: C,
  month: _e,
  day: C,
  weekday: _e,
  hour: C,
  minute: C,
  second: C,
  timeZoneName: _e
};
class As {
  /**
   * The type of zone
   * @abstract
   * @type {string}
   */
  get type() {
    throw new Yt();
  }
  /**
   * The name of this zone.
   * @abstract
   * @type {string}
   */
  get name() {
    throw new Yt();
  }
  get ianaName() {
    return this.name;
  }
  /**
   * Returns whether the offset is known to be fixed for the whole year.
   * @abstract
   * @type {boolean}
   */
  get isUniversal() {
    throw new Yt();
  }
  /**
   * Returns the offset's common name (such as EST) at the specified timestamp
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to get the name
   * @param {Object} opts - Options to affect the format
   * @param {string} opts.format - What style of offset to return. Accepts 'long' or 'short'.
   * @param {string} opts.locale - What locale to return the offset name in.
   * @return {string}
   */
  offsetName(t, n) {
    throw new Yt();
  }
  /**
   * Returns the offset's value as a string
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to get the offset
   * @param {string} format - What style of offset to return.
   *                          Accepts 'narrow', 'short', or 'techie'. Returning '+6', '+06:00', or '+0600' respectively
   * @return {string}
   */
  formatOffset(t, n) {
    throw new Yt();
  }
  /**
   * Return the offset in minutes for this zone at the specified timestamp.
   * @abstract
   * @param {number} ts - Epoch milliseconds for which to compute the offset
   * @return {number}
   */
  offset(t) {
    throw new Yt();
  }
  /**
   * Return whether this Zone is equal to another zone
   * @abstract
   * @param {Zone} otherZone - the zone to compare
   * @return {boolean}
   */
  equals(t) {
    throw new Yt();
  }
  /**
   * Return whether this Zone is valid.
   * @abstract
   * @type {boolean}
   */
  get isValid() {
    throw new Yt();
  }
}
let tl = null;
class La extends As {
  /**
   * Get a singleton instance of the local zone
   * @return {SystemZone}
   */
  static get instance() {
    return tl === null && (tl = new La()), tl;
  }
  /** @override **/
  get type() {
    return "system";
  }
  /** @override **/
  get name() {
    return new Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName(t, { format: n, locale: o }) {
    return Ny(t, n, o);
  }
  /** @override **/
  formatOffset(t, n) {
    return Yr(this.offset(t), n);
  }
  /** @override **/
  offset(t) {
    return -new Date(t).getTimezoneOffset();
  }
  /** @override **/
  equals(t) {
    return t.type === "system";
  }
  /** @override **/
  get isValid() {
    return !0;
  }
}
let Ni = {};
function tD(e) {
  return Ni[e] || (Ni[e] = new Intl.DateTimeFormat("en-US", {
    hour12: !1,
    timeZone: e,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    era: "short"
  })), Ni[e];
}
const nD = {
  year: 0,
  month: 1,
  day: 2,
  era: 3,
  hour: 4,
  minute: 5,
  second: 6
};
function oD(e, t) {
  const n = e.format(t).replace(/\u200E/g, ""), o = /(\d+)\/(\d+)\/(\d+) (AD|BC),? (\d+):(\d+):(\d+)/.exec(n), [, r, s, i, a, l, c, u] = o;
  return [i, r, s, a, l, c, u];
}
function rD(e, t) {
  const n = e.formatToParts(t), o = [];
  for (let r = 0; r < n.length; r++) {
    const { type: s, value: i } = n[r], a = nD[s];
    s === "era" ? o[a] = i : N(a) || (o[a] = parseInt(i, 10));
  }
  return o;
}
let Ws = {};
class qt extends As {
  /**
   * @param {string} name - Zone name
   * @return {IANAZone}
   */
  static create(t) {
    return Ws[t] || (Ws[t] = new qt(t)), Ws[t];
  }
  /**
   * Reset local caches. Should only be necessary in testing scenarios.
   * @return {void}
   */
  static resetCache() {
    Ws = {}, Ni = {};
  }
  /**
   * Returns whether the provided string is a valid specifier. This only checks the string's format, not that the specifier identifies a known zone; see isValidZone for that.
   * @param {string} s - The string to check validity on
   * @example IANAZone.isValidSpecifier("America/New_York") //=> true
   * @example IANAZone.isValidSpecifier("Sport~~blorp") //=> false
   * @deprecated This method returns false for some valid IANA names. Use isValidZone instead.
   * @return {boolean}
   */
  static isValidSpecifier(t) {
    return this.isValidZone(t);
  }
  /**
   * Returns whether the provided string identifies a real zone
   * @param {string} zone - The string to check
   * @example IANAZone.isValidZone("America/New_York") //=> true
   * @example IANAZone.isValidZone("Fantasia/Castle") //=> false
   * @example IANAZone.isValidZone("Sport~~blorp") //=> false
   * @return {boolean}
   */
  static isValidZone(t) {
    if (!t)
      return !1;
    try {
      return new Intl.DateTimeFormat("en-US", { timeZone: t }).format(), !0;
    } catch {
      return !1;
    }
  }
  constructor(t) {
    super(), this.zoneName = t, this.valid = qt.isValidZone(t);
  }
  /** @override **/
  get type() {
    return "iana";
  }
  /** @override **/
  get name() {
    return this.zoneName;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName(t, { format: n, locale: o }) {
    return Ny(t, n, o, this.name);
  }
  /** @override **/
  formatOffset(t, n) {
    return Yr(this.offset(t), n);
  }
  /** @override **/
  offset(t) {
    const n = new Date(t);
    if (isNaN(n))
      return NaN;
    const o = tD(this.name);
    let [r, s, i, a, l, c, u] = o.formatToParts ? rD(o, n) : oD(o, n);
    a === "BC" && (r = -Math.abs(r) + 1);
    const f = _a({
      year: r,
      month: s,
      day: i,
      hour: l === 24 ? 0 : l,
      minute: c,
      second: u,
      millisecond: 0
    });
    let m = +n;
    const p = m % 1e3;
    return m -= p >= 0 ? p : 1e3 + p, (f - m) / (60 * 1e3);
  }
  /** @override **/
  equals(t) {
    return t.type === "iana" && t.name === this.name;
  }
  /** @override **/
  get isValid() {
    return this.valid;
  }
}
let Bd = {};
function sD(e, t = {}) {
  const n = JSON.stringify([e, t]);
  let o = Bd[n];
  return o || (o = new Intl.ListFormat(e, t), Bd[n] = o), o;
}
let Wl = {};
function Ul(e, t = {}) {
  const n = JSON.stringify([e, t]);
  let o = Wl[n];
  return o || (o = new Intl.DateTimeFormat(e, t), Wl[n] = o), o;
}
let jl = {};
function iD(e, t = {}) {
  const n = JSON.stringify([e, t]);
  let o = jl[n];
  return o || (o = new Intl.NumberFormat(e, t), jl[n] = o), o;
}
let Fl = {};
function aD(e, t = {}) {
  const { base: n, ...o } = t, r = JSON.stringify([e, o]);
  let s = Fl[r];
  return s || (s = new Intl.RelativeTimeFormat(e, t), Fl[r] = s), s;
}
let jr = null;
function lD() {
  return jr || (jr = new Intl.DateTimeFormat().resolvedOptions().locale, jr);
}
let Wd = {};
function cD(e) {
  let t = Wd[e];
  if (!t) {
    const n = new Intl.Locale(e);
    t = "getWeekInfo" in n ? n.getWeekInfo() : n.weekInfo, Wd[e] = t;
  }
  return t;
}
function uD(e) {
  const t = e.indexOf("-x-");
  t !== -1 && (e = e.substring(0, t));
  const n = e.indexOf("-u-");
  if (n === -1)
    return [e];
  {
    let o, r;
    try {
      o = Ul(e).resolvedOptions(), r = e;
    } catch {
      const l = e.substring(0, n);
      o = Ul(l).resolvedOptions(), r = l;
    }
    const { numberingSystem: s, calendar: i } = o;
    return [r, s, i];
  }
}
function dD(e, t, n) {
  return (n || t) && (e.includes("-u-") || (e += "-u"), n && (e += `-ca-${n}`), t && (e += `-nu-${t}`)), e;
}
function fD(e) {
  const t = [];
  for (let n = 1; n <= 12; n++) {
    const o = D.utc(2009, n, 1);
    t.push(e(o));
  }
  return t;
}
function mD(e) {
  const t = [];
  for (let n = 1; n <= 7; n++) {
    const o = D.utc(2016, 11, 13 + n);
    t.push(e(o));
  }
  return t;
}
function Us(e, t, n, o) {
  const r = e.listingMode();
  return r === "error" ? null : r === "en" ? n(t) : o(t);
}
function hD(e) {
  return e.numberingSystem && e.numberingSystem !== "latn" ? !1 : e.numberingSystem === "latn" || !e.locale || e.locale.startsWith("en") || new Intl.DateTimeFormat(e.intl).resolvedOptions().numberingSystem === "latn";
}
class pD {
  constructor(t, n, o) {
    this.padTo = o.padTo || 0, this.floor = o.floor || !1;
    const { padTo: r, floor: s, ...i } = o;
    if (!n || Object.keys(i).length > 0) {
      const a = { useGrouping: !1, ...o };
      o.padTo > 0 && (a.minimumIntegerDigits = o.padTo), this.inf = iD(t, a);
    }
  }
  format(t) {
    if (this.inf) {
      const n = this.floor ? Math.floor(t) : t;
      return this.inf.format(n);
    } else {
      const n = this.floor ? Math.floor(t) : mu(t, 3);
      return de(n, this.padTo);
    }
  }
}
class yD {
  constructor(t, n, o) {
    this.opts = o, this.originalZone = void 0;
    let r;
    if (this.opts.timeZone)
      this.dt = t;
    else if (t.zone.type === "fixed") {
      const i = -1 * (t.offset / 60), a = i >= 0 ? `Etc/GMT+${i}` : `Etc/GMT${i}`;
      t.offset !== 0 && qt.create(a).valid ? (r = a, this.dt = t) : (r = "UTC", this.dt = t.offset === 0 ? t : t.setZone("UTC").plus({ minutes: t.offset }), this.originalZone = t.zone);
    } else
      t.zone.type === "system" ? this.dt = t : t.zone.type === "iana" ? (this.dt = t, r = t.zone.name) : (r = "UTC", this.dt = t.setZone("UTC").plus({ minutes: t.offset }), this.originalZone = t.zone);
    const s = { ...this.opts };
    s.timeZone = s.timeZone || r, this.dtf = Ul(n, s);
  }
  format() {
    return this.originalZone ? this.formatToParts().map(({ value: t }) => t).join("") : this.dtf.format(this.dt.toJSDate());
  }
  formatToParts() {
    const t = this.dtf.formatToParts(this.dt.toJSDate());
    return this.originalZone ? t.map((n) => {
      if (n.type === "timeZoneName") {
        const o = this.originalZone.offsetName(this.dt.ts, {
          locale: this.dt.locale,
          format: this.opts.timeZoneName
        });
        return {
          ...n,
          value: o
        };
      } else
        return n;
    }) : t;
  }
  resolvedOptions() {
    return this.dtf.resolvedOptions();
  }
}
class gD {
  constructor(t, n, o) {
    this.opts = { style: "long", ...o }, !n && Ay() && (this.rtf = aD(t, o));
  }
  format(t, n) {
    return this.rtf ? this.rtf.format(t, n) : _D(n, t, this.opts.numeric, this.opts.style !== "long");
  }
  formatToParts(t, n) {
    return this.rtf ? this.rtf.formatToParts(t, n) : [];
  }
}
const vD = {
  firstDay: 1,
  minimalDays: 4,
  weekend: [6, 7]
};
class V {
  static fromOpts(t) {
    return V.create(
      t.locale,
      t.numberingSystem,
      t.outputCalendar,
      t.weekSettings,
      t.defaultToEN
    );
  }
  static create(t, n, o, r, s = !1) {
    const i = t || le.defaultLocale, a = i || (s ? "en-US" : lD()), l = n || le.defaultNumberingSystem, c = o || le.defaultOutputCalendar, u = ql(r) || le.defaultWeekSettings;
    return new V(a, l, c, u, i);
  }
  static resetCache() {
    jr = null, Wl = {}, jl = {}, Fl = {};
  }
  static fromObject({ locale: t, numberingSystem: n, outputCalendar: o, weekSettings: r } = {}) {
    return V.create(t, n, o, r);
  }
  constructor(t, n, o, r, s) {
    const [i, a, l] = uD(t);
    this.locale = i, this.numberingSystem = n || a || null, this.outputCalendar = o || l || null, this.weekSettings = r, this.intl = dD(this.locale, this.numberingSystem, this.outputCalendar), this.weekdaysCache = { format: {}, standalone: {} }, this.monthsCache = { format: {}, standalone: {} }, this.meridiemCache = null, this.eraCache = {}, this.specifiedLocale = s, this.fastNumbersCached = null;
  }
  get fastNumbers() {
    return this.fastNumbersCached == null && (this.fastNumbersCached = hD(this)), this.fastNumbersCached;
  }
  listingMode() {
    const t = this.isEnglish(), n = (this.numberingSystem === null || this.numberingSystem === "latn") && (this.outputCalendar === null || this.outputCalendar === "gregory");
    return t && n ? "en" : "intl";
  }
  clone(t) {
    return !t || Object.getOwnPropertyNames(t).length === 0 ? this : V.create(
      t.locale || this.specifiedLocale,
      t.numberingSystem || this.numberingSystem,
      t.outputCalendar || this.outputCalendar,
      ql(t.weekSettings) || this.weekSettings,
      t.defaultToEN || !1
    );
  }
  redefaultToEN(t = {}) {
    return this.clone({ ...t, defaultToEN: !0 });
  }
  redefaultToSystem(t = {}) {
    return this.clone({ ...t, defaultToEN: !1 });
  }
  months(t, n = !1) {
    return Us(this, t, Dy, () => {
      const o = n ? { month: t, day: "numeric" } : { month: t }, r = n ? "format" : "standalone";
      return this.monthsCache[r][t] || (this.monthsCache[r][t] = fD((s) => this.extract(s, o, "month"))), this.monthsCache[r][t];
    });
  }
  weekdays(t, n = !1) {
    return Us(this, t, Py, () => {
      const o = n ? { weekday: t, year: "numeric", month: "long", day: "numeric" } : { weekday: t }, r = n ? "format" : "standalone";
      return this.weekdaysCache[r][t] || (this.weekdaysCache[r][t] = mD(
        (s) => this.extract(s, o, "weekday")
      )), this.weekdaysCache[r][t];
    });
  }
  meridiems() {
    return Us(
      this,
      void 0,
      () => _y,
      () => {
        if (!this.meridiemCache) {
          const t = { hour: "numeric", hourCycle: "h12" };
          this.meridiemCache = [D.utc(2016, 11, 13, 9), D.utc(2016, 11, 13, 19)].map(
            (n) => this.extract(n, t, "dayperiod")
          );
        }
        return this.meridiemCache;
      }
    );
  }
  eras(t) {
    return Us(this, t, By, () => {
      const n = { era: t };
      return this.eraCache[t] || (this.eraCache[t] = [D.utc(-40, 1, 1), D.utc(2017, 1, 1)].map(
        (o) => this.extract(o, n, "era")
      )), this.eraCache[t];
    });
  }
  extract(t, n, o) {
    const r = this.dtFormatter(t, n), s = r.formatToParts(), i = s.find((a) => a.type.toLowerCase() === o);
    return i ? i.value : null;
  }
  numberFormatter(t = {}) {
    return new pD(this.intl, t.forceSimple || this.fastNumbers, t);
  }
  dtFormatter(t, n = {}) {
    return new yD(t, this.intl, n);
  }
  relFormatter(t = {}) {
    return new gD(this.intl, this.isEnglish(), t);
  }
  listFormatter(t = {}) {
    return sD(this.intl, t);
  }
  isEnglish() {
    return this.locale === "en" || this.locale.toLowerCase() === "en-us" || new Intl.DateTimeFormat(this.intl).resolvedOptions().locale.startsWith("en-us");
  }
  getWeekSettings() {
    return this.weekSettings ? this.weekSettings : My() ? cD(this.locale) : vD;
  }
  getStartOfWeek() {
    return this.getWeekSettings().firstDay;
  }
  getMinDaysInFirstWeek() {
    return this.getWeekSettings().minimalDays;
  }
  getWeekendDays() {
    return this.getWeekSettings().weekend;
  }
  equals(t) {
    return this.locale === t.locale && this.numberingSystem === t.numberingSystem && this.outputCalendar === t.outputCalendar;
  }
}
let nl = null;
class Ee extends As {
  /**
   * Get a singleton instance of UTC
   * @return {FixedOffsetZone}
   */
  static get utcInstance() {
    return nl === null && (nl = new Ee(0)), nl;
  }
  /**
   * Get an instance with a specified offset
   * @param {number} offset - The offset in minutes
   * @return {FixedOffsetZone}
   */
  static instance(t) {
    return t === 0 ? Ee.utcInstance : new Ee(t);
  }
  /**
   * Get an instance of FixedOffsetZone from a UTC offset string, like "UTC+6"
   * @param {string} s - The offset string to parse
   * @example FixedOffsetZone.parseSpecifier("UTC+6")
   * @example FixedOffsetZone.parseSpecifier("UTC+06")
   * @example FixedOffsetZone.parseSpecifier("UTC-6:00")
   * @return {FixedOffsetZone}
   */
  static parseSpecifier(t) {
    if (t) {
      const n = t.match(/^utc(?:([+-]\d{1,2})(?::(\d{2}))?)?$/i);
      if (n)
        return new Ee(Ba(n[1], n[2]));
    }
    return null;
  }
  constructor(t) {
    super(), this.fixed = t;
  }
  /** @override **/
  get type() {
    return "fixed";
  }
  /** @override **/
  get name() {
    return this.fixed === 0 ? "UTC" : `UTC${Yr(this.fixed, "narrow")}`;
  }
  get ianaName() {
    return this.fixed === 0 ? "Etc/UTC" : `Etc/GMT${Yr(-this.fixed, "narrow")}`;
  }
  /** @override **/
  offsetName() {
    return this.name;
  }
  /** @override **/
  formatOffset(t, n) {
    return Yr(this.fixed, n);
  }
  /** @override **/
  get isUniversal() {
    return !0;
  }
  /** @override **/
  offset() {
    return this.fixed;
  }
  /** @override **/
  equals(t) {
    return t.type === "fixed" && t.fixed === this.fixed;
  }
  /** @override **/
  get isValid() {
    return !0;
  }
}
class bD extends As {
  constructor(t) {
    super(), this.zoneName = t;
  }
  /** @override **/
  get type() {
    return "invalid";
  }
  /** @override **/
  get name() {
    return this.zoneName;
  }
  /** @override **/
  get isUniversal() {
    return !1;
  }
  /** @override **/
  offsetName() {
    return null;
  }
  /** @override **/
  formatOffset() {
    return "";
  }
  /** @override **/
  offset() {
    return NaN;
  }
  /** @override **/
  equals() {
    return !1;
  }
  /** @override **/
  get isValid() {
    return !1;
  }
}
function rn(e, t) {
  if (N(e) || e === null)
    return t;
  if (e instanceof As)
    return e;
  if (wD(e)) {
    const n = e.toLowerCase();
    return n === "default" ? t : n === "local" || n === "system" ? La.instance : n === "utc" || n === "gmt" ? Ee.utcInstance : Ee.parseSpecifier(n) || qt.create(e);
  } else
    return Ln(e) ? Ee.instance(e) : typeof e == "object" && "offset" in e && typeof e.offset == "function" ? e : new bD(e);
}
let Ud = () => Date.now(), jd = "system", Fd = null, qd = null, Hd = null, Zd = 60, Vd, zd = null;
class le {
  /**
   * Get the callback for returning the current timestamp.
   * @type {function}
   */
  static get now() {
    return Ud;
  }
  /**
   * Set the callback for returning the current timestamp.
   * The function should return a number, which will be interpreted as an Epoch millisecond count
   * @type {function}
   * @example Settings.now = () => Date.now() + 3000 // pretend it is 3 seconds in the future
   * @example Settings.now = () => 0 // always pretend it's Jan 1, 1970 at midnight in UTC time
   */
  static set now(t) {
    Ud = t;
  }
  /**
   * Set the default time zone to create DateTimes in. Does not affect existing instances.
   * Use the value "system" to reset this value to the system's time zone.
   * @type {string}
   */
  static set defaultZone(t) {
    jd = t;
  }
  /**
   * Get the default time zone object currently used to create DateTimes. Does not affect existing instances.
   * The default value is the system's time zone (the one set on the machine that runs this code).
   * @type {Zone}
   */
  static get defaultZone() {
    return rn(jd, La.instance);
  }
  /**
   * Get the default locale to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultLocale() {
    return Fd;
  }
  /**
   * Set the default locale to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultLocale(t) {
    Fd = t;
  }
  /**
   * Get the default numbering system to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultNumberingSystem() {
    return qd;
  }
  /**
   * Set the default numbering system to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultNumberingSystem(t) {
    qd = t;
  }
  /**
   * Get the default output calendar to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static get defaultOutputCalendar() {
    return Hd;
  }
  /**
   * Set the default output calendar to create DateTimes with. Does not affect existing instances.
   * @type {string}
   */
  static set defaultOutputCalendar(t) {
    Hd = t;
  }
  /**
   * @typedef {Object} WeekSettings
   * @property {number} firstDay
   * @property {number} minimalDays
   * @property {number[]} weekend
   */
  /**
   * @return {WeekSettings|null}
   */
  static get defaultWeekSettings() {
    return zd;
  }
  /**
   * Allows overriding the default locale week settings, i.e. the start of the week, the weekend and
   * how many days are required in the first week of a year.
   * Does not affect existing instances.
   *
   * @param {WeekSettings|null} weekSettings
   */
  static set defaultWeekSettings(t) {
    zd = ql(t);
  }
  /**
   * Get the cutoff year after which a string encoding a year as two digits is interpreted to occur in the current century.
   * @type {number}
   */
  static get twoDigitCutoffYear() {
    return Zd;
  }
  /**
   * Set the cutoff year after which a string encoding a year as two digits is interpreted to occur in the current century.
   * @type {number}
   * @example Settings.twoDigitCutoffYear = 0 // cut-off year is 0, so all 'yy' are interpreted as current century
   * @example Settings.twoDigitCutoffYear = 50 // '49' -> 1949; '50' -> 2050
   * @example Settings.twoDigitCutoffYear = 1950 // interpreted as 50
   * @example Settings.twoDigitCutoffYear = 2050 // ALSO interpreted as 50
   */
  static set twoDigitCutoffYear(t) {
    Zd = t % 100;
  }
  /**
   * Get whether Luxon will throw when it encounters invalid DateTimes, Durations, or Intervals
   * @type {boolean}
   */
  static get throwOnInvalid() {
    return Vd;
  }
  /**
   * Set whether Luxon will throw when it encounters invalid DateTimes, Durations, or Intervals
   * @type {boolean}
   */
  static set throwOnInvalid(t) {
    Vd = t;
  }
  /**
   * Reset Luxon's global caches. Should only be necessary in testing scenarios.
   * @return {void}
   */
  static resetCaches() {
    V.resetCache(), qt.resetCache();
  }
}
class ut {
  constructor(t, n) {
    this.reason = t, this.explanation = n;
  }
  toMessage() {
    return this.explanation ? `${this.reason}: ${this.explanation}` : this.reason;
  }
}
const wy = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334], Cy = [0, 31, 60, 91, 121, 152, 182, 213, 244, 274, 305, 335];
function Ge(e, t) {
  return new ut(
    "unit out of range",
    `you specified ${t} (of type ${typeof t}) as a ${e}, which is invalid`
  );
}
function uu(e, t, n) {
  const o = new Date(Date.UTC(e, t - 1, n));
  e < 100 && e >= 0 && o.setUTCFullYear(o.getUTCFullYear() - 1900);
  const r = o.getUTCDay();
  return r === 0 ? 7 : r;
}
function Oy(e, t, n) {
  return n + (Ms(e) ? Cy : wy)[t - 1];
}
function Ey(e, t) {
  const n = Ms(e) ? Cy : wy, o = n.findIndex((s) => s < t), r = t - n[o];
  return { month: o + 1, day: r };
}
function du(e, t) {
  return (e - t + 7) % 7 + 1;
}
function ea(e, t = 4, n = 1) {
  const { year: o, month: r, day: s } = e, i = Oy(o, r, s), a = du(uu(o, r, s), n);
  let l = Math.floor((i - a + 14 - t) / 7), c;
  return l < 1 ? (c = o - 1, l = ss(c, t, n)) : l > ss(o, t, n) ? (c = o + 1, l = 1) : c = o, { weekYear: c, weekNumber: l, weekday: a, ...Wa(e) };
}
function Gd(e, t = 4, n = 1) {
  const { weekYear: o, weekNumber: r, weekday: s } = e, i = du(uu(o, 1, t), n), a = xo(o);
  let l = r * 7 + s - i - 7 + t, c;
  l < 1 ? (c = o - 1, l += xo(c)) : l > a ? (c = o + 1, l -= xo(o)) : c = o;
  const { month: u, day: d } = Ey(c, l);
  return { year: c, month: u, day: d, ...Wa(e) };
}
function ol(e) {
  const { year: t, month: n, day: o } = e, r = Oy(t, n, o);
  return { year: t, ordinal: r, ...Wa(e) };
}
function Yd(e) {
  const { year: t, ordinal: n } = e, { month: o, day: r } = Ey(t, n);
  return { year: t, month: o, day: r, ...Wa(e) };
}
function Kd(e, t) {
  if (!N(e.localWeekday) || !N(e.localWeekNumber) || !N(e.localWeekYear)) {
    if (!N(e.weekday) || !N(e.weekNumber) || !N(e.weekYear))
      throw new yo(
        "Cannot mix locale-based week fields with ISO-based week fields"
      );
    return N(e.localWeekday) || (e.weekday = e.localWeekday), N(e.localWeekNumber) || (e.weekNumber = e.localWeekNumber), N(e.localWeekYear) || (e.weekYear = e.localWeekYear), delete e.localWeekday, delete e.localWeekNumber, delete e.localWeekYear, {
      minDaysInFirstWeek: t.getMinDaysInFirstWeek(),
      startOfWeek: t.getStartOfWeek()
    };
  } else
    return { minDaysInFirstWeek: 4, startOfWeek: 1 };
}
function ID(e, t = 4, n = 1) {
  const o = Pa(e.weekYear), r = Ye(
    e.weekNumber,
    1,
    ss(e.weekYear, t, n)
  ), s = Ye(e.weekday, 1, 7);
  return o ? r ? s ? !1 : Ge("weekday", e.weekday) : Ge("week", e.weekNumber) : Ge("weekYear", e.weekYear);
}
function TD(e) {
  const t = Pa(e.year), n = Ye(e.ordinal, 1, xo(e.year));
  return t ? n ? !1 : Ge("ordinal", e.ordinal) : Ge("year", e.year);
}
function Sy(e) {
  const t = Pa(e.year), n = Ye(e.month, 1, 12), o = Ye(e.day, 1, ta(e.year, e.month));
  return t ? n ? o ? !1 : Ge("day", e.day) : Ge("month", e.month) : Ge("year", e.year);
}
function xy(e) {
  const { hour: t, minute: n, second: o, millisecond: r } = e, s = Ye(t, 0, 23) || t === 24 && n === 0 && o === 0 && r === 0, i = Ye(n, 0, 59), a = Ye(o, 0, 59), l = Ye(r, 0, 999);
  return s ? i ? a ? l ? !1 : Ge("millisecond", r) : Ge("second", o) : Ge("minute", n) : Ge("hour", t);
}
function N(e) {
  return typeof e > "u";
}
function Ln(e) {
  return typeof e == "number";
}
function Pa(e) {
  return typeof e == "number" && e % 1 === 0;
}
function wD(e) {
  return typeof e == "string";
}
function CD(e) {
  return Object.prototype.toString.call(e) === "[object Date]";
}
function Ay() {
  try {
    return typeof Intl < "u" && !!Intl.RelativeTimeFormat;
  } catch {
    return !1;
  }
}
function My() {
  try {
    return typeof Intl < "u" && !!Intl.Locale && ("weekInfo" in Intl.Locale.prototype || "getWeekInfo" in Intl.Locale.prototype);
  } catch {
    return !1;
  }
}
function OD(e) {
  return Array.isArray(e) ? e : [e];
}
function Qd(e, t, n) {
  if (e.length !== 0)
    return e.reduce((o, r) => {
      const s = [t(r), r];
      return o && n(o[0], s[0]) === o[0] ? o : s;
    }, null)[1];
}
function ED(e, t) {
  return t.reduce((n, o) => (n[o] = e[o], n), {});
}
function Bo(e, t) {
  return Object.prototype.hasOwnProperty.call(e, t);
}
function ql(e) {
  if (e == null)
    return null;
  if (typeof e != "object")
    throw new Me("Week settings must be an object");
  if (!Ye(e.firstDay, 1, 7) || !Ye(e.minimalDays, 1, 7) || !Array.isArray(e.weekend) || e.weekend.some((t) => !Ye(t, 1, 7)))
    throw new Me("Invalid week settings");
  return {
    firstDay: e.firstDay,
    minimalDays: e.minimalDays,
    weekend: Array.from(e.weekend)
  };
}
function Ye(e, t, n) {
  return Pa(e) && e >= t && e <= n;
}
function SD(e, t) {
  return e - t * Math.floor(e / t);
}
function de(e, t = 2) {
  const n = e < 0;
  let o;
  return n ? o = "-" + ("" + -e).padStart(t, "0") : o = ("" + e).padStart(t, "0"), o;
}
function on(e) {
  if (!(N(e) || e === null || e === ""))
    return parseInt(e, 10);
}
function yn(e) {
  if (!(N(e) || e === null || e === ""))
    return parseFloat(e);
}
function fu(e) {
  if (!(N(e) || e === null || e === "")) {
    const t = parseFloat("0." + e) * 1e3;
    return Math.floor(t);
  }
}
function mu(e, t, n = !1) {
  const o = 10 ** t;
  return (n ? Math.trunc : Math.round)(e * o) / o;
}
function Ms(e) {
  return e % 4 === 0 && (e % 100 !== 0 || e % 400 === 0);
}
function xo(e) {
  return Ms(e) ? 366 : 365;
}
function ta(e, t) {
  const n = SD(t - 1, 12) + 1, o = e + (t - n) / 12;
  return n === 2 ? Ms(o) ? 29 : 28 : [31, null, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][n - 1];
}
function _a(e) {
  let t = Date.UTC(
    e.year,
    e.month - 1,
    e.day,
    e.hour,
    e.minute,
    e.second,
    e.millisecond
  );
  return e.year < 100 && e.year >= 0 && (t = new Date(t), t.setUTCFullYear(e.year, e.month - 1, e.day)), +t;
}
function Jd(e, t, n) {
  return -du(uu(e, 1, t), n) + t - 1;
}
function ss(e, t = 4, n = 1) {
  const o = Jd(e, t, n), r = Jd(e + 1, t, n);
  return (xo(e) - o + r) / 7;
}
function Hl(e) {
  return e > 99 ? e : e > le.twoDigitCutoffYear ? 1900 + e : 2e3 + e;
}
function Ny(e, t, n, o = null) {
  const r = new Date(e), s = {
    hourCycle: "h23",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  };
  o && (s.timeZone = o);
  const i = { timeZoneName: t, ...s }, a = new Intl.DateTimeFormat(n, i).formatToParts(r).find((l) => l.type.toLowerCase() === "timezonename");
  return a ? a.value : null;
}
function Ba(e, t) {
  let n = parseInt(e, 10);
  Number.isNaN(n) && (n = 0);
  const o = parseInt(t, 10) || 0, r = n < 0 || Object.is(n, -0) ? -o : o;
  return n * 60 + r;
}
function $y(e) {
  const t = Number(e);
  if (typeof e == "boolean" || e === "" || Number.isNaN(t))
    throw new Me(`Invalid unit value ${e}`);
  return t;
}
function na(e, t) {
  const n = {};
  for (const o in e)
    if (Bo(e, o)) {
      const r = e[o];
      if (r == null)
        continue;
      n[t(o)] = $y(r);
    }
  return n;
}
function Yr(e, t) {
  const n = Math.trunc(Math.abs(e / 60)), o = Math.trunc(Math.abs(e % 60)), r = e >= 0 ? "+" : "-";
  switch (t) {
    case "short":
      return `${r}${de(n, 2)}:${de(o, 2)}`;
    case "narrow":
      return `${r}${n}${o > 0 ? `:${o}` : ""}`;
    case "techie":
      return `${r}${de(n, 2)}${de(o, 2)}`;
    default:
      throw new RangeError(`Value format ${t} is out of range for property format`);
  }
}
function Wa(e) {
  return ED(e, ["hour", "minute", "second", "millisecond"]);
}
const xD = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
], ky = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
], AD = ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"];
function Dy(e) {
  switch (e) {
    case "narrow":
      return [...AD];
    case "short":
      return [...ky];
    case "long":
      return [...xD];
    case "numeric":
      return ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
    case "2-digit":
      return ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
    default:
      return null;
  }
}
const Ry = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
], Ly = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"], MD = ["M", "T", "W", "T", "F", "S", "S"];
function Py(e) {
  switch (e) {
    case "narrow":
      return [...MD];
    case "short":
      return [...Ly];
    case "long":
      return [...Ry];
    case "numeric":
      return ["1", "2", "3", "4", "5", "6", "7"];
    default:
      return null;
  }
}
const _y = ["AM", "PM"], ND = ["Before Christ", "Anno Domini"], $D = ["BC", "AD"], kD = ["B", "A"];
function By(e) {
  switch (e) {
    case "narrow":
      return [...kD];
    case "short":
      return [...$D];
    case "long":
      return [...ND];
    default:
      return null;
  }
}
function DD(e) {
  return _y[e.hour < 12 ? 0 : 1];
}
function RD(e, t) {
  return Py(t)[e.weekday - 1];
}
function LD(e, t) {
  return Dy(t)[e.month - 1];
}
function PD(e, t) {
  return By(t)[e.year < 0 ? 0 : 1];
}
function _D(e, t, n = "always", o = !1) {
  const r = {
    years: ["year", "yr."],
    quarters: ["quarter", "qtr."],
    months: ["month", "mo."],
    weeks: ["week", "wk."],
    days: ["day", "day", "days"],
    hours: ["hour", "hr."],
    minutes: ["minute", "min."],
    seconds: ["second", "sec."]
  }, s = ["hours", "minutes", "seconds"].indexOf(e) === -1;
  if (n === "auto" && s) {
    const d = e === "days";
    switch (t) {
      case 1:
        return d ? "tomorrow" : `next ${r[e][0]}`;
      case -1:
        return d ? "yesterday" : `last ${r[e][0]}`;
      case 0:
        return d ? "today" : `this ${r[e][0]}`;
    }
  }
  const i = Object.is(t, -0) || t < 0, a = Math.abs(t), l = a === 1, c = r[e], u = o ? l ? c[1] : c[2] || c[1] : l ? r[e][0] : e;
  return i ? `${a} ${u} ago` : `in ${a} ${u}`;
}
function Xd(e, t) {
  let n = "";
  for (const o of e)
    o.literal ? n += o.val : n += t(o.val);
  return n;
}
const BD = {
  D: Xi,
  DD: oy,
  DDD: ry,
  DDDD: sy,
  t: iy,
  tt: ay,
  ttt: ly,
  tttt: cy,
  T: uy,
  TT: dy,
  TTT: fy,
  TTTT: my,
  f: hy,
  ff: yy,
  fff: vy,
  ffff: Iy,
  F: py,
  FF: gy,
  FFF: by,
  FFFF: Ty
};
class Te {
  static create(t, n = {}) {
    return new Te(t, n);
  }
  static parseFormat(t) {
    let n = null, o = "", r = !1;
    const s = [];
    for (let i = 0; i < t.length; i++) {
      const a = t.charAt(i);
      a === "'" ? (o.length > 0 && s.push({ literal: r || /^\s+$/.test(o), val: o }), n = null, o = "", r = !r) : r || a === n ? o += a : (o.length > 0 && s.push({ literal: /^\s+$/.test(o), val: o }), o = a, n = a);
    }
    return o.length > 0 && s.push({ literal: r || /^\s+$/.test(o), val: o }), s;
  }
  static macroTokenToFormatOpts(t) {
    return BD[t];
  }
  constructor(t, n) {
    this.opts = n, this.loc = t, this.systemLoc = null;
  }
  formatWithSystemDefault(t, n) {
    return this.systemLoc === null && (this.systemLoc = this.loc.redefaultToSystem()), this.systemLoc.dtFormatter(t, { ...this.opts, ...n }).format();
  }
  dtFormatter(t, n = {}) {
    return this.loc.dtFormatter(t, { ...this.opts, ...n });
  }
  formatDateTime(t, n) {
    return this.dtFormatter(t, n).format();
  }
  formatDateTimeParts(t, n) {
    return this.dtFormatter(t, n).formatToParts();
  }
  formatInterval(t, n) {
    return this.dtFormatter(t.start, n).dtf.formatRange(t.start.toJSDate(), t.end.toJSDate());
  }
  resolvedOptions(t, n) {
    return this.dtFormatter(t, n).resolvedOptions();
  }
  num(t, n = 0) {
    if (this.opts.forceSimple)
      return de(t, n);
    const o = { ...this.opts };
    return n > 0 && (o.padTo = n), this.loc.numberFormatter(o).format(t);
  }
  formatDateTimeFromString(t, n) {
    const o = this.loc.listingMode() === "en", r = this.loc.outputCalendar && this.loc.outputCalendar !== "gregory", s = (m, p) => this.loc.extract(t, m, p), i = (m) => t.isOffsetFixed && t.offset === 0 && m.allowZ ? "Z" : t.isValid ? t.zone.formatOffset(t.ts, m.format) : "", a = () => o ? DD(t) : s({ hour: "numeric", hourCycle: "h12" }, "dayperiod"), l = (m, p) => o ? LD(t, m) : s(p ? { month: m } : { month: m, day: "numeric" }, "month"), c = (m, p) => o ? RD(t, m) : s(
      p ? { weekday: m } : { weekday: m, month: "long", day: "numeric" },
      "weekday"
    ), u = (m) => {
      const p = Te.macroTokenToFormatOpts(m);
      return p ? this.formatWithSystemDefault(t, p) : m;
    }, d = (m) => o ? PD(t, m) : s({ era: m }, "era"), f = (m) => {
      switch (m) {
        case "S":
          return this.num(t.millisecond);
        case "u":
        case "SSS":
          return this.num(t.millisecond, 3);
        case "s":
          return this.num(t.second);
        case "ss":
          return this.num(t.second, 2);
        case "uu":
          return this.num(Math.floor(t.millisecond / 10), 2);
        case "uuu":
          return this.num(Math.floor(t.millisecond / 100));
        case "m":
          return this.num(t.minute);
        case "mm":
          return this.num(t.minute, 2);
        case "h":
          return this.num(t.hour % 12 === 0 ? 12 : t.hour % 12);
        case "hh":
          return this.num(t.hour % 12 === 0 ? 12 : t.hour % 12, 2);
        case "H":
          return this.num(t.hour);
        case "HH":
          return this.num(t.hour, 2);
        case "Z":
          return i({ format: "narrow", allowZ: this.opts.allowZ });
        case "ZZ":
          return i({ format: "short", allowZ: this.opts.allowZ });
        case "ZZZ":
          return i({ format: "techie", allowZ: this.opts.allowZ });
        case "ZZZZ":
          return t.zone.offsetName(t.ts, { format: "short", locale: this.loc.locale });
        case "ZZZZZ":
          return t.zone.offsetName(t.ts, { format: "long", locale: this.loc.locale });
        case "z":
          return t.zoneName;
        case "a":
          return a();
        case "d":
          return r ? s({ day: "numeric" }, "day") : this.num(t.day);
        case "dd":
          return r ? s({ day: "2-digit" }, "day") : this.num(t.day, 2);
        case "c":
          return this.num(t.weekday);
        case "ccc":
          return c("short", !0);
        case "cccc":
          return c("long", !0);
        case "ccccc":
          return c("narrow", !0);
        case "E":
          return this.num(t.weekday);
        case "EEE":
          return c("short", !1);
        case "EEEE":
          return c("long", !1);
        case "EEEEE":
          return c("narrow", !1);
        case "L":
          return r ? s({ month: "numeric", day: "numeric" }, "month") : this.num(t.month);
        case "LL":
          return r ? s({ month: "2-digit", day: "numeric" }, "month") : this.num(t.month, 2);
        case "LLL":
          return l("short", !0);
        case "LLLL":
          return l("long", !0);
        case "LLLLL":
          return l("narrow", !0);
        case "M":
          return r ? s({ month: "numeric" }, "month") : this.num(t.month);
        case "MM":
          return r ? s({ month: "2-digit" }, "month") : this.num(t.month, 2);
        case "MMM":
          return l("short", !1);
        case "MMMM":
          return l("long", !1);
        case "MMMMM":
          return l("narrow", !1);
        case "y":
          return r ? s({ year: "numeric" }, "year") : this.num(t.year);
        case "yy":
          return r ? s({ year: "2-digit" }, "year") : this.num(t.year.toString().slice(-2), 2);
        case "yyyy":
          return r ? s({ year: "numeric" }, "year") : this.num(t.year, 4);
        case "yyyyyy":
          return r ? s({ year: "numeric" }, "year") : this.num(t.year, 6);
        case "G":
          return d("short");
        case "GG":
          return d("long");
        case "GGGGG":
          return d("narrow");
        case "kk":
          return this.num(t.weekYear.toString().slice(-2), 2);
        case "kkkk":
          return this.num(t.weekYear, 4);
        case "W":
          return this.num(t.weekNumber);
        case "WW":
          return this.num(t.weekNumber, 2);
        case "n":
          return this.num(t.localWeekNumber);
        case "nn":
          return this.num(t.localWeekNumber, 2);
        case "ii":
          return this.num(t.localWeekYear.toString().slice(-2), 2);
        case "iiii":
          return this.num(t.localWeekYear, 4);
        case "o":
          return this.num(t.ordinal);
        case "ooo":
          return this.num(t.ordinal, 3);
        case "q":
          return this.num(t.quarter);
        case "qq":
          return this.num(t.quarter, 2);
        case "X":
          return this.num(Math.floor(t.ts / 1e3));
        case "x":
          return this.num(t.ts);
        default:
          return u(m);
      }
    };
    return Xd(Te.parseFormat(n), f);
  }
  formatDurationFromString(t, n) {
    const o = (l) => {
      switch (l[0]) {
        case "S":
          return "millisecond";
        case "s":
          return "second";
        case "m":
          return "minute";
        case "h":
          return "hour";
        case "d":
          return "day";
        case "w":
          return "week";
        case "M":
          return "month";
        case "y":
          return "year";
        default:
          return null;
      }
    }, r = (l) => (c) => {
      const u = o(c);
      return u ? this.num(l.get(u), c.length) : c;
    }, s = Te.parseFormat(n), i = s.reduce(
      (l, { literal: c, val: u }) => c ? l : l.concat(u),
      []
    ), a = t.shiftTo(...i.map(o).filter((l) => l));
    return Xd(s, r(a));
  }
}
const Wy = /[A-Za-z_+-]{1,256}(?::?\/[A-Za-z0-9_+-]{1,256}(?:\/[A-Za-z0-9_+-]{1,256})?)?/;
function or(...e) {
  const t = e.reduce((n, o) => n + o.source, "");
  return RegExp(`^${t}$`);
}
function rr(...e) {
  return (t) => e.reduce(
    ([n, o, r], s) => {
      const [i, a, l] = s(t, r);
      return [{ ...n, ...i }, a || o, l];
    },
    [{}, null, 1]
  ).slice(0, 2);
}
function sr(e, ...t) {
  if (e == null)
    return [null, null];
  for (const [n, o] of t) {
    const r = n.exec(e);
    if (r)
      return o(r);
  }
  return [null, null];
}
function Uy(...e) {
  return (t, n) => {
    const o = {};
    let r;
    for (r = 0; r < e.length; r++)
      o[e[r]] = on(t[n + r]);
    return [o, null, n + r];
  };
}
const jy = /(?:(Z)|([+-]\d\d)(?::?(\d\d))?)/, WD = `(?:${jy.source}?(?:\\[(${Wy.source})\\])?)?`, hu = /(\d\d)(?::?(\d\d)(?::?(\d\d)(?:[.,](\d{1,30}))?)?)?/, Fy = RegExp(`${hu.source}${WD}`), pu = RegExp(`(?:T${Fy.source})?`), UD = /([+-]\d{6}|\d{4})(?:-?(\d\d)(?:-?(\d\d))?)?/, jD = /(\d{4})-?W(\d\d)(?:-?(\d))?/, FD = /(\d{4})-?(\d{3})/, qD = Uy("weekYear", "weekNumber", "weekDay"), HD = Uy("year", "ordinal"), ZD = /(\d{4})-(\d\d)-(\d\d)/, qy = RegExp(
  `${hu.source} ?(?:${jy.source}|(${Wy.source}))?`
), VD = RegExp(`(?: ${qy.source})?`);
function Ao(e, t, n) {
  const o = e[t];
  return N(o) ? n : on(o);
}
function zD(e, t) {
  return [{
    year: Ao(e, t),
    month: Ao(e, t + 1, 1),
    day: Ao(e, t + 2, 1)
  }, null, t + 3];
}
function ir(e, t) {
  return [{
    hours: Ao(e, t, 0),
    minutes: Ao(e, t + 1, 0),
    seconds: Ao(e, t + 2, 0),
    milliseconds: fu(e[t + 3])
  }, null, t + 4];
}
function Ns(e, t) {
  const n = !e[t] && !e[t + 1], o = Ba(e[t + 1], e[t + 2]), r = n ? null : Ee.instance(o);
  return [{}, r, t + 3];
}
function $s(e, t) {
  const n = e[t] ? qt.create(e[t]) : null;
  return [{}, n, t + 1];
}
const GD = RegExp(`^T?${hu.source}$`), YD = /^-?P(?:(?:(-?\d{1,20}(?:\.\d{1,20})?)Y)?(?:(-?\d{1,20}(?:\.\d{1,20})?)M)?(?:(-?\d{1,20}(?:\.\d{1,20})?)W)?(?:(-?\d{1,20}(?:\.\d{1,20})?)D)?(?:T(?:(-?\d{1,20}(?:\.\d{1,20})?)H)?(?:(-?\d{1,20}(?:\.\d{1,20})?)M)?(?:(-?\d{1,20})(?:[.,](-?\d{1,20}))?S)?)?)$/;
function KD(e) {
  const [t, n, o, r, s, i, a, l, c] = e, u = t[0] === "-", d = l && l[0] === "-", f = (m, p = !1) => m !== void 0 && (p || m && u) ? -m : m;
  return [
    {
      years: f(yn(n)),
      months: f(yn(o)),
      weeks: f(yn(r)),
      days: f(yn(s)),
      hours: f(yn(i)),
      minutes: f(yn(a)),
      seconds: f(yn(l), l === "-0"),
      milliseconds: f(fu(c), d)
    }
  ];
}
const QD = {
  GMT: 0,
  EDT: -4 * 60,
  EST: -5 * 60,
  CDT: -5 * 60,
  CST: -6 * 60,
  MDT: -6 * 60,
  MST: -7 * 60,
  PDT: -7 * 60,
  PST: -8 * 60
};
function yu(e, t, n, o, r, s, i) {
  const a = {
    year: t.length === 2 ? Hl(on(t)) : on(t),
    month: ky.indexOf(n) + 1,
    day: on(o),
    hour: on(r),
    minute: on(s)
  };
  return i && (a.second = on(i)), e && (a.weekday = e.length > 3 ? Ry.indexOf(e) + 1 : Ly.indexOf(e) + 1), a;
}
const JD = /^(?:(Mon|Tue|Wed|Thu|Fri|Sat|Sun),\s)?(\d{1,2})\s(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)\s(\d{2,4})\s(\d\d):(\d\d)(?::(\d\d))?\s(?:(UT|GMT|[ECMP][SD]T)|([Zz])|(?:([+-]\d\d)(\d\d)))$/;
function XD(e) {
  const [
    ,
    t,
    n,
    o,
    r,
    s,
    i,
    a,
    l,
    c,
    u,
    d
  ] = e, f = yu(t, r, o, n, s, i, a);
  let m;
  return l ? m = QD[l] : c ? m = 0 : m = Ba(u, d), [f, new Ee(m)];
}
function eR(e) {
  return e.replace(/\([^()]*\)|[\n\t]/g, " ").replace(/(\s\s+)/g, " ").trim();
}
const tR = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun), (\d\d) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) (\d{4}) (\d\d):(\d\d):(\d\d) GMT$/, nR = /^(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday), (\d\d)-(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-(\d\d) (\d\d):(\d\d):(\d\d) GMT$/, oR = /^(Mon|Tue|Wed|Thu|Fri|Sat|Sun) (Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec) ( \d|\d\d) (\d\d):(\d\d):(\d\d) (\d{4})$/;
function ef(e) {
  const [, t, n, o, r, s, i, a] = e;
  return [yu(t, r, o, n, s, i, a), Ee.utcInstance];
}
function rR(e) {
  const [, t, n, o, r, s, i, a] = e;
  return [yu(t, a, n, o, r, s, i), Ee.utcInstance];
}
const sR = or(UD, pu), iR = or(jD, pu), aR = or(FD, pu), lR = or(Fy), Hy = rr(
  zD,
  ir,
  Ns,
  $s
), cR = rr(
  qD,
  ir,
  Ns,
  $s
), uR = rr(
  HD,
  ir,
  Ns,
  $s
), dR = rr(
  ir,
  Ns,
  $s
);
function fR(e) {
  return sr(
    e,
    [sR, Hy],
    [iR, cR],
    [aR, uR],
    [lR, dR]
  );
}
function mR(e) {
  return sr(eR(e), [JD, XD]);
}
function hR(e) {
  return sr(
    e,
    [tR, ef],
    [nR, ef],
    [oR, rR]
  );
}
function pR(e) {
  return sr(e, [YD, KD]);
}
const yR = rr(ir);
function gR(e) {
  return sr(e, [GD, yR]);
}
const vR = or(ZD, VD), bR = or(qy), IR = rr(
  ir,
  Ns,
  $s
);
function TR(e) {
  return sr(
    e,
    [vR, Hy],
    [bR, IR]
  );
}
const tf = "Invalid Duration", Zy = {
  weeks: {
    days: 7,
    hours: 7 * 24,
    minutes: 7 * 24 * 60,
    seconds: 7 * 24 * 60 * 60,
    milliseconds: 7 * 24 * 60 * 60 * 1e3
  },
  days: {
    hours: 24,
    minutes: 24 * 60,
    seconds: 24 * 60 * 60,
    milliseconds: 24 * 60 * 60 * 1e3
  },
  hours: { minutes: 60, seconds: 60 * 60, milliseconds: 60 * 60 * 1e3 },
  minutes: { seconds: 60, milliseconds: 60 * 1e3 },
  seconds: { milliseconds: 1e3 }
}, wR = {
  years: {
    quarters: 4,
    months: 12,
    weeks: 52,
    days: 365,
    hours: 365 * 24,
    minutes: 365 * 24 * 60,
    seconds: 365 * 24 * 60 * 60,
    milliseconds: 365 * 24 * 60 * 60 * 1e3
  },
  quarters: {
    months: 3,
    weeks: 13,
    days: 91,
    hours: 91 * 24,
    minutes: 91 * 24 * 60,
    seconds: 91 * 24 * 60 * 60,
    milliseconds: 91 * 24 * 60 * 60 * 1e3
  },
  months: {
    weeks: 4,
    days: 30,
    hours: 30 * 24,
    minutes: 30 * 24 * 60,
    seconds: 30 * 24 * 60 * 60,
    milliseconds: 30 * 24 * 60 * 60 * 1e3
  },
  ...Zy
}, Ue = 146097 / 400, Xn = 146097 / 4800, CR = {
  years: {
    quarters: 4,
    months: 12,
    weeks: Ue / 7,
    days: Ue,
    hours: Ue * 24,
    minutes: Ue * 24 * 60,
    seconds: Ue * 24 * 60 * 60,
    milliseconds: Ue * 24 * 60 * 60 * 1e3
  },
  quarters: {
    months: 3,
    weeks: Ue / 28,
    days: Ue / 4,
    hours: Ue * 24 / 4,
    minutes: Ue * 24 * 60 / 4,
    seconds: Ue * 24 * 60 * 60 / 4,
    milliseconds: Ue * 24 * 60 * 60 * 1e3 / 4
  },
  months: {
    weeks: Xn / 7,
    days: Xn,
    hours: Xn * 24,
    minutes: Xn * 24 * 60,
    seconds: Xn * 24 * 60 * 60,
    milliseconds: Xn * 24 * 60 * 60 * 1e3
  },
  ...Zy
}, Mn = [
  "years",
  "quarters",
  "months",
  "weeks",
  "days",
  "hours",
  "minutes",
  "seconds",
  "milliseconds"
], OR = Mn.slice(0).reverse();
function Kt(e, t, n = !1) {
  const o = {
    values: n ? t.values : { ...e.values, ...t.values || {} },
    loc: e.loc.clone(t.loc),
    conversionAccuracy: t.conversionAccuracy || e.conversionAccuracy,
    matrix: t.matrix || e.matrix
  };
  return new j(o);
}
function Vy(e, t) {
  let n = t.milliseconds ?? 0;
  for (const o of OR.slice(1))
    t[o] && (n += t[o] * e[o].milliseconds);
  return n;
}
function nf(e, t) {
  const n = Vy(e, t) < 0 ? -1 : 1;
  Mn.reduceRight((o, r) => {
    if (N(t[r]))
      return o;
    if (o) {
      const s = t[o] * n, i = e[r][o], a = Math.floor(s / i);
      t[r] += a * n, t[o] -= a * i * n;
    }
    return r;
  }, null), Mn.reduce((o, r) => {
    if (N(t[r]))
      return o;
    if (o) {
      const s = t[o] % 1;
      t[o] -= s, t[r] += s * e[o][r];
    }
    return r;
  }, null);
}
function ER(e) {
  const t = {};
  for (const [n, o] of Object.entries(e))
    o !== 0 && (t[n] = o);
  return t;
}
class j {
  /**
   * @private
   */
  constructor(t) {
    const n = t.conversionAccuracy === "longterm" || !1;
    let o = n ? CR : wR;
    t.matrix && (o = t.matrix), this.values = t.values, this.loc = t.loc || V.create(), this.conversionAccuracy = n ? "longterm" : "casual", this.invalid = t.invalid || null, this.matrix = o, this.isLuxonDuration = !0;
  }
  /**
   * Create Duration from a number of milliseconds.
   * @param {number} count of milliseconds
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @return {Duration}
   */
  static fromMillis(t, n) {
    return j.fromObject({ milliseconds: t }, n);
  }
  /**
   * Create a Duration from a JavaScript object with keys like 'years' and 'hours'.
   * If this object is empty then a zero milliseconds duration is returned.
   * @param {Object} obj - the object to create the DateTime from
   * @param {number} obj.years
   * @param {number} obj.quarters
   * @param {number} obj.months
   * @param {number} obj.weeks
   * @param {number} obj.days
   * @param {number} obj.hours
   * @param {number} obj.minutes
   * @param {number} obj.seconds
   * @param {number} obj.milliseconds
   * @param {Object} [opts=[]] - options for creating this Duration
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the custom conversion system to use
   * @return {Duration}
   */
  static fromObject(t, n = {}) {
    if (t == null || typeof t != "object")
      throw new Me(
        `Duration.fromObject: argument expected to be an object, got ${t === null ? "null" : typeof t}`
      );
    return new j({
      values: na(t, j.normalizeUnit),
      loc: V.fromObject(n),
      conversionAccuracy: n.conversionAccuracy,
      matrix: n.matrix
    });
  }
  /**
   * Create a Duration from DurationLike.
   *
   * @param {Object | number | Duration} durationLike
   * One of:
   * - object with keys like 'years' and 'hours'.
   * - number representing milliseconds
   * - Duration instance
   * @return {Duration}
   */
  static fromDurationLike(t) {
    if (Ln(t))
      return j.fromMillis(t);
    if (j.isDuration(t))
      return t;
    if (typeof t == "object")
      return j.fromObject(t);
    throw new Me(
      `Unknown duration argument ${t} of type ${typeof t}`
    );
  }
  /**
   * Create a Duration from an ISO 8601 duration string.
   * @param {string} text - text to parse
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the preset conversion system to use
   * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
   * @example Duration.fromISO('P3Y6M1W4DT12H30M5S').toObject() //=> { years: 3, months: 6, weeks: 1, days: 4, hours: 12, minutes: 30, seconds: 5 }
   * @example Duration.fromISO('PT23H').toObject() //=> { hours: 23 }
   * @example Duration.fromISO('P5Y3M').toObject() //=> { years: 5, months: 3 }
   * @return {Duration}
   */
  static fromISO(t, n) {
    const [o] = pR(t);
    return o ? j.fromObject(o, n) : j.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  /**
   * Create a Duration from an ISO 8601 time string.
   * @param {string} text - text to parse
   * @param {Object} opts - options for parsing
   * @param {string} [opts.locale='en-US'] - the locale to use
   * @param {string} opts.numberingSystem - the numbering system to use
   * @param {string} [opts.conversionAccuracy='casual'] - the preset conversion system to use
   * @param {string} [opts.matrix=Object] - the conversion system to use
   * @see https://en.wikipedia.org/wiki/ISO_8601#Times
   * @example Duration.fromISOTime('11:22:33.444').toObject() //=> { hours: 11, minutes: 22, seconds: 33, milliseconds: 444 }
   * @example Duration.fromISOTime('11:00').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('T11:00').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('1100').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @example Duration.fromISOTime('T1100').toObject() //=> { hours: 11, minutes: 0, seconds: 0 }
   * @return {Duration}
   */
  static fromISOTime(t, n) {
    const [o] = gR(t);
    return o ? j.fromObject(o, n) : j.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  /**
   * Create an invalid Duration.
   * @param {string} reason - simple string of why this datetime is invalid. Should not contain parameters or anything else data-dependent
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {Duration}
   */
  static invalid(t, n = null) {
    if (!t)
      throw new Me("need to specify a reason the Duration is invalid");
    const o = t instanceof ut ? t : new ut(t, n);
    if (le.throwOnInvalid)
      throw new J2(o);
    return new j({ invalid: o });
  }
  /**
   * @private
   */
  static normalizeUnit(t) {
    const n = {
      year: "years",
      years: "years",
      quarter: "quarters",
      quarters: "quarters",
      month: "months",
      months: "months",
      week: "weeks",
      weeks: "weeks",
      day: "days",
      days: "days",
      hour: "hours",
      hours: "hours",
      minute: "minutes",
      minutes: "minutes",
      second: "seconds",
      seconds: "seconds",
      millisecond: "milliseconds",
      milliseconds: "milliseconds"
    }[t && t.toLowerCase()];
    if (!n)
      throw new ny(t);
    return n;
  }
  /**
   * Check if an object is a Duration. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isDuration(t) {
    return t && t.isLuxonDuration || !1;
  }
  /**
   * Get  the locale of a Duration, such 'en-GB'
   * @type {string}
   */
  get locale() {
    return this.isValid ? this.loc.locale : null;
  }
  /**
   * Get the numbering system of a Duration, such 'beng'. The numbering system is used when formatting the Duration
   *
   * @type {string}
   */
  get numberingSystem() {
    return this.isValid ? this.loc.numberingSystem : null;
  }
  /**
   * Returns a string representation of this Duration formatted according to the specified format string. You may use these tokens:
   * * `S` for milliseconds
   * * `s` for seconds
   * * `m` for minutes
   * * `h` for hours
   * * `d` for days
   * * `w` for weeks
   * * `M` for months
   * * `y` for years
   * Notes:
   * * Add padding by repeating the token, e.g. "yy" pads the years to two digits, "hhhh" pads the hours out to four digits
   * * Tokens can be escaped by wrapping with single quotes.
   * * The duration will be converted to the set of units in the format string using {@link Duration#shiftTo} and the Durations's conversion accuracy setting.
   * @param {string} fmt - the format string
   * @param {Object} opts - options
   * @param {boolean} [opts.floor=true] - floor numerical values
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("y d s") //=> "1 6 2"
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("yy dd sss") //=> "01 06 002"
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toFormat("M S") //=> "12 518402000"
   * @return {string}
   */
  toFormat(t, n = {}) {
    const o = {
      ...n,
      floor: n.round !== !1 && n.floor !== !1
    };
    return this.isValid ? Te.create(this.loc, o).formatDurationFromString(this, t) : tf;
  }
  /**
   * Returns a string representation of a Duration with all units included.
   * To modify its behavior, use `listStyle` and any Intl.NumberFormat option, though `unitDisplay` is especially relevant.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#options
   * @param {Object} opts - Formatting options. Accepts the same keys as the options parameter of the native `Intl.NumberFormat` constructor, as well as `listStyle`.
   * @param {string} [opts.listStyle='narrow'] - How to format the merged list. Corresponds to the `style` property of the options parameter of the native `Intl.ListFormat` constructor.
   * @example
   * ```js
   * var dur = Duration.fromObject({ days: 1, hours: 5, minutes: 6 })
   * dur.toHuman() //=> '1 day, 5 hours, 6 minutes'
   * dur.toHuman({ listStyle: "long" }) //=> '1 day, 5 hours, and 6 minutes'
   * dur.toHuman({ unitDisplay: "short" }) //=> '1 day, 5 hr, 6 min'
   * ```
   */
  toHuman(t = {}) {
    if (!this.isValid)
      return tf;
    const n = Mn.map((o) => {
      const r = this.values[o];
      return N(r) ? null : this.loc.numberFormatter({ style: "unit", unitDisplay: "long", ...t, unit: o.slice(0, -1) }).format(r);
    }).filter((o) => o);
    return this.loc.listFormatter({ type: "conjunction", style: t.listStyle || "narrow", ...t }).format(n);
  }
  /**
   * Returns a JavaScript object with this Duration's values.
   * @example Duration.fromObject({ years: 1, days: 6, seconds: 2 }).toObject() //=> { years: 1, days: 6, seconds: 2 }
   * @return {Object}
   */
  toObject() {
    return this.isValid ? { ...this.values } : {};
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Duration.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Durations
   * @example Duration.fromObject({ years: 3, seconds: 45 }).toISO() //=> 'P3YT45S'
   * @example Duration.fromObject({ months: 4, seconds: 45 }).toISO() //=> 'P4MT45S'
   * @example Duration.fromObject({ months: 5 }).toISO() //=> 'P5M'
   * @example Duration.fromObject({ minutes: 5 }).toISO() //=> 'PT5M'
   * @example Duration.fromObject({ milliseconds: 6 }).toISO() //=> 'PT0.006S'
   * @return {string}
   */
  toISO() {
    if (!this.isValid)
      return null;
    let t = "P";
    return this.years !== 0 && (t += this.years + "Y"), (this.months !== 0 || this.quarters !== 0) && (t += this.months + this.quarters * 3 + "M"), this.weeks !== 0 && (t += this.weeks + "W"), this.days !== 0 && (t += this.days + "D"), (this.hours !== 0 || this.minutes !== 0 || this.seconds !== 0 || this.milliseconds !== 0) && (t += "T"), this.hours !== 0 && (t += this.hours + "H"), this.minutes !== 0 && (t += this.minutes + "M"), (this.seconds !== 0 || this.milliseconds !== 0) && (t += mu(this.seconds + this.milliseconds / 1e3, 3) + "S"), t === "P" && (t += "T0S"), t;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Duration, formatted as a time of day.
   * Note that this will return null if the duration is invalid, negative, or equal to or greater than 24 hours.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Times
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includePrefix=false] - include the `T` prefix
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example Duration.fromObject({ hours: 11 }).toISOTime() //=> '11:00:00.000'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ suppressMilliseconds: true }) //=> '11:00:00'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ suppressSeconds: true }) //=> '11:00'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ includePrefix: true }) //=> 'T11:00:00.000'
   * @example Duration.fromObject({ hours: 11 }).toISOTime({ format: 'basic' }) //=> '110000.000'
   * @return {string}
   */
  toISOTime(t = {}) {
    if (!this.isValid)
      return null;
    const n = this.toMillis();
    return n < 0 || n >= 864e5 ? null : (t = {
      suppressMilliseconds: !1,
      suppressSeconds: !1,
      includePrefix: !1,
      format: "extended",
      ...t,
      includeOffset: !1
    }, D.fromMillis(n, { zone: "UTC" }).toISOTime(t));
  }
  /**
   * Returns an ISO 8601 representation of this Duration appropriate for use in JSON.
   * @return {string}
   */
  toJSON() {
    return this.toISO();
  }
  /**
   * Returns an ISO 8601 representation of this Duration appropriate for use in debugging.
   * @return {string}
   */
  toString() {
    return this.toISO();
  }
  /**
   * Returns a string representation of this Duration appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `Duration { values: ${JSON.stringify(this.values)} }` : `Duration { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns an milliseconds value of this Duration.
   * @return {number}
   */
  toMillis() {
    return this.isValid ? Vy(this.matrix, this.values) : NaN;
  }
  /**
   * Returns an milliseconds value of this Duration. Alias of {@link toMillis}
   * @return {number}
   */
  valueOf() {
    return this.toMillis();
  }
  /**
   * Make this Duration longer by the specified amount. Return a newly-constructed Duration.
   * @param {Duration|Object|number} duration - The amount to add. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @return {Duration}
   */
  plus(t) {
    if (!this.isValid)
      return this;
    const n = j.fromDurationLike(t), o = {};
    for (const r of Mn)
      (Bo(n.values, r) || Bo(this.values, r)) && (o[r] = n.get(r) + this.get(r));
    return Kt(this, { values: o }, !0);
  }
  /**
   * Make this Duration shorter by the specified amount. Return a newly-constructed Duration.
   * @param {Duration|Object|number} duration - The amount to subtract. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @return {Duration}
   */
  minus(t) {
    if (!this.isValid)
      return this;
    const n = j.fromDurationLike(t);
    return this.plus(n.negate());
  }
  /**
   * Scale this Duration by the specified amount. Return a newly-constructed Duration.
   * @param {function} fn - The function to apply to each unit. Arity is 1 or 2: the value of the unit and, optionally, the unit name. Must return a number.
   * @example Duration.fromObject({ hours: 1, minutes: 30 }).mapUnits(x => x * 2) //=> { hours: 2, minutes: 60 }
   * @example Duration.fromObject({ hours: 1, minutes: 30 }).mapUnits((x, u) => u === "hours" ? x * 2 : x) //=> { hours: 2, minutes: 30 }
   * @return {Duration}
   */
  mapUnits(t) {
    if (!this.isValid)
      return this;
    const n = {};
    for (const o of Object.keys(this.values))
      n[o] = $y(t(this.values[o], o));
    return Kt(this, { values: n }, !0);
  }
  /**
   * Get the value of unit.
   * @param {string} unit - a unit such as 'minute' or 'day'
   * @example Duration.fromObject({years: 2, days: 3}).get('years') //=> 2
   * @example Duration.fromObject({years: 2, days: 3}).get('months') //=> 0
   * @example Duration.fromObject({years: 2, days: 3}).get('days') //=> 3
   * @return {number}
   */
  get(t) {
    return this[j.normalizeUnit(t)];
  }
  /**
   * "Set" the values of specified units. Return a newly-constructed Duration.
   * @param {Object} values - a mapping of units to numbers
   * @example dur.set({ years: 2017 })
   * @example dur.set({ hours: 8, minutes: 30 })
   * @return {Duration}
   */
  set(t) {
    if (!this.isValid)
      return this;
    const n = { ...this.values, ...na(t, j.normalizeUnit) };
    return Kt(this, { values: n });
  }
  /**
   * "Set" the locale and/or numberingSystem.  Returns a newly-constructed Duration.
   * @example dur.reconfigure({ locale: 'en-GB' })
   * @return {Duration}
   */
  reconfigure({ locale: t, numberingSystem: n, conversionAccuracy: o, matrix: r } = {}) {
    const i = { loc: this.loc.clone({ locale: t, numberingSystem: n }), matrix: r, conversionAccuracy: o };
    return Kt(this, i);
  }
  /**
   * Return the length of the duration in the specified unit.
   * @param {string} unit - a unit such as 'minutes' or 'days'
   * @example Duration.fromObject({years: 1}).as('days') //=> 365
   * @example Duration.fromObject({years: 1}).as('months') //=> 12
   * @example Duration.fromObject({hours: 60}).as('days') //=> 2.5
   * @return {number}
   */
  as(t) {
    return this.isValid ? this.shiftTo(t).get(t) : NaN;
  }
  /**
   * Reduce this Duration to its canonical representation in its current units.
   * Assuming the overall value of the Duration is positive, this means:
   * - excessive values for lower-order units are converted to higher-order units (if possible, see first and second example)
   * - negative lower-order units are converted to higher order units (there must be such a higher order unit, otherwise
   *   the overall value would be negative, see third example)
   * - fractional values for higher-order units are converted to lower-order units (if possible, see fourth example)
   *
   * If the overall value is negative, the result of this method is equivalent to `this.negate().normalize().negate()`.
   * @example Duration.fromObject({ years: 2, days: 5000 }).normalize().toObject() //=> { years: 15, days: 255 }
   * @example Duration.fromObject({ days: 5000 }).normalize().toObject() //=> { days: 5000 }
   * @example Duration.fromObject({ hours: 12, minutes: -45 }).normalize().toObject() //=> { hours: 11, minutes: 15 }
   * @example Duration.fromObject({ years: 2.5, days: 0, hours: 0 }).normalize().toObject() //=> { years: 2, days: 182, hours: 12 }
   * @return {Duration}
   */
  normalize() {
    if (!this.isValid)
      return this;
    const t = this.toObject();
    return nf(this.matrix, t), Kt(this, { values: t }, !0);
  }
  /**
   * Rescale units to its largest representation
   * @example Duration.fromObject({ milliseconds: 90000 }).rescale().toObject() //=> { minutes: 1, seconds: 30 }
   * @return {Duration}
   */
  rescale() {
    if (!this.isValid)
      return this;
    const t = ER(this.normalize().shiftToAll().toObject());
    return Kt(this, { values: t }, !0);
  }
  /**
   * Convert this Duration into its representation in a different set of units.
   * @example Duration.fromObject({ hours: 1, seconds: 30 }).shiftTo('minutes', 'milliseconds').toObject() //=> { minutes: 60, milliseconds: 30000 }
   * @return {Duration}
   */
  shiftTo(...t) {
    if (!this.isValid)
      return this;
    if (t.length === 0)
      return this;
    t = t.map((i) => j.normalizeUnit(i));
    const n = {}, o = {}, r = this.toObject();
    let s;
    for (const i of Mn)
      if (t.indexOf(i) >= 0) {
        s = i;
        let a = 0;
        for (const c in o)
          a += this.matrix[c][i] * o[c], o[c] = 0;
        Ln(r[i]) && (a += r[i]);
        const l = Math.trunc(a);
        n[i] = l, o[i] = (a * 1e3 - l * 1e3) / 1e3;
      } else
        Ln(r[i]) && (o[i] = r[i]);
    for (const i in o)
      o[i] !== 0 && (n[s] += i === s ? o[i] : o[i] / this.matrix[s][i]);
    return nf(this.matrix, n), Kt(this, { values: n }, !0);
  }
  /**
   * Shift this Duration to all available units.
   * Same as shiftTo("years", "months", "weeks", "days", "hours", "minutes", "seconds", "milliseconds")
   * @return {Duration}
   */
  shiftToAll() {
    return this.isValid ? this.shiftTo(
      "years",
      "months",
      "weeks",
      "days",
      "hours",
      "minutes",
      "seconds",
      "milliseconds"
    ) : this;
  }
  /**
   * Return the negative of this Duration.
   * @example Duration.fromObject({ hours: 1, seconds: 30 }).negate().toObject() //=> { hours: -1, seconds: -30 }
   * @return {Duration}
   */
  negate() {
    if (!this.isValid)
      return this;
    const t = {};
    for (const n of Object.keys(this.values))
      t[n] = this.values[n] === 0 ? 0 : -this.values[n];
    return Kt(this, { values: t }, !0);
  }
  /**
   * Get the years.
   * @type {number}
   */
  get years() {
    return this.isValid ? this.values.years || 0 : NaN;
  }
  /**
   * Get the quarters.
   * @type {number}
   */
  get quarters() {
    return this.isValid ? this.values.quarters || 0 : NaN;
  }
  /**
   * Get the months.
   * @type {number}
   */
  get months() {
    return this.isValid ? this.values.months || 0 : NaN;
  }
  /**
   * Get the weeks
   * @type {number}
   */
  get weeks() {
    return this.isValid ? this.values.weeks || 0 : NaN;
  }
  /**
   * Get the days.
   * @type {number}
   */
  get days() {
    return this.isValid ? this.values.days || 0 : NaN;
  }
  /**
   * Get the hours.
   * @type {number}
   */
  get hours() {
    return this.isValid ? this.values.hours || 0 : NaN;
  }
  /**
   * Get the minutes.
   * @type {number}
   */
  get minutes() {
    return this.isValid ? this.values.minutes || 0 : NaN;
  }
  /**
   * Get the seconds.
   * @return {number}
   */
  get seconds() {
    return this.isValid ? this.values.seconds || 0 : NaN;
  }
  /**
   * Get the milliseconds.
   * @return {number}
   */
  get milliseconds() {
    return this.isValid ? this.values.milliseconds || 0 : NaN;
  }
  /**
   * Returns whether the Duration is invalid. Invalid durations are returned by diff operations
   * on invalid DateTimes or Intervals.
   * @return {boolean}
   */
  get isValid() {
    return this.invalid === null;
  }
  /**
   * Returns an error code if this Duration became invalid, or null if the Duration is valid
   * @return {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this Duration became invalid, or null if the Duration is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Equality check
   * Two Durations are equal iff they have the same units and the same values for each unit.
   * @param {Duration} other
   * @return {boolean}
   */
  equals(t) {
    if (!this.isValid || !t.isValid || !this.loc.equals(t.loc))
      return !1;
    function n(o, r) {
      return o === void 0 || o === 0 ? r === void 0 || r === 0 : o === r;
    }
    for (const o of Mn)
      if (!n(this.values[o], t.values[o]))
        return !1;
    return !0;
  }
}
const eo = "Invalid Interval";
function SR(e, t) {
  return !e || !e.isValid ? ie.invalid("missing or invalid start") : !t || !t.isValid ? ie.invalid("missing or invalid end") : t < e ? ie.invalid(
    "end before start",
    `The end of an interval must be after its start, but you had start=${e.toISO()} and end=${t.toISO()}`
  ) : null;
}
class ie {
  /**
   * @private
   */
  constructor(t) {
    this.s = t.start, this.e = t.end, this.invalid = t.invalid || null, this.isLuxonInterval = !0;
  }
  /**
   * Create an invalid Interval.
   * @param {string} reason - simple string of why this Interval is invalid. Should not contain parameters or anything else data-dependent
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {Interval}
   */
  static invalid(t, n = null) {
    if (!t)
      throw new Me("need to specify a reason the Interval is invalid");
    const o = t instanceof ut ? t : new ut(t, n);
    if (le.throwOnInvalid)
      throw new Q2(o);
    return new ie({ invalid: o });
  }
  /**
   * Create an Interval from a start DateTime and an end DateTime. Inclusive of the start but not the end.
   * @param {DateTime|Date|Object} start
   * @param {DateTime|Date|Object} end
   * @return {Interval}
   */
  static fromDateTimes(t, n) {
    const o = yr(t), r = yr(n), s = SR(o, r);
    return s ?? new ie({
      start: o,
      end: r
    });
  }
  /**
   * Create an Interval from a start DateTime and a Duration to extend to.
   * @param {DateTime|Date|Object} start
   * @param {Duration|Object|number} duration - the length of the Interval.
   * @return {Interval}
   */
  static after(t, n) {
    const o = j.fromDurationLike(n), r = yr(t);
    return ie.fromDateTimes(r, r.plus(o));
  }
  /**
   * Create an Interval from an end DateTime and a Duration to extend backwards to.
   * @param {DateTime|Date|Object} end
   * @param {Duration|Object|number} duration - the length of the Interval.
   * @return {Interval}
   */
  static before(t, n) {
    const o = j.fromDurationLike(n), r = yr(t);
    return ie.fromDateTimes(r.minus(o), r);
  }
  /**
   * Create an Interval from an ISO 8601 string.
   * Accepts `<start>/<end>`, `<start>/<duration>`, and `<duration>/<end>` formats.
   * @param {string} text - the ISO string to parse
   * @param {Object} [opts] - options to pass {@link DateTime#fromISO} and optionally {@link Duration#fromISO}
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @return {Interval}
   */
  static fromISO(t, n) {
    const [o, r] = (t || "").split("/", 2);
    if (o && r) {
      let s, i;
      try {
        s = D.fromISO(o, n), i = s.isValid;
      } catch {
        i = !1;
      }
      let a, l;
      try {
        a = D.fromISO(r, n), l = a.isValid;
      } catch {
        l = !1;
      }
      if (i && l)
        return ie.fromDateTimes(s, a);
      if (i) {
        const c = j.fromISO(r, n);
        if (c.isValid)
          return ie.after(s, c);
      } else if (l) {
        const c = j.fromISO(o, n);
        if (c.isValid)
          return ie.before(a, c);
      }
    }
    return ie.invalid("unparsable", `the input "${t}" can't be parsed as ISO 8601`);
  }
  /**
   * Check if an object is an Interval. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isInterval(t) {
    return t && t.isLuxonInterval || !1;
  }
  /**
   * Returns the start of the Interval
   * @type {DateTime}
   */
  get start() {
    return this.isValid ? this.s : null;
  }
  /**
   * Returns the end of the Interval
   * @type {DateTime}
   */
  get end() {
    return this.isValid ? this.e : null;
  }
  /**
   * Returns whether this Interval's end is at least its start, meaning that the Interval isn't 'backwards'.
   * @type {boolean}
   */
  get isValid() {
    return this.invalidReason === null;
  }
  /**
   * Returns an error code if this Interval is invalid, or null if the Interval is valid
   * @type {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this Interval became invalid, or null if the Interval is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Returns the length of the Interval in the specified unit.
   * @param {string} unit - the unit (such as 'hours' or 'days') to return the length in.
   * @return {number}
   */
  length(t = "milliseconds") {
    return this.isValid ? this.toDuration(t).get(t) : NaN;
  }
  /**
   * Returns the count of minutes, hours, days, months, or years included in the Interval, even in part.
   * Unlike {@link Interval#length} this counts sections of the calendar, not periods of time, e.g. specifying 'day'
   * asks 'what dates are included in this interval?', not 'how many days long is this interval?'
   * @param {string} [unit='milliseconds'] - the unit of time to count.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week; this operation will always use the locale of the start DateTime
   * @return {number}
   */
  count(t = "milliseconds", n) {
    if (!this.isValid)
      return NaN;
    const o = this.start.startOf(t, n);
    let r;
    return n != null && n.useLocaleWeeks ? r = this.end.reconfigure({ locale: o.locale }) : r = this.end, r = r.startOf(t, n), Math.floor(r.diff(o, t).get(t)) + (r.valueOf() !== this.end.valueOf());
  }
  /**
   * Returns whether this Interval's start and end are both in the same unit of time
   * @param {string} unit - the unit of time to check sameness on
   * @return {boolean}
   */
  hasSame(t) {
    return this.isValid ? this.isEmpty() || this.e.minus(1).hasSame(this.s, t) : !1;
  }
  /**
   * Return whether this Interval has the same start and end DateTimes.
   * @return {boolean}
   */
  isEmpty() {
    return this.s.valueOf() === this.e.valueOf();
  }
  /**
   * Return whether this Interval's start is after the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  isAfter(t) {
    return this.isValid ? this.s > t : !1;
  }
  /**
   * Return whether this Interval's end is before the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  isBefore(t) {
    return this.isValid ? this.e <= t : !1;
  }
  /**
   * Return whether this Interval contains the specified DateTime.
   * @param {DateTime} dateTime
   * @return {boolean}
   */
  contains(t) {
    return this.isValid ? this.s <= t && this.e > t : !1;
  }
  /**
   * "Sets" the start and/or end dates. Returns a newly-constructed Interval.
   * @param {Object} values - the values to set
   * @param {DateTime} values.start - the starting DateTime
   * @param {DateTime} values.end - the ending DateTime
   * @return {Interval}
   */
  set({ start: t, end: n } = {}) {
    return this.isValid ? ie.fromDateTimes(t || this.s, n || this.e) : this;
  }
  /**
   * Split this Interval at each of the specified DateTimes
   * @param {...DateTime} dateTimes - the unit of time to count.
   * @return {Array}
   */
  splitAt(...t) {
    if (!this.isValid)
      return [];
    const n = t.map(yr).filter((i) => this.contains(i)).sort((i, a) => i.toMillis() - a.toMillis()), o = [];
    let { s: r } = this, s = 0;
    for (; r < this.e; ) {
      const i = n[s] || this.e, a = +i > +this.e ? this.e : i;
      o.push(ie.fromDateTimes(r, a)), r = a, s += 1;
    }
    return o;
  }
  /**
   * Split this Interval into smaller Intervals, each of the specified length.
   * Left over time is grouped into a smaller interval
   * @param {Duration|Object|number} duration - The length of each resulting interval.
   * @return {Array}
   */
  splitBy(t) {
    const n = j.fromDurationLike(t);
    if (!this.isValid || !n.isValid || n.as("milliseconds") === 0)
      return [];
    let { s: o } = this, r = 1, s;
    const i = [];
    for (; o < this.e; ) {
      const a = this.start.plus(n.mapUnits((l) => l * r));
      s = +a > +this.e ? this.e : a, i.push(ie.fromDateTimes(o, s)), o = s, r += 1;
    }
    return i;
  }
  /**
   * Split this Interval into the specified number of smaller intervals.
   * @param {number} numberOfParts - The number of Intervals to divide the Interval into.
   * @return {Array}
   */
  divideEqually(t) {
    return this.isValid ? this.splitBy(this.length() / t).slice(0, t) : [];
  }
  /**
   * Return whether this Interval overlaps with the specified Interval
   * @param {Interval} other
   * @return {boolean}
   */
  overlaps(t) {
    return this.e > t.s && this.s < t.e;
  }
  /**
   * Return whether this Interval's end is adjacent to the specified Interval's start.
   * @param {Interval} other
   * @return {boolean}
   */
  abutsStart(t) {
    return this.isValid ? +this.e == +t.s : !1;
  }
  /**
   * Return whether this Interval's start is adjacent to the specified Interval's end.
   * @param {Interval} other
   * @return {boolean}
   */
  abutsEnd(t) {
    return this.isValid ? +t.e == +this.s : !1;
  }
  /**
   * Return whether this Interval engulfs the start and end of the specified Interval.
   * @param {Interval} other
   * @return {boolean}
   */
  engulfs(t) {
    return this.isValid ? this.s <= t.s && this.e >= t.e : !1;
  }
  /**
   * Return whether this Interval has the same start and end as the specified Interval.
   * @param {Interval} other
   * @return {boolean}
   */
  equals(t) {
    return !this.isValid || !t.isValid ? !1 : this.s.equals(t.s) && this.e.equals(t.e);
  }
  /**
   * Return an Interval representing the intersection of this Interval and the specified Interval.
   * Specifically, the resulting Interval has the maximum start time and the minimum end time of the two Intervals.
   * Returns null if the intersection is empty, meaning, the intervals don't intersect.
   * @param {Interval} other
   * @return {Interval}
   */
  intersection(t) {
    if (!this.isValid)
      return this;
    const n = this.s > t.s ? this.s : t.s, o = this.e < t.e ? this.e : t.e;
    return n >= o ? null : ie.fromDateTimes(n, o);
  }
  /**
   * Return an Interval representing the union of this Interval and the specified Interval.
   * Specifically, the resulting Interval has the minimum start time and the maximum end time of the two Intervals.
   * @param {Interval} other
   * @return {Interval}
   */
  union(t) {
    if (!this.isValid)
      return this;
    const n = this.s < t.s ? this.s : t.s, o = this.e > t.e ? this.e : t.e;
    return ie.fromDateTimes(n, o);
  }
  /**
   * Merge an array of Intervals into a equivalent minimal set of Intervals.
   * Combines overlapping and adjacent Intervals.
   * @param {Array} intervals
   * @return {Array}
   */
  static merge(t) {
    const [n, o] = t.sort((r, s) => r.s - s.s).reduce(
      ([r, s], i) => s ? s.overlaps(i) || s.abutsStart(i) ? [r, s.union(i)] : [r.concat([s]), i] : [r, i],
      [[], null]
    );
    return o && n.push(o), n;
  }
  /**
   * Return an array of Intervals representing the spans of time that only appear in one of the specified Intervals.
   * @param {Array} intervals
   * @return {Array}
   */
  static xor(t) {
    let n = null, o = 0;
    const r = [], s = t.map((l) => [
      { time: l.s, type: "s" },
      { time: l.e, type: "e" }
    ]), i = Array.prototype.concat(...s), a = i.sort((l, c) => l.time - c.time);
    for (const l of a)
      o += l.type === "s" ? 1 : -1, o === 1 ? n = l.time : (n && +n != +l.time && r.push(ie.fromDateTimes(n, l.time)), n = null);
    return ie.merge(r);
  }
  /**
   * Return an Interval representing the span of time in this Interval that doesn't overlap with any of the specified Intervals.
   * @param {...Interval} intervals
   * @return {Array}
   */
  difference(...t) {
    return ie.xor([this].concat(t)).map((n) => this.intersection(n)).filter((n) => n && !n.isEmpty());
  }
  /**
   * Returns a string representation of this Interval appropriate for debugging.
   * @return {string}
   */
  toString() {
    return this.isValid ? `[${this.s.toISO()} – ${this.e.toISO()})` : eo;
  }
  /**
   * Returns a string representation of this Interval appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `Interval { start: ${this.s.toISO()}, end: ${this.e.toISO()} }` : `Interval { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns a localized string representing this Interval. Accepts the same options as the
   * Intl.DateTimeFormat constructor and any presets defined by Luxon, such as
   * {@link DateTime.DATE_FULL} or {@link DateTime.TIME_SIMPLE}. The exact behavior of this method
   * is browser-specific, but in general it will return an appropriate representation of the
   * Interval in the assigned locale. Defaults to the system's locale if no locale has been
   * specified.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {Object} [formatOpts=DateTime.DATE_SHORT] - Either a DateTime preset or
   * Intl.DateTimeFormat constructor options.
   * @param {Object} opts - Options to override the configuration of the start DateTime.
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(); //=> 11/7/2022 – 11/8/2022
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(DateTime.DATE_FULL); //=> November 7 – 8, 2022
   * @example Interval.fromISO('2022-11-07T09:00Z/2022-11-08T09:00Z').toLocaleString(DateTime.DATE_FULL, { locale: 'fr-FR' }); //=> 7–8 novembre 2022
   * @example Interval.fromISO('2022-11-07T17:00Z/2022-11-07T19:00Z').toLocaleString(DateTime.TIME_SIMPLE); //=> 6:00 – 8:00 PM
   * @example Interval.fromISO('2022-11-07T17:00Z/2022-11-07T19:00Z').toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }); //=> Mon, Nov 07, 6:00 – 8:00 p
   * @return {string}
   */
  toLocaleString(t = Xi, n = {}) {
    return this.isValid ? Te.create(this.s.loc.clone(n), t).formatInterval(this) : eo;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this Interval.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @param {Object} opts - The same options as {@link DateTime#toISO}
   * @return {string}
   */
  toISO(t) {
    return this.isValid ? `${this.s.toISO(t)}/${this.e.toISO(t)}` : eo;
  }
  /**
   * Returns an ISO 8601-compliant string representation of date of this Interval.
   * The time components are ignored.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @return {string}
   */
  toISODate() {
    return this.isValid ? `${this.s.toISODate()}/${this.e.toISODate()}` : eo;
  }
  /**
   * Returns an ISO 8601-compliant string representation of time of this Interval.
   * The date components are ignored.
   * @see https://en.wikipedia.org/wiki/ISO_8601#Time_intervals
   * @param {Object} opts - The same options as {@link DateTime#toISO}
   * @return {string}
   */
  toISOTime(t) {
    return this.isValid ? `${this.s.toISOTime(t)}/${this.e.toISOTime(t)}` : eo;
  }
  /**
   * Returns a string representation of this Interval formatted according to the specified format
   * string. **You may not want this.** See {@link Interval#toLocaleString} for a more flexible
   * formatting tool.
   * @param {string} dateFormat - The format string. This string formats the start and end time.
   * See {@link DateTime#toFormat} for details.
   * @param {Object} opts - Options.
   * @param {string} [opts.separator =  ' – '] - A separator to place between the start and end
   * representations.
   * @return {string}
   */
  toFormat(t, { separator: n = " – " } = {}) {
    return this.isValid ? `${this.s.toFormat(t)}${n}${this.e.toFormat(t)}` : eo;
  }
  /**
   * Return a Duration representing the time spanned by this interval.
   * @param {string|string[]} [unit=['milliseconds']] - the unit or units (such as 'hours' or 'days') to include in the duration.
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @example Interval.fromDateTimes(dt1, dt2).toDuration().toObject() //=> { milliseconds: 88489257 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration('days').toObject() //=> { days: 1.0241812152777778 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration(['hours', 'minutes']).toObject() //=> { hours: 24, minutes: 34.82095 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration(['hours', 'minutes', 'seconds']).toObject() //=> { hours: 24, minutes: 34, seconds: 49.257 }
   * @example Interval.fromDateTimes(dt1, dt2).toDuration('seconds').toObject() //=> { seconds: 88489.257 }
   * @return {Duration}
   */
  toDuration(t, n) {
    return this.isValid ? this.e.diff(this.s, t, n) : j.invalid(this.invalidReason);
  }
  /**
   * Run mapFn on the interval start and end, returning a new Interval from the resulting DateTimes
   * @param {function} mapFn
   * @return {Interval}
   * @example Interval.fromDateTimes(dt1, dt2).mapEndpoints(endpoint => endpoint.toUTC())
   * @example Interval.fromDateTimes(dt1, dt2).mapEndpoints(endpoint => endpoint.plus({ hours: 2 }))
   */
  mapEndpoints(t) {
    return ie.fromDateTimes(t(this.s), t(this.e));
  }
}
class js {
  /**
   * Return whether the specified zone contains a DST.
   * @param {string|Zone} [zone='local'] - Zone to check. Defaults to the environment's local zone.
   * @return {boolean}
   */
  static hasDST(t = le.defaultZone) {
    const n = D.now().setZone(t).set({ month: 12 });
    return !t.isUniversal && n.offset !== n.set({ month: 6 }).offset;
  }
  /**
   * Return whether the specified zone is a valid IANA specifier.
   * @param {string} zone - Zone to check
   * @return {boolean}
   */
  static isValidIANAZone(t) {
    return qt.isValidZone(t);
  }
  /**
   * Converts the input into a {@link Zone} instance.
   *
   * * If `input` is already a Zone instance, it is returned unchanged.
   * * If `input` is a string containing a valid time zone name, a Zone instance
   *   with that name is returned.
   * * If `input` is a string that doesn't refer to a known time zone, a Zone
   *   instance with {@link Zone#isValid} == false is returned.
   * * If `input is a number, a Zone instance with the specified fixed offset
   *   in minutes is returned.
   * * If `input` is `null` or `undefined`, the default zone is returned.
   * @param {string|Zone|number} [input] - the value to be converted
   * @return {Zone}
   */
  static normalizeZone(t) {
    return rn(t, le.defaultZone);
  }
  /**
   * Get the weekday on which the week starts according to the given locale.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number} the start of the week, 1 for Monday through 7 for Sunday
   */
  static getStartOfWeek({ locale: t = null, locObj: n = null } = {}) {
    return (n || V.create(t)).getStartOfWeek();
  }
  /**
   * Get the minimum number of days necessary in a week before it is considered part of the next year according
   * to the given locale.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number}
   */
  static getMinimumDaysInFirstWeek({ locale: t = null, locObj: n = null } = {}) {
    return (n || V.create(t)).getMinDaysInFirstWeek();
  }
  /**
   * Get the weekdays, which are considered the weekend according to the given locale
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @returns {number[]} an array of weekdays, 1 for Monday through 7 for Sunday
   */
  static getWeekendWeekdays({ locale: t = null, locObj: n = null } = {}) {
    return (n || V.create(t)).getWeekendDays().slice();
  }
  /**
   * Return an array of standalone month names.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {string} [length='long'] - the length of the month representation, such as "numeric", "2-digit", "narrow", "short", "long"
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @param {string} [opts.outputCalendar='gregory'] - the calendar
   * @example Info.months()[0] //=> 'January'
   * @example Info.months('short')[0] //=> 'Jan'
   * @example Info.months('numeric')[0] //=> '1'
   * @example Info.months('short', { locale: 'fr-CA' } )[0] //=> 'janv.'
   * @example Info.months('numeric', { locale: 'ar' })[0] //=> '١'
   * @example Info.months('long', { outputCalendar: 'islamic' })[0] //=> 'Rabiʻ I'
   * @return {Array}
   */
  static months(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null, outputCalendar: s = "gregory" } = {}) {
    return (r || V.create(n, o, s)).months(t);
  }
  /**
   * Return an array of format month names.
   * Format months differ from standalone months in that they're meant to appear next to the day of the month. In some languages, that
   * changes the string.
   * See {@link Info#months}
   * @param {string} [length='long'] - the length of the month representation, such as "numeric", "2-digit", "narrow", "short", "long"
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @param {string} [opts.outputCalendar='gregory'] - the calendar
   * @return {Array}
   */
  static monthsFormat(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null, outputCalendar: s = "gregory" } = {}) {
    return (r || V.create(n, o, s)).months(t, !0);
  }
  /**
   * Return an array of standalone week names.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param {string} [length='long'] - the length of the weekday representation, such as "narrow", "short", "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @example Info.weekdays()[0] //=> 'Monday'
   * @example Info.weekdays('short')[0] //=> 'Mon'
   * @example Info.weekdays('short', { locale: 'fr-CA' })[0] //=> 'lun.'
   * @example Info.weekdays('short', { locale: 'ar' })[0] //=> 'الاثنين'
   * @return {Array}
   */
  static weekdays(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null } = {}) {
    return (r || V.create(n, o, null)).weekdays(t);
  }
  /**
   * Return an array of format week names.
   * Format weekdays differ from standalone weekdays in that they're meant to appear next to more date information. In some languages, that
   * changes the string.
   * See {@link Info#weekdays}
   * @param {string} [length='long'] - the length of the month representation, such as "narrow", "short", "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale=null] - the locale code
   * @param {string} [opts.numberingSystem=null] - the numbering system
   * @param {string} [opts.locObj=null] - an existing locale object to use
   * @return {Array}
   */
  static weekdaysFormat(t = "long", { locale: n = null, numberingSystem: o = null, locObj: r = null } = {}) {
    return (r || V.create(n, o, null)).weekdays(t, !0);
  }
  /**
   * Return an array of meridiems.
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @example Info.meridiems() //=> [ 'AM', 'PM' ]
   * @example Info.meridiems({ locale: 'my' }) //=> [ 'နံနက်', 'ညနေ' ]
   * @return {Array}
   */
  static meridiems({ locale: t = null } = {}) {
    return V.create(t).meridiems();
  }
  /**
   * Return an array of eras, such as ['BC', 'AD']. The locale can be specified, but the calendar system is always Gregorian.
   * @param {string} [length='short'] - the length of the era representation, such as "short" or "long".
   * @param {Object} opts - options
   * @param {string} [opts.locale] - the locale code
   * @example Info.eras() //=> [ 'BC', 'AD' ]
   * @example Info.eras('long') //=> [ 'Before Christ', 'Anno Domini' ]
   * @example Info.eras('long', { locale: 'fr' }) //=> [ 'avant Jésus-Christ', 'après Jésus-Christ' ]
   * @return {Array}
   */
  static eras(t = "short", { locale: n = null } = {}) {
    return V.create(n, null, "gregory").eras(t);
  }
  /**
   * Return the set of available features in this environment.
   * Some features of Luxon are not available in all environments. For example, on older browsers, relative time formatting support is not available. Use this function to figure out if that's the case.
   * Keys:
   * * `relative`: whether this environment supports relative time formatting
   * * `localeWeek`: whether this environment supports different weekdays for the start of the week based on the locale
   * @example Info.features() //=> { relative: false, localeWeek: true }
   * @return {Object}
   */
  static features() {
    return { relative: Ay(), localeWeek: My() };
  }
}
function of(e, t) {
  const n = (r) => r.toUTC(0, { keepLocalTime: !0 }).startOf("day").valueOf(), o = n(t) - n(e);
  return Math.floor(j.fromMillis(o).as("days"));
}
function xR(e, t, n) {
  const o = [
    ["years", (l, c) => c.year - l.year],
    ["quarters", (l, c) => c.quarter - l.quarter + (c.year - l.year) * 4],
    ["months", (l, c) => c.month - l.month + (c.year - l.year) * 12],
    [
      "weeks",
      (l, c) => {
        const u = of(l, c);
        return (u - u % 7) / 7;
      }
    ],
    ["days", of]
  ], r = {}, s = e;
  let i, a;
  for (const [l, c] of o)
    n.indexOf(l) >= 0 && (i = l, r[l] = c(e, t), a = s.plus(r), a > t ? (r[l]--, e = s.plus(r), e > t && (a = e, r[l]--, e = s.plus(r))) : e = a);
  return [e, r, a, i];
}
function AR(e, t, n, o) {
  let [r, s, i, a] = xR(e, t, n);
  const l = t - r, c = n.filter(
    (d) => ["hours", "minutes", "seconds", "milliseconds"].indexOf(d) >= 0
  );
  c.length === 0 && (i < t && (i = r.plus({ [a]: 1 })), i !== r && (s[a] = (s[a] || 0) + l / (i - r)));
  const u = j.fromObject(s, o);
  return c.length > 0 ? j.fromMillis(l, o).shiftTo(...c).plus(u) : u;
}
const gu = {
  arab: "[٠-٩]",
  arabext: "[۰-۹]",
  bali: "[᭐-᭙]",
  beng: "[০-৯]",
  deva: "[०-९]",
  fullwide: "[０-９]",
  gujr: "[૦-૯]",
  hanidec: "[〇|一|二|三|四|五|六|七|八|九]",
  khmr: "[០-៩]",
  knda: "[೦-೯]",
  laoo: "[໐-໙]",
  limb: "[᥆-᥏]",
  mlym: "[൦-൯]",
  mong: "[᠐-᠙]",
  mymr: "[၀-၉]",
  orya: "[୦-୯]",
  tamldec: "[௦-௯]",
  telu: "[౦-౯]",
  thai: "[๐-๙]",
  tibt: "[༠-༩]",
  latn: "\\d"
}, rf = {
  arab: [1632, 1641],
  arabext: [1776, 1785],
  bali: [6992, 7001],
  beng: [2534, 2543],
  deva: [2406, 2415],
  fullwide: [65296, 65303],
  gujr: [2790, 2799],
  khmr: [6112, 6121],
  knda: [3302, 3311],
  laoo: [3792, 3801],
  limb: [6470, 6479],
  mlym: [3430, 3439],
  mong: [6160, 6169],
  mymr: [4160, 4169],
  orya: [2918, 2927],
  tamldec: [3046, 3055],
  telu: [3174, 3183],
  thai: [3664, 3673],
  tibt: [3872, 3881]
}, MR = gu.hanidec.replace(/[\[|\]]/g, "").split("");
function NR(e) {
  let t = parseInt(e, 10);
  if (isNaN(t)) {
    t = "";
    for (let n = 0; n < e.length; n++) {
      const o = e.charCodeAt(n);
      if (e[n].search(gu.hanidec) !== -1)
        t += MR.indexOf(e[n]);
      else
        for (const r in rf) {
          const [s, i] = rf[r];
          o >= s && o <= i && (t += o - s);
        }
    }
    return parseInt(t, 10);
  } else
    return t;
}
function it({ numberingSystem: e }, t = "") {
  return new RegExp(`${gu[e || "latn"]}${t}`);
}
const $R = "missing Intl.DateTimeFormat.formatToParts support";
function H(e, t = (n) => n) {
  return { regex: e, deser: ([n]) => t(NR(n)) };
}
const kR = " ", zy = `[ ${kR}]`, Gy = new RegExp(zy, "g");
function DR(e) {
  return e.replace(/\./g, "\\.?").replace(Gy, zy);
}
function sf(e) {
  return e.replace(/\./g, "").replace(Gy, " ").toLowerCase();
}
function at(e, t) {
  return e === null ? null : {
    regex: RegExp(e.map(DR).join("|")),
    deser: ([n]) => e.findIndex((o) => sf(n) === sf(o)) + t
  };
}
function af(e, t) {
  return { regex: e, deser: ([, n, o]) => Ba(n, o), groups: t };
}
function Fs(e) {
  return { regex: e, deser: ([t]) => t };
}
function RR(e) {
  return e.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, "\\$&");
}
function LR(e, t) {
  const n = it(t), o = it(t, "{2}"), r = it(t, "{3}"), s = it(t, "{4}"), i = it(t, "{6}"), a = it(t, "{1,2}"), l = it(t, "{1,3}"), c = it(t, "{1,6}"), u = it(t, "{1,9}"), d = it(t, "{2,4}"), f = it(t, "{4,6}"), m = (v) => ({ regex: RegExp(RR(v.val)), deser: ([T]) => T, literal: !0 }), g = ((v) => {
    if (e.literal)
      return m(v);
    switch (v.val) {
      case "G":
        return at(t.eras("short"), 0);
      case "GG":
        return at(t.eras("long"), 0);
      case "y":
        return H(c);
      case "yy":
        return H(d, Hl);
      case "yyyy":
        return H(s);
      case "yyyyy":
        return H(f);
      case "yyyyyy":
        return H(i);
      case "M":
        return H(a);
      case "MM":
        return H(o);
      case "MMM":
        return at(t.months("short", !0), 1);
      case "MMMM":
        return at(t.months("long", !0), 1);
      case "L":
        return H(a);
      case "LL":
        return H(o);
      case "LLL":
        return at(t.months("short", !1), 1);
      case "LLLL":
        return at(t.months("long", !1), 1);
      case "d":
        return H(a);
      case "dd":
        return H(o);
      case "o":
        return H(l);
      case "ooo":
        return H(r);
      case "HH":
        return H(o);
      case "H":
        return H(a);
      case "hh":
        return H(o);
      case "h":
        return H(a);
      case "mm":
        return H(o);
      case "m":
        return H(a);
      case "q":
        return H(a);
      case "qq":
        return H(o);
      case "s":
        return H(a);
      case "ss":
        return H(o);
      case "S":
        return H(l);
      case "SSS":
        return H(r);
      case "u":
        return Fs(u);
      case "uu":
        return Fs(a);
      case "uuu":
        return H(n);
      case "a":
        return at(t.meridiems(), 0);
      case "kkkk":
        return H(s);
      case "kk":
        return H(d, Hl);
      case "W":
        return H(a);
      case "WW":
        return H(o);
      case "E":
      case "c":
        return H(n);
      case "EEE":
        return at(t.weekdays("short", !1), 1);
      case "EEEE":
        return at(t.weekdays("long", !1), 1);
      case "ccc":
        return at(t.weekdays("short", !0), 1);
      case "cccc":
        return at(t.weekdays("long", !0), 1);
      case "Z":
      case "ZZ":
        return af(new RegExp(`([+-]${a.source})(?::(${o.source}))?`), 2);
      case "ZZZ":
        return af(new RegExp(`([+-]${a.source})(${o.source})?`), 2);
      case "z":
        return Fs(/[a-z_+-/]{1,256}?/i);
      case " ":
        return Fs(/[^\S\n\r]/);
      default:
        return m(v);
    }
  })(e) || {
    invalidReason: $R
  };
  return g.token = e, g;
}
const PR = {
  year: {
    "2-digit": "yy",
    numeric: "yyyyy"
  },
  month: {
    numeric: "M",
    "2-digit": "MM",
    short: "MMM",
    long: "MMMM"
  },
  day: {
    numeric: "d",
    "2-digit": "dd"
  },
  weekday: {
    short: "EEE",
    long: "EEEE"
  },
  dayperiod: "a",
  dayPeriod: "a",
  hour12: {
    numeric: "h",
    "2-digit": "hh"
  },
  hour24: {
    numeric: "H",
    "2-digit": "HH"
  },
  minute: {
    numeric: "m",
    "2-digit": "mm"
  },
  second: {
    numeric: "s",
    "2-digit": "ss"
  },
  timeZoneName: {
    long: "ZZZZZ",
    short: "ZZZ"
  }
};
function _R(e, t, n) {
  const { type: o, value: r } = e;
  if (o === "literal") {
    const l = /^\s+$/.test(r);
    return {
      literal: !l,
      val: l ? " " : r
    };
  }
  const s = t[o];
  let i = o;
  o === "hour" && (t.hour12 != null ? i = t.hour12 ? "hour12" : "hour24" : t.hourCycle != null ? t.hourCycle === "h11" || t.hourCycle === "h12" ? i = "hour12" : i = "hour24" : i = n.hour12 ? "hour12" : "hour24");
  let a = PR[i];
  if (typeof a == "object" && (a = a[s]), a)
    return {
      literal: !1,
      val: a
    };
}
function BR(e) {
  return [`^${e.map((n) => n.regex).reduce((n, o) => `${n}(${o.source})`, "")}$`, e];
}
function WR(e, t, n) {
  const o = e.match(t);
  if (o) {
    const r = {};
    let s = 1;
    for (const i in n)
      if (Bo(n, i)) {
        const a = n[i], l = a.groups ? a.groups + 1 : 1;
        !a.literal && a.token && (r[a.token.val[0]] = a.deser(o.slice(s, s + l))), s += l;
      }
    return [o, r];
  } else
    return [o, {}];
}
function UR(e) {
  const t = (s) => {
    switch (s) {
      case "S":
        return "millisecond";
      case "s":
        return "second";
      case "m":
        return "minute";
      case "h":
      case "H":
        return "hour";
      case "d":
        return "day";
      case "o":
        return "ordinal";
      case "L":
      case "M":
        return "month";
      case "y":
        return "year";
      case "E":
      case "c":
        return "weekday";
      case "W":
        return "weekNumber";
      case "k":
        return "weekYear";
      case "q":
        return "quarter";
      default:
        return null;
    }
  };
  let n = null, o;
  return N(e.z) || (n = qt.create(e.z)), N(e.Z) || (n || (n = new Ee(e.Z)), o = e.Z), N(e.q) || (e.M = (e.q - 1) * 3 + 1), N(e.h) || (e.h < 12 && e.a === 1 ? e.h += 12 : e.h === 12 && e.a === 0 && (e.h = 0)), e.G === 0 && e.y && (e.y = -e.y), N(e.u) || (e.S = fu(e.u)), [Object.keys(e).reduce((s, i) => {
    const a = t(i);
    return a && (s[a] = e[i]), s;
  }, {}), n, o];
}
let rl = null;
function jR() {
  return rl || (rl = D.fromMillis(1555555555555)), rl;
}
function FR(e, t) {
  if (e.literal)
    return e;
  const n = Te.macroTokenToFormatOpts(e.val), o = Qy(n, t);
  return o == null || o.includes(void 0) ? e : o;
}
function Yy(e, t) {
  return Array.prototype.concat(...e.map((n) => FR(n, t)));
}
function Ky(e, t, n) {
  const o = Yy(Te.parseFormat(n), e), r = o.map((i) => LR(i, e)), s = r.find((i) => i.invalidReason);
  if (s)
    return { input: t, tokens: o, invalidReason: s.invalidReason };
  {
    const [i, a] = BR(r), l = RegExp(i, "i"), [c, u] = WR(t, l, a), [d, f, m] = u ? UR(u) : [null, null, void 0];
    if (Bo(u, "a") && Bo(u, "H"))
      throw new yo(
        "Can't include meridiem when specifying 24-hour format"
      );
    return { input: t, tokens: o, regex: l, rawMatches: c, matches: u, result: d, zone: f, specificOffset: m };
  }
}
function qR(e, t, n) {
  const { result: o, zone: r, specificOffset: s, invalidReason: i } = Ky(e, t, n);
  return [o, r, s, i];
}
function Qy(e, t) {
  if (!e)
    return null;
  const o = Te.create(t, e).dtFormatter(jR()), r = o.formatToParts(), s = o.resolvedOptions();
  return r.map((i) => _R(i, e, s));
}
const sl = "Invalid DateTime", lf = 864e13;
function qs(e) {
  return new ut("unsupported zone", `the zone "${e.name}" is not supported`);
}
function il(e) {
  return e.weekData === null && (e.weekData = ea(e.c)), e.weekData;
}
function al(e) {
  return e.localWeekData === null && (e.localWeekData = ea(
    e.c,
    e.loc.getMinDaysInFirstWeek(),
    e.loc.getStartOfWeek()
  )), e.localWeekData;
}
function gn(e, t) {
  const n = {
    ts: e.ts,
    zone: e.zone,
    c: e.c,
    o: e.o,
    loc: e.loc,
    invalid: e.invalid
  };
  return new D({ ...n, ...t, old: n });
}
function Jy(e, t, n) {
  let o = e - t * 60 * 1e3;
  const r = n.offset(o);
  if (t === r)
    return [o, t];
  o -= (r - t) * 60 * 1e3;
  const s = n.offset(o);
  return r === s ? [o, r] : [e - Math.min(r, s) * 60 * 1e3, Math.max(r, s)];
}
function Hs(e, t) {
  e += t * 60 * 1e3;
  const n = new Date(e);
  return {
    year: n.getUTCFullYear(),
    month: n.getUTCMonth() + 1,
    day: n.getUTCDate(),
    hour: n.getUTCHours(),
    minute: n.getUTCMinutes(),
    second: n.getUTCSeconds(),
    millisecond: n.getUTCMilliseconds()
  };
}
function $i(e, t, n) {
  return Jy(_a(e), t, n);
}
function cf(e, t) {
  const n = e.o, o = e.c.year + Math.trunc(t.years), r = e.c.month + Math.trunc(t.months) + Math.trunc(t.quarters) * 3, s = {
    ...e.c,
    year: o,
    month: r,
    day: Math.min(e.c.day, ta(o, r)) + Math.trunc(t.days) + Math.trunc(t.weeks) * 7
  }, i = j.fromObject({
    years: t.years - Math.trunc(t.years),
    quarters: t.quarters - Math.trunc(t.quarters),
    months: t.months - Math.trunc(t.months),
    weeks: t.weeks - Math.trunc(t.weeks),
    days: t.days - Math.trunc(t.days),
    hours: t.hours,
    minutes: t.minutes,
    seconds: t.seconds,
    milliseconds: t.milliseconds
  }).as("milliseconds"), a = _a(s);
  let [l, c] = Jy(a, n, e.zone);
  return i !== 0 && (l += i, c = e.zone.offset(l)), { ts: l, o: c };
}
function pr(e, t, n, o, r, s) {
  const { setZone: i, zone: a } = n;
  if (e && Object.keys(e).length !== 0 || t) {
    const l = t || a, c = D.fromObject(e, {
      ...n,
      zone: l,
      specificOffset: s
    });
    return i ? c : c.setZone(a);
  } else
    return D.invalid(
      new ut("unparsable", `the input "${r}" can't be parsed as ${o}`)
    );
}
function Zs(e, t, n = !0) {
  return e.isValid ? Te.create(V.create("en-US"), {
    allowZ: n,
    forceSimple: !0
  }).formatDateTimeFromString(e, t) : null;
}
function ll(e, t) {
  const n = e.c.year > 9999 || e.c.year < 0;
  let o = "";
  return n && e.c.year >= 0 && (o += "+"), o += de(e.c.year, n ? 6 : 4), t ? (o += "-", o += de(e.c.month), o += "-", o += de(e.c.day)) : (o += de(e.c.month), o += de(e.c.day)), o;
}
function uf(e, t, n, o, r, s) {
  let i = de(e.c.hour);
  return t ? (i += ":", i += de(e.c.minute), (e.c.millisecond !== 0 || e.c.second !== 0 || !n) && (i += ":")) : i += de(e.c.minute), (e.c.millisecond !== 0 || e.c.second !== 0 || !n) && (i += de(e.c.second), (e.c.millisecond !== 0 || !o) && (i += ".", i += de(e.c.millisecond, 3))), r && (e.isOffsetFixed && e.offset === 0 && !s ? i += "Z" : e.o < 0 ? (i += "-", i += de(Math.trunc(-e.o / 60)), i += ":", i += de(Math.trunc(-e.o % 60))) : (i += "+", i += de(Math.trunc(e.o / 60)), i += ":", i += de(Math.trunc(e.o % 60)))), s && (i += "[" + e.zone.ianaName + "]"), i;
}
const Xy = {
  month: 1,
  day: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, HR = {
  weekNumber: 1,
  weekday: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, ZR = {
  ordinal: 1,
  hour: 0,
  minute: 0,
  second: 0,
  millisecond: 0
}, eg = ["year", "month", "day", "hour", "minute", "second", "millisecond"], VR = [
  "weekYear",
  "weekNumber",
  "weekday",
  "hour",
  "minute",
  "second",
  "millisecond"
], zR = ["year", "ordinal", "hour", "minute", "second", "millisecond"];
function GR(e) {
  const t = {
    year: "year",
    years: "year",
    month: "month",
    months: "month",
    day: "day",
    days: "day",
    hour: "hour",
    hours: "hour",
    minute: "minute",
    minutes: "minute",
    quarter: "quarter",
    quarters: "quarter",
    second: "second",
    seconds: "second",
    millisecond: "millisecond",
    milliseconds: "millisecond",
    weekday: "weekday",
    weekdays: "weekday",
    weeknumber: "weekNumber",
    weeksnumber: "weekNumber",
    weeknumbers: "weekNumber",
    weekyear: "weekYear",
    weekyears: "weekYear",
    ordinal: "ordinal"
  }[e.toLowerCase()];
  if (!t)
    throw new ny(e);
  return t;
}
function df(e) {
  switch (e.toLowerCase()) {
    case "localweekday":
    case "localweekdays":
      return "localWeekday";
    case "localweeknumber":
    case "localweeknumbers":
      return "localWeekNumber";
    case "localweekyear":
    case "localweekyears":
      return "localWeekYear";
    default:
      return GR(e);
  }
}
function ff(e, t) {
  const n = rn(t.zone, le.defaultZone), o = V.fromObject(t), r = le.now();
  let s, i;
  if (N(e.year))
    s = r;
  else {
    for (const c of eg)
      N(e[c]) && (e[c] = Xy[c]);
    const a = Sy(e) || xy(e);
    if (a)
      return D.invalid(a);
    const l = n.offset(r);
    [s, i] = $i(e, l, n);
  }
  return new D({ ts: s, zone: n, loc: o, o: i });
}
function mf(e, t, n) {
  const o = N(n.round) ? !0 : n.round, r = (i, a) => (i = mu(i, o || n.calendary ? 0 : 2, !0), t.loc.clone(n).relFormatter(n).format(i, a)), s = (i) => n.calendary ? t.hasSame(e, i) ? 0 : t.startOf(i).diff(e.startOf(i), i).get(i) : t.diff(e, i).get(i);
  if (n.unit)
    return r(s(n.unit), n.unit);
  for (const i of n.units) {
    const a = s(i);
    if (Math.abs(a) >= 1)
      return r(a, i);
  }
  return r(e > t ? -0 : 0, n.units[n.units.length - 1]);
}
function hf(e) {
  let t = {}, n;
  return e.length > 0 && typeof e[e.length - 1] == "object" ? (t = e[e.length - 1], n = Array.from(e).slice(0, e.length - 1)) : n = Array.from(e), [t, n];
}
class D {
  /**
   * @access private
   */
  constructor(t) {
    const n = t.zone || le.defaultZone;
    let o = t.invalid || (Number.isNaN(t.ts) ? new ut("invalid input") : null) || (n.isValid ? null : qs(n));
    this.ts = N(t.ts) ? le.now() : t.ts;
    let r = null, s = null;
    if (!o)
      if (t.old && t.old.ts === this.ts && t.old.zone.equals(n))
        [r, s] = [t.old.c, t.old.o];
      else {
        const a = n.offset(this.ts);
        r = Hs(this.ts, a), o = Number.isNaN(r.year) ? new ut("invalid input") : null, r = o ? null : r, s = o ? null : a;
      }
    this._zone = n, this.loc = t.loc || V.create(), this.invalid = o, this.weekData = null, this.localWeekData = null, this.c = r, this.o = s, this.isLuxonDateTime = !0;
  }
  // CONSTRUCT
  /**
   * Create a DateTime for the current instant, in the system's time zone.
   *
   * Use Settings to override these default values if needed.
   * @example DateTime.now().toISO() //~> now in the ISO format
   * @return {DateTime}
   */
  static now() {
    return new D({});
  }
  /**
   * Create a local DateTime
   * @param {number} [year] - The calendar year. If omitted (as in, call `local()` with no arguments), the current time will be used
   * @param {number} [month=1] - The month, 1-indexed
   * @param {number} [day=1] - The day of the month, 1-indexed
   * @param {number} [hour=0] - The hour of the day, in 24-hour time
   * @param {number} [minute=0] - The minute of the hour, meaning a number between 0 and 59
   * @param {number} [second=0] - The second of the minute, meaning a number between 0 and 59
   * @param {number} [millisecond=0] - The millisecond of the second, meaning a number between 0 and 999
   * @example DateTime.local()                                  //~> now
   * @example DateTime.local({ zone: "America/New_York" })      //~> now, in US east coast time
   * @example DateTime.local(2017)                              //~> 2017-01-01T00:00:00
   * @example DateTime.local(2017, 3)                           //~> 2017-03-01T00:00:00
   * @example DateTime.local(2017, 3, 12, { locale: "fr" })     //~> 2017-03-12T00:00:00, with a French locale
   * @example DateTime.local(2017, 3, 12, 5)                    //~> 2017-03-12T05:00:00
   * @example DateTime.local(2017, 3, 12, 5, { zone: "utc" })   //~> 2017-03-12T05:00:00, in UTC
   * @example DateTime.local(2017, 3, 12, 5, 45)                //~> 2017-03-12T05:45:00
   * @example DateTime.local(2017, 3, 12, 5, 45, 10)            //~> 2017-03-12T05:45:10
   * @example DateTime.local(2017, 3, 12, 5, 45, 10, 765)       //~> 2017-03-12T05:45:10.765
   * @return {DateTime}
   */
  static local() {
    const [t, n] = hf(arguments), [o, r, s, i, a, l, c] = n;
    return ff({ year: o, month: r, day: s, hour: i, minute: a, second: l, millisecond: c }, t);
  }
  /**
   * Create a DateTime in UTC
   * @param {number} [year] - The calendar year. If omitted (as in, call `utc()` with no arguments), the current time will be used
   * @param {number} [month=1] - The month, 1-indexed
   * @param {number} [day=1] - The day of the month
   * @param {number} [hour=0] - The hour of the day, in 24-hour time
   * @param {number} [minute=0] - The minute of the hour, meaning a number between 0 and 59
   * @param {number} [second=0] - The second of the minute, meaning a number between 0 and 59
   * @param {number} [millisecond=0] - The millisecond of the second, meaning a number between 0 and 999
   * @param {Object} options - configuration options for the DateTime
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} [options.outputCalendar] - the output calendar to set on the resulting DateTime instance
   * @param {string} [options.numberingSystem] - the numbering system to set on the resulting DateTime instance
   * @example DateTime.utc()                                              //~> now
   * @example DateTime.utc(2017)                                          //~> 2017-01-01T00:00:00Z
   * @example DateTime.utc(2017, 3)                                       //~> 2017-03-01T00:00:00Z
   * @example DateTime.utc(2017, 3, 12)                                   //~> 2017-03-12T00:00:00Z
   * @example DateTime.utc(2017, 3, 12, 5)                                //~> 2017-03-12T05:00:00Z
   * @example DateTime.utc(2017, 3, 12, 5, 45)                            //~> 2017-03-12T05:45:00Z
   * @example DateTime.utc(2017, 3, 12, 5, 45, { locale: "fr" })          //~> 2017-03-12T05:45:00Z with a French locale
   * @example DateTime.utc(2017, 3, 12, 5, 45, 10)                        //~> 2017-03-12T05:45:10Z
   * @example DateTime.utc(2017, 3, 12, 5, 45, 10, 765, { locale: "fr" }) //~> 2017-03-12T05:45:10.765Z with a French locale
   * @return {DateTime}
   */
  static utc() {
    const [t, n] = hf(arguments), [o, r, s, i, a, l, c] = n;
    return t.zone = Ee.utcInstance, ff({ year: o, month: r, day: s, hour: i, minute: a, second: l, millisecond: c }, t);
  }
  /**
   * Create a DateTime from a JavaScript Date object. Uses the default zone.
   * @param {Date} date - a JavaScript Date object
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @return {DateTime}
   */
  static fromJSDate(t, n = {}) {
    const o = CD(t) ? t.valueOf() : NaN;
    if (Number.isNaN(o))
      return D.invalid("invalid input");
    const r = rn(n.zone, le.defaultZone);
    return r.isValid ? new D({
      ts: o,
      zone: r,
      loc: V.fromObject(n)
    }) : D.invalid(qs(r));
  }
  /**
   * Create a DateTime from a number of milliseconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Uses the default zone.
   * @param {number} milliseconds - a number of milliseconds since 1970 UTC
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} options.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} options.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromMillis(t, n = {}) {
    if (Ln(t))
      return t < -lf || t > lf ? D.invalid("Timestamp out of range") : new D({
        ts: t,
        zone: rn(n.zone, le.defaultZone),
        loc: V.fromObject(n)
      });
    throw new Me(
      `fromMillis requires a numerical input, but received a ${typeof t} with value ${t}`
    );
  }
  /**
   * Create a DateTime from a number of seconds since the epoch (meaning since 1 January 1970 00:00:00 UTC). Uses the default zone.
   * @param {number} seconds - a number of seconds since 1970 UTC
   * @param {Object} options - configuration options for the DateTime
   * @param {string|Zone} [options.zone='local'] - the zone to place the DateTime into
   * @param {string} [options.locale] - a locale to set on the resulting DateTime instance
   * @param {string} options.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} options.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromSeconds(t, n = {}) {
    if (Ln(t))
      return new D({
        ts: t * 1e3,
        zone: rn(n.zone, le.defaultZone),
        loc: V.fromObject(n)
      });
    throw new Me("fromSeconds requires a numerical input");
  }
  /**
   * Create a DateTime from a JavaScript object with keys like 'year' and 'hour' with reasonable defaults.
   * @param {Object} obj - the object to create the DateTime from
   * @param {number} obj.year - a year, such as 1987
   * @param {number} obj.month - a month, 1-12
   * @param {number} obj.day - a day of the month, 1-31, depending on the month
   * @param {number} obj.ordinal - day of the year, 1-365 or 366
   * @param {number} obj.weekYear - an ISO week year
   * @param {number} obj.weekNumber - an ISO week number, between 1 and 52 or 53, depending on the year
   * @param {number} obj.weekday - an ISO weekday, 1-7, where 1 is Monday and 7 is Sunday
   * @param {number} obj.localWeekYear - a week year, according to the locale
   * @param {number} obj.localWeekNumber - a week number, between 1 and 52 or 53, depending on the year, according to the locale
   * @param {number} obj.localWeekday - a weekday, 1-7, where 1 is the first and 7 is the last day of the week, according to the locale
   * @param {number} obj.hour - hour of the day, 0-23
   * @param {number} obj.minute - minute of the hour, 0-59
   * @param {number} obj.second - second of the minute, 0-59
   * @param {number} obj.millisecond - millisecond of the second, 0-999
   * @param {Object} opts - options for creating this DateTime
   * @param {string|Zone} [opts.zone='local'] - interpret the numbers in the context of a particular zone. Can take any value taken as the first argument to setZone()
   * @param {string} [opts.locale='system\'s locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromObject({ year: 1982, month: 5, day: 25}).toISODate() //=> '1982-05-25'
   * @example DateTime.fromObject({ year: 1982 }).toISODate() //=> '1982-01-01'
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }) //~> today at 10:26:06
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'utc' }),
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'local' })
   * @example DateTime.fromObject({ hour: 10, minute: 26, second: 6 }, { zone: 'America/New_York' })
   * @example DateTime.fromObject({ weekYear: 2016, weekNumber: 2, weekday: 3 }).toISODate() //=> '2016-01-13'
   * @example DateTime.fromObject({ localWeekYear: 2022, localWeekNumber: 1, localWeekday: 1 }, { locale: "en-US" }).toISODate() //=> '2021-12-26'
   * @return {DateTime}
   */
  static fromObject(t, n = {}) {
    t = t || {};
    const o = rn(n.zone, le.defaultZone);
    if (!o.isValid)
      return D.invalid(qs(o));
    const r = V.fromObject(n), s = na(t, df), { minDaysInFirstWeek: i, startOfWeek: a } = Kd(s, r), l = le.now(), c = N(n.specificOffset) ? o.offset(l) : n.specificOffset, u = !N(s.ordinal), d = !N(s.year), f = !N(s.month) || !N(s.day), m = d || f, p = s.weekYear || s.weekNumber;
    if ((m || u) && p)
      throw new yo(
        "Can't mix weekYear/weekNumber units with year/month/day or ordinals"
      );
    if (f && u)
      throw new yo("Can't mix ordinal dates with month/day");
    const g = p || s.weekday && !m;
    let v, T, O = Hs(l, c);
    g ? (v = VR, T = HR, O = ea(O, i, a)) : u ? (v = zR, T = ZR, O = ol(O)) : (v = eg, T = Xy);
    let k = !1;
    for (const G of v) {
      const _ = s[G];
      N(_) ? k ? s[G] = T[G] : s[G] = O[G] : k = !0;
    }
    const R = g ? ID(s, i, a) : u ? TD(s) : Sy(s), ae = R || xy(s);
    if (ae)
      return D.invalid(ae);
    const vt = g ? Gd(s, i, a) : u ? Yd(s) : s, [bt, nt] = $i(vt, c, o), We = new D({
      ts: bt,
      zone: o,
      o: nt,
      loc: r
    });
    return s.weekday && m && t.weekday !== We.weekday ? D.invalid(
      "mismatched weekday",
      `you can't specify both a weekday of ${s.weekday} and a date of ${We.toISO()}`
    ) : We;
  }
  /**
   * Create a DateTime from an ISO 8601 string
   * @param {string} text - the ISO string
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the time to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a fixed-offset zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} [opts.outputCalendar] - the output calendar to set on the resulting DateTime instance
   * @param {string} [opts.numberingSystem] - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromISO('2016-05-25T09:08:34.123')
   * @example DateTime.fromISO('2016-05-25T09:08:34.123+06:00')
   * @example DateTime.fromISO('2016-05-25T09:08:34.123+06:00', {setZone: true})
   * @example DateTime.fromISO('2016-05-25T09:08:34.123', {zone: 'utc'})
   * @example DateTime.fromISO('2016-W05-4')
   * @return {DateTime}
   */
  static fromISO(t, n = {}) {
    const [o, r] = fR(t);
    return pr(o, r, n, "ISO 8601", t);
  }
  /**
   * Create a DateTime from an RFC 2822 string
   * @param {string} text - the RFC 2822 string
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - convert the time to this zone. Since the offset is always specified in the string itself, this has no effect on the interpretation of string, merely the zone the resulting DateTime is expressed in.
   * @param {boolean} [opts.setZone=false] - override the zone with a fixed-offset zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromRFC2822('25 Nov 2016 13:23:12 GMT')
   * @example DateTime.fromRFC2822('Fri, 25 Nov 2016 13:23:12 +0600')
   * @example DateTime.fromRFC2822('25 Nov 2016 13:23 Z')
   * @return {DateTime}
   */
  static fromRFC2822(t, n = {}) {
    const [o, r] = mR(t);
    return pr(o, r, n, "RFC 2822", t);
  }
  /**
   * Create a DateTime from an HTTP header date
   * @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec3.html#sec3.3.1
   * @param {string} text - the HTTP header date
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - convert the time to this zone. Since HTTP dates are always in UTC, this has no effect on the interpretation of string, merely the zone the resulting DateTime is expressed in.
   * @param {boolean} [opts.setZone=false] - override the zone with the fixed-offset zone specified in the string. For HTTP dates, this is always UTC, so this option is equivalent to setting the `zone` option to 'utc', but this option is included for consistency with similar methods.
   * @param {string} [opts.locale='system's locale'] - a locale to set on the resulting DateTime instance
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @param {string} opts.numberingSystem - the numbering system to set on the resulting DateTime instance
   * @example DateTime.fromHTTP('Sun, 06 Nov 1994 08:49:37 GMT')
   * @example DateTime.fromHTTP('Sunday, 06-Nov-94 08:49:37 GMT')
   * @example DateTime.fromHTTP('Sun Nov  6 08:49:37 1994')
   * @return {DateTime}
   */
  static fromHTTP(t, n = {}) {
    const [o, r] = hR(t);
    return pr(o, r, n, "HTTP", n);
  }
  /**
   * Create a DateTime from an input string and format string.
   * Defaults to en-US if no locale has been specified, regardless of the system's locale. For a table of tokens and their interpretations, see [here](https://moment.github.io/luxon/#/parsing?id=table-of-tokens).
   * @param {string} text - the string to parse
   * @param {string} fmt - the format the string is expected to be in (see the link below for the formats)
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the DateTime to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='en-US'] - a locale string to use when parsing. Will also set the DateTime to this locale
   * @param {string} opts.numberingSystem - the numbering system to use when parsing. Will also set the resulting DateTime to this numbering system
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @return {DateTime}
   */
  static fromFormat(t, n, o = {}) {
    if (N(t) || N(n))
      throw new Me("fromFormat requires an input string and a format");
    const { locale: r = null, numberingSystem: s = null } = o, i = V.fromOpts({
      locale: r,
      numberingSystem: s,
      defaultToEN: !0
    }), [a, l, c, u] = qR(i, t, n);
    return u ? D.invalid(u) : pr(a, l, o, `format ${n}`, t, c);
  }
  /**
   * @deprecated use fromFormat instead
   */
  static fromString(t, n, o = {}) {
    return D.fromFormat(t, n, o);
  }
  /**
   * Create a DateTime from a SQL date, time, or datetime
   * Defaults to en-US if no locale has been specified, regardless of the system's locale
   * @param {string} text - the string to parse
   * @param {Object} opts - options to affect the creation
   * @param {string|Zone} [opts.zone='local'] - use this zone if no offset is specified in the input string itself. Will also convert the DateTime to this zone
   * @param {boolean} [opts.setZone=false] - override the zone with a zone specified in the string itself, if it specifies one
   * @param {string} [opts.locale='en-US'] - a locale string to use when parsing. Will also set the DateTime to this locale
   * @param {string} opts.numberingSystem - the numbering system to use when parsing. Will also set the resulting DateTime to this numbering system
   * @param {string} opts.outputCalendar - the output calendar to set on the resulting DateTime instance
   * @example DateTime.fromSQL('2017-05-15')
   * @example DateTime.fromSQL('2017-05-15 09:12:34')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342+06:00')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342 America/Los_Angeles')
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342 America/Los_Angeles', { setZone: true })
   * @example DateTime.fromSQL('2017-05-15 09:12:34.342', { zone: 'America/Los_Angeles' })
   * @example DateTime.fromSQL('09:12:34.342')
   * @return {DateTime}
   */
  static fromSQL(t, n = {}) {
    const [o, r] = TR(t);
    return pr(o, r, n, "SQL", t);
  }
  /**
   * Create an invalid DateTime.
   * @param {string} reason - simple string of why this DateTime is invalid. Should not contain parameters or anything else data-dependent.
   * @param {string} [explanation=null] - longer explanation, may include parameters and other useful debugging information
   * @return {DateTime}
   */
  static invalid(t, n = null) {
    if (!t)
      throw new Me("need to specify a reason the DateTime is invalid");
    const o = t instanceof ut ? t : new ut(t, n);
    if (le.throwOnInvalid)
      throw new K2(o);
    return new D({ invalid: o });
  }
  /**
   * Check if an object is an instance of DateTime. Works across context boundaries
   * @param {object} o
   * @return {boolean}
   */
  static isDateTime(t) {
    return t && t.isLuxonDateTime || !1;
  }
  /**
   * Produce the format string for a set of options
   * @param formatOpts
   * @param localeOpts
   * @returns {string}
   */
  static parseFormatForOpts(t, n = {}) {
    const o = Qy(t, V.fromObject(n));
    return o ? o.map((r) => r ? r.val : null).join("") : null;
  }
  /**
   * Produce the the fully expanded format token for the locale
   * Does NOT quote characters, so quoted tokens will not round trip correctly
   * @param fmt
   * @param localeOpts
   * @returns {string}
   */
  static expandFormat(t, n = {}) {
    return Yy(Te.parseFormat(t), V.fromObject(n)).map((r) => r.val).join("");
  }
  // INFO
  /**
   * Get the value of unit.
   * @param {string} unit - a unit such as 'minute' or 'day'
   * @example DateTime.local(2017, 7, 4).get('month'); //=> 7
   * @example DateTime.local(2017, 7, 4).get('day'); //=> 4
   * @return {number}
   */
  get(t) {
    return this[t];
  }
  /**
   * Returns whether the DateTime is valid. Invalid DateTimes occur when:
   * * The DateTime was created from invalid calendar information, such as the 13th month or February 30
   * * The DateTime was created by an operation on another invalid date
   * @type {boolean}
   */
  get isValid() {
    return this.invalid === null;
  }
  /**
   * Returns an error code if this DateTime is invalid, or null if the DateTime is valid
   * @type {string}
   */
  get invalidReason() {
    return this.invalid ? this.invalid.reason : null;
  }
  /**
   * Returns an explanation of why this DateTime became invalid, or null if the DateTime is valid
   * @type {string}
   */
  get invalidExplanation() {
    return this.invalid ? this.invalid.explanation : null;
  }
  /**
   * Get the locale of a DateTime, such 'en-GB'. The locale is used when formatting the DateTime
   *
   * @type {string}
   */
  get locale() {
    return this.isValid ? this.loc.locale : null;
  }
  /**
   * Get the numbering system of a DateTime, such 'beng'. The numbering system is used when formatting the DateTime
   *
   * @type {string}
   */
  get numberingSystem() {
    return this.isValid ? this.loc.numberingSystem : null;
  }
  /**
   * Get the output calendar of a DateTime, such 'islamic'. The output calendar is used when formatting the DateTime
   *
   * @type {string}
   */
  get outputCalendar() {
    return this.isValid ? this.loc.outputCalendar : null;
  }
  /**
   * Get the time zone associated with this DateTime.
   * @type {Zone}
   */
  get zone() {
    return this._zone;
  }
  /**
   * Get the name of the time zone.
   * @type {string}
   */
  get zoneName() {
    return this.isValid ? this.zone.name : null;
  }
  /**
   * Get the year
   * @example DateTime.local(2017, 5, 25).year //=> 2017
   * @type {number}
   */
  get year() {
    return this.isValid ? this.c.year : NaN;
  }
  /**
   * Get the quarter
   * @example DateTime.local(2017, 5, 25).quarter //=> 2
   * @type {number}
   */
  get quarter() {
    return this.isValid ? Math.ceil(this.c.month / 3) : NaN;
  }
  /**
   * Get the month (1-12).
   * @example DateTime.local(2017, 5, 25).month //=> 5
   * @type {number}
   */
  get month() {
    return this.isValid ? this.c.month : NaN;
  }
  /**
   * Get the day of the month (1-30ish).
   * @example DateTime.local(2017, 5, 25).day //=> 25
   * @type {number}
   */
  get day() {
    return this.isValid ? this.c.day : NaN;
  }
  /**
   * Get the hour of the day (0-23).
   * @example DateTime.local(2017, 5, 25, 9).hour //=> 9
   * @type {number}
   */
  get hour() {
    return this.isValid ? this.c.hour : NaN;
  }
  /**
   * Get the minute of the hour (0-59).
   * @example DateTime.local(2017, 5, 25, 9, 30).minute //=> 30
   * @type {number}
   */
  get minute() {
    return this.isValid ? this.c.minute : NaN;
  }
  /**
   * Get the second of the minute (0-59).
   * @example DateTime.local(2017, 5, 25, 9, 30, 52).second //=> 52
   * @type {number}
   */
  get second() {
    return this.isValid ? this.c.second : NaN;
  }
  /**
   * Get the millisecond of the second (0-999).
   * @example DateTime.local(2017, 5, 25, 9, 30, 52, 654).millisecond //=> 654
   * @type {number}
   */
  get millisecond() {
    return this.isValid ? this.c.millisecond : NaN;
  }
  /**
   * Get the week year
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2014, 12, 31).weekYear //=> 2015
   * @type {number}
   */
  get weekYear() {
    return this.isValid ? il(this).weekYear : NaN;
  }
  /**
   * Get the week number of the week year (1-52ish).
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2017, 5, 25).weekNumber //=> 21
   * @type {number}
   */
  get weekNumber() {
    return this.isValid ? il(this).weekNumber : NaN;
  }
  /**
   * Get the day of the week.
   * 1 is Monday and 7 is Sunday
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2014, 11, 31).weekday //=> 4
   * @type {number}
   */
  get weekday() {
    return this.isValid ? il(this).weekday : NaN;
  }
  /**
   * Returns true if this date is on a weekend according to the locale, false otherwise
   * @returns {boolean}
   */
  get isWeekend() {
    return this.isValid && this.loc.getWeekendDays().includes(this.weekday);
  }
  /**
   * Get the day of the week according to the locale.
   * 1 is the first day of the week and 7 is the last day of the week.
   * If the locale assigns Sunday as the first day of the week, then a date which is a Sunday will return 1,
   * @returns {number}
   */
  get localWeekday() {
    return this.isValid ? al(this).weekday : NaN;
  }
  /**
   * Get the week number of the week year according to the locale. Different locales assign week numbers differently,
   * because the week can start on different days of the week (see localWeekday) and because a different number of days
   * is required for a week to count as the first week of a year.
   * @returns {number}
   */
  get localWeekNumber() {
    return this.isValid ? al(this).weekNumber : NaN;
  }
  /**
   * Get the week year according to the locale. Different locales assign week numbers (and therefor week years)
   * differently, see localWeekNumber.
   * @returns {number}
   */
  get localWeekYear() {
    return this.isValid ? al(this).weekYear : NaN;
  }
  /**
   * Get the ordinal (meaning the day of the year)
   * @example DateTime.local(2017, 5, 25).ordinal //=> 145
   * @type {number|DateTime}
   */
  get ordinal() {
    return this.isValid ? ol(this.c).ordinal : NaN;
  }
  /**
   * Get the human readable short month name, such as 'Oct'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).monthShort //=> Oct
   * @type {string}
   */
  get monthShort() {
    return this.isValid ? js.months("short", { locObj: this.loc })[this.month - 1] : null;
  }
  /**
   * Get the human readable long month name, such as 'October'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).monthLong //=> October
   * @type {string}
   */
  get monthLong() {
    return this.isValid ? js.months("long", { locObj: this.loc })[this.month - 1] : null;
  }
  /**
   * Get the human readable short weekday, such as 'Mon'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).weekdayShort //=> Mon
   * @type {string}
   */
  get weekdayShort() {
    return this.isValid ? js.weekdays("short", { locObj: this.loc })[this.weekday - 1] : null;
  }
  /**
   * Get the human readable long weekday, such as 'Monday'.
   * Defaults to the system's locale if no locale has been specified
   * @example DateTime.local(2017, 10, 30).weekdayLong //=> Monday
   * @type {string}
   */
  get weekdayLong() {
    return this.isValid ? js.weekdays("long", { locObj: this.loc })[this.weekday - 1] : null;
  }
  /**
   * Get the UTC offset of this DateTime in minutes
   * @example DateTime.now().offset //=> -240
   * @example DateTime.utc().offset //=> 0
   * @type {number}
   */
  get offset() {
    return this.isValid ? +this.o : NaN;
  }
  /**
   * Get the short human name for the zone's current offset, for example "EST" or "EDT".
   * Defaults to the system's locale if no locale has been specified
   * @type {string}
   */
  get offsetNameShort() {
    return this.isValid ? this.zone.offsetName(this.ts, {
      format: "short",
      locale: this.locale
    }) : null;
  }
  /**
   * Get the long human name for the zone's current offset, for example "Eastern Standard Time" or "Eastern Daylight Time".
   * Defaults to the system's locale if no locale has been specified
   * @type {string}
   */
  get offsetNameLong() {
    return this.isValid ? this.zone.offsetName(this.ts, {
      format: "long",
      locale: this.locale
    }) : null;
  }
  /**
   * Get whether this zone's offset ever changes, as in a DST.
   * @type {boolean}
   */
  get isOffsetFixed() {
    return this.isValid ? this.zone.isUniversal : null;
  }
  /**
   * Get whether the DateTime is in a DST.
   * @type {boolean}
   */
  get isInDST() {
    return this.isOffsetFixed ? !1 : this.offset > this.set({ month: 1, day: 1 }).offset || this.offset > this.set({ month: 5 }).offset;
  }
  /**
   * Get those DateTimes which have the same local time as this DateTime, but a different offset from UTC
   * in this DateTime's zone. During DST changes local time can be ambiguous, for example
   * `2023-10-29T02:30:00` in `Europe/Berlin` can have offset `+01:00` or `+02:00`.
   * This method will return both possible DateTimes if this DateTime's local time is ambiguous.
   * @returns {DateTime[]}
   */
  getPossibleOffsets() {
    if (!this.isValid || this.isOffsetFixed)
      return [this];
    const t = 864e5, n = 6e4, o = _a(this.c), r = this.zone.offset(o - t), s = this.zone.offset(o + t), i = this.zone.offset(o - r * n), a = this.zone.offset(o - s * n);
    if (i === a)
      return [this];
    const l = o - i * n, c = o - a * n, u = Hs(l, i), d = Hs(c, a);
    return u.hour === d.hour && u.minute === d.minute && u.second === d.second && u.millisecond === d.millisecond ? [gn(this, { ts: l }), gn(this, { ts: c })] : [this];
  }
  /**
   * Returns true if this DateTime is in a leap year, false otherwise
   * @example DateTime.local(2016).isInLeapYear //=> true
   * @example DateTime.local(2013).isInLeapYear //=> false
   * @type {boolean}
   */
  get isInLeapYear() {
    return Ms(this.year);
  }
  /**
   * Returns the number of days in this DateTime's month
   * @example DateTime.local(2016, 2).daysInMonth //=> 29
   * @example DateTime.local(2016, 3).daysInMonth //=> 31
   * @type {number}
   */
  get daysInMonth() {
    return ta(this.year, this.month);
  }
  /**
   * Returns the number of days in this DateTime's year
   * @example DateTime.local(2016).daysInYear //=> 366
   * @example DateTime.local(2013).daysInYear //=> 365
   * @type {number}
   */
  get daysInYear() {
    return this.isValid ? xo(this.year) : NaN;
  }
  /**
   * Returns the number of weeks in this DateTime's year
   * @see https://en.wikipedia.org/wiki/ISO_week_date
   * @example DateTime.local(2004).weeksInWeekYear //=> 53
   * @example DateTime.local(2013).weeksInWeekYear //=> 52
   * @type {number}
   */
  get weeksInWeekYear() {
    return this.isValid ? ss(this.weekYear) : NaN;
  }
  /**
   * Returns the number of weeks in this DateTime's local week year
   * @example DateTime.local(2020, 6, {locale: 'en-US'}).weeksInLocalWeekYear //=> 52
   * @example DateTime.local(2020, 6, {locale: 'de-DE'}).weeksInLocalWeekYear //=> 53
   * @type {number}
   */
  get weeksInLocalWeekYear() {
    return this.isValid ? ss(
      this.localWeekYear,
      this.loc.getMinDaysInFirstWeek(),
      this.loc.getStartOfWeek()
    ) : NaN;
  }
  /**
   * Returns the resolved Intl options for this DateTime.
   * This is useful in understanding the behavior of formatting methods
   * @param {Object} opts - the same options as toLocaleString
   * @return {Object}
   */
  resolvedLocaleOptions(t = {}) {
    const { locale: n, numberingSystem: o, calendar: r } = Te.create(
      this.loc.clone(t),
      t
    ).resolvedOptions(this);
    return { locale: n, numberingSystem: o, outputCalendar: r };
  }
  // TRANSFORM
  /**
   * "Set" the DateTime's zone to UTC. Returns a newly-constructed DateTime.
   *
   * Equivalent to {@link DateTime#setZone}('utc')
   * @param {number} [offset=0] - optionally, an offset from UTC in minutes
   * @param {Object} [opts={}] - options to pass to `setZone()`
   * @return {DateTime}
   */
  toUTC(t = 0, n = {}) {
    return this.setZone(Ee.instance(t), n);
  }
  /**
   * "Set" the DateTime's zone to the host's local zone. Returns a newly-constructed DateTime.
   *
   * Equivalent to `setZone('local')`
   * @return {DateTime}
   */
  toLocal() {
    return this.setZone(le.defaultZone);
  }
  /**
   * "Set" the DateTime's zone to specified zone. Returns a newly-constructed DateTime.
   *
   * By default, the setter keeps the underlying time the same (as in, the same timestamp), but the new instance will report different local times and consider DSTs when making computations, as with {@link DateTime#plus}. You may wish to use {@link DateTime#toLocal} and {@link DateTime#toUTC} which provide simple convenience wrappers for commonly used zones.
   * @param {string|Zone} [zone='local'] - a zone identifier. As a string, that can be any IANA zone supported by the host environment, or a fixed-offset name of the form 'UTC+3', or the strings 'local' or 'utc'. You may also supply an instance of a {@link DateTime#Zone} class.
   * @param {Object} opts - options
   * @param {boolean} [opts.keepLocalTime=false] - If true, adjust the underlying time so that the local time stays the same, but in the target zone. You should rarely need this.
   * @return {DateTime}
   */
  setZone(t, { keepLocalTime: n = !1, keepCalendarTime: o = !1 } = {}) {
    if (t = rn(t, le.defaultZone), t.equals(this.zone))
      return this;
    if (t.isValid) {
      let r = this.ts;
      if (n || o) {
        const s = t.offset(this.ts), i = this.toObject();
        [r] = $i(i, s, t);
      }
      return gn(this, { ts: r, zone: t });
    } else
      return D.invalid(qs(t));
  }
  /**
   * "Set" the locale, numberingSystem, or outputCalendar. Returns a newly-constructed DateTime.
   * @param {Object} properties - the properties to set
   * @example DateTime.local(2017, 5, 25).reconfigure({ locale: 'en-GB' })
   * @return {DateTime}
   */
  reconfigure({ locale: t, numberingSystem: n, outputCalendar: o } = {}) {
    const r = this.loc.clone({ locale: t, numberingSystem: n, outputCalendar: o });
    return gn(this, { loc: r });
  }
  /**
   * "Set" the locale. Returns a newly-constructed DateTime.
   * Just a convenient alias for reconfigure({ locale })
   * @example DateTime.local(2017, 5, 25).setLocale('en-GB')
   * @return {DateTime}
   */
  setLocale(t) {
    return this.reconfigure({ locale: t });
  }
  /**
   * "Set" the values of specified units. Returns a newly-constructed DateTime.
   * You can only set units with this method; for "setting" metadata, see {@link DateTime#reconfigure} and {@link DateTime#setZone}.
   *
   * This method also supports setting locale-based week units, i.e. `localWeekday`, `localWeekNumber` and `localWeekYear`.
   * They cannot be mixed with ISO-week units like `weekday`.
   * @param {Object} values - a mapping of units to numbers
   * @example dt.set({ year: 2017 })
   * @example dt.set({ hour: 8, minute: 30 })
   * @example dt.set({ weekday: 5 })
   * @example dt.set({ year: 2005, ordinal: 234 })
   * @return {DateTime}
   */
  set(t) {
    if (!this.isValid)
      return this;
    const n = na(t, df), { minDaysInFirstWeek: o, startOfWeek: r } = Kd(n, this.loc), s = !N(n.weekYear) || !N(n.weekNumber) || !N(n.weekday), i = !N(n.ordinal), a = !N(n.year), l = !N(n.month) || !N(n.day), c = a || l, u = n.weekYear || n.weekNumber;
    if ((c || i) && u)
      throw new yo(
        "Can't mix weekYear/weekNumber units with year/month/day or ordinals"
      );
    if (l && i)
      throw new yo("Can't mix ordinal dates with month/day");
    let d;
    s ? d = Gd(
      { ...ea(this.c, o, r), ...n },
      o,
      r
    ) : N(n.ordinal) ? (d = { ...this.toObject(), ...n }, N(n.day) && (d.day = Math.min(ta(d.year, d.month), d.day))) : d = Yd({ ...ol(this.c), ...n });
    const [f, m] = $i(d, this.o, this.zone);
    return gn(this, { ts: f, o: m });
  }
  /**
   * Add a period of time to this DateTime and return the resulting DateTime
   *
   * Adding hours, minutes, seconds, or milliseconds increases the timestamp by the right number of milliseconds. Adding days, months, or years shifts the calendar, accounting for DSTs and leap years along the way. Thus, `dt.plus({ hours: 24 })` may result in a different time than `dt.plus({ days: 1 })` if there's a DST shift in between.
   * @param {Duration|Object|number} duration - The amount to add. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   * @example DateTime.now().plus(123) //~> in 123 milliseconds
   * @example DateTime.now().plus({ minutes: 15 }) //~> in 15 minutes
   * @example DateTime.now().plus({ days: 1 }) //~> this time tomorrow
   * @example DateTime.now().plus({ days: -1 }) //~> this time yesterday
   * @example DateTime.now().plus({ hours: 3, minutes: 13 }) //~> in 3 hr, 13 min
   * @example DateTime.now().plus(Duration.fromObject({ hours: 3, minutes: 13 })) //~> in 3 hr, 13 min
   * @return {DateTime}
   */
  plus(t) {
    if (!this.isValid)
      return this;
    const n = j.fromDurationLike(t);
    return gn(this, cf(this, n));
  }
  /**
   * Subtract a period of time to this DateTime and return the resulting DateTime
   * See {@link DateTime#plus}
   * @param {Duration|Object|number} duration - The amount to subtract. Either a Luxon Duration, a number of milliseconds, the object argument to Duration.fromObject()
   @return {DateTime}
   */
  minus(t) {
    if (!this.isValid)
      return this;
    const n = j.fromDurationLike(t).negate();
    return gn(this, cf(this, n));
  }
  /**
   * "Set" this DateTime to the beginning of a unit of time.
   * @param {string} unit - The unit to go to the beginning of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week
   * @example DateTime.local(2014, 3, 3).startOf('month').toISODate(); //=> '2014-03-01'
   * @example DateTime.local(2014, 3, 3).startOf('year').toISODate(); //=> '2014-01-01'
   * @example DateTime.local(2014, 3, 3).startOf('week').toISODate(); //=> '2014-03-03', weeks always start on Mondays
   * @example DateTime.local(2014, 3, 3, 5, 30).startOf('day').toISOTime(); //=> '00:00.000-05:00'
   * @example DateTime.local(2014, 3, 3, 5, 30).startOf('hour').toISOTime(); //=> '05:00:00.000-05:00'
   * @return {DateTime}
   */
  startOf(t, { useLocaleWeeks: n = !1 } = {}) {
    if (!this.isValid)
      return this;
    const o = {}, r = j.normalizeUnit(t);
    switch (r) {
      case "years":
        o.month = 1;
      case "quarters":
      case "months":
        o.day = 1;
      case "weeks":
      case "days":
        o.hour = 0;
      case "hours":
        o.minute = 0;
      case "minutes":
        o.second = 0;
      case "seconds":
        o.millisecond = 0;
        break;
    }
    if (r === "weeks")
      if (n) {
        const s = this.loc.getStartOfWeek(), { weekday: i } = this;
        i < s && (o.weekNumber = this.weekNumber - 1), o.weekday = s;
      } else
        o.weekday = 1;
    if (r === "quarters") {
      const s = Math.ceil(this.month / 3);
      o.month = (s - 1) * 3 + 1;
    }
    return this.set(o);
  }
  /**
   * "Set" this DateTime to the end (meaning the last millisecond) of a unit of time
   * @param {string} unit - The unit to go to the end of. Can be 'year', 'quarter', 'month', 'week', 'day', 'hour', 'minute', 'second', or 'millisecond'.
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week
   * @example DateTime.local(2014, 3, 3).endOf('month').toISO(); //=> '2014-03-31T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3).endOf('year').toISO(); //=> '2014-12-31T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3).endOf('week').toISO(); // => '2014-03-09T23:59:59.999-05:00', weeks start on Mondays
   * @example DateTime.local(2014, 3, 3, 5, 30).endOf('day').toISO(); //=> '2014-03-03T23:59:59.999-05:00'
   * @example DateTime.local(2014, 3, 3, 5, 30).endOf('hour').toISO(); //=> '2014-03-03T05:59:59.999-05:00'
   * @return {DateTime}
   */
  endOf(t, n) {
    return this.isValid ? this.plus({ [t]: 1 }).startOf(t, n).minus(1) : this;
  }
  // OUTPUT
  /**
   * Returns a string representation of this DateTime formatted according to the specified format string.
   * **You may not want this.** See {@link DateTime#toLocaleString} for a more flexible formatting tool. For a table of tokens and their interpretations, see [here](https://moment.github.io/luxon/#/formatting?id=table-of-tokens).
   * Defaults to en-US if no locale has been specified, regardless of the system's locale.
   * @param {string} fmt - the format string
   * @param {Object} opts - opts to override the configuration options on this DateTime
   * @example DateTime.now().toFormat('yyyy LLL dd') //=> '2017 Apr 22'
   * @example DateTime.now().setLocale('fr').toFormat('yyyy LLL dd') //=> '2017 avr. 22'
   * @example DateTime.now().toFormat('yyyy LLL dd', { locale: "fr" }) //=> '2017 avr. 22'
   * @example DateTime.now().toFormat("HH 'hours and' mm 'minutes'") //=> '20 hours and 55 minutes'
   * @return {string}
   */
  toFormat(t, n = {}) {
    return this.isValid ? Te.create(this.loc.redefaultToEN(n)).formatDateTimeFromString(this, t) : sl;
  }
  /**
   * Returns a localized string representing this date. Accepts the same options as the Intl.DateTimeFormat constructor and any presets defined by Luxon, such as `DateTime.DATE_FULL` or `DateTime.TIME_SIMPLE`.
   * The exact behavior of this method is browser-specific, but in general it will return an appropriate representation
   * of the DateTime in the assigned locale.
   * Defaults to the system's locale if no locale has been specified
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @param formatOpts {Object} - Intl.DateTimeFormat constructor options and configuration options
   * @param {Object} opts - opts to override the configuration options on this DateTime
   * @example DateTime.now().toLocaleString(); //=> 4/20/2017
   * @example DateTime.now().setLocale('en-gb').toLocaleString(); //=> '20/04/2017'
   * @example DateTime.now().toLocaleString(DateTime.DATE_FULL); //=> 'April 20, 2017'
   * @example DateTime.now().toLocaleString(DateTime.DATE_FULL, { locale: 'fr' }); //=> '28 août 2022'
   * @example DateTime.now().toLocaleString(DateTime.TIME_SIMPLE); //=> '11:32 AM'
   * @example DateTime.now().toLocaleString(DateTime.DATETIME_SHORT); //=> '4/20/2017, 11:32 AM'
   * @example DateTime.now().toLocaleString({ weekday: 'long', month: 'long', day: '2-digit' }); //=> 'Thursday, April 20'
   * @example DateTime.now().toLocaleString({ weekday: 'short', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' }); //=> 'Thu, Apr 20, 11:27 AM'
   * @example DateTime.now().toLocaleString({ hour: '2-digit', minute: '2-digit', hourCycle: 'h23' }); //=> '11:32'
   * @return {string}
   */
  toLocaleString(t = Xi, n = {}) {
    return this.isValid ? Te.create(this.loc.clone(n), t).formatDateTime(this) : sl;
  }
  /**
   * Returns an array of format "parts", meaning individual tokens along with metadata. This is allows callers to post-process individual sections of the formatted output.
   * Defaults to the system's locale if no locale has been specified
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat/formatToParts
   * @param opts {Object} - Intl.DateTimeFormat constructor options, same as `toLocaleString`.
   * @example DateTime.now().toLocaleParts(); //=> [
   *                                   //=>   { type: 'day', value: '25' },
   *                                   //=>   { type: 'literal', value: '/' },
   *                                   //=>   { type: 'month', value: '05' },
   *                                   //=>   { type: 'literal', value: '/' },
   *                                   //=>   { type: 'year', value: '1982' }
   *                                   //=> ]
   */
  toLocaleParts(t = {}) {
    return this.isValid ? Te.create(this.loc.clone(t), t).formatDateTimeParts(this) : [];
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.extendedZone=false] - add the time zone format extension
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc(1983, 5, 25).toISO() //=> '1982-05-25T00:00:00.000Z'
   * @example DateTime.now().toISO() //=> '2017-04-22T20:47:05.335-04:00'
   * @example DateTime.now().toISO({ includeOffset: false }) //=> '2017-04-22T20:47:05.335'
   * @example DateTime.now().toISO({ format: 'basic' }) //=> '20170422T204705.335-0400'
   * @return {string}
   */
  toISO({
    format: t = "extended",
    suppressSeconds: n = !1,
    suppressMilliseconds: o = !1,
    includeOffset: r = !0,
    extendedZone: s = !1
  } = {}) {
    if (!this.isValid)
      return null;
    const i = t === "extended";
    let a = ll(this, i);
    return a += "T", a += uf(this, i, n, o, r, s), a;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's date component
   * @param {Object} opts - options
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc(1982, 5, 25).toISODate() //=> '1982-05-25'
   * @example DateTime.utc(1982, 5, 25).toISODate({ format: 'basic' }) //=> '19820525'
   * @return {string}
   */
  toISODate({ format: t = "extended" } = {}) {
    return this.isValid ? ll(this, t === "extended") : null;
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's week date
   * @example DateTime.utc(1982, 5, 25).toISOWeekDate() //=> '1982-W21-2'
   * @return {string}
   */
  toISOWeekDate() {
    return Zs(this, "kkkk-'W'WW-c");
  }
  /**
   * Returns an ISO 8601-compliant string representation of this DateTime's time component
   * @param {Object} opts - options
   * @param {boolean} [opts.suppressMilliseconds=false] - exclude milliseconds from the format if they're 0
   * @param {boolean} [opts.suppressSeconds=false] - exclude seconds from the format if they're 0
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.extendedZone=true] - add the time zone format extension
   * @param {boolean} [opts.includePrefix=false] - include the `T` prefix
   * @param {string} [opts.format='extended'] - choose between the basic and extended format
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime() //=> '07:34:19.361Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34, seconds: 0, milliseconds: 0 }).toISOTime({ suppressSeconds: true }) //=> '07:34Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime({ format: 'basic' }) //=> '073419.361Z'
   * @example DateTime.utc().set({ hour: 7, minute: 34 }).toISOTime({ includePrefix: true }) //=> 'T07:34:19.361Z'
   * @return {string}
   */
  toISOTime({
    suppressMilliseconds: t = !1,
    suppressSeconds: n = !1,
    includeOffset: o = !0,
    includePrefix: r = !1,
    extendedZone: s = !1,
    format: i = "extended"
  } = {}) {
    return this.isValid ? (r ? "T" : "") + uf(
      this,
      i === "extended",
      n,
      t,
      o,
      s
    ) : null;
  }
  /**
   * Returns an RFC 2822-compatible string representation of this DateTime
   * @example DateTime.utc(2014, 7, 13).toRFC2822() //=> 'Sun, 13 Jul 2014 00:00:00 +0000'
   * @example DateTime.local(2014, 7, 13).toRFC2822() //=> 'Sun, 13 Jul 2014 00:00:00 -0400'
   * @return {string}
   */
  toRFC2822() {
    return Zs(this, "EEE, dd LLL yyyy HH:mm:ss ZZZ", !1);
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in HTTP headers. The output is always expressed in GMT.
   * Specifically, the string conforms to RFC 1123.
   * @see https://www.w3.org/Protocols/rfc2616/rfc2616-sec3.html#sec3.3.1
   * @example DateTime.utc(2014, 7, 13).toHTTP() //=> 'Sun, 13 Jul 2014 00:00:00 GMT'
   * @example DateTime.utc(2014, 7, 13, 19).toHTTP() //=> 'Sun, 13 Jul 2014 19:00:00 GMT'
   * @return {string}
   */
  toHTTP() {
    return Zs(this.toUTC(), "EEE, dd LLL yyyy HH:mm:ss 'GMT'");
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL Date
   * @example DateTime.utc(2014, 7, 13).toSQLDate() //=> '2014-07-13'
   * @return {string}
   */
  toSQLDate() {
    return this.isValid ? ll(this, !0) : null;
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL Time
   * @param {Object} opts - options
   * @param {boolean} [opts.includeZone=false] - include the zone, such as 'America/New_York'. Overrides includeOffset.
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.includeOffsetSpace=true] - include the space between the time and the offset, such as '05:15:16.345 -04:00'
   * @example DateTime.utc().toSQL() //=> '05:15:16.345'
   * @example DateTime.now().toSQL() //=> '05:15:16.345 -04:00'
   * @example DateTime.now().toSQL({ includeOffset: false }) //=> '05:15:16.345'
   * @example DateTime.now().toSQL({ includeZone: false }) //=> '05:15:16.345 America/New_York'
   * @return {string}
   */
  toSQLTime({ includeOffset: t = !0, includeZone: n = !1, includeOffsetSpace: o = !0 } = {}) {
    let r = "HH:mm:ss.SSS";
    return (n || t) && (o && (r += " "), n ? r += "z" : t && (r += "ZZ")), Zs(this, r, !0);
  }
  /**
   * Returns a string representation of this DateTime appropriate for use in SQL DateTime
   * @param {Object} opts - options
   * @param {boolean} [opts.includeZone=false] - include the zone, such as 'America/New_York'. Overrides includeOffset.
   * @param {boolean} [opts.includeOffset=true] - include the offset, such as 'Z' or '-04:00'
   * @param {boolean} [opts.includeOffsetSpace=true] - include the space between the time and the offset, such as '05:15:16.345 -04:00'
   * @example DateTime.utc(2014, 7, 13).toSQL() //=> '2014-07-13 00:00:00.000 Z'
   * @example DateTime.local(2014, 7, 13).toSQL() //=> '2014-07-13 00:00:00.000 -04:00'
   * @example DateTime.local(2014, 7, 13).toSQL({ includeOffset: false }) //=> '2014-07-13 00:00:00.000'
   * @example DateTime.local(2014, 7, 13).toSQL({ includeZone: true }) //=> '2014-07-13 00:00:00.000 America/New_York'
   * @return {string}
   */
  toSQL(t = {}) {
    return this.isValid ? `${this.toSQLDate()} ${this.toSQLTime(t)}` : null;
  }
  /**
   * Returns a string representation of this DateTime appropriate for debugging
   * @return {string}
   */
  toString() {
    return this.isValid ? this.toISO() : sl;
  }
  /**
   * Returns a string representation of this DateTime appropriate for the REPL.
   * @return {string}
   */
  [Symbol.for("nodejs.util.inspect.custom")]() {
    return this.isValid ? `DateTime { ts: ${this.toISO()}, zone: ${this.zone.name}, locale: ${this.locale} }` : `DateTime { Invalid, reason: ${this.invalidReason} }`;
  }
  /**
   * Returns the epoch milliseconds of this DateTime. Alias of {@link DateTime#toMillis}
   * @return {number}
   */
  valueOf() {
    return this.toMillis();
  }
  /**
   * Returns the epoch milliseconds of this DateTime.
   * @return {number}
   */
  toMillis() {
    return this.isValid ? this.ts : NaN;
  }
  /**
   * Returns the epoch seconds of this DateTime.
   * @return {number}
   */
  toSeconds() {
    return this.isValid ? this.ts / 1e3 : NaN;
  }
  /**
   * Returns the epoch seconds (as a whole number) of this DateTime.
   * @return {number}
   */
  toUnixInteger() {
    return this.isValid ? Math.floor(this.ts / 1e3) : NaN;
  }
  /**
   * Returns an ISO 8601 representation of this DateTime appropriate for use in JSON.
   * @return {string}
   */
  toJSON() {
    return this.toISO();
  }
  /**
   * Returns a BSON serializable equivalent to this DateTime.
   * @return {Date}
   */
  toBSON() {
    return this.toJSDate();
  }
  /**
   * Returns a JavaScript object with this DateTime's year, month, day, and so on.
   * @param opts - options for generating the object
   * @param {boolean} [opts.includeConfig=false] - include configuration attributes in the output
   * @example DateTime.now().toObject() //=> { year: 2017, month: 4, day: 22, hour: 20, minute: 49, second: 42, millisecond: 268 }
   * @return {Object}
   */
  toObject(t = {}) {
    if (!this.isValid)
      return {};
    const n = { ...this.c };
    return t.includeConfig && (n.outputCalendar = this.outputCalendar, n.numberingSystem = this.loc.numberingSystem, n.locale = this.loc.locale), n;
  }
  /**
   * Returns a JavaScript Date equivalent to this DateTime.
   * @return {Date}
   */
  toJSDate() {
    return new Date(this.isValid ? this.ts : NaN);
  }
  // COMPARE
  /**
   * Return the difference between two DateTimes as a Duration.
   * @param {DateTime} otherDateTime - the DateTime to compare this one to
   * @param {string|string[]} [unit=['milliseconds']] - the unit or array of units (such as 'hours' or 'days') to include in the duration.
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @example
   * var i1 = DateTime.fromISO('1982-05-25T09:45'),
   *     i2 = DateTime.fromISO('1983-10-14T10:30');
   * i2.diff(i1).toObject() //=> { milliseconds: 43807500000 }
   * i2.diff(i1, 'hours').toObject() //=> { hours: 12168.75 }
   * i2.diff(i1, ['months', 'days']).toObject() //=> { months: 16, days: 19.03125 }
   * i2.diff(i1, ['months', 'days', 'hours']).toObject() //=> { months: 16, days: 19, hours: 0.75 }
   * @return {Duration}
   */
  diff(t, n = "milliseconds", o = {}) {
    if (!this.isValid || !t.isValid)
      return j.invalid("created by diffing an invalid DateTime");
    const r = { locale: this.locale, numberingSystem: this.numberingSystem, ...o }, s = OD(n).map(j.normalizeUnit), i = t.valueOf() > this.valueOf(), a = i ? this : t, l = i ? t : this, c = AR(a, l, s, r);
    return i ? c.negate() : c;
  }
  /**
   * Return the difference between this DateTime and right now.
   * See {@link DateTime#diff}
   * @param {string|string[]} [unit=['milliseconds']] - the unit or units units (such as 'hours' or 'days') to include in the duration
   * @param {Object} opts - options that affect the creation of the Duration
   * @param {string} [opts.conversionAccuracy='casual'] - the conversion system to use
   * @return {Duration}
   */
  diffNow(t = "milliseconds", n = {}) {
    return this.diff(D.now(), t, n);
  }
  /**
   * Return an Interval spanning between this DateTime and another DateTime
   * @param {DateTime} otherDateTime - the other end point of the Interval
   * @return {Interval}
   */
  until(t) {
    return this.isValid ? ie.fromDateTimes(this, t) : this;
  }
  /**
   * Return whether this DateTime is in the same unit of time as another DateTime.
   * Higher-order units must also be identical for this function to return `true`.
   * Note that time zones are **ignored** in this comparison, which compares the **local** calendar time. Use {@link DateTime#setZone} to convert one of the dates if needed.
   * @param {DateTime} otherDateTime - the other DateTime
   * @param {string} unit - the unit of time to check sameness on
   * @param {Object} opts - options
   * @param {boolean} [opts.useLocaleWeeks=false] - If true, use weeks based on the locale, i.e. use the locale-dependent start of the week; only the locale of this DateTime is used
   * @example DateTime.now().hasSame(otherDT, 'day'); //~> true if otherDT is in the same current calendar day
   * @return {boolean}
   */
  hasSame(t, n, o) {
    if (!this.isValid)
      return !1;
    const r = t.valueOf(), s = this.setZone(t.zone, { keepLocalTime: !0 });
    return s.startOf(n, o) <= r && r <= s.endOf(n, o);
  }
  /**
   * Equality check
   * Two DateTimes are equal if and only if they represent the same millisecond, have the same zone and location, and are both valid.
   * To compare just the millisecond values, use `+dt1 === +dt2`.
   * @param {DateTime} other - the other DateTime
   * @return {boolean}
   */
  equals(t) {
    return this.isValid && t.isValid && this.valueOf() === t.valueOf() && this.zone.equals(t.zone) && this.loc.equals(t.loc);
  }
  /**
   * Returns a string representation of a this time relative to now, such as "in two days". Can only internationalize if your
   * platform supports Intl.RelativeTimeFormat. Rounds down by default.
   * @param {Object} options - options that affect the output
   * @param {DateTime} [options.base=DateTime.now()] - the DateTime to use as the basis to which this time is compared. Defaults to now.
   * @param {string} [options.style="long"] - the style of units, must be "long", "short", or "narrow"
   * @param {string|string[]} options.unit - use a specific unit or array of units; if omitted, or an array, the method will pick the best unit. Use an array or one of "years", "quarters", "months", "weeks", "days", "hours", "minutes", or "seconds"
   * @param {boolean} [options.round=true] - whether to round the numbers in the output.
   * @param {number} [options.padding=0] - padding in milliseconds. This allows you to round up the result if it fits inside the threshold. Don't use in combination with {round: false} because the decimal output will include the padding.
   * @param {string} options.locale - override the locale of this DateTime
   * @param {string} options.numberingSystem - override the numberingSystem of this DateTime. The Intl system may choose not to honor this
   * @example DateTime.now().plus({ days: 1 }).toRelative() //=> "in 1 day"
   * @example DateTime.now().setLocale("es").toRelative({ days: 1 }) //=> "dentro de 1 día"
   * @example DateTime.now().plus({ days: 1 }).toRelative({ locale: "fr" }) //=> "dans 23 heures"
   * @example DateTime.now().minus({ days: 2 }).toRelative() //=> "2 days ago"
   * @example DateTime.now().minus({ days: 2 }).toRelative({ unit: "hours" }) //=> "48 hours ago"
   * @example DateTime.now().minus({ hours: 36 }).toRelative({ round: false }) //=> "1.5 days ago"
   */
  toRelative(t = {}) {
    if (!this.isValid)
      return null;
    const n = t.base || D.fromObject({}, { zone: this.zone }), o = t.padding ? this < n ? -t.padding : t.padding : 0;
    let r = ["years", "months", "days", "hours", "minutes", "seconds"], s = t.unit;
    return Array.isArray(t.unit) && (r = t.unit, s = void 0), mf(n, this.plus(o), {
      ...t,
      numeric: "always",
      units: r,
      unit: s
    });
  }
  /**
   * Returns a string representation of this date relative to today, such as "yesterday" or "next month".
   * Only internationalizes on platforms that supports Intl.RelativeTimeFormat.
   * @param {Object} options - options that affect the output
   * @param {DateTime} [options.base=DateTime.now()] - the DateTime to use as the basis to which this time is compared. Defaults to now.
   * @param {string} options.locale - override the locale of this DateTime
   * @param {string} options.unit - use a specific unit; if omitted, the method will pick the unit. Use one of "years", "quarters", "months", "weeks", or "days"
   * @param {string} options.numberingSystem - override the numberingSystem of this DateTime. The Intl system may choose not to honor this
   * @example DateTime.now().plus({ days: 1 }).toRelativeCalendar() //=> "tomorrow"
   * @example DateTime.now().setLocale("es").plus({ days: 1 }).toRelative() //=> ""mañana"
   * @example DateTime.now().plus({ days: 1 }).toRelativeCalendar({ locale: "fr" }) //=> "demain"
   * @example DateTime.now().minus({ days: 2 }).toRelativeCalendar() //=> "2 days ago"
   */
  toRelativeCalendar(t = {}) {
    return this.isValid ? mf(t.base || D.fromObject({}, { zone: this.zone }), this, {
      ...t,
      numeric: "auto",
      units: ["years", "months", "days"],
      calendary: !0
    }) : null;
  }
  /**
   * Return the min of several date times
   * @param {...DateTime} dateTimes - the DateTimes from which to choose the minimum
   * @return {DateTime} the min DateTime, or undefined if called with no argument
   */
  static min(...t) {
    if (!t.every(D.isDateTime))
      throw new Me("min requires all arguments be DateTimes");
    return Qd(t, (n) => n.valueOf(), Math.min);
  }
  /**
   * Return the max of several date times
   * @param {...DateTime} dateTimes - the DateTimes from which to choose the maximum
   * @return {DateTime} the max DateTime, or undefined if called with no argument
   */
  static max(...t) {
    if (!t.every(D.isDateTime))
      throw new Me("max requires all arguments be DateTimes");
    return Qd(t, (n) => n.valueOf(), Math.max);
  }
  // MISC
  /**
   * Explain how a string would be parsed by fromFormat()
   * @param {string} text - the string to parse
   * @param {string} fmt - the format the string is expected to be in (see description)
   * @param {Object} options - options taken by fromFormat()
   * @return {Object}
   */
  static fromFormatExplain(t, n, o = {}) {
    const { locale: r = null, numberingSystem: s = null } = o, i = V.fromOpts({
      locale: r,
      numberingSystem: s,
      defaultToEN: !0
    });
    return Ky(i, t, n);
  }
  /**
   * @deprecated use fromFormatExplain instead
   */
  static fromStringExplain(t, n, o = {}) {
    return D.fromFormatExplain(t, n, o);
  }
  // FORMAT PRESETS
  /**
   * {@link DateTime#toLocaleString} format like 10/14/1983
   * @type {Object}
   */
  static get DATE_SHORT() {
    return Xi;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983'
   * @type {Object}
   */
  static get DATE_MED() {
    return oy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Fri, Oct 14, 1983'
   * @type {Object}
   */
  static get DATE_MED_WITH_WEEKDAY() {
    return X2;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983'
   * @type {Object}
   */
  static get DATE_FULL() {
    return ry;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Tuesday, October 14, 1983'
   * @type {Object}
   */
  static get DATE_HUGE() {
    return sy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_SIMPLE() {
    return iy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_SECONDS() {
    return ay;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_SHORT_OFFSET() {
    return ly;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get TIME_WITH_LONG_OFFSET() {
    return cy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_SIMPLE() {
    return uy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_SECONDS() {
    return dy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 EDT', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_SHORT_OFFSET() {
    return fy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '09:30:23 Eastern Daylight Time', always 24-hour.
   * @type {Object}
   */
  static get TIME_24_WITH_LONG_OFFSET() {
    return my;
  }
  /**
   * {@link DateTime#toLocaleString} format like '10/14/1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_SHORT() {
    return hy;
  }
  /**
   * {@link DateTime#toLocaleString} format like '10/14/1983, 9:30:33 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_SHORT_WITH_SECONDS() {
    return py;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED() {
    return yy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Oct 14, 1983, 9:30:33 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED_WITH_SECONDS() {
    return gy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Fri, 14 Oct 1983, 9:30 AM'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_MED_WITH_WEEKDAY() {
    return eD;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983, 9:30 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_FULL() {
    return vy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'October 14, 1983, 9:30:33 AM EDT'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_FULL_WITH_SECONDS() {
    return by;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Friday, October 14, 1983, 9:30 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_HUGE() {
    return Iy;
  }
  /**
   * {@link DateTime#toLocaleString} format like 'Friday, October 14, 1983, 9:30:33 AM Eastern Daylight Time'. Only 12-hour if the locale is.
   * @type {Object}
   */
  static get DATETIME_HUGE_WITH_SECONDS() {
    return Ty;
  }
}
function yr(e) {
  if (D.isDateTime(e))
    return e;
  if (e && e.valueOf && Ln(e.valueOf()))
    return D.fromJSDate(e);
  if (e && typeof e == "object")
    return D.fromObject(e);
  throw new Me(
    `Unknown datetime argument: ${e}, of type ${typeof e}`
  );
}
function YR({ collections: e, lww: t }, { tableIds: n, tableColumnIds: o, relationshipIds: r, indexIds: s, indexColumnIds: i, memoIds: a }) {
  y(e).collection("tableEntities").removeMany(n), y(e).collection("tableColumnEntities").removeMany(o), y(e).collection("relationshipEntities").removeMany(r), y(e).collection("indexEntities").removeMany(s), y(e).collection("indexColumnEntities").removeMany(i), y(e).collection("memoEntities").removeMany(a), [
    ...n,
    ...o,
    ...r,
    ...s,
    ...i,
    ...a
  ].forEach((l) => Reflect.deleteProperty(t, l));
}
const KR = 3, QR = $([
  "tableEntities",
  "tableColumnEntities",
  "relationshipEntities",
  "indexEntities",
  "indexColumnEntities",
  "memoEntities"
]);
class oP {
  async run(t) {
    const n = JSON.parse(t), o = qn(n), { doc: { tableIds: r, memoIds: s, indexIds: i, relationshipIds: a }, collections: l, lww: c } = o, u = $(r), d = $(s), f = $(i), m = $(a), p = JR(D.now()), g = y(l).collection("tableEntities"), v = y(l).collection("tableColumnEntities"), T = y(l).collection("indexEntities"), O = y(l).collection("indexColumnEntities"), k = y(l).collection("relationshipEntities"), R = y(l).collection("memoEntities"), ae = new Set(g.selectAll().filter(p(u)).map(({ id: S }) => S)), vt = /* @__PURE__ */ new Set(), bt = new Set(k.selectAll().filter(p(m)).map(({ id: S }) => S)), nt = new Set(T.selectAll().filter(p(f)).map(({ id: S }) => S)), We = /* @__PURE__ */ new Set(), G = new Set(R.selectAll().filter(p(d)).map(({ id: S }) => S));
    v.selectAll().filter(({ tableId: S }) => ae.has(S)).forEach(({ id: S }) => vt.add(S)), k.selectAll().filter(({ id: S, start: It, end: ar }) => !bt.has(S) && (ae.has(It.tableId) || ae.has(ar.tableId))).forEach(({ id: S }) => bt.add(S)), T.selectAll().filter(({ id: S, tableId: It }) => !nt.has(S) && ae.has(It)).forEach(({ id: S }) => nt.add(S)), O.selectAll().filter(({ indexId: S }) => nt.has(S)).forEach(({ id: S }) => We.add(S)), YR(o, {
      tableIds: [...ae],
      tableColumnIds: [...vt],
      relationshipIds: [...bt],
      indexIds: [...nt],
      indexColumnIds: [...We],
      memoIds: [...G]
    });
    const _ = $(g.selectAll().map(({ id: S }) => S)), ot = $(v.selectAll().map(({ id: S }) => S)), Vt = $(T.selectAll().map(({ id: S }) => S)), $t = $(O.selectAll().map(({ id: S }) => S)), tg = $(k.selectAll().map(({ id: S }) => S)), ng = $(R.selectAll().map(({ id: S }) => S));
    return v.selectAll().filter(({ tableId: S, id: It, meta: ar }) => !_(S) && p(() => !1)({ id: It, meta: ar })).forEach(({ id: S }) => vt.add(S)), O.selectAll().filter(({ indexId: S, id: It, meta: ar }) => !Vt(S) && p(() => !1)({ id: It, meta: ar })).forEach(({ id: S }) => We.add(S)), Object.entries(c).forEach(([S, [It]]) => {
      if (QR(It))
        switch (It) {
          case "tableEntities":
            !_(S) && ae.add(S);
            break;
          case "tableColumnEntities":
            !ot(S) && vt.add(S);
            break;
          case "relationshipEntities":
            !tg(S) && bt.add(S);
            break;
          case "indexEntities":
            !Vt(S) && nt.add(S);
            break;
          case "indexColumnEntities":
            !$t(S) && We.add(S);
            break;
          case "memoEntities":
            !ng(S) && G.add(S);
            break;
        }
    }), {
      tableIds: [...ae],
      tableColumnIds: [...vt],
      relationshipIds: [...bt],
      indexIds: [...nt],
      indexColumnIds: [...We],
      memoIds: [...G]
    };
  }
}
const JR = (e) => (t) => ({ id: n, meta: o }) => {
  if (t(n))
    return !1;
  const r = Math.floor(e.diff(D.fromMillis(o.updateAt), "days").toObject().days ?? 0);
  return KR < r;
};
export {
  FL as $,
  M as A,
  $ as B,
  qL as C,
  YL as D,
  nP as E,
  zx as F,
  zL as G,
  VL as H,
  Rd as I,
  Hp as J,
  QS as K,
  UL as L,
  d2 as M,
  m2 as N,
  tt as O,
  f2 as P,
  lu as Q,
  pe as R,
  vs as S,
  Vn as T,
  Eh as U,
  XR as V,
  I as W,
  bL as X,
  uC as Y,
  fs as Z,
  ji as _,
  Ui as a,
  Yk as a$,
  lL as a0,
  sp as a1,
  oL as a2,
  Ct as a3,
  QL as a4,
  JL as a5,
  eP as a6,
  XL as a7,
  tP as a8,
  Bh as a9,
  eu as aA,
  fm as aB,
  eS as aC,
  hp as aD,
  xx as aE,
  Ax as aF,
  zi as aG,
  Ix as aH,
  z$ as aI,
  G$ as aJ,
  D$ as aK,
  wp as aL,
  mp as aM,
  aL as aN,
  W as aO,
  eO as aP,
  dL as aQ,
  uL as aR,
  Jn as aS,
  jh as aT,
  xp as aU,
  Pe as aV,
  Zh as aW,
  U as aX,
  Sx as aY,
  Ex as aZ,
  Sp as a_,
  yL as aa,
  $l as ab,
  Uc as ac,
  vL as ad,
  y as ae,
  hL as af,
  tO as ag,
  eL as ah,
  k2 as ai,
  Pd as aj,
  Bl as ak,
  re as al,
  rL as am,
  vA as an,
  Zi as ao,
  Fh as ap,
  wx as aq,
  SL as ar,
  Cx as as,
  Tx as at,
  $n as au,
  Yh as av,
  YS as aw,
  B$ as ax,
  Uk as ay,
  Xc as az,
  Xw as b,
  fp as b$,
  Kk as b0,
  P as b1,
  Y as b2,
  zh as b3,
  Vh as b4,
  cn as b5,
  j$ as b6,
  q$ as b7,
  cA as b8,
  dA as b9,
  ux as bA,
  l2 as bB,
  $a as bC,
  wo as bD,
  CL as bE,
  NL as bF,
  hx as bG,
  px as bH,
  yx as bI,
  Fk as bJ,
  Wh as bK,
  W1 as bL,
  fe as bM,
  q as bN,
  jp as bO,
  Vk as bP,
  nN as bQ,
  Hk as bR,
  D as bS,
  gp as bT,
  Jc as bU,
  _$ as bV,
  k$ as bW,
  _o as bX,
  Z$ as bY,
  Cd as bZ,
  nu as b_,
  H$ as ba,
  ex as bb,
  iL as bc,
  Ep as bd,
  Cp as be,
  Sa as bf,
  ru as bg,
  ou as bh,
  zk as bi,
  EA as bj,
  Gk as bk,
  fL as bl,
  PL as bm,
  Vr as bn,
  xL as bo,
  AL as bp,
  Qc as bq,
  ty as br,
  cL as bs,
  Xx as bt,
  Wk as bu,
  gc as bv,
  Kc as bw,
  ft as bx,
  wA as by,
  Je as bz,
  s0 as c,
  GL as c$,
  EL as c0,
  os as c1,
  Xk as c2,
  up as c3,
  t2 as c4,
  n2 as c5,
  o2 as c6,
  r2 as c7,
  Jk as c8,
  uN as c9,
  W$ as cA,
  Zk as cB,
  xA as cC,
  rN as cD,
  kL as cE,
  OL as cF,
  hN as cG,
  _N as cH,
  bp as cI,
  Ze as cJ,
  Kh as cK,
  TN as cL,
  wL as cM,
  vN as cN,
  Rb as cO,
  Vl as cP,
  sL as cQ,
  ML as cR,
  DL as cS,
  GM as cT,
  Dl as cU,
  vp as cV,
  wa as cW,
  a2 as cX,
  oP as cY,
  ZL as cZ,
  HL as c_,
  TL as ca,
  iN as cb,
  IL as cc,
  lN as cd,
  mL as ce,
  BL as cf,
  i0 as cg,
  KL as ch,
  fN as ci,
  Bs as cj,
  WL as ck,
  PA as cl,
  P$ as cm,
  L$ as cn,
  Mt as co,
  jL as cp,
  _h as cq,
  ct as cr,
  _L as cs,
  ip as ct,
  qk as cu,
  Yx as cv,
  jk as cw,
  De as cx,
  mA as cy,
  Wc as cz,
  xt as d,
  fx as d0,
  dx as d1,
  fa as d2,
  _c as d3,
  LL as d4,
  we as d5,
  RL as d6,
  $L as d7,
  gL as d8,
  YR as d9,
  MA as da,
  Jw as db,
  ZS as e,
  bs as f,
  ve as g,
  LS as h,
  AS as i,
  $S as j,
  MS as k,
  NS as l,
  kS as m,
  PS as n,
  Zn as o,
  BS as p,
  DS as q,
  _S as r,
  qi as s,
  Ph as t,
  gS as u,
  Ml as v,
  Rh as w,
  Al as x,
  KS as y,
  Zp as z
};
