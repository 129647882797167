import { D as C, E as w, w as A, O as x, G as d, K as E, s as G, bv as h, J as l, Y as I, d4 as O, bu as T, aB as V, d9 as $, da as j, V as k, db as J, cY as R } from "./schemaGCService-AAKKj104.js";
/*!
 * @dineug/erd-editor
 * @version 3.2.7 | Fri Feb 28 2025
 * @author SeungHwan-Lee <dineug2@gmail.com>
 * @license MIT
 */
const Y = {
  change: "change"
};
function B(p) {
  const o = /* @__PURE__ */ new Set(), t = C(p, !1), b = w(t), c = new A(), u = new x((e) => t.subscribe((a) => e.next(a))).pipe(d(l), E(200)), n = /* @__PURE__ */ new Set(), m = new R(), S = (e) => (n.has(e) || n.add(e), () => {
    n.delete(e);
  }), f = (e, a) => {
    n.forEach((s) => {
      const r = Reflect.get(s, e);
      I(r, a);
    });
  }, g = (e) => {
    const a = O(e);
    t.dispatchSync(T(V(a) ? "{}" : a)), m.run(h(t.state)).then((s) => {
      (s.tableIds.length || s.tableColumnIds.length || s.relationshipIds.length || s.indexIds.length || s.indexColumnIds.length || s.memoIds.length) && ($(t.state, s), t.dispatchSync(j()));
    });
  }, i = (e) => {
    c.next([e].flat());
  }, y = (e) => {
    k(() => i(e));
  }, v = () => {
    Array.from(o).forEach((e) => e.unsubscribe()), o.clear(), n.clear(), t.destroy(), b.destroy();
  };
  return o.add(u.subscribe(() => f(Y.change, void 0))).add(c.pipe(d(l), G((e) => e.map((a) => J(a, "tags")))).subscribe(t.dispatchSync)), Object.freeze({
    get value() {
      return h(t.state);
    },
    on: S,
    setInitialValue: g,
    dispatch: y,
    dispatchSync: i,
    destroy: v
  });
}
export {
  B as createReplicationStore
};
